/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { NodeViewWrapper } from "@tiptap/react";
import { FileCard } from "../../../../File";

export const FileCardNodeViewComponent = (props: any) => {
  return (
    <NodeViewWrapper className="w-fit">
      <FileCard
        url={props.node.attrs.url}
        fileName={props.node.attrs.fileName}
        fileSizeBytes={props.node.attrs.fileSizeBytes}
        fileExtension={props.node.attrs.fileExtension}
      />
    </NodeViewWrapper>
  );
};
