import { Page } from "@whyuz/components";
import { User } from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { Modal, ModalProps } from "flowbite-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserCombobox } from "../../Users";

interface AddMemberModalProps {
  modalProps: ModalProps;
  addMember: (userId: string, mail: string) => void;
  onClose: () => void;
}

export const AddMemberModal = ({ modalProps, onClose, addMember }: AddMemberModalProps) => {
  const { t } = useTranslation();
  const { t: tChat } = useTranslation("chat");
  const [mail, setMail] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const addMemberValidation = () => {
    if (mail === "" && userId === "") {
      notifyError(tChat("requiredmail"));
    } else {
      addMember(userId, mail);
      setMail("");
    }
  };

  return (
    <Modal popup {...modalProps} onClose={onClose}>
      <Modal.Header className="p-6">{tChat("addmember")}</Modal.Header>
      <Modal.Body>
        <Page.FormContainer>
          {/* TODO: Support in one single component both mail and user */}
          <div className="mb-1 dark:text-white">{tChat("addmemberbymail")}</div>
          <Page.FormTextInputField
            id="mail"
            value={mail}
            label={t("word.email")}
            error={undefined}
            onChange={(e) => setMail(e)}
          />
          <div className="mt-4 mb-1 dark:text-white">{tChat("ormessage")}</div>
          <UserCombobox
            id="userId"
            selectedItemId={userId}
            label={t("word.user")}
            placeholder={tChat("selectuser")}
            disabled={false}
            addTextOption
            error={undefined}
            onManualText={(value) => setUserId(value)}
            onChange={(user: User | null) => {
              if (user?.id) {
                setUserId(user.id as string);
              }
            }}
          />
        </Page.FormContainer>
        <Page.PanelFooterContainer>
          <Page.SaveButton text={t("buttons.save")} onSave={addMemberValidation} />
        </Page.PanelFooterContainer>
      </Modal.Body>
    </Modal>
  );
};
