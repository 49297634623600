import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { TimeFilter } from "../../Date";

export interface AdvancedTableTimeFilterProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
}

export const AdvancedTableTimeFilter = <T extends EntityId, Filter extends T>({
  tableState,
}: AdvancedTableTimeFilterProps<T, Filter>) => {
  return (
    <TimeFilter
      onChange={(filterSinceDate?: string) => tableState.setFilters({ ...tableState.filters, filterSinceDate })}
    />
  );
};
