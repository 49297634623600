import { API_REST_ENDPOINT } from "@whyuz/data";
import {
  SuccessCaseContentItem,
  useCollaboratorAddAssetToSuccessCaseContentItemMutation,
  useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation,
  useServerFiles,
} from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorQuestionContainer } from "../SuccessCaseContentEditorQuestionContainer";
import { SuccessCaseContentEditorQuestionInstructions } from "../SuccessCaseContentEditorQuestionInstructions";
import { SuccessCaseContentEditorQuestionTitle } from "../SuccessCaseContentEditorQuestionTitle";
import { SuccessCaseContentEditorQuestionVideoCameraOrFile } from "./SuccessCaseContentEditorQuestionVideoCameraOrFile";
import { SuccessCaseContentEditorQuestionVideoPlayer } from "./SuccessCaseContentEditorQuestionVideoPlayer";
import { SuccessCaseContentEditorQuestionVideoRecorder } from "./SuccessCaseContentEditorQuestionVideoRecorder";
import { SuccessCaseContentEditorQuestionVideoUpload } from "./SuccessCaseContentEditorQuestionVideoUpload";

export interface SuccessCaseContentEditorQuestionVideoProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled?: boolean;
}

enum VIDEO_GENERATION_METHOD {
  CAMERA,
  UPLOAD_FILE,
}

export const SuccessCaseContentEditorQuestionVideo = ({
  tenantId,
  question,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionVideoProps) => {
  const [cameraOrUploadFile, setCameraOrUploadFile] = useState<VIDEO_GENERATION_METHOD>();
  const { t: tContentItem } = useTranslation("contentItem");
  const serverFiles = useServerFiles();
  const [addAssetMutation] = useCollaboratorAddAssetToSuccessCaseContentItemMutation();
  const [removeAssetMutation] = useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation();

  const onFileAdded = useCallback(
    (fileAdded: File) => {
      const uploadVideo = () => {
        serverFiles
          .uploadFileAndGetAsset(fileAdded, false, tenantId)
          .then((response) => {
            addAssetMutation({
              variables: { contentItemId: question.id as string, assetId: response.data.id as string, tenantId },
            })
              .then((newQuestion) => {
                onQuestionUpdated(newQuestion);
              })
              .catch((e) => {
                notifyError(tContentItem("errors.attachingfile") + " " + fileAdded.name + ": " + (e as string));
              });
          })
          .catch((e) => {
            notifyError(tContentItem("errors.attachingfile") + " " + fileAdded.name + ": " + (e as string));
          });
      };

      if (question.assets && question.assets.length > 0) {
        removeAssetMutation({
          variables: {
            contentItemId: question.id as string,
            assetId: question.assets[0]?.id as string,
            deleteAsset: true,
            tenantId,
          },
        })
          .then(uploadVideo)
          .catch((e) => notifyError(tContentItem("errors.deletingattachedfile") + ": " + (e as string)));
      } else {
        uploadVideo();
      }
    },
    [
      addAssetMutation,
      onQuestionUpdated,
      question.assets,
      question.id,
      removeAssetMutation,
      serverFiles,
      tContentItem,
      tenantId,
    ],
  );

  const videoAsset = useMemo(() => {
    if (!question.assets || question.assets.length === 0) {
      return undefined;
    }

    return question.assets[0];
  }, [question.assets]);

  const onDeleteVideo = useCallback(() => {
    if (videoAsset) {
      removeAssetMutation({
        variables: {
          contentItemId: question.id as string,
          assetId: videoAsset.id as string,
          deleteAsset: true,
          tenantId,
        },
      })
        .then((newQuestion) => {
          onQuestionUpdated(newQuestion);
          setCameraOrUploadFile(undefined);
        })
        .catch((e) => notifyError(tContentItem("errors.deletingattachedfile") + ": " + (e as string)));
    }
  }, [videoAsset, removeAssetMutation, question.id, tenantId, onQuestionUpdated, tContentItem]);

  const videoURL = useMemo(() => {
    if (!videoAsset) {
      return undefined;
    }

    return videoAsset?.accessToken ? API_REST_ENDPOINT.ASSETS + "?token=" + videoAsset?.accessToken : undefined;
  }, [videoAsset]);

  const componentToShow = useMemo(() => {
    if (videoURL || disabled) {
      return (
        <SuccessCaseContentEditorQuestionVideoPlayer
          src={videoURL}
          disabled={disabled}
          onVideoTrimmed={onFileAdded}
          onDeleteVideo={onDeleteVideo}
        />
      );
    }

    switch (cameraOrUploadFile) {
      case VIDEO_GENERATION_METHOD.CAMERA:
        return (
          <SuccessCaseContentEditorQuestionVideoRecorder
            onVideoAdded={onFileAdded}
            disabled={disabled}
            maxSizeInSeconds={question.contentMaxSize ?? undefined}
            onCancel={() => setCameraOrUploadFile(undefined)}
          />
        );
      case VIDEO_GENERATION_METHOD.UPLOAD_FILE:
        return (
          <SuccessCaseContentEditorQuestionVideoUpload
            onVideoAdded={onFileAdded}
            disabled={disabled}
            maxSizeInSeconds={question.contentMaxSize ?? undefined}
          />
        );
      default:
        return (
          <SuccessCaseContentEditorQuestionVideoCameraOrFile
            onUseCamera={() => setCameraOrUploadFile(VIDEO_GENERATION_METHOD.CAMERA)}
            onUploadFile={() => setCameraOrUploadFile(VIDEO_GENERATION_METHOD.UPLOAD_FILE)}
          />
        );
    }
  }, [question.contentMaxSize, cameraOrUploadFile, disabled, onDeleteVideo, onFileAdded, videoURL]);

  return (
    <SuccessCaseContentEditorQuestionContainer
      className={cameraOrUploadFile !== VIDEO_GENERATION_METHOD.UPLOAD_FILE ? "sm:max-w-max" : undefined}>
      <SuccessCaseContentEditorQuestionTitle title={question.title as string} />
      <SuccessCaseContentEditorQuestionInstructions instructions={question.htmlInstructions as string} />
      {componentToShow}
    </SuccessCaseContentEditorQuestionContainer>
  );
};
