import {
  AdvancedTableColumnConfiguration,
  AdvancedTableConfiguration,
  AdvancedTableState,
  DebouncedTextInput,
  FlowbiteDropdownThemes,
  FlowbiteTextInputThemes,
  useWebPageNavigation,
} from "@whyuz/components";
import { WHYUZ_DYNAMIC_FRONTEND_URL } from "@whyuz/data";
import {
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLSort,
  SortDirection,
  Tenant,
  UserLicense,
  UserRole,
  UserTenantRole,
  useUser,
} from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRolesDropdown } from "../UserRolesDropdown";
import { useUserRoleTranslations } from "./useUserRoleTranslations";

export const USER_TENANT_ROLES_TABLE_COLUMNS = {
  FIRST_NAME: "firstname",
  LAST_NAME: "lastname",
  MAIL: "mail",
  ROLE: "role",
  COMPANY_NAME: "companyname",
  CORPORATION_NAME: "corporationname",
};

export const useUserTenantRolesTableConfiguration = () => {
  const { t: tUserTenantRole } = useTranslation("usertenantrole");
  const userCtx = useUser();
  const { navigate } = useWebPageNavigation();
  const userRoleTranslations = useUserRoleTranslations();

  const columnUserFirstName: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.FIRST_NAME,
      entityField: "user.firstName",
      columnName: tUserTenantRole("userlicense.firstname"),
      position: 1,
      isVisible: true,
      isMobileVisible: false,
      boldText: true,
      onPointerDown: (userRole) => navigate(WHYUZ_DYNAMIC_FRONTEND_URL.userTenantRole.view(userRole.id as string)),
      enableFilter: true,
      renderColumnFilter: (tableState: AdvancedTableState<UserTenantRole, UserTenantRole>) => (
        <DebouncedTextInput
          id="user.firstName"
          type="search"
          theme={FlowbiteTextInputThemes.underlined}
          value={tableState.filters.user?.firstName as string}
          onChange={(entityFilterValue) => {
            tableState.setFilters((prevFilter) => {
              const userLicense = entityFilterValue
                ? { ...(prevFilter.user as UserLicense), firstName: entityFilterValue }
                : { ...(prevFilter.user as UserLicense), firstName: undefined };
              return { ...prevFilter, userLicense };
            });
          }}
        />
      ),
    };
  }, [navigate, tUserTenantRole]);

  const columnUserLastName: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.LAST_NAME,
      entityField: "user.lastName",
      columnName: tUserTenantRole("userlicense.lastname"),
      position: 2,
      isVisible: true,
      isMobileVisible: false,
      boldText: true,
      onPointerDown: (userRole) => navigate(WHYUZ_DYNAMIC_FRONTEND_URL.userTenantRole.view(userRole.id as string)),
      enableFilter: true,
      renderColumnFilter: (tableState: AdvancedTableState<UserTenantRole, UserTenantRole>) => (
        <DebouncedTextInput
          id="user.lastName"
          type="search"
          theme={FlowbiteTextInputThemes.underlined}
          value={tableState.filters.user?.lastName as string}
          onChange={(entityFilterValue) => {
            tableState.setFilters((prevFilter) => {
              const userLicense = entityFilterValue
                ? { ...(prevFilter.userLicense as UserLicense), lastName: entityFilterValue }
                : { ...(prevFilter.userLicense as UserLicense), lastName: undefined };
              return { ...prevFilter, userLicense };
            });
          }}
        />
      ),
    };
  }, [navigate, tUserTenantRole]);

  const columnUserMail: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.MAIL,
      entityField: "user.mail",
      columnName: tUserTenantRole("mail"),
      position: 3,
      isVisible: true,
      isMobileVisible: true,
      boldText: true,
      onPointerDown: (userRole) => navigate(WHYUZ_DYNAMIC_FRONTEND_URL.userTenantRole.view(userRole.id as string)),
      enableFilter: true,
      renderColumnFilter: (tableState: AdvancedTableState<UserTenantRole, UserTenantRole>) => (
        <DebouncedTextInput
          id="user.mail"
          type="search"
          theme={FlowbiteTextInputThemes.underlined}
          value={tableState.filters.user?.mail as string}
          onChange={(entityFilterValue) => {
            tableState.setFilters((prevFilter) => {
              const userLicense = entityFilterValue
                ? { ...(prevFilter.userLicense as UserLicense), mail: entityFilterValue }
                : { ...(prevFilter.userLicense as UserLicense), mail: undefined };
              return { ...prevFilter, userLicense };
            });
          }}
        />
      ),
    };
  }, [navigate, tUserTenantRole]);

  const columnUserRole: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.ROLE,
      entityField: "role",
      columnName: tUserTenantRole("role"),
      position: 4,
      isVisible: true,
      isMobileVisible: false,
      boldText: true,
      onPointerDown: (userRole) => navigate(WHYUZ_DYNAMIC_FRONTEND_URL.userTenantRole.view(userRole.id as string)),
      enableFilter: true,
      cellContent: (userRole) => userRoleTranslations[userRole.role as UserRole],
      renderColumnFilter: (tableState: AdvancedTableState<UserTenantRole, UserTenantRole>) => (
        <div className="border-0 border-b-2 py-3 border-gray-300 text-gray-900 dark:text-white dark:border-gray-600 whitespace-nowrap text-sm">
          <UserRolesDropdown
            showItemAll={true}
            label={tableState.filters.role ? userRoleTranslations[tableState.filters.role] : undefined}
            theme={FlowbiteDropdownThemes.underline}
            inline
            onChangeUserRole={(role) =>
              tableState.setFilters((prev) => {
                return { ...prev, role };
              })
            }
          />
        </div>
      ),
    };
  }, [navigate, tUserTenantRole, userRoleTranslations]);

  const columnCompanyName: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.COMPANY_NAME,
      entityField: "tenant.name",
      columnName: tUserTenantRole("company"),
      position: 5,
      isVisible: true,
      isMobileVisible: false,
      boldText: true,
      onPointerDown: (userRole) => navigate(WHYUZ_DYNAMIC_FRONTEND_URL.tenant.view(userRole.tenant?.id as string)),
      renderColumnFilter: (tableState: AdvancedTableState<UserTenantRole, UserTenantRole>) => (
        <DebouncedTextInput
          id="tenant.name"
          type="search"
          theme={FlowbiteTextInputThemes.underlined}
          value={tableState.filters.tenant?.name as string}
          onChange={(entityFilterValue) => {
            tableState.setFilters((prevFilter) => {
              const tenant = entityFilterValue
                ? { ...(prevFilter.tenant as Tenant), name: entityFilterValue }
                : { ...(prevFilter.tenant as Tenant), name: undefined };
              return { ...prevFilter, tenant };
            });
          }}
        />
      ),
      enableFilter: true,
    };
  }, [navigate, tUserTenantRole]);

  const columnCorporationName: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      columnKey: USER_TENANT_ROLES_TABLE_COLUMNS.CORPORATION_NAME,
      entityField: "tenant.corporationLicense.name",
      columnName: tUserTenantRole("corporation"),
      position: 6,
      isVisible: true,
      isMobileVisible: false,
      boldText: true,
      onPointerDown: (userRole) =>
        navigate(WHYUZ_DYNAMIC_FRONTEND_URL.corporation.view(userRole.tenant?.corporationLicense?.id as string)),
      enableFilter: true,
      disableOrder: true,
    };
  }, [navigate, tUserTenantRole]);

  const tableColumnsConfiguration: AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole>[] = useMemo(
    (): AdvancedTableColumnConfiguration<UserTenantRole, UserTenantRole>[] => [
      columnUserFirstName,
      columnUserLastName,
      columnUserMail,
      columnUserRole,
      columnCompanyName,
      columnCorporationName,
    ],
    [columnCompanyName, columnCorporationName, columnUserFirstName, columnUserLastName, columnUserMail, columnUserRole],
  );

  const enableMultipleSelection = useMemo(
    () => userCtx.isUserSuperAdmin || userCtx.isUserCurrentCorporationAdministrator,
    [userCtx.isUserCurrentCorporationAdministrator, userCtx.isUserSuperAdmin],
  );

  const tableConfiguration: AdvancedTableConfiguration<UserTenantRole, UserTenantRole> = useMemo(() => {
    return {
      title: tUserTenantRole("users"),
      entityNameSingular: tUserTenantRole("user"),
      entityNamePlural: tUserTenantRole("users"),
      entityDescription: (userRole: UserTenantRole) => userRole.user?.mail as string,
      enableColumnManagement: true,
      enableMultipleSelection,
      showRowActions: true,
      cleanSelectedEntitiesOnFilterChange: false,
      cleanSelectedEntitiesOnSearchChange: false,
      columnsDefinition: tableColumnsConfiguration,
    };
  }, [enableMultipleSelection, tUserTenantRole, tableColumnsConfiguration]);

  return tableConfiguration;
};

export const userTenantRolesTableFilterExpression = (filters: UserTenantRole) => {
  const filterFields: FilterField[] = [];
  const filterExpressions: FilterExpression[] = [];

  if (filters.user?.firstName) {
    filterFields.push({
      field: "user.firstName",
      operator: FilterOperator.Like,
      value: "%" + filters.user?.firstName + "%",
    });
  }

  if (filters.user?.lastName) {
    filterFields.push({
      field: "user.lastName",
      operator: FilterOperator.Like,
      value: "%" + filters.user?.lastName + "%",
    });
  }

  if (filters.user?.mail) {
    filterFields.push({
      field: "user.mail",
      operator: FilterOperator.Like,
      value: "%" + filters.user?.mail + "%",
    });
  }

  if (filters.role) {
    filterFields.push({
      field: "role",
      operator: FilterOperator.Equal,
      value: filters.role,
    });
  }

  if (filters.tenant?.name) {
    filterFields.push({
      field: "tenant.name",
      operator: FilterOperator.Like,
      value: "%" + filters.tenant.name + "%",
    });
  }

  if (filters.tenant?.corporationLicense?.name) {
    filterFields.push({
      field: "tenant.corporationLicense.name",
      operator: FilterOperator.Like,
      value: "%" + filters.tenant.corporationLicense.name + "%",
    });
  }

  return filterExpressions.length > 0 || filterFields.length > 0
    ? ({
        operator: FilterExpressionOperator.And,
        fields: filterFields.length > 0 ? filterFields : undefined,
        expressions: filterExpressions.length > 0 ? filterExpressions : undefined,
      } as FilterExpression)
    : undefined;
};

export const filterAndSortUserTenantRolesToShow = (
  userRoles: UserTenantRole[] | undefined,
  filters: UserTenantRole | undefined,
  includeDeleted: boolean,
  orderBy: GQLSort<UserTenantRole> | undefined,
) =>
  [...(userRoles ?? [])]
    .filter(
      (actUserTenantRole) =>
        (includeDeleted || !actUserTenantRole.deleted) &&
        (!filters ||
          ((!filters.user?.firstName ||
            actUserTenantRole.user?.firstName?.toLowerCase().includes(filters.user?.firstName.toLowerCase())) &&
            (!filters.user?.lastName ||
              actUserTenantRole.user?.lastName?.toLowerCase().includes(filters.user?.lastName.toLowerCase())) &&
            (!filters.user?.mail ||
              actUserTenantRole.user?.mail?.toLowerCase().includes(filters.user?.mail.toLowerCase())) &&
            (!filters.role || actUserTenantRole.role === filters.role) &&
            (!filters.tenant?.name ||
              actUserTenantRole.tenant?.name?.toLowerCase().includes(filters.tenant?.name.toLowerCase())) &&
            (!filters.tenant?.corporationLicense?.name ||
              actUserTenantRole.tenant?.corporationLicense?.name
                ?.toLowerCase()
                .includes(filters.tenant?.corporationLicense?.name.toLowerCase())))),
    )
    .sort((userRole1, userRole2) =>
      orderBy?.field
        ? String(getPropertyByPath(userRole1, orderBy.field)).localeCompare(
            String(getPropertyByPath(userRole2, orderBy.field)),
          ) < 0
          ? orderBy.sortDirection === SortDirection.Asc
            ? 1
            : -1
          : orderBy.sortDirection === SortDirection.Asc
            ? -1
            : 1
        : userRole1?.createdOn && userRole2?.createdOn
          ? String(userRole1.createdOn).localeCompare(String(userRole2.createdOn))
          : 1,
    );
