import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiRedo } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonRedoProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonRedo = ({ className, children }: TextEditorMenuButtonRedoProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  return (
    <Tooltip content={t("buttons.redo")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().redo().run();
          }
        }}
        className={twMerge(buttonStyleHiddenOnMobile, className)}
        disabled={disabled}
      >
        {children ?? <BiRedo className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
