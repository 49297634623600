import { DropImageZone } from "@whyuz/components";
import { API_REST_ENDPOINT } from "@whyuz/data";
import {
  SuccessCaseContentItem,
  useCollaboratorAddAssetToSuccessCaseContentItemMutation,
  useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation,
  useServerFiles,
} from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorQuestionContainer } from "./SuccessCaseContentEditorQuestionContainer";
import { SuccessCaseContentEditorQuestionInstructions } from "./SuccessCaseContentEditorQuestionInstructions";
import { SuccessCaseContentEditorQuestionTitle } from "./SuccessCaseContentEditorQuestionTitle";

export interface SuccessCaseContentEditorQuestionImageProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled?: boolean;
}

export const SuccessCaseContentEditorQuestionImage = ({
  tenantId,
  question,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionImageProps) => {
  const { t: tContentItem } = useTranslation("contentItem");
  const serverFiles = useServerFiles();
  const [addAssetMutation] = useCollaboratorAddAssetToSuccessCaseContentItemMutation();
  const [removeAssetMutation] = useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation();

  const onFileAdded = useCallback(
    (fileAdded: File) => {
      const uploadImage = () => {
        serverFiles
          .uploadFileAndGetAsset(fileAdded, false, tenantId)
          .then((response) => {
            addAssetMutation({
              variables: { contentItemId: question.id as string, assetId: response.data.id as string, tenantId },
            })
              .then((newQuestion) => {
                onQuestionUpdated(newQuestion);
              })
              .catch((e) => {
                notifyError(tContentItem("errors.attachingfile") + " " + fileAdded.name + ": " + (e as string));
              });
          })
          .catch((e) => {
            notifyError(tContentItem("errors.attachingfile") + " " + fileAdded.name + ": " + (e as string));
          });
      };

      if (question.assets && question.assets.length > 0) {
        removeAssetMutation({
          variables: {
            contentItemId: question.id as string,
            assetId: question.assets[0]?.id as string,
            deleteAsset: true,
            tenantId,
          },
        })
          .then(uploadImage)
          .catch((e) => notifyError(tContentItem("errors.deletingattachedfile") + ": " + (e as string)));
      } else {
        uploadImage();
      }
    },
    [
      addAssetMutation,
      onQuestionUpdated,
      question.assets,
      question.id,
      removeAssetMutation,
      serverFiles,
      tContentItem,
      tenantId,
    ],
  );

  const imageURL = useMemo(() => {
    if (!question.assets || question.assets.length === 0) {
      return undefined;
    }

    const asset = question.assets[0];
    return asset?.accessToken ? API_REST_ENDPOINT.ASSETS + "?token=" + asset?.accessToken : undefined;
  }, [question.assets]);

  return (
    <SuccessCaseContentEditorQuestionContainer>
      <SuccessCaseContentEditorQuestionTitle title={question.title as string} />
      <SuccessCaseContentEditorQuestionInstructions instructions={question.htmlInstructions as string} />
      <DropImageZone
        maxSizeInBytes={question.contentMaxSize ? question.contentMaxSize * 1024 * 1024 : undefined}
        imageURL={imageURL}
        disabled={disabled}
        theme={{ placeholder: "border-primary-300 bg-primary-50 hover:bg-primary-100 h-[33vh]" }}
        onImageAdded={onFileAdded}
      />
    </SuccessCaseContentEditorQuestionContainer>
  );
};
