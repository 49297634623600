import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import { Button, Error404PageNotFound, Page, useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import {
  ContentType,
  SuccessCase,
  SuccessCaseContentItem,
  SuccessCaseStatus,
  useCollaboratorSuccessCaseAskForReviewMutation,
  useUser,
} from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ChatComponent } from "../../Chat";
import { SuccessCaseContentEditorQuestionsEditor } from "./QuestionsEditor";
import { SuccessCaseContentEditorQuestion } from "./QuestionsEditor/Question";
import { SuccessCaseContentEditorSidebar } from "./QuestionsSidebar";
import { SuccessCaseContentEditorSidebarQuestionCard } from "./QuestionsSidebar/SuccessCaseContentEditorSidebarQuestionCard";
import { notifyError, notifyInfo } from "@whyuz/utils";
import { useState } from "react";

export interface SuccessCaseContentEditorProps {
  successCase: SuccessCase;
  tenantId: string;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
}

export const SuccessCaseContentEditor = ({
  successCase,
  tenantId,
  onQuestionUpdated,
}: SuccessCaseContentEditorProps) => {
  const userCtx = useUser();
  const { t: tSuccessCaseContentEditor } = useTranslation("successcasecontenteditor");
  const { isPossibleNavigateBack } = useWebPageNavigation();
  const [searchParams, setSearchParams] = useSearchParams({ [WHYUZ_URL_PARAMETERS.TAB]: "0" });
  const [successCaseAskForReviewMutation] = useCollaboratorSuccessCaseAskForReviewMutation();

  const [successCaseStatus, setSuccessCaseStatus] = useState<SuccessCaseStatus>(
    successCase.status as SuccessCaseStatus,
  );
  const selectedQuestionIndex = isNaN(Number(searchParams.get(WHYUZ_URL_PARAMETERS.TAB)))
    ? 0
    : Number(searchParams.get(WHYUZ_URL_PARAMETERS.TAB));

  if (!successCase || (userCtx.isUserSuperAdmin && successCase.deleted)) {
    return <Error404PageNotFound />;
  }

  const handlerSuccessCaseSendToReview = () => {
    successCaseAskForReviewMutation({
      variables: { id: successCase?.id as string, tenantId: tenantId },
    })
      .then((res) => {
        setSuccessCaseStatus(res.status as SuccessCaseStatus);
        notifyInfo(tSuccessCaseContentEditor("messages.succeed.sent"));
      })
      .catch(() => {
        notifyError(tSuccessCaseContentEditor("messages.error.general"));
      });
  };

  const isUserMainSuccessCaseContact = () => {
    return successCase?.mainContact?.mail === userCtx.userLicense?.mail;
  };
  const handlerQuestionUpdated = (updatedQuestion: SuccessCaseContentItem) => {
    if (successCaseStatus === SuccessCaseStatus.CustomerInvited) {
      setSuccessCaseStatus(SuccessCaseStatus.InProgress);
    }
    onQuestionUpdated(updatedQuestion);
  };

  return (
    <Page className="flex flex-col h-full">
      {successCase && (
        <>
          <Page.NavigationPanel requestDiscardChanges={false} />
          <Page.TitlePanel
            title={successCase.title as string}
            icon={<CheckBadgeIcon className="w-8 h-8 text-primary-600" />}>
            {isUserMainSuccessCaseContact() &&
              (successCaseStatus === SuccessCaseStatus.InProgress ||
                successCaseStatus === SuccessCaseStatus.CustomerInvited) && (
                <Button
                  type="button"
                  size="sm"
                  breakpointSM={false}
                  color="green"
                  onPointerDown={handlerSuccessCaseSendToReview}
                  leftIcon={<PlayIcon className="h-4 w-4 stroke-2 self-center" />}>
                  {tSuccessCaseContentEditor("button.send")}
                </Button>
              )}
          </Page.TitlePanel>
          <div
            className={`flex flex-row flex-1 ${isPossibleNavigateBack ? "max-h-[calc(100vh-172px)]" : "max-h-[calc(100vh-144px)]"} overflow-hidden`}>
            <SuccessCaseContentEditorSidebar>
              {successCase.contentItems?.map((contentItem, index) => (
                <SuccessCaseContentEditorSidebarQuestionCard
                  key={index}
                  type={contentItem?.contentType as ContentType}
                  title={contentItem?.title as string}
                  isSelected={selectedQuestionIndex === index}
                  onSelected={() =>
                    setSearchParams(
                      (prev) => {
                        prev.set(WHYUZ_URL_PARAMETERS.TAB, String(index));
                        return prev;
                      },
                      { replace: true },
                    )
                  }
                />
              ))}
            </SuccessCaseContentEditorSidebar>
            <SuccessCaseContentEditorQuestionsEditor
              enablePreviousQuestion={selectedQuestionIndex !== 0}
              onPreviousQuestion={() =>
                setSearchParams(
                  (prev) => {
                    prev.set(
                      WHYUZ_URL_PARAMETERS.TAB,
                      String(Math.min(selectedQuestionIndex - 1, successCase.contentItems?.length ?? 0)),
                    );
                    return prev;
                  },
                  { replace: true },
                )
              }
              enableNextQuestion={
                selectedQuestionIndex !== (successCase.contentItems?.length ? successCase.contentItems.length - 1 : 0)
              }
              onNextQuestion={() =>
                setSearchParams(
                  (prev) => {
                    prev.set(WHYUZ_URL_PARAMETERS.TAB, String(Math.max(selectedQuestionIndex + 1, 0)));
                    return prev;
                  },
                  { replace: true },
                )
              }>
              {successCase.contentItems && (
                <SuccessCaseContentEditorQuestion
                  disabled={
                    successCaseStatus === SuccessCaseStatus.Finished ||
                    successCaseStatus === SuccessCaseStatus.PendingCustomerContentApproval
                  }
                  tenantId={tenantId}
                  question={successCase.contentItems[selectedQuestionIndex] as SuccessCaseContentItem}
                  onQuestionUpdated={handlerQuestionUpdated}
                />
              )}
            </SuccessCaseContentEditorQuestionsEditor>
            {successCase.chat?.id && (
              <ChatComponent
                disabled={
                  successCaseStatus === SuccessCaseStatus.Finished ||
                  successCaseStatus === SuccessCaseStatus.PendingCustomerContentApproval
                }
                chat={successCase.chat}
                tenantId={tenantId}
                className={`hidden sm:flex border-l border-gray-200 dark:border-gray-600 w-96 ${isPossibleNavigateBack ? "max-h-[calc(100vh-176px)]" : "max-h-[calc(100vh-148px)]"}`}
              />
            )}
          </div>
        </>
      )}
    </Page>
  );
};
