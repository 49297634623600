import React from "react";
import { useTranslation } from "react-i18next";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";

export interface AdvancedTableFilterContainerProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
  children?: React.ReactNode;
}

export const AdvancedTableFilterContainer = <T extends EntityId, Filter extends T>({
  tableState,
  children,
}: AdvancedTableFilterContainerProps<T, Filter>) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full md:w-fit md:flex-row items-start md:items-center justify-between md:space-x-4">
      <span className="flex flex-row text-base font-semibold items-center dark:text-white">{t("word.filter")}</span>
      <div
        className={
          tableState.isShowingAllFilters
            ? "flex flex-col w-full md:w-fit md:space-x-4 space-y-4 mt-0 md:space-y-0 md:flex-row items-center justify-center"
            : "hidden md:flex md:flex-row md:items-center space-x-4"
        }>
        {children}
      </div>
    </div>
  );
};
