import { graphql } from "../../codegen/gql";

const currencies = graphql(`
  query currencies(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    currencies(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        symbol
        en
        es
        it
        fr
        de
        pt
      }
    }
  }
`);

export const CurrencyGQL = {
  query: { currencies },
  mutation: {},
};
