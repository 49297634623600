import { ENDPOINT, KEYCLOAK } from "@whyuz/data";
import { jwtDecode } from "jwt-decode";
import Keycloak from "keycloak-js";
import React, { createContext, useMemo, useRef, useState } from "react";

export type KeycloakContextType = {
  keycloak: Keycloak;
  initialized: boolean;
  getPersonalInformation: () => KeycloakPersonalInformation | undefined;
};

export const KeycloakContext = createContext<KeycloakContextType | null>(null);

export interface KeycloakToken {
  picture?: string;
  family_name?: string;
  name?: string;
  given_name?: string;
  email: string;
  exp: number;
}

export interface KeycloakPersonalInformation {
  mail?: string;
  firstName?: string;
  lastName?: string;
  pictureURL?: string;
}

export interface KeycloakContextProviderProps {
  clientId: string;
  children: React.ReactNode;
}

export const KeycloakContextProvider = ({ clientId, children }: KeycloakContextProviderProps) => {
  const [initialized, setInitialized] = useState(false);
  const keycloakRef = useRef<Keycloak>(
    new Keycloak({
      url: ENDPOINT.KEYCLOAK,
      realm: KEYCLOAK.REALM,
      clientId,
    }),
  );
  keycloakRef.current.onTokenExpired = () => {
    keycloakRef.current.updateToken(60 * 15).catch((e) => console.log(e));
  };
  const isInitializingRef = useRef<boolean>(false);

  useMemo(() => {
    if (!isInitializingRef.current && !initialized) {
      isInitializingRef.current = true;
      keycloakRef.current
        .init({
          //onLoad: "check-sso",
          //onLoad: "login-required",
          enableLogging: process.env.NODE_ENV === "development",
          checkLoginIframe: false,
          //silentCheckSsoFallback: false,
          //silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
          pkceMethod: "S256",
        })
        .then(() => {
          setInitialized(() => true);
        })
        .catch((e) => {
          setInitialized(() => false);
          console.log(e);
        })
        .finally(() => (isInitializingRef.current = false));
    }
  }, [initialized]);

  const getPersonalInformation = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const decodedKeycloakToken: KeycloakToken | undefined = keycloakRef.current.token
      ? jwtDecode(keycloakRef.current.token)
      : undefined;
    if (!decodedKeycloakToken) {
      return undefined;
    }

    const personalInfo: KeycloakPersonalInformation = {};

    const nameWords: string[] = decodedKeycloakToken.given_name ? decodedKeycloakToken.given_name.split("\\s+") : [];
    if (decodedKeycloakToken.given_name != null) {
      personalInfo.firstName = decodedKeycloakToken.given_name.trim();
    } else {
      personalInfo.firstName = nameWords[0];
    }

    if (decodedKeycloakToken.family_name != null) {
      personalInfo.lastName = decodedKeycloakToken.family_name.trim();
    } else {
      const builder: string[] = [];
      for (let i = 1; i < nameWords.length; i++) {
        builder.push(nameWords[i]);
      }
      personalInfo.lastName = builder.join(" ").trim();
    }

    return personalInfo;
  };

  return (
    <KeycloakContext.Provider value={{ initialized, keycloak: keycloakRef.current, getPersonalInformation }}>
      {children}
    </KeycloakContext.Provider>
  );
};
