import { RIGHT_BUTTON } from "@whyuz/data";
import { EntityDictionary } from "@whyuz/types";
import { Checkbox, Table, TableProps } from "flowbite-react";
import { PointerEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdFilterAltOff } from "react-icons/md";
import { AdvancedTableCell } from "./components/AdvancedTableCell.tsx";
import { AdvancedTableColumnHeader } from "./components/AdvancedTableColumnHeader.tsx";
import { AdvancedTableColumnsManagementDropdown } from "./components/AdvancedTableColumnsManagementDropdown.tsx";
import { AdvancedTableDeleteEntitiesButton } from "./components/AdvancedTableDeleteEntitiesButton.tsx";
import { AdvancedTableFilterButton } from "./components/AdvancedTableFilterButton.tsx";
import { AdvancedTableFilterContainer } from "./components/AdvancedTableFilterContainer.tsx";
import { AdvancedTableMultipleSelectionActionDropdown } from "./components/AdvancedTableMultipleSelectionActionDropdown.tsx";
import { AdvancedTablePanelFooter } from "./components/AdvancedTablePanelFooter.tsx";
import { AdvancedTablePanelPagination } from "./components/AdvancedTablePanelPagination.tsx";
import { AdvancedTablePanelTimeFilter } from "./components/AdvancedTablePanelTimeFilter.tsx";
import { AdvancedTableRowActionsCell } from "./components/AdvancedTableRowActionsCell.tsx";
import { AdvancedTableShowMoreOptions } from "./components/AdvancedTableShowMoreOptions.tsx";
import { AdvancedTableState, EntityId } from "./components/AdvancedTableState.ts";
import { AdvancedTableTimeFilter } from "./components/AdvancedTableTimeFilter.tsx";
import { AdvancedTableTitle } from "./components/AdvancedTableTitle.tsx";
import { ColoredTableCell } from "./components/ColoredTableCell.tsx";

export interface AdvancedTableProps<T extends EntityId, Filter extends T> extends TableProps {
  tableState: AdvancedTableState<T, Filter>;
  entitiesToShow: T[];
  onViewEntity?: (entity: T) => void;
  onEditEntity?: (entity: T) => void;
  onDeleteEntity?: (entity: T) => void;
  additionalRowActions?: (entity: T) => React.ReactNode;
  showColumnActions?: boolean;
}

const AdvancedTableComponent = <T extends EntityId, Filter extends T>({
  tableState,
  entitiesToShow,
  onViewEntity,
  onEditEntity,
  onDeleteEntity,
  additionalRowActions,
  showColumnActions = true,
  ...props
}: AdvancedTableProps<T, Filter>) => {
  const { t } = useTranslation();

  const areAllEntitiesSelected = useMemo(() => {
    if (entitiesToShow.length === 0) {
      return false;
    }

    for (let i = 0; i < entitiesToShow.length; i++) {
      const current = entitiesToShow[i];
      if (current.id && tableState.selectedEntities[current.id] === undefined) {
        return false;
      }
    }
    return true;
  }, [entitiesToShow, tableState.selectedEntities]);

  return (
    <Table hoverable {...props}>
      <Table.Head>
        {tableState.showMultipleSelect && (
          <Table.HeadCell key="multiselect" className="w-10">
            <Checkbox
              id="multiselect"
              className="cursor-pointer"
              checked={areAllEntitiesSelected}
              onChange={(e) => {
                const newSelectedEntities: EntityDictionary<T> = { ...tableState.selectedEntities };
                if (e.target.checked) {
                  entitiesToShow.forEach((current) => {
                    if (current.id) {
                      newSelectedEntities[current.id] = current;
                    }
                  });
                } else {
                  entitiesToShow.forEach((current) => {
                    if (current.id) {
                      delete newSelectedEntities[current.id];
                    }
                  });
                }
                tableState.setSelectedEntities(newSelectedEntities);
              }}
            />

            {tableState.isShowingAllFilters && <div className="h-10" />}
          </Table.HeadCell>
        )}
        {tableState?.columns?.map((column) => {
          if (column.renderColumnHeader) {
            return column.renderColumnHeader(tableState, column);
          } else {
            return (
              <AdvancedTableColumnHeader<T, Filter>
                key={column.columnKey}
                tableState={tableState}
                columnConfiguration={column}
              />
            );
          }
        })}
        {tableState?.tableConfiguration?.showRowActions && showColumnActions && (
          <Table.HeadCell key="rowactions" className="w-36">
            {t("word.actions").toLocaleUpperCase()}
            {tableState.isShowingAllFilters && (
              <MdFilterAltOff
                className="mt-4 mb-1 h-5 w-5 text-gray-300 dark:text-gray-500 hover:text-gray-400 cursor-pointer"
                onPointerDown={(e: PointerEvent) => {
                  if (e.button !== RIGHT_BUTTON) {
                    tableState.setFilters({} as Filter);
                  }
                }}
              />
            )}
          </Table.HeadCell>
        )}
      </Table.Head>
      <Table.Body>
        {entitiesToShow.length > 0 ? (
          entitiesToShow?.map(
            (entity) =>
              entity && (
                <Table.Row key={entity.id}>
                  {tableState.showMultipleSelect && (
                    <Table.Cell key={"multiselect" + (entity.id?.toString() ?? "")} className="w-10">
                      <Checkbox
                        checked={entity.id ? tableState.selectedEntities[entity.id] !== undefined : false}
                        className="cursor-pointer"
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (entity.id) {
                              tableState.setSelectedEntities({ ...tableState.selectedEntities, [entity.id]: entity });
                            }
                          } else {
                            if (entity.id) {
                              const newSelectedCampaigns = { ...tableState.selectedEntities };
                              delete newSelectedCampaigns[entity.id];
                              tableState.setSelectedEntities(newSelectedCampaigns);
                            }
                          }
                        }}
                      />
                    </Table.Cell>
                  )}
                  {tableState?.columns?.map((column) => {
                    if (column.renderColumnValue) {
                      return column.renderColumnValue(tableState, column, entity);
                    } else {
                      return (
                        <AdvancedTableCell<T, Filter>
                          key={column.columnKey + (entity.id?.toString() ?? "")}
                          tableState={tableState}
                          columnConfiguration={column}
                          entity={entity}
                          enableSelectText={column.enableSelectText}
                          boldText={column.boldText}
                          coloredTableCellColor={
                            column.coloredTableCellColor ? column.coloredTableCellColor(entity) : undefined
                          }
                          className={column.className}
                          onPointerDown={
                            column.onPointerDown
                              ? (e) => {
                                  if (e.button !== RIGHT_BUTTON && column.onPointerDown) column.onPointerDown(entity);
                                }
                              : undefined
                          }
                        />
                      );
                    }
                  })}
                  {tableState?.tableConfiguration?.showRowActions &&
                    showColumnActions &&
                    (tableState?.tableConfiguration?.renderRowActions ? (
                      tableState.tableConfiguration.renderRowActions(entity)
                    ) : (
                      <AdvancedTableRowActionsCell<T>
                        entity={entity}
                        entityDescription={tableState.tableConfiguration.entityDescription(entity)}
                        onViewEntity={onViewEntity}
                        onEditEntity={onEditEntity}
                        onDeleteEntity={onDeleteEntity}>
                        {additionalRowActions && additionalRowActions(entity)}
                      </AdvancedTableRowActionsCell>
                    ))}
                </Table.Row>
              ),
          )
        ) : (
          <Table.Row
            key="empy-table"
            className="hover:dark:transparent hover:bg-transparent bg-gray-50 dark:bg-gray-800 border-t-[1px]">
            <Table.Cell
              key="empty-table-mesage"
              className="select-none w-full italic"
              colSpan={
                (tableState?.columns?.filter(
                  (col) =>
                    (!tableState.isMobileScreenSize && col.isVisible) ||
                    (tableState.isMobileScreenSize && col.isMobileVisible),
                )?.length ?? 0) +
                (tableState.tableConfiguration?.enableMultipleSelection && !tableState.isMobileScreenSize ? 1 : 0) +
                (tableState.tableConfiguration?.showRowActions && showColumnActions ? 1 : 0)
              }>
              {t("errors.itemsnotfound")}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

AdvancedTableComponent.displayName = "AdvancedTable";

export const AdvancedTable = Object.assign(AdvancedTableComponent, {
  FilterButton: AdvancedTableFilterButton,
  DeleteEntitiesButton: AdvancedTableDeleteEntitiesButton,
  ColumnHeader: AdvancedTableColumnHeader,
  Cell: AdvancedTableCell,
  TimeFilter: AdvancedTableTimeFilter,
  FilterContainer: AdvancedTableFilterContainer,
  Title: AdvancedTableTitle,
  ColoredCell: ColoredTableCell,
  ColumnsManagementDropdown: AdvancedTableColumnsManagementDropdown,
  MultipleSelectionActionDropdown: AdvancedTableMultipleSelectionActionDropdown,
  PanelFooter: AdvancedTablePanelFooter,
  PanelPagination: AdvancedTablePanelPagination,
  ShowMoreOptions: AdvancedTableShowMoreOptions,
  PanelTimeFilter: AdvancedTablePanelTimeFilter,
  RowActionsCell: AdvancedTableRowActionsCell,
});
