import { ContentType, SuccessCaseContentItem } from "@whyuz/services";
import { SuccessCaseContentEditorQuestionFiles } from "./SuccessCaseContentEditorQuestionFiles";
import { SuccessCaseContentEditorQuestionFree } from "./SuccessCaseContentEditorQuestionFree";
import { SuccessCaseContentEditorQuestionImage } from "./SuccessCaseContentEditorQuestionImage";
import { SuccessCaseContentEditorQuestionText } from "./SuccessCaseContentEditorQuestionText";
import { SuccessCaseContentEditorQuestionVideo } from "./video/SuccessCaseContentEditorQuestionVideo";

export interface SuccessCaseContentEditorQuestionProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled: boolean;
}

export const SuccessCaseContentEditorQuestion = ({
  question,
  tenantId,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionProps) => {
  switch (question.contentType) {
    case ContentType.Text:
      return (
        <SuccessCaseContentEditorQuestionText
          tenantId={tenantId}
          question={question}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      );
    case ContentType.Image:
      return (
        <SuccessCaseContentEditorQuestionImage
          tenantId={tenantId}
          question={question}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      );
    case ContentType.Video:
      return (
        <SuccessCaseContentEditorQuestionVideo
          tenantId={tenantId}
          question={question}
          onQuestionUpdated={onQuestionUpdated}
        />
      );
    case ContentType.Files:
      return (
        <SuccessCaseContentEditorQuestionFiles
          tenantId={tenantId}
          question={question}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      );
    case ContentType.Free:
    default:
      return (
        <SuccessCaseContentEditorQuestionFree
          tenantId={tenantId}
          question={question}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      );
  }
};
