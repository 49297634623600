import { useContext } from "react";
import { SessionContext, SessionContextProps } from "../context/SessionContext";

export const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);

  if (!context || typeof context === "undefined") {
    throw new Error("useSession should be used within the SessionContext provider!");
  }

  return context;
};
