/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mergeAttributes, Node } from "@tiptap/core";
import { getNodeContent } from "./extensionUtils";

export interface DivOptions {
  HTMLAttributes: Record<string, any>;
}

export interface DivStyleAttributes {
  class?: string;
  style?: string;
}

// declare module "@tiptap/core" {
//   interface Commands<ReturnType> {
//     div: {
//       /**
//        * Set the div
//        */
//       setDiv: (options?: DivStyleAttributes) => ReturnType;
//     };
//   }
// }

export const Div = Node.create<DivOptions>({
  name: "div",
  group: "block",
  atom: true,
  draggable: true,
  content: "block*",
  selectable: true,
  isolating: false,
  allowGapCursor: true,
  defining: true,

  addAttributes() {
    return {
      class: {
        default: this.options.HTMLAttributes.class,
      },
      style: {
        default: this.options.HTMLAttributes.style,
      },
    };
  },

  parseHTML: () => {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const content = getNodeContent(node);
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ...content];
  },

  parseDOM: [{ tag: "div" }],

  toDOM: () => ["div", 0],

  // addCommands: () => {
  //   return {
  //     setDiv:
  //       (options) =>
  //       ({ tr, dispatch, editor }) => {
  //         const divNode = editor.schema.nodes.div.createChecked(options, null);
  //         if (dispatch) {
  //           const offset = tr.selection.anchor + 1;

  //           tr.replaceSelectionWith(divNode)
  //             .scrollIntoView()
  //             .setSelection(TextSelection.near(tr.doc.resolve(offset)));
  //         }
  //         return true;
  //       },
  //   };
  // },

  addOptions: () => {
    return {
      HTMLAttributes: {},
    };
  },
});
