import { getGridCols } from "@whyuz/utils";

export interface PageFormGridProps {
  columns: number;
  columnsBreakpointSM?: boolean;
  children?: React.ReactNode;
}

export const PageFormGrid = ({ columns, columnsBreakpointSM = true, children }: PageFormGridProps) => {
  return (
    <div className={`grid gap-5 w-full ${columns ? getGridCols(columns, columnsBreakpointSM) ?? "" : ""}`}>{children}</div>
  );
};
