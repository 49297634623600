import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTooltipTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteTooltipThemes {
  default = "default",
}

const FlowbiteDefaultTooltipTheme = FlowbiteDefaultTheme.tooltip;

export const FlowbiteTooltipThemes: Record<EnumFlowbiteTooltipThemes, RecursivePartial<FlowbiteTooltipTheme>> = {
  default: {
    target: "", // To avoid changing childrens size as default theme is using w-fit
    animation: twMerge(FlowbiteDefaultTooltipTheme.animation, "delay-700"),
  },
};
