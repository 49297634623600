import { GB_bytes, KB_bytes, MB_bytes } from "@whyuz/data";

export const downloadFileFromURI = (uri: string, filename?: string) => {
  const link = document.createElement("a");
  link.href = uri;
  if (filename) {
    link.download = filename; // The filename will be taken from the server header response Content-Disposition (attachment; filename)
  }
  link.click();
};

export const downloadAndOpenFileInNewTabFromURI = (uri: string, filename?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetch(uri)
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      const newTab = window.open(objectUrl, "_blank");
      if (newTab) {
        if (filename) {
          newTab.document.title = filename;
          newTab.onload = () => (document.title = filename);
        }
        newTab.focus();
      }
    });
};

export const openFileInNewTabFromURI = (uri: string, filename?: string) => {
  const link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  link.rel = "noopener noreferrer";
  if (filename) {
    link.download = filename;
    link.innerText = filename;
  }
  link.click();
};

export const downloadFileFromURLAsync = (url: string, filename?: string) => {
  return fetch(url).then((t) => {
    const serverFilename = t.headers.get("Content-Disposition")?.split("filename=")[1];
    return t.blob().then((b) => downloadFileFromBlob(b, filename ?? serverFilename ?? "downloadedFile"));
  });
};

export const downloadFileFromBlob = (b: Blob, filename: string) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(b);
  link.setAttribute("download", filename);
  link.click();
  return link.href;
};

export const readTextFile = (csvFile: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result as string;
      resolve(content);
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsText(csvFile);
  });
};

export const parseCSV = (csv: string, hasHeader = false) => {
  return hasHeader ? parseCSVFileWithHeader(csv) : parseCSVFileWithoutHeader(csv);
};

const parseCSVFileWithoutHeader = (csvContent: string) => {
  const rows = csvContent.split("\n");
  return rows.map((row) => {
    const values = row.split(",");
    const rowData: { [key: string]: string } = {};
    values.forEach((value, index) => {
      rowData[`column${index + 1}`] = value;
    });
    return rowData;
  });
};

const parseCSVFileWithHeader = (csvContent: string) => {
  const rows = csvContent.split("\n");

  const headerRow = rows[0];
  const headers = headerRow ? headerRow.split(",") : [];

  const dataRows = rows.slice(1);
  return dataRows.map((row) => {
    const values = row.split(",");
    const rowData: { [key: string]: string } = {};
    headers.forEach((header, index) => {
      rowData[header] = values[index] ?? "";
    });
    return rowData;
  });
};

export const downloadCSVFile = (headers: string[], data: string[][], filename: string) => {
  const csvContent = `${headers.join(",")}\n${data.map((row) => row.join(",")).join("\n")}`;
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  downloadFileFromURI(url, filename);

  URL.revokeObjectURL(url);
};

export const getFileSizeText = (fileSizeBytes: number, language: string) => {
  return fileSizeBytes >= GB_bytes
    ? Number((fileSizeBytes / GB_bytes).toFixed(2)).toLocaleString(language) + " GB"
    : fileSizeBytes >= MB_bytes
      ? Number((fileSizeBytes / MB_bytes).toFixed(2)).toLocaleString(language) + " MB"
      : Number((fileSizeBytes / KB_bytes).toFixed(2)).toLocaleString(language) + " KB";
};

export const getFileExtensionFromFileName = (fileName: string): string | undefined => {
  // return /[^.]+$/.exec(fileName);
  const parts = fileName.split(".");
  if (parts.length > 1) {
    return parts.pop();
  }
  return undefined;
};

export const getFileExtension = (file: File): string | undefined => {
  return getFileExtensionFromFileName(file.name);
};

export const blobToFile = (blob: Blob, fileName: string) => {
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
  return file;
};
