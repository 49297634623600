import { Tooltip } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonVariablesProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonVariables = ({ className, children }: TextEditorMenuButtonVariablesProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();
  const mentionExtension = useMemo(
    () => editor?.extensionManager.extensions.find((extension) => extension.name === "mention"),
    [editor],
  );

  if (!mentionExtension) {
    return undefined;
  }

  const addVariable = () => {
    if (editor) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const char: string = mentionExtension?.options["suggestion"]["char"];
      editor.chain().focus().insertContent(char).focus().run();
    }
  };

  return (
    <Tooltip content={t("buttons.variables")}>
      <button
        type="button"
        onClick={addVariable}
        className={twMerge(buttonStyleHiddenOnMobile, className)}
        disabled={disabled}
      >
        {children ?? <HiOutlineAtSymbol className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
