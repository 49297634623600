import { ENDPOINT } from "@whyuz/data";
import { isEmpty } from "@whyuz/utils";
import { GraphQLClient } from "graphql-request";
import { useCallback, useMemo } from "react";
import { useKeycloak, useSession } from ".";

export const useGQLClient = (baseURL: string = ENDPOINT.GRAPHQL) => {
  const { initialized, keycloak } = useKeycloak();
  const { sessionToken } = useSession();
  const isUserAuthenticated = useMemo(() => {
    return initialized && keycloak?.authenticated && keycloak?.token ? true : false;
  }, [initialized, keycloak?.authenticated, keycloak?.token]);

  const GQLClient = useMemo(() => {
    if (isUserAuthenticated && !isEmpty(keycloak.token)) {
      return new GraphQLClient(baseURL, {
        headers: {
          Authorization: `Bearer ${keycloak.token ?? ""}`,
          "X-SESSION-TOKEN": sessionToken ?? "",
        },
      });
    } else {
      return new GraphQLClient(baseURL);
    }
  }, [baseURL, isUserAuthenticated, keycloak.token, sessionToken]);

  const getGQLClient = useCallback(() => {
    return GQLClient;
  }, [GQLClient]);

  return getGQLClient();
};
