import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTextareaTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteTextAreaThemes {
  default = "default",
}

const FlowbiteDefaultTextAreaTheme = FlowbiteDefaultTheme.textarea;

export const FlowbiteTextAreaThemes: Record<EnumFlowbiteTextAreaThemes, RecursivePartial<FlowbiteTextareaTheme>> = {
  default: {
    colors: {
      gray: twMerge(
        FlowbiteDefaultTextAreaTheme.colors.gray,
        "focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500",
      ),
      info: twMerge(
        FlowbiteDefaultTextAreaTheme.colors.gray,
        "border-primary-500 bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 focus:ring-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500",
      ),
    },
  },
};
