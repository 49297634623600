import hexRgb from "hex-rgb";
import { RgbaColor, RgbColor } from "react-colorful";
import rgbHex from "rgb-hex";

export const rgb2Rgba = ({ r, g, b }: RgbColor): RgbaColor => ({ r, g, b, a: 1 });

export const rgba2Hex = ({ r, g, b, a }: RgbaColor) => {
  return rgbHex(r, g, b, a);
};

export const rgb2Hex = ({ r, g, b }: RgbColor) => {
  return rgbHex(r, g, b);
};

export const hex2Rgba = (color: string) => {
  const { red: r, green: g, blue: b, alpha: a } = hexRgb(color);
  return { r, g, b, a };
};

export const rgba2RgbaString = (color: RgbaColor) => {
  const { r, g, b, a } = color;
  return `rgba(${r},${g},${b},${a})`;
};

export const equalRgbColors = (color1: RgbColor, color2: RgbColor) => {
  if (!color1 || !color2) return false;
  return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b;
};

export const equalRgbaColors = (color1: RgbaColor, color2: RgbaColor) => {
  return equalRgbColors(color1, color2) && color1.a === color2.a;
};
