import { Page } from "@whyuz/components";
import {
  Error_Code,
  GQLError,
  GQLFieldErrorMessageDictionary,
  UserLicense,
  UserLicenseInput,
  useAddUserLicenseMutation,
  useUpdateUserLicenseMutation,
  useUser,
} from "@whyuz/services";
import { isEmpty } from "@whyuz/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PiMedal } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { CountryCombobox } from "../../Countries";
import { formatUserName } from "../../Users";

const getFormData = (userLicense: UserLicense): UserLicenseInput => ({
  enabled: userLicense.enabled,
  firstName: userLicense.personalInfo?.firstName,
  lastName: userLicense.personalInfo?.lastName,
  locale: userLicense.locale,
  mail: userLicense.mail,
  countryId: userLicense.country?.id as string,
  optOutDate: userLicense.optOutDate as string,
  phone: userLicense.personalInfo?.phone,
});

export interface UserLicenseAddOrEditFormProps {
  userLicense?: UserLicense;
  onUserLicenseAdded: (addedUserLicense: UserLicense) => void;
  onUserLicenseUpdated: (updatedUserLicense: UserLicense) => void;
  onCancel: () => void;
}

export const UserLicenseAddOrEditForm = ({
  userLicense,
  onUserLicenseAdded,
  onUserLicenseUpdated,
  onCancel,
}: UserLicenseAddOrEditFormProps) => {
  const { t } = useTranslation();
  const { t: tUser } = useTranslation("user");
  const { id } = useParams();
  const userCtx = useUser();
  const [addUserLicenseMutation, { isLoading: isAddLoading }] = useAddUserLicenseMutation();
  const [updateUserLicenseMutation, { isLoading: isUpdateLoading }] = useUpdateUserLicenseMutation();
  const [error, setError] = useState<GQLError>();
  const [formData, setFormData] = useState<UserLicenseInput>(
    userLicense
      ? getFormData(userLicense)
      : {
          enabled: true,
        },
  );

  const updateFields = (fields: Partial<UserLicenseInput>) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const validateFields = (): boolean => {
    const validationErrors: GQLFieldErrorMessageDictionary = {};
    setError(undefined);

    if (isEmpty(formData.firstName as string)) {
      validationErrors["firstName"] = {
        entity: "userlicense",
        field: "firstName",
        error: Error_Code.RequiredField,
      };
    }

    if (isEmpty(formData.mail)) {
      validationErrors["mail"] = {
        entity: "usertenantrole",
        field: "usermail",
        error: Error_Code.RequiredField,
      };
    }

    if (isEmpty(formData.lastName as string)) {
      validationErrors["lastName"] = {
        entity: "userlicense",
        field: "lastName",
        error: Error_Code.RequiredField,
      };
    }

    if (!formData.countryId) {
      validationErrors["countryId"] = {
        entity: "userlicense",
        field: "countryId",
        error: Error_Code.RequiredField,
      };
    }

    const errorsFound = Object.keys(validationErrors).length > 0;
    if (errorsFound) {
      const validationError: GQLError = {
        message: t("errors.validationfailed"),
        fieldErrors: validationErrors,
        isUncontrolledError: false,
      };
      setError(validationError);
    }
    return !errorsFound;
  };

  const addUserLicense = () => {
    if (validateFields()) {
      addUserLicenseMutation({
        variables: {
          userLicense: formData,
        },
      })
        .then((addedUserLicense) => {
          setError(undefined);
          if (addedUserLicense) {
            onUserLicenseAdded(addedUserLicense);
          }
        })
        .catch((error: GQLError) => {
          setError(error);
        });
    }
  };

  const updateUserLicense = () => {
    if (validateFields()) {
      // If the update is for the logged user
      if (userLicense?.id === userCtx.userLicense?.id) {
        userCtx.updateUserLicense
          .execute({
            ...formData,
          })
          .then((updatedUserLicense) => {
            setError(undefined);
            if (updatedUserLicense) {
              onUserLicenseUpdated(updatedUserLicense);
            }
          })
          .catch((error: GQLError) => {
            setError(error);
          });
      } else {
        // If the update is for another user (not the one logged in)
        updateUserLicenseMutation({
          variables: {
            id: userLicense?.id as string,
            userLicense: formData,
          },
        })
          .then((updatedUserLicense) => {
            setError(undefined);
            if (updatedUserLicense) {
              onUserLicenseUpdated(updatedUserLicense);
            }
          })
          .catch((error: GQLError) => {
            setError(error);
          });
      }
    }
  };

  const addOrUpdateUserLicense = () => {
    if (userLicense?.id) {
      updateUserLicense();
    } else {
      addUserLicense();
    }
  };

  return (
    <Page isLoading={isAddLoading || isUpdateLoading} error={error} onErrorClose={() => setError(undefined)}>
      <Page.NavigationPanel requestDiscardChanges={true} />
      <Page.TitlePanel
        icon={<PiMedal className="w-8 h-8 text-primary-600" />}
        title={tUser("user")}
        bottomBorder={false}
        detail={userLicense?.personalInfo ? formatUserName(userLicense.personalInfo) : undefined}>
        <Page.DiscardChangesButton
          text={t("buttons.discardchanges")}
          onDiscardChanges={() => {
            onCancel();
          }}
        />
        <Page.SaveButton text={t("buttons.save")} onSave={addOrUpdateUserLicense} />
      </Page.TitlePanel>
      <Page.FormContainer columnsToCalculateWidth={2}>
        <Page.FormGrid columns={2} columnsBreakpointSM={true}>
          <Page.FormTextInputField
            id="firstname"
            label={tUser("firstname")}
            value={formData.firstName as string}
            error={error}
            onChange={(newValue) => updateFields({ firstName: newValue })}
          />
          <Page.FormTextInputField
            id="lastname"
            label={tUser("lastname")}
            value={formData.lastName as string}
            error={error}
            onChange={(newValue) => updateFields({ lastName: newValue })}
          />
          <Page.FormTextInputField
            id="usermail"
            label={tUser("email")}
            placeholder={tUser("emailplaceholder")}
            value={formData.mail as string}
            disabled={!!id}
            error={error}
            onChange={(newValue) => updateFields({ mail: newValue })}
          />
          <CountryCombobox
            id="countryId"
            error={error}
            label={tUser("usercurrentlocation")}
            placeholder={tUser("usercountryplaceholder")}
            selectedItemId={formData.countryId as string}
            onChange={(country) => updateFields({ countryId: country.id })}
          />
          {userCtx.isUserSuperAdmin && (
            <>
              <Page.FormToggleField
                key={"userEnabled" + (userLicense?.id as string)}
                id={"userEnabled" + (userLicense?.id as string)}
                checked={formData.enabled as boolean}
                label={t("word.enabled")}
                error={error}
                onChange={(e) => updateFields({ enabled: e })}
              />
              {id && (
                <Page.FormDateTimeInfoField
                  id={"userOptOutDate" + (userLicense?.id as string)}
                  key={"userOptOutDate" + (userLicense?.id as string)}
                  dateStringGMT00={userLicense?.optOutDate as string}
                  placeholder={t("word.never")}
                  label={tUser("optoutdate")}
                />
              )}
            </>
          )}
        </Page.FormGrid>
      </Page.FormContainer>
    </Page>
  );
};
