import { useGQLLazyQuery, useGQLQuery } from "../";
import { ChatMessagePage, ChatMessagesQueryVariables } from "../../codegen/graphql";
import { ChatMessageGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useChatMessagesLazyQuery = (queryContext?: GQLQueryContext<ChatMessagePage, ChatMessagesQueryVariables>) =>
  useGQLLazyQuery<ChatMessagePage, ChatMessagesQueryVariables>(ChatMessageGQL.query.chatMessages, queryContext);

export const useChatMessagesQuery = (queryContext?: GQLQueryContext<ChatMessagePage, ChatMessagesQueryVariables>) =>
  useGQLQuery<ChatMessagePage, ChatMessagesQueryVariables>(ChatMessageGQL.query.chatMessages, queryContext);
