import { graphql } from "../../codegen/gql";

const approvalFlowExecution = graphql(`
  query approvalFlowExecution($id: UUID!, $tenantId: UUID) {
    approvalFlowExecution(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      requester
      steps {
        id
        stepNumber
        status
        requiredAll
        messageHtml
        subjectHtml
        users {
          id
          name
          email
          status
          sentDate
          updatedOn
          user {
            id
            mail
          }
        }
      }
      cancelledBy
      rejectedDate
      rejectedBy
      approvedDate
      mailAccount {
        id
      }
    }
  }
`);

const approveUser = graphql(`
  mutation approveFlowExecutionUserAndCheckExecution($approvalFlowUserId: UUID!, $tenantId: UUID) {
    approveFlowExecutionUserAndCheckExecution(approvalFlowUserId: $approvalFlowUserId, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      sentDate
      status
      user {
        id
      }
      approvalFlowExecutionStep {
        id
        approvalFlowExecution {
          id
          type
          entityId
          status
        }
      }
    }
  }
`);

const processExecution = graphql(`
  mutation processExecution($id: UUID!) {
    processExecution(id: $id)
  }
`);

const declineUser = graphql(`
  mutation declineFlowExecutionUserAndCheckExecution($approvalFlowUserId: UUID!, $reason: String!, $tenantId: UUID) {
    declineFlowExecutionUserAndCheckExecution(
      approvalFlowUserId: $approvalFlowUserId
      reason: $reason
      tenantId: $tenantId
    ) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      sentDate
      status
      user {
        id
      }
      approvalFlowExecutionStep {
        id
        approvalFlowExecution {
          id
          type
          entityId
          status
        }
      }
    }
  }
`);

const approvalFlowExecutions = graphql(`
  query approvalFlowExecutions(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowExecutions(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        name
        type
        messageHtml
        subjectHtml
        status
        launchedDate
        launchedBy
        cancelledDate
        cancelledBy
        rejectedDate
        rejectedBy
        approvedDate
        requester
        mailAccount {
          id
        }
        steps {
          id
          stepNumber
          status
          requiredAll
          messageHtml
          subjectHtml
          users {
            id
            name
            email
            status
            sentDate
            updatedOn
            user {
              id
              mail
            }
          }
        }
      }
    }
  }
`);

const cancelApprovalFlowExecution = graphql(`
  mutation cancelApprovalFlowExecution($approvalFlowExecutionId: UUID!, $reason: String!, $tenantId: UUID) {
    cancelApprovalFlowExecution(
      approvalFlowExecutionId: $approvalFlowExecutionId
      reason: $reason
      tenantId: $tenantId
    ) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      rejectedDate
      approvedDate
      cancelledBy
      requester
      mailAccount {
        id
      }
    }
  }
`);

const updateApprovalFlowExecution = graphql(`
  mutation updateApprovalFlowExecution(
    $id: UUID!
    $approvalFlowExecution: ApprovalFlowExecutionInput!
    $tenantId: UUID
  ) {
    updateApprovalFlowExecution(id: $id, approvalFlowExecution: $approvalFlowExecution, tenantId: $tenantId) {
      id
      requester
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      cancelledBy
      rejectedDate
      rejectedBy
      approvedDate
      mailAccount {
        id
      }
    }
  }
`);

const deleteApprovalFlowExecution = graphql(`
  mutation deleteApprovalFlowExecution($id: UUID!, $tenantId: UUID) {
    deleteApprovalFlowExecution(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      cancelledBy
      rejectedDate
      rejectedBy
      approvedDate
      requester
      mailAccount {
        id
      }
    }
  }
`);

const launchApprovalFlowExecution = graphql(`
  mutation launchApprovalFlowExecution($id: UUID!, $tenantId: UUID) {
    launchApprovalFlowExecution(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      requester
      steps {
        id
        stepNumber
        status
        requiredAll
        messageHtml
        subjectHtml
        users {
          id
          name
          email
          status
          sentDate
          updatedOn
          user {
            id
            mail
          }
        }
      }
      cancelledBy
      rejectedDate
      rejectedBy
      approvedDate
      mailAccount {
        id
      }
    }
  }
`);

const resendApprovalFlowExecutionUsersEmails = graphql(`
  mutation resendApprovalFlowExecutionUsersEmails($id: UUID!, $tenantId: UUID) {
    resendApprovalFlowExecutionUsersEmails(id: $id, tenantId: $tenantId) {
      id
      requester
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      status
      launchedDate
      launchedBy
      cancelledDate
      cancelledBy
      rejectedDate
      rejectedBy
      approvedDate
    }
  }
`);

export const ApprovalFlowExecutionGQL = {
  query: { approvalFlowExecution, approvalFlowExecutions },
  mutation: {
    updateApprovalFlowExecution,
    deleteApprovalFlowExecution,
    approveUser,
    declineUser,
    processExecution,
    cancelApprovalFlowExecution,
    launchApprovalFlowExecution,
    resendApprovalFlowExecutionUsersEmails,
  },
};
