import { StompContextProvider, WebPageNavigationContextProvider, WhyUzFlowbiteTheme } from "@whyuz/components";
import { ENDPOINT } from "@whyuz/data";
import { SessionContextProvider, UserContextProvider } from "@whyuz/services";
import { Flowbite } from "flowbite-react";
import { CountriesContextProvider } from "../../Countries/index.ts";
import { CurrenciesContextProvider } from "../../Currencies/context/CurrencyContext.tsx";
import { LanguagesContextProvider } from "../../Languages";
import { SidebarContextProvider } from "./SidebarContext.tsx";

export const AppComponentsContextProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <Flowbite theme={{ theme: WhyUzFlowbiteTheme }}>
      <SessionContextProvider>
        <UserContextProvider>
          <StompContextProvider endpoint={ENDPOINT.WEB_SOCKET}>
            <WebPageNavigationContextProvider>
              <SidebarContextProvider>
                <LanguagesContextProvider>
                  <CurrenciesContextProvider>
                    <CountriesContextProvider>{children}</CountriesContextProvider>
                  </CurrenciesContextProvider>
                </LanguagesContextProvider>
              </SidebarContextProvider>
            </WebPageNavigationContextProvider>
          </StompContextProvider>
        </UserContextProvider>
      </SessionContextProvider>
    </Flowbite>
  );
};
