import { twMerge } from "tailwind-merge";

export interface TextEditorMenuButtonPanelProps extends React.PropsWithChildren {
  className?: string;
}

export const TextEditorMenuButtonPanel = ({ className, children }: TextEditorMenuButtonPanelProps) => {
  return (
    <div className={twMerge(`flex flex-wrap items-center px-2 align-middle bg-gra-50`, className)}>{children}</div>
  );
};
