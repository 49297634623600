import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "flowbite-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../Page";
import { Pagination } from "../../Pagination";

export interface AdvancedTableTitleProps {
  icon?: React.ReactNode;
  title: string;
  pagination: Pagination;
  children?: React.ReactNode;
}

export const AdvancedTableTitle = ({ icon, title, pagination, children }: AdvancedTableTitleProps) => {
  const { t } = useTranslation();

  const detail = useMemo(
    () => (
      <div className="flex flex-row items-center space-x-2">
        <div className="text-gray-400 font-medium select-none truncate">
          {pagination?.totalElements ?? 0}{" "}
          {pagination?.totalElements === 1
            ? t("word.result").toLocaleLowerCase()
            : t("word.results").toLocaleLowerCase()}
        </div>
        <Tooltip
          placement="bottom"
          content={`${t("pagination.showing")} ${
            pagination?.totalElements && pagination?.totalElements > 0
              ? `${(pagination?.number ?? 0) * (pagination?.size ?? 0) + 1}-${
                  (pagination?.number ?? 0) * (pagination?.size ? pagination.size : 0) + pagination.numberOfElements
                } ${t("pagination.showingof")}`
              : ""
          } ${pagination?.totalElements ?? 0} ${
            pagination?.totalElements === 1
              ? t("word.result").toLocaleLowerCase()
              : t("word.results").toLocaleLowerCase()
          }`}>
          <InformationCircleIcon className="h-4 w-4 text-gray-400" />
          <span className="sr-only">More info</span>
        </Tooltip>
      </div>
    ),
    [pagination?.number, pagination.numberOfElements, pagination.size, pagination?.totalElements, t],
  );

  return (
    <Page.TitlePanel icon={icon} title={title} detail={detail}>
      {children}
    </Page.TitlePanel>
  );
};
