import { Error404PageNotFound, Page, useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_URL_ACTIONS, WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import { GQLError, GQLErrorStatusCode, UserLicense, useUser, useUserLicenseLazyQuery } from "@whyuz/services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { UserLicenseAddOrEditForm } from "./components/UserLicenseAddOrEditForm.tsx";
import { UserLicenseInformation } from "./components/UserLicenseInformation.tsx";

export const UserLicenseManager = () => {
  const { t: tUserLicense } = useTranslation("usertenantrole");
  const userCtx = useUser();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { navigate, isPossibleNavigateBack } = useWebPageNavigation();
  const [action, setAction] = useState<WHYUZ_URL_ACTIONS>(
    (searchParams.get(WHYUZ_URL_PARAMETERS.ACTION) as WHYUZ_URL_ACTIONS) ?? WHYUZ_URL_ACTIONS.VIEW,
  );
  const [lazyQuery, { isLoading, hasEverBeenExecuted }] = useUserLicenseLazyQuery();
  const [error, setError] = useState<GQLError>();
  const [userLicense, setUserLicense] = useState<UserLicense>();

  useEffect(() => {
    if (id) {
      lazyQuery({ variables: { id } })
        .then((user) =>
          user
            ? setUserLicense(user)
            : setError({
                isUncontrolledError: true,
                statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
                message: tUserLicense("user") + " " + String(id),
                fieldErrors: {},
              }),
        )
        .catch((error: GQLError) => setError(error));
    }
  }, [id, lazyQuery, tUserLicense]);

  const onCancelAddOrEdit = () => {
    if (isPossibleNavigateBack) {
      navigate(-1);
    } else {
      setAction(WHYUZ_URL_ACTIONS.VIEW);
    }
  };

  const onEditUserLicense = () => {
    if (userLicense?.id) {
      setAction(WHYUZ_URL_ACTIONS.EDIT);
    }
  };

  const onUserLicenseUpdated = (updatedUser: UserLicense) => {
    setUserLicense(updatedUser);
    if (isPossibleNavigateBack) {
      navigate(-1);
    } else {
      setAction(WHYUZ_URL_ACTIONS.VIEW);
    }
  };

  if (!userCtx?.isUserSuperAdmin && id !== userCtx?.userLicense?.id) {
    return <Error404PageNotFound />;
  }

  return (
    <Page.LoadingOrError isLoading={isLoading} error={error}>
      {action === WHYUZ_URL_ACTIONS.VIEW && userLicense ? (
        <UserLicenseInformation userLicense={userLicense} onEditUserLicense={onEditUserLicense} />
      ) : action === WHYUZ_URL_ACTIONS.ADD || (action === WHYUZ_URL_ACTIONS.EDIT && userLicense) ? (
        <UserLicenseAddOrEditForm
          userLicense={userLicense}
          onUserLicenseAdded={onUserLicenseUpdated}
          onUserLicenseUpdated={onUserLicenseUpdated}
          onCancel={onCancelAddOrEdit}
        />
      ) : (
        hasEverBeenExecuted && <Error404PageNotFound /> // To avoid flickering during loading
      )}
    </Page.LoadingOrError>
  );
};
