import { useTranslation } from "react-i18next";
import { GenericError } from "./GenericError.tsx";

export interface Error404PageNotFoundProps {
  onClose?: () => void;
}

export const Error404PageNotFound = ({ onClose }: Error404PageNotFoundProps) => {
  const { t } = useTranslation("common");

  return (
    <GenericError
      errorNumber={404}
      title={t("errors.httperror404")}
      description={t("errors.httperror404detail")}
      onClose={onClose}
    />
  );
};
