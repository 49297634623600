import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteToggleSwitchTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteToggleSwitchThemes {
  default = "default",
}

const FlowbiteDefaultToggleSwitchTheme = FlowbiteDefaultTheme.toggleSwitch;

export const FlowbiteToggleSwitchThemes: Record<
  EnumFlowbiteToggleSwitchThemes,
  RecursivePartial<FlowbiteToggleSwitchTheme>
> = {
  default: {
    toggle: {
      // NOTE: all the "after" styles in base, checked.on/off are used to fix a bug of the component: https://github.com/themesberg/flowbite-react/issues/1360
      base: twMerge(FlowbiteDefaultToggleSwitchTheme.toggle.base, "h-6 w-11", "after:rounded-full"),
      checked: {
        on: twMerge(FlowbiteDefaultToggleSwitchTheme.toggle.checked.on, "after:bg-white"),
        off: twMerge(FlowbiteDefaultToggleSwitchTheme.toggle.checked.off, "after:bg-gray-400 dark:after:bg-gray-500"),
        color: {
          primary: "bg-primary-600 border-primary-600",
        },
      },
    },
  },
};
