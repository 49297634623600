import { useGQLLazyQuery, useGQLQuery } from "../";
import { LanguagePage, LanguagesQueryVariables } from "../../codegen/graphql.ts";
import { LanguageGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useLanguagesLazyQuery = (queryContext?: GQLQueryContext<LanguagePage, LanguagesQueryVariables>) =>
  useGQLLazyQuery<LanguagePage, LanguagesQueryVariables>(LanguageGQL.query.languages, queryContext);

export const useLanguagesQuery = (queryContext?: GQLQueryContext<LanguagePage, LanguagesQueryVariables>) =>
  useGQLQuery<LanguagePage, LanguagesQueryVariables>(LanguageGQL.query.languages, queryContext);
