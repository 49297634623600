import { graphql } from "../../codegen/gql";

const users = graphql(`
  query users(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    users(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        mail
        enabled
        firstName
        lastName
        pictureURL
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
      }
    }
  }
`);

const user = graphql(`
  query user($id: UUID!) {
    user(id: $id) {
      id
      mail
      enabled
      firstName
      lastName
      pictureURL
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

export const UserGQL = {
  query: { users, user },
  mutation: {},
};
