import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorSidebarQuestionsSelector } from "./SuccessCaseContentEditorSidebarQuestionsSelector";

export const SuccessCaseContentEditorSidebar = ({ children }: React.PropsWithChildren) => {
  const { t: tSCContentEditor } = useTranslation("successcasecontenteditor");
  return (
    <div className="hidden sm:flex flex-col flex-none w-80 truncate border-r border-gray-200 dark:border-gray-600">
      <div className="flex flex-none h-14 items-center text-base font-medium text-gray-500 dark:text-gray-300 px-4 py-3 gap-2 border-b border-gray-200 dark:border-gray-600 truncate">
        {tSCContentEditor("questionssidebar.questions")}
      </div>
      <SuccessCaseContentEditorSidebarQuestionsSelector>{children}</SuccessCaseContentEditorSidebarQuestionsSelector>
    </div>
  );
};
