import { observer } from "mobx-react";
import React from "react";
import { useEventCallback } from "@whyuz/hooks";

const escapeRGB = (rgb: string) => rgb.replace(/^[^\d]$/gi, "")
const validRGB = (rgb: string) => regex0to255.test(rgb)
const regex0to255 = /^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/

type RGBProps = {
	rgb: string
	onChange: (value: string) => void
	onBlur?: (value: React.FocusEvent<HTMLInputElement>) => void
}

type InputProps = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"rgb" | "onChange" | "onBlur"
>

export const RGBInput = observer(
	({ rgb = "", onChange, onBlur, ...rest }: RGBProps & InputProps) => {
		const [value, setValue] = React.useState(() => escapeRGB(rgb))
		const onChangeCallback = useEventCallback<string>(onChange)
		const onBlurCallback = useEventCallback<React.FocusEvent<HTMLInputElement>>(
			onBlur,
		)

		const handleChange = React.useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				const inputValue = escapeRGB(e.target.value)
				setValue(inputValue)
				if (validRGB(inputValue)) onChangeCallback(inputValue)
			},
			[onChangeCallback],
		)

		const handleBlur = React.useCallback(
			(e: React.FocusEvent<HTMLInputElement>) => {
				if (!validRGB(e.target.value)) setValue(escapeRGB(rgb))
				onBlurCallback(e)
			},
			[rgb, onBlurCallback],
		)

		React.useEffect(() => {
			setValue(escapeRGB(rgb))
		}, [rgb])

		return (
			<input
				{...rest}
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		)
	},
)
