import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import { GQLQueryContext } from "../../types";

import {
  CollaboratorFinishTheSuccessCaseWithoutApprovalMutationVariables,
  CollaboratorNeedsContentApprovalSuccessCaseMutationVariables,
  CollaboratorSuccessCaseAskForReviewMutationVariables,
  CollaboratorSuccessCaseQueryVariables,
  LaunchCustomerSuccessCaseAppFlowMutationVariables,
  SuccessCase,
} from "../../codegen/graphql";
import { CollaboratorSuccessCaseGQL } from "../../queries";

export const useCollaboratorSuccessCaseLazyQuery = (
  queryContext?: GQLQueryContext<SuccessCase, CollaboratorSuccessCaseQueryVariables>,
) =>
  useGQLLazyQuery<SuccessCase, CollaboratorSuccessCaseQueryVariables>(
    CollaboratorSuccessCaseGQL.query.collaboratorSuccessCase,
    queryContext,
  );

export const useCollaboratorSuccessCaseQuery = (
  queryContext?: GQLQueryContext<SuccessCase, CollaboratorSuccessCaseQueryVariables>,
) =>
  useGQLQuery<SuccessCase, CollaboratorSuccessCaseQueryVariables>(
    CollaboratorSuccessCaseGQL.query.collaboratorSuccessCase,
    queryContext,
  );

export const useLaunchCustomerSuccessCaseAppFlowMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, LaunchCustomerSuccessCaseAppFlowMutationVariables>,
) =>
  useGQLMutation<SuccessCase, LaunchCustomerSuccessCaseAppFlowMutationVariables>(
    CollaboratorSuccessCaseGQL.mutation.launchCustomerSuccessCaseAppFlow,
    mutationContext,
  );

export const useCollaboratorSuccessCaseAskForReviewMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, CollaboratorSuccessCaseAskForReviewMutationVariables>,
) =>
  useGQLMutation<SuccessCase, CollaboratorSuccessCaseAskForReviewMutationVariables>(
    CollaboratorSuccessCaseGQL.mutation.collaboratorSuccessCaseAskForReview,
    mutationContext,
  );

export const useCollaboratorNeedsContentApprovalSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, CollaboratorNeedsContentApprovalSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, CollaboratorNeedsContentApprovalSuccessCaseMutationVariables>(
    CollaboratorSuccessCaseGQL.mutation.collaboratorNeedsContentApprovalSuccessCase,
    mutationContext,
  );

export const useCollaboratorFinishTheSuccessCaseWithoutApprovalMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, CollaboratorFinishTheSuccessCaseWithoutApprovalMutationVariables>,
) =>
  useGQLMutation<SuccessCase, CollaboratorFinishTheSuccessCaseWithoutApprovalMutationVariables>(
    CollaboratorSuccessCaseGQL.mutation.collaboratorFinishTheSuccessCaseWithoutApproval,
    mutationContext,
  );
