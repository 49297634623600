import {
  TextEditorMenuButtonTrackChanges,
  TextEditorMenuButtonTrackChangesAcceptAll,
  TextEditorMenuButtonTrackChangesRejectAll,
} from ".";
import { TextEditorMenuButtonBackgroundColor } from "./TextEditorMenuButtonBackgroundColor";
import { TextEditorMenuButtonBold } from "./TextEditorMenuButtonBold";
import { TextEditorMenuButtonDeleteFormat } from "./TextEditorMenuButtonDeleteFormat";
import { TextEditorMenuButtonDivider } from "./TextEditorMenuButtonDivider";
import { TextEditorMenuButtonEmojiPicker } from "./TextEditorMenuButtonEmojiPicker";
import { TextEditorMenuButtonFont } from "./TextEditorMenuButtonFont";
import { TextEditorMenuButtonFontColor } from "./TextEditorMenuButtonFontColor";
import { TextEditorMenuButtonFontSize } from "./TextEditorMenuButtonFontSize";
import { TextEditorMenuButtonHeading } from "./TextEditorMenuButtonHeading";
import { TextEditorMenuButtonImageSize } from "./TextEditorMenuButtonImageSize";
import { TextEditorMenuButtonItalic } from "./TextEditorMenuButtonItalic";
import { TextEditorMenuButtonLineHeight } from "./TextEditorMenuButtonLineHeight";
import { TextEditorMenuButtonLink } from "./TextEditorMenuButtonLink";
import { TextEditorMenuButtonOrderedList } from "./TextEditorMenuButtonOrderedList";
import { TextEditorMenuButtonPanel } from "./TextEditorMenuButtonPanel";
import { TextEditorMenuButtonQuote } from "./TextEditorMenuButtonQuote";
import { TextEditorMenuButtonRedo } from "./TextEditorMenuButtonRedo";
import { TextEditorMenuButtonStrike } from "./TextEditorMenuButtonStrike";
import { TextEditorMenuButtonSubscript } from "./TextEditorMenuButtonSubscript";
import { TextEditorMenuButtonSuperscript } from "./TextEditorMenuButtonSuperscript";
import { TextEditorMenuButtonTable } from "./TextEditorMenuButtonTable";
import { TextEditorMenuButtonTextAlign } from "./TextEditorMenuButtonTextAlign";
import { TextEditorMenuButtonTrackChangesAccept } from "./TextEditorMenuButtonTrackChangesAccept";
import { TextEditorMenuButtonTrackChangesReject } from "./TextEditorMenuButtonTrackChangesReject";
import { TextEditorMenuButtonUnderline } from "./TextEditorMenuButtonUnderline";
import { TextEditorMenuButtonUndo } from "./TextEditorMenuButtonUndo";
import { TextEditorMenuButtonUnorderedList } from "./TextEditorMenuButtonUnorderedList";
import { TextEditorMenuButtonUploadFile } from "./TextEditorMenuButtonUploadFile";
import { TextEditorMenuButtonUploadImage } from "./TextEditorMenuButtonUploadImage";
import { TextEditorMenuButtonVariables } from "./TextEditorMenuButtonVariables";

export type TextEditorMenuActions =
  | "textAlignments"
  | "table"
  | "fontStyle"
  | "fontFamily"
  | "fontSize"
  | "link"
  | "fontColor"
  | "background"
  | "title"
  | "divider"
  | "variables"
  | "subSuperScript"
  | "deleteFormat"
  | "quotes"
  | "trackChanges"
  | "uploadImage"
  | "uploadFile"
  | "emojis";

const defaultActions: TextEditorMenuActions[] = [
  "textAlignments",
  "table",
  "fontStyle",
  "fontFamily",
  "fontSize",
  "link",
  "fontColor",
  "background",
  "title",
  "divider",
  "variables",
  "subSuperScript",
  "deleteFormat",
  "quotes",
  "uploadImage",
  "uploadFile",
];

export interface TextEditorMenuProps {
  actions?: TextEditorMenuActions[];
}

export const TextEditorMenu = ({ actions = defaultActions }: TextEditorMenuProps) => {
  return (
    <TextEditorMenuButtonPanel className="bg-gray-100 dark:bg-gray-600 rounded-t">
      <TextEditorMenuButtonUndo />
      <TextEditorMenuButtonRedo />
      {actions.includes("title") && <TextEditorMenuButtonHeading />}
      {actions.includes("fontFamily") && <TextEditorMenuButtonFont />}
      {actions.includes("fontSize") && <TextEditorMenuButtonFontSize />}
      {actions.includes("fontStyle") && (
        <>
          <TextEditorMenuButtonBold />
          <TextEditorMenuButtonItalic />
          <TextEditorMenuButtonUnderline />
          <TextEditorMenuButtonStrike />
        </>
      )}
      {actions.includes("fontColor") && <TextEditorMenuButtonFontColor />}
      {actions.includes("background") && <TextEditorMenuButtonBackgroundColor />}
      {actions.includes("textAlignments") && (
        <>
          <TextEditorMenuButtonTextAlign />
          <TextEditorMenuButtonLineHeight />
          <TextEditorMenuButtonUnorderedList />
          <TextEditorMenuButtonOrderedList />
        </>
      )}
      {actions.includes("emojis") && <TextEditorMenuButtonEmojiPicker />}
      {actions.includes("table") && <TextEditorMenuButtonTable />}
      {actions.includes("subSuperScript") && (
        <>
          <TextEditorMenuButtonSuperscript />
          <TextEditorMenuButtonSubscript />
        </>
      )}
      {actions.includes("quotes") && <TextEditorMenuButtonQuote />}
      {actions.includes("deleteFormat") && <TextEditorMenuButtonDeleteFormat />}
      {actions.includes("divider") && <TextEditorMenuButtonDivider />}
      {actions.includes("link") && <TextEditorMenuButtonLink />}
      {actions.includes("variables") && <TextEditorMenuButtonVariables />}
      {actions.includes("uploadFile") && <TextEditorMenuButtonUploadFile />}
      {actions.includes("uploadImage") && <TextEditorMenuButtonUploadImage />}
      {actions.includes("trackChanges") && (
        <>
          <TextEditorMenuButtonTrackChanges />
          <TextEditorMenuButtonTrackChangesAccept />
          <TextEditorMenuButtonTrackChangesAcceptAll />
          <TextEditorMenuButtonTrackChangesReject />
          <TextEditorMenuButtonTrackChangesRejectAll />
        </>
      )}
      <TextEditorMenuButtonImageSize />
    </TextEditorMenuButtonPanel>
  );
};
