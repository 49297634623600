export interface SuccessCaseContentEditorSidebarQuestionsSelectorProps {
  children?: React.ReactNode;
}

export const SuccessCaseContentEditorSidebarQuestionsSelector = ({
  children,
}: SuccessCaseContentEditorSidebarQuestionsSelectorProps) => {
  return (
    <div className="flex-1 overflow-hidden overflow-y-auto">
      <div className="flex flex-col w-full">{children}</div>
    </div>
  );
};
