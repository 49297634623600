import { GQLError, useErrorTranslation } from "@whyuz/services";
import { isEmpty } from "@whyuz/utils";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

const Modal = lazy(() => import("../Modal").then(({ Modal }) => ({ default: Modal })));
const FormWrapper = lazy(() => import("../Form").then(({ FormWrapper }) => ({ default: FormWrapper })));

export interface ErrorDetailsProps {
  error?: GQLError;
  show: boolean;
  onClose: () => void;
}

export const ErrorDetails = ({ error, show, onClose }: ErrorDetailsProps) => {
  const { t } = useTranslation();
  const { getMessage, getMessageFromErrorCode } = useErrorTranslation();
  const { t: tEntity } = useTranslation("entities");

  return (
    error && (
      <Suspense>
        <Modal type="error" buttonType="none" title={getMessage(error)} open={show} onClose={onClose}>
          {error.fieldErrors && Object.entries(error.fieldErrors).length > 0 && (
            <FormWrapper childrenInColumns={true}>
              {Object.entries(error.fieldErrors).map(([field, fieldError], index) => {
                const messageFromErrorCode = getMessageFromErrorCode(fieldError);
                return (
                  <div
                    key={field + index.toString()}
                    className="flex flex-wrap space-x-2 w-full py-1 px-2 rounded-lg border border-solid m-1">
                    {!isEmpty(fieldError.entity) ? (
                      <div>
                        <b>{"[" + tEntity("entity") + ": "}</b>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <i>{tEntity(((fieldError?.entity?.toLowerCase() ?? "") + ".entityname") as any)}</i>
                        <b>{"]"}</b>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <b>{"[" + tEntity("field") + ": "}</b>
                      <i className="break-all">
                        {!isEmpty(fieldError.entity)
                          ? tEntity(
                              ((fieldError?.entity?.toLowerCase() ?? "") +
                                "." +
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (fieldError.field ? fieldError.field.toLowerCase() : field.toLowerCase())) as any,
                            )
                          : field}
                      </i>
                      <b>{"]"}</b>
                    </div>
                    {!isEmpty(fieldError.value) ? (
                      <div>
                        <b>{"[" + t("word.value") + ": "}</b>
                        <i className="break-all">{fieldError?.value}</i>
                        <b>{"]"}</b>
                      </div>
                    ) : (
                      ""
                    )}
                    {!isEmpty(messageFromErrorCode) ? (
                      <div>
                        <b>{"[" + t("word.error") + ": "}</b>
                        <i className="break-all">{messageFromErrorCode}</i>
                        <b>{"]"}</b>
                      </div>
                    ) : (
                      ""
                    )}
                    {!isEmpty(fieldError.message) && fieldError.message !== messageFromErrorCode && (
                      <div>
                        <b>{"[" + t("word.message") + ": "}</b>
                        <i className="break-all">{fieldError.message}</i>
                        <b>{"]"}</b>
                      </div>
                    )}
                  </div>
                );
              })}
            </FormWrapper>
          )}
        </Modal>
      </Suspense>
    )
  );
};
