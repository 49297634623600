import { Client, StompSubscription } from "@stomp/stompjs";
import { useStomp } from "@whyuz/components";
import { ChatMember, ChatMessage, useHeaders } from "@whyuz/services";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { WSMessageChatMember, WSMessageChatMessage } from "..";

export interface useStompForChatProps {
  chatId: string;
  onNewMessage: (newMessage: ChatMessage) => void;
  onUpdatedMessage: (updatedMessage: ChatMessage) => void;
  onDeletedMessage: (deletedMessage: ChatMessage) => void;
  onNewMember: (newMember: ChatMember) => void;
  onDeletedMember: (deletedMember: ChatMember) => void;
}

export const useStompForChat = ({
  chatId,
  onNewMessage,
  onUpdatedMessage,
  onDeletedMessage,
  onNewMember,
  onDeletedMember,
}: useStompForChatProps) => {
  const headers = useHeaders();
  const subscriptionsRef = useRef<StompSubscription[]>([]);

  const newMessagesEndpoint = useMemo(() => `/chat/${chatId}/sentMessages`, [chatId]);

  const subscribeToNewMessages = useCallback(
    (stompClient: Client) => {
      return stompClient.subscribe(
        newMessagesEndpoint,
        (message) => {
          const parsedMessage = JSON.parse(message.body) as WSMessageChatMessage;
          const newMessage = {
            chatMember: {
              id: parsedMessage.chatMemberId,
              firstName: parsedMessage.firstName,
              lastName: parsedMessage.lastName,
              pictureURL: parsedMessage.pictureURL,
              user: {
                id: parsedMessage.userId,
              },
              mail: parsedMessage.mail,
            },
            createdOn: parsedMessage.createdOn,
            id: parsedMessage.id,
            textHtml: parsedMessage.textHtml,
            deleted: parsedMessage.deleted,
            updated: parsedMessage.updated,
          };
          onNewMessage(newMessage);
        },
        { ...headers, id: Date.now() + "chatnewmessage" + chatId },
      );
    },
    [chatId, headers, newMessagesEndpoint, onNewMessage],
  );

  const updatedMessagesEndpoint = useMemo(() => `/chat/${chatId}/updatedMessages`, [chatId]);

  const subscribeToUpdatedMessages = useCallback(
    (stompClient: Client) => {
      return stompClient.subscribe(
        updatedMessagesEndpoint,
        (message) => {
          const parsedMessage = JSON.parse(message.body) as WSMessageChatMessage;
          const updatedMessage = {
            chatMember: {
              id: parsedMessage.chatMemberId,
              firstName: parsedMessage.firstName,
              lastName: parsedMessage.lastName,
              pictureURL: parsedMessage.pictureURL,
              user: {
                id: parsedMessage.userId,
                firstName: parsedMessage.firstName,
                lastName: parsedMessage.lastName,
              },
              mail: parsedMessage.mail,
            },
            createdOn: parsedMessage.createdOn,
            id: parsedMessage.id,
            textHtml: parsedMessage.textHtml,
            deleted: parsedMessage.deleted,
            updated: parsedMessage.updated,
          };
          onUpdatedMessage(updatedMessage);
        },
        { ...headers, id: Date.now() + "chatupdatemessage" + chatId },
      );
    },
    [chatId, headers, onUpdatedMessage, updatedMessagesEndpoint],
  );

  const deletedMessagesEndpoint = useMemo(() => `/chat/${chatId}/deletedMessages`, [chatId]);

  const subscribeToDeletedMessages = useCallback(
    (stompClient: Client) => {
      return stompClient.subscribe(
        deletedMessagesEndpoint,
        (message) => {
          const parsedMessage = JSON.parse(message.body) as WSMessageChatMessage;
          const deletedMessage = {
            chatMember: {
              id: parsedMessage.chatMemberId,
              firstName: parsedMessage.firstName,
              lastName: parsedMessage.lastName,
              pictureURL: parsedMessage.pictureURL,
              user: {
                id: parsedMessage.userId,
                firstName: parsedMessage.firstName,
                lastName: parsedMessage.lastName,
              },
              mail: parsedMessage.mail,
            },
            createdOn: parsedMessage.createdOn,
            id: parsedMessage.id,
            textHtml: parsedMessage.textHtml,
            deleted: parsedMessage.deleted,
            updated: parsedMessage.updated,
          };
          onDeletedMessage(deletedMessage);
        },
        { ...headers, id: Date.now() + "chatdeletemessage" + chatId },
      );
    },
    [chatId, deletedMessagesEndpoint, headers, onDeletedMessage],
  );

  const newMemberEndpoint = useMemo(() => `/chat/${chatId}/addedMembers`, [chatId]);

  const subscribeToNewMembers = useCallback(
    (stompClient: Client) => {
      return stompClient.subscribe(
        newMemberEndpoint,
        (message) => {
          const parsedMessage = JSON.parse(message.body) as WSMessageChatMember;
          const newMember = {
            id: parsedMessage.id,
            firstName: parsedMessage.firstName,
            lastName: parsedMessage.lastName,
            pictureURL: parsedMessage.pictureURL,
            mail: parsedMessage.mail,
            user: {
              id: parsedMessage.userId || "",
              firstName: parsedMessage.firstName || "",
              lastName: parsedMessage.lastName || "",
            },
          };
          onNewMember(newMember);
        },
        { ...headers, id: Date.now() + "chatnewmember" + chatId },
      );
    },
    [chatId, headers, newMemberEndpoint, onNewMember],
  );

  const deleteMemberEndpoint = useMemo(() => `/chat/${chatId}/deletedMembers`, [chatId]);

  const subscribeToDeletedMembers = useCallback(
    (stompClient: Client) => {
      return stompClient.subscribe(
        deleteMemberEndpoint,
        (message) => {
          const parsedMessage = JSON.parse(message.body) as WSMessageChatMember;
          const deletedMember = {
            id: parsedMessage.id,
            firstName: parsedMessage.firstName,
            lastName: parsedMessage.lastName,
            pictureURL: parsedMessage.pictureURL,
            mail: parsedMessage.mail,
            user: {
              id: parsedMessage.userId || "",
              firstName: parsedMessage.firstName || "",
              lastName: parsedMessage.lastName || "",
            },
          };

          onDeletedMember(deletedMember);
        },
        { ...headers, id: Date.now() + "chatdeletemember" + chatId },
      );
    },
    [chatId, deleteMemberEndpoint, headers, onDeletedMember],
  );

  const unsubscribe = () => {
    subscriptionsRef.current.forEach((subscription) => subscription.unsubscribe());
    subscriptionsRef.current = [];
  };

  const subscribe = useCallback(
    (stompClient: Client) => {
      if (stompClient) {
        subscriptionsRef.current.push(subscribeToNewMessages(stompClient));
        subscriptionsRef.current.push(subscribeToUpdatedMessages(stompClient));
        subscriptionsRef.current.push(subscribeToDeletedMessages(stompClient));
        subscriptionsRef.current.push(subscribeToNewMembers(stompClient));
        subscriptionsRef.current.push(subscribeToDeletedMembers(stompClient));
      }
    },
    [
      subscribeToDeletedMembers,
      subscribeToDeletedMessages,
      subscribeToNewMembers,
      subscribeToNewMessages,
      subscribeToUpdatedMessages,
    ],
  );

  const { stompClient } = useStomp();

  useEffect(() => {
    if (subscriptionsRef.current.length === 0) {
      if (stompClient) {
        subscribe(stompClient);
        return () => {
          unsubscribe();
        };
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return stompClient;
};
