import { FilmIcon } from "@heroicons/react/24/outline";
import { getFileSizeText, getVideoDuration, notifyError } from "@whyuz/utils";
import { useMemo } from "react";
import { DropzoneInputProps, DropzoneOptions, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { VideoPlayer } from "./VideoPlayer";

type DropProps = DropzoneInputProps & Omit<DropzoneOptions, "accept" | "onDrop" | "maxSize">;

export interface DropVideoZoneProps extends Omit<DropProps, "maxSize"> {
  id?: string;
  videoURL?: string;
  alt?: string;
  maxSizeInBytes?: number;
  maxSizeInSeconds?: number;
  theme?: { container?: string; placeholder?: string };
  onVideoAdded: (fileAdded: File) => void;
}

export const DropVideoZone = ({
  id = "dropzone-video",
  videoURL,
  alt,
  maxSizeInBytes = 50 * 1024 * 1024, // 50 MB,
  maxSizeInSeconds,
  theme,
  onVideoAdded,
  multiple = false,
  ...props
}: DropVideoZoneProps) => {
  const { t, i18n } = useTranslation();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    ...props,
    multiple,
    accept: {
      "video/mpeg": [".mpeg", ".mpg"],
      "video/mp4": [".mp4"],
      "video/webm": [".webm"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-flv": [".flv"],
      "video/x-matroska": [".mkv"],
    },
    // The beyond line is commented because if we check here the maxSize and some image exceed it, never enter in accecptedFiles in the onDrop function
    // maxSize: maxSizeInBytes,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((videoFile) => {
        if (maxSizeInSeconds) {
          getVideoDuration(URL.createObjectURL(videoFile))
            .then((duration) => {
              if (!isNaN(duration)) {
                if (maxSizeInSeconds > duration) {
                  onVideoAdded(videoFile);
                } else {
                  notifyError(t("errors.maximumsizeexceeded"));
                }
              } else {
                if (maxSizeInBytes > videoFile.size) {
                  onVideoAdded(videoFile);
                } else {
                  //The video is too big
                  notifyError(t("errors.maximumsizeexceeded"));
                }
              }
            })
            .catch((e) =>
              //Notify error algo fue mal
              console.log(e),
            );
        } else if (maxSizeInBytes > videoFile.size) {
          onVideoAdded(videoFile);
        } else {
          notifyError(t("errors.maximumsizeexceeded"));
        }
      });
    },
  });

  const borderColor = useMemo(
    () =>
      isFocused
        ? "border-primary-500"
        : isDragAccept
          ? "border-primary-500"
          : isDragReject
            ? "border-red-600"
            : undefined,
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className={twMerge("flex items-center justify-center w-full outline-0", theme?.container)} {...getRootProps()}>
      <label
        htmlFor={id}
        className={twMerge(
          "flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600",
          theme?.placeholder,
          borderColor,
        )}>
        {videoURL ? (
          <VideoPlayer src={videoURL} className="p-1 max-w-full max-h-full" />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <FilmIcon className="w-8 h-8 mb-4 text-gray-300 dark:text-gray-400" />
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <Trans
                i18nKey="placeholders.clicktouploadvideoordraganddrop"
                components={{ semibold: <span className="font-semibold" /> }}
              />
            </p>
            <p className="flex flex-col text-xs text-gray-500 dark:text-gray-400 space-y-2 items-center">
              {t("placeholders.videotypes")}
              <div>
                {maxSizeInSeconds
                  ? t("placeholders.videomaxbytesandseconds", {
                      maxsizeinbytes: getFileSizeText(maxSizeInBytes, i18n.language),
                      maxsizeinseconds: maxSizeInSeconds,
                    })
                  : t("placeholders.videomaxbytes", {
                      maxsizeinbytes: getFileSizeText(maxSizeInBytes, i18n.language),
                    })}
              </div>
            </p>
          </div>
        )}
      </label>
      <input id={id} type="file" multiple={multiple} className="hidden" {...getInputProps} />
    </div>
  );
};
