import { SelectMenuItem } from "../../SelectMenu";
import { FontType } from "@whyuz/base-components";

type FontMenuItemType = { id: FontType; name: React.ReactNode };

export const textFontMenuItems: FontMenuItemType[] = [
  {
    id: "Comic_Sans",
    name: <p className='font-["Comic_Sans"]'>Comic Sans</p>,
  },
  {
    id: "Cursive",
    name: <p className='font-["Cursive"]'>Cursive</p>,
  },
  {
    id: "Inter, sans-serif",
    name: <p className='font-["Inter"]'>Inter</p>,
  },
  {
    id: "Garamond",
    name: <p className='font-["Garamond"]'>Garamond</p>,
  },
  {
    id: "Georgia",
    name: <p className='font-["Georgia"]'>Georgia</p>,
  },
  {
    id: "Monospace",
    name: <p className='font-["Monospace"]'>Monospace</p>,
  },
  {
    id: "Sans_Serif",
    name: <p className='font-["Sans_Serif"]'>Sans Serif</p>,
  },
  {
    id: "Serif",
    name: <p className='font-["Serif"]'>Serif</p>,
  },
  {
    id: "Tahoma",
    name: <p className='font-["Tahoma"]'>Tahoma</p>,
  },
  {
    id: "Trebuchet_MS",
    name: <p className='font-["Trebuchet_MS"]'>Trebuchet MS</p>,
  },
  {
    id: "UI_Sans_Serif",
    name: <p className='font-["UI_Sans_Serif"]'>UI Sans Serif</p>,
  },
  {
    id: "Verdana",
    name: <p className='font-["Verdana"]'>Verdana</p>,
  },
];

export const textFontSizeMenuItems: SelectMenuItem<number>[] = [
  {
    id: "8px",
    name: 8, //<p className="text-[8px] ml-3">8</p>,
    entity: 8,
  },
  {
    id: "10px",
    name: 10, //<p className="text-[10px] ml-3">10</p>,
    entity: 10,
  },
  {
    id: "12px",
    name: 12, //<p className="text-[12px] ml-3">12</p>,
    entity: 12,
  },
  {
    id: "14px",
    name: 14, //<p className="text-[14px] ml-3">14</p>,
    entity: 14,
  },
  {
    id: "16px",
    name: 16, //<p className="text-[16px] ml-3">16</p>,
    entity: 16,
  },
  {
    id: "18px",
    name: 18, //<p className="text-[18px] ml-3">18</p>,
    entity: 18,
  },
  {
    id: "20px",
    name: 20, //<p className="text-[20px] ml-3">20</p>,
    entity: 20,
  },
  {
    id: "26px",
    name: 26, //<p className="text-[26px] ml-3">26</p>,
    entity: 26,
  },
  {
    id: "32px",
    name: 32, //<p className="text-[32px] ml-3">32</p>,
    entity: 32,
  },
  {
    id: "40px",
    name: 40, //<p className="text-[40px] ml-3">40</p>,
    entity: 40,
  },
  {
    id: "48px",
    name: 48, //<p className="text-[48px] ml-3">48</p>,
    entity: 48,
  },
  {
    id: "56px",
    name: 56, //<p className="text-[56px] ml-3">56</p>,
    entity: 56,
  },
  {
    id: "64px",
    name: 64, //<p className="text-[64px] ml-3">64</p>,
    entity: 64,
  },
];
