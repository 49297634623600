import { MouseTool } from "@api.video/media-stream-composer";

export interface DrawingSettings {
  color: string;
  lineWidth: number;
  autoEraseDelay: number;
}

export interface RecorderState {
  isScreenShareAvailable: boolean;
  mouseTool: MouseTool;
  screenStreamId?: string;
  cameraStreamId?: string;
  microphoneDeviceId?: string;
  cameraDeviceId?: string;
  isCameraEnabled: boolean;
  isMicrophoneEnabled: boolean;
  isScreenShared: boolean;
  isCameraShapeCircle: boolean;
  isCanvasInitialized: boolean;
  isRecordingInProgress: boolean;
  isCameraMirrorEnabled: boolean;
  drawingSettings: DrawingSettings;
  showDeviceConfigurationModal: boolean;
  videoBlob?: Blob;
}

const enum RECORDER_STATE_ACTION_TYPE {
  TOGGLE_VIDEO_RECORDING,
  TOGGLE_MICROPHONE_ENABLED,
  TOGGLE_CAMERA_ENABLED,
  TOGGLE_MOUSE_TOOL,
  TOGGLE_CAMERA_SHAPE,
  TOGGLE_CAMERA_MIRROR,
  SET_SCREEN_SHARED,
  SET_SCREEN_STREAM_ID,
  SET_CAMERA_DEVICE,
  SET_CAMERA_STREAM_ID,
  SET_MICROPHONE_DEVICE,
  SET_RECORDING_IN_PROGRESS,
  SET_SHOW_DEVICE_CONFIGURATION_MODAL,
  SET_VIDEO_BLOB,
}

interface RecorderStateAction {
  type: RECORDER_STATE_ACTION_TYPE;
}

class ActionToggleVideoRecording implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_VIDEO_RECORDING;
}

class ActionToggleCamera implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_ENABLED;
}

class ActionToggleMicrophone implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_MICROPHONE_ENABLED;
}

class ActionToggleMouseTool implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_MOUSE_TOOL;
}

class ActionToggleCameraShape implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_SHAPE;
}

class ActionToggleCameraMirror implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_MIRROR;
}

class ActionSetScreenShared implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_SCREEN_SHARED;
  payload: boolean;

  constructor(screenShared: boolean) {
    this.payload = screenShared;
  }
}

class ActionSetShowDeviceConfigurationModal implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_SHOW_DEVICE_CONFIGURATION_MODAL;
  payload: boolean;

  constructor(showDeviceConfigurationModal: boolean) {
    this.payload = showDeviceConfigurationModal;
  }
}

class ActionSetCameraDevice implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_CAMERA_DEVICE;
  payload?: string;

  constructor(cameraDeviceId?: string) {
    this.payload = cameraDeviceId;
  }
}

class ActionSetCameraStreamId implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_CAMERA_STREAM_ID;
  payload?: string;

  constructor(cameraStreamId?: string) {
    this.payload = cameraStreamId;
  }
}

class ActionSetMicrophoneDevice implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_MICROPHONE_DEVICE;
  payload?: string;

  constructor(microphoneDeviceId?: string) {
    this.payload = microphoneDeviceId;
  }
}

class ActionSetScreenStreamId implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_SCREEN_STREAM_ID;
  payload?: string;

  constructor(screenStreamId?: string) {
    this.payload = screenStreamId;
  }
}

class ActionSetVideoBlob implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_VIDEO_BLOB;
  payload?: Blob;

  constructor(videoBlob?: Blob) {
    this.payload = videoBlob;
  }
}

class ActionSetRecordingInProgress implements RecorderStateAction {
  type = RECORDER_STATE_ACTION_TYPE.SET_RECORDING_IN_PROGRESS;
  payload: boolean;

  constructor(isRecordingInProgress: boolean) {
    this.payload = isRecordingInProgress;
  }
}

export const Actions = {
  ActionToggleVideoRecording,
  ActionToggleCamera,
  ActionToggleMicrophone,
  ActionToggleMouseTool,
  ActionToggleCameraShape,
  ActionToggleCameraMirror,
  ActionSetScreenShared,
  ActionSetShowDeviceConfigurationModal,
  ActionSetCameraDevice,
  ActionSetMicrophoneDevice,
  ActionSetScreenStreamId,
  ActionSetVideoBlob,
  ActionSetRecordingInProgress,
  ActionSetCameraStreamId,
};

export const recorderStateReducer = (state: RecorderState, action: RecorderStateAction): RecorderState => {
  switch (action.type) {
    case RECORDER_STATE_ACTION_TYPE.TOGGLE_VIDEO_RECORDING: {
      return { ...state, isRecordingInProgress: !state.isRecordingInProgress };
    }

    case RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_ENABLED: {
      return { ...state, isCameraEnabled: !state.isCameraEnabled };
    }

    case RECORDER_STATE_ACTION_TYPE.TOGGLE_MICROPHONE_ENABLED: {
      return { ...state, isMicrophoneEnabled: !state.isMicrophoneEnabled };
    }

    case RECORDER_STATE_ACTION_TYPE.TOGGLE_MOUSE_TOOL: {
      return { ...state, mouseTool: state.mouseTool === "draw" ? "move-resize" : "draw" };
    }

    case RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_SHAPE: {
      return { ...state, isCameraShapeCircle: !state.isCameraShapeCircle };
    }

    case RECORDER_STATE_ACTION_TYPE.TOGGLE_CAMERA_MIRROR: {
      return { ...state, isCameraMirrorEnabled: !state.isCameraMirrorEnabled };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_SCREEN_SHARED: {
      const screenShared = (action as ActionSetScreenShared).payload;
      if (screenShared === state.isScreenShared) return state;
      else return { ...state, isScreenShared: screenShared };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_SHOW_DEVICE_CONFIGURATION_MODAL: {
      const showDeviceConfigurationModal = (action as ActionSetShowDeviceConfigurationModal).payload;
      if (showDeviceConfigurationModal === state.showDeviceConfigurationModal) return state;
      else return { ...state, showDeviceConfigurationModal: showDeviceConfigurationModal };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_RECORDING_IN_PROGRESS: {
      const recordingInProgress = (action as ActionSetScreenShared).payload;
      if (recordingInProgress === state.isRecordingInProgress) return state;
      else return { ...state, isRecordingInProgress: recordingInProgress };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_CAMERA_DEVICE: {
      const cameraDeviceId = (action as ActionSetCameraDevice).payload;
      if (state.cameraDeviceId === cameraDeviceId) return state;
      else return { ...state, cameraDeviceId: cameraDeviceId };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_CAMERA_STREAM_ID: {
      const cameraStreamId = (action as ActionSetCameraStreamId).payload;
      if (state.cameraStreamId === cameraStreamId) return state;
      else return { ...state, cameraStreamId: cameraStreamId };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_SCREEN_STREAM_ID: {
      const screenStreamId = (action as ActionSetScreenStreamId).payload;
      if (state.screenStreamId === screenStreamId) return state;
      else return { ...state, screenStreamId: screenStreamId };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_MICROPHONE_DEVICE: {
      const microphoneDeviceId = (action as ActionSetMicrophoneDevice).payload;
      if (state.microphoneDeviceId === microphoneDeviceId) return state;
      else return { ...state, microphoneDeviceId: microphoneDeviceId };
    }

    case RECORDER_STATE_ACTION_TYPE.SET_VIDEO_BLOB: {
      const videoBlob = (action as ActionSetVideoBlob).payload;
      if (state.videoBlob === videoBlob) return state;
      else return { ...state, videoBlob: videoBlob };
    }
  }
  throw new Error("recorderStateReducer is not able to manage actions of type: " + String(action.type));
};
