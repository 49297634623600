import { useCallback, useRef, useState } from "react";
import { DEFAULT_PAGINATION, Pagination } from "../types/pagination";

export interface InfiniteScrollPaginationCallbackProps {
  pageNumber: number;
  pageSize: number;
  setPagination: (newPage: Pagination) => void;
}

export interface UseInfiniteScrollProps {
  onScrollReachEnd: ({ pageNumber, pageSize, setPagination }: InfiniteScrollPaginationCallbackProps) => void;
}

export const useInfiniteScroll = <T extends HTMLElement>({ onScrollReachEnd }: UseInfiniteScrollProps) => {
  const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
  const observer = useRef<IntersectionObserver>();
  const lastHTMLElementRef = useCallback<(node: T) => void>(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pagination && pagination.number < pagination.totalPages - 1) {
            onScrollReachEnd({ pageNumber: pagination.number + 1, pageSize: pagination.size, setPagination });
          }
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [pagination, onScrollReachEnd],
  );

  return {
    lastHTMLElementRef,
    setPagination,
    pagination,
  };
};
