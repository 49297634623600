import { CardFrame, TextEditor, useTextEditor } from "@whyuz/components";
import { MailTemplateLanguage, MailTemplateType } from "@whyuz/services";
import React, { forwardRef } from "react";
import { useMailTemplateTypesTranslations } from "../MailTemplateTypes.tsx";

interface MailTemplateLanguageCardProps extends React.HTMLAttributes<HTMLDivElement> {
  mailTemplateLanguage: MailTemplateLanguage;
}

export const MailTemplateLanguageCard = forwardRef<HTMLDivElement, MailTemplateLanguageCardProps>(
  ({ mailTemplateLanguage, ...props }: MailTemplateLanguageCardProps, ref) => {
    const mailTemplateTypesTranslations = useMailTemplateTypesTranslations();

    const mailBodyEditor = useTextEditor({
      content: mailTemplateLanguage?.body,
      contentClassName: "p-2",
    });

    return (
      <CardFrame {...props} ref={ref}>
        <div className="text-primary-600 font-semibold text-sm">{mailTemplateLanguage.mailTemplate?.name}</div>
        <div className="space-x-0 md:space-x-2 flex flex-col md:flex-row">
          <div className="text-xs text-gray-600 font-semibold dark:text-gray-400 truncate">
            {mailTemplateLanguage.language?.name}
          </div>
          <div className="text-xs text-gray-600 dark:text-gray-400 truncate">
            {mailTemplateTypesTranslations[mailTemplateLanguage.mailTemplate?.type as MailTemplateType]}
          </div>
        </div>
        <div className="px-2 pt-2 text-sm truncate">{mailTemplateLanguage.subject}</div>
        <TextEditor editor={mailBodyEditor}>
          <TextEditor.ContentEditor className="h-[100px]" />
        </TextEditor>
      </CardFrame>
    );
  },
);
