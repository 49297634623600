import { EditorContent } from "@tiptap/react";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../hooks/useTextEditorContext";

export interface TextEditorContentProps {
  id?: string;
  className?: string;
}

export const TextEditorContent = ({ id, className }: TextEditorContentProps) => {
  const { editor, disabled } = useTextEditorContext();
  return (
    <EditorContent
      id={id}
      className={twMerge("h-auto overflow-y-auto max-h-96", className)}
      editor={editor}
      disabled={disabled}
    />
  );
};
