import { leadingZeroFormatter } from "./stringUtils.ts";

// (int) The current year
export const THIS_YEAR = new Date().getFullYear();

// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = new Date().getMonth() + 1;

export const getDateOneYearAgo = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const getDateOneMonthAgo = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
};

export const getDateOneWeekAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
};

export const waitMS = (delayInMS?: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInMS));
};

export const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getFirstWeekDayOfMonth = (year: number, month: number) => {
  return new Date(year, month, 1).getDay();
};

export const now = () => {
  return new Date().toLocaleString();
};

export const getLocalesTimeStringHHMM = (dateToTransform: string): string => {
  const date = new Date(dateToTransform);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const getLocaleTimeStringHHMMSS = (totalSeconds: number, showLeadingZeros: boolean) => {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / 3600);
  if (hours === 0 && !showLeadingZeros) {
    return `${minutes}:${leadingZeroFormatter.format(seconds)}`;
  } else {
    return `${hours}:${leadingZeroFormatter.format(minutes)}:${leadingZeroFormatter.format(seconds)}`;
  }
};

export const getLocaleDateTimeString = (
  locale: Intl.LocalesArgument,
  date: Date,
  dateFormatOptions?: Intl.DateTimeFormatOptions,
  withComma = false,
) => {
  const options: Intl.DateTimeFormatOptions = {
    ...dateFormatOptions,
    year: dateFormatOptions?.year ?? "numeric",
    month: dateFormatOptions?.month ?? "numeric",
    day: dateFormatOptions?.day ?? "numeric",
    hour: dateFormatOptions?.hour ?? "numeric",
    minute: dateFormatOptions?.minute ?? "numeric",
    second: dateFormatOptions?.second ?? "numeric",
    hour12: dateFormatOptions?.hour12 ?? false,
  };

  const localeDateTimeString = date.toLocaleDateString(locale, options);
  return withComma ? localeDateTimeString : localeDateTimeString.replace(",", "");
};

export const getLocaleDateString = (
  locale: Intl.LocalesArgument,
  date: Date,
  dateFormatOptions?: Intl.DateTimeFormatOptions,
) => {
  const options: Intl.DateTimeFormatOptions = {
    ...dateFormatOptions,
    year: dateFormatOptions?.year ?? "numeric",
    month: dateFormatOptions?.month ?? "numeric",
    day: dateFormatOptions?.day ?? "numeric",
    hour: undefined,
    minute: undefined,
    second: undefined,
  };

  return date.toLocaleDateString(locale, options);
};

export const calculateDateDifferenceInSeconds = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / 1000);
};

export const calculateDateDifferenceInMinutes = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / (1000 * 60));
};

export const calculateDateDifferenceInHours = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / (1000 * 3600));
};

export const calculateDateDifferenceInDays = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const calculateDateDifferenceInMonths = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  return Math.abs((date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth()));
};

export const calclateDateDifferenceInYears = (date1?: Date, date2?: Date): number | undefined => {
  if (!date1 || !date2) {
    return undefined;
  }

  return Math.abs(date2.getFullYear() - date1.getFullYear());
};
