import { useContext } from "react";
import { UserContext, UserContextType } from "../context";

export const useUser = () => {
  const context = useContext(UserContext) as UserContextType;

  if (!context) {
    throw new Error("useUser must be used within a UserContextProvider");
  }

  return context;
};
