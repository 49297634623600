import { ErrorMessageType, GQLError } from "@whyuz/services";
import React, { FormEventHandler, forwardRef } from "react";
import { ErrorMessage } from "../Error";
import { SpinnerCard } from "../Spinner";

export type FormBackgroundColorType = "none" | "lightBlue";

export interface FormProps {
  id: string;
  error?: { message?: ErrorMessageType; details: GQLError };
  className?: string;
  bgColor?: FormBackgroundColorType;
  children?: React.ReactNode;
  showActionInProgress?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const getBgColorClassName = (bgColor: FormBackgroundColorType | undefined) => {
  if (!bgColor) {
    return "";
  }

  switch (bgColor) {
    case "lightBlue":
      return "bg-whyuzblue-50";
    default:
      return "";
  }
};

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ id, error, showActionInProgress = false, children, className, bgColor, onSubmit }: FormProps, ref) => {
    const DEFAULT_FORM_CLASSNAME = "flex-col flex-wrap px-2 sm:px-4 pb-4 " + getBgColorClassName(bgColor);
    return (
      <ErrorMessage error={error?.message} details={error?.details}>
        <SpinnerCard showSpinner={showActionInProgress}>
          <form id={id} ref={ref} className={className ?? DEFAULT_FORM_CLASSNAME} onSubmit={onSubmit}>
            {children}
          </form>
        </SpinnerCard>
      </ErrorMessage>
    );
  },
);
