import { Country } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useCountryTranslation = () => {
  const { i18n } = useTranslation();

  return useCallback(
    (country: Country) => (i18n.language === "en" ? country.name : getPropertyByPath(country, i18n.language)) as string,
    [i18n.language],
  );
};
