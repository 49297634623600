import { useTranslation } from "react-i18next";
import { BsCameraVideo } from "react-icons/bs";
import { TbMicrophone } from "react-icons/tb";
import { Modal } from "../../../Modal";
import { SelectMenu } from "../../../SelectMenu";

interface Props {
  open: boolean;
  audioDevices: MediaDeviceInfo[];
  videoDevices: MediaDeviceInfo[];
  selectedAudioDeviceId: string | undefined;
  selectedCameraDeviceId: string | undefined;
  onSelectedAudioDevice: (audioDeviceId: string) => void;
  onSelectedCameraDevice: (cameraDeviceId: string) => void;
  onClose: () => void;
}

export const DeviceConfigurationModal = ({
  open,
  audioDevices,
  videoDevices,
  selectedAudioDeviceId,
  selectedCameraDeviceId,
  onSelectedAudioDevice,
  onSelectedCameraDevice,
  onClose,
}: Props) => {
  const { t } = useTranslation("videoRecorder");

  const audioDevicesMenuItems = audioDevices.map((device) => {
    return { id: device.deviceId, name: device.label };
  });

  const videoDevicesMenuItems = videoDevices.map((device) => {
    return { id: device.deviceId, name: device.label };
  });

  return (
    <Modal
      type="none"
      buttonType="accept"
      open={open}
      onClose={onClose}
      title={t("configurationmodal.messages.title")}
      text={t("configurationmodal.messages.description")}>
      <div className="pt-3 sm:pt-5">
        <div className="flex items-center py-2">
          <label
            htmlFor="whyuz_videoRecorder_audioSource"
            className="inline-block text-left align-middle text-2xl pr-2">
            <TbMicrophone />
          </label>
          <div className="min-w-[80%] sm:w-96">
            <SelectMenu
              menuItems={audioDevicesMenuItems}
              fullWidth={true}
              selectedItemId={selectedAudioDeviceId}
              onChange={(device) => onSelectedAudioDevice(device.id)}
            />
          </div>
        </div>
        <div className="flex items-center py-2">
          <label
            htmlFor="whyuz_videoRecorder_videoSource"
            className="inline-block text-left align-middle text-2xl pr-2">
            <BsCameraVideo />
          </label>
          <div className="min-w-[80%] sm:w-96">
            <SelectMenu
              menuItems={videoDevicesMenuItems}
              fullWidth={true}
              selectedItemId={selectedCameraDeviceId}
              onChange={(device) => onSelectedCameraDevice(device.id)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
