import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiFontSize } from "react-icons/bi";
import { textFontSizeMenuItems } from "../../../Fonts";
import { SelectMenu, SelectMenuItem } from "../../../SelectMenu";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonFontSizeProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonFontSize = ({ hideOnMobile = true, children }: TextEditorMenuButtonFontSizeProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const getSelectedFontSizeSelectedOnFocus = () => {
    return editor ? (editor.getAttributes("textStyle").fontSize as string) : undefined;
  };

  const handleTextFontSizeSelected = (item: SelectMenuItem<unknown>) => {
    if (editor) {
      editor.chain().focus().setFontSize(item.id).run();
    }
  };

  return (
    <Tooltip content={t("buttons.fontsize")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={textFontSizeMenuItems}
          selectedItemId={getSelectedFontSizeSelectedOnFocus()}
          showSelectedOnButton={false}
          itemNotSelected={children ?? <BiFontSize className={iconSelectStyle} />}
          onChange={handleTextFontSizeSelected}
          textPadding="my-1 p-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          textMargin=""
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={true}
          showChevron={false}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};
