import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { CardHeader } from "./CardHeader.tsx";
interface CardFrameProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const CardFrame = forwardRef<HTMLDivElement, CardFrameProps>(
  ({ children, className, title, ...props }: CardFrameProps, ref) => {
    return (
      <div
        className={twMerge(
          "p-4 border border-gray-200 dark:border-gray-600 rounded select-none cursor-pointer flex flex-col",
          className,
        )}
        ref={ref}
        {...props}>
        {children}
      </div>
    );
  },
);

CardFrame.displayName = "Card";
export const Card = Object.assign(CardFrame, {
  Header: CardHeader,
});
