import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteSidebarTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteSidebarThemes {
  default = "default",
}

const FlowbiteDefaultSidebarTheme = FlowbiteDefaultTheme.sidebar;

export const FlowbiteSidebarThemes: Record<EnumFlowbiteSidebarThemes, RecursivePartial<FlowbiteSidebarTheme>> = {
  default: {
    root: {
      base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700",
      inner: twMerge(FlowbiteDefaultSidebarTheme.root.inner, "bg-white px-2"),
    },
    // collapse: {
    //   button: twMerge(FlowbiteDefaultSidebarTheme.collapse.button, "py-1"),
    //   label: {
    //     base: twMerge(FlowbiteDefaultSidebarTheme.collapse.label.base, "text-sm"),
    //   },
    // },
    // item: {
    //   base: twMerge(FlowbiteDefaultSidebarTheme.item.base, "py-1"),
    //   content: {
    //     base: twMerge(FlowbiteDefaultSidebarTheme.item.content.base, "text-sm"),
    //   },
    // },
  },
};
