import { twMerge } from "tailwind-merge";

export interface PageButtonsContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const PageButtonsContainer = ({ children, className }: PageButtonsContainerProps) => {
  return (
    <div className={twMerge("w-full flex md:flex-row items-center justify-end space-x-2", className)}>{children}</div>
  );
};
