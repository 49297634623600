import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteDropdownItemTheme } from "flowbite-react";

enum EnumFlowbiteDropdownItemThemes {
  default = "default",
  noStyle = "noStyle",
}

const FlowbiteDefaultDropdownItemTheme = FlowbiteDefaultTheme.dropdown.floating.item;

export const FlowbiteDropdownItemThemes: Record<
  EnumFlowbiteDropdownItemThemes,
  RecursivePartial<FlowbiteDropdownItemTheme>
> = {
  default: FlowbiteDefaultDropdownItemTheme,
  noStyle: {
    container: "",
    base: "",
    icon: "",
  },
};
