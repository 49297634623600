import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiItalic } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyle, buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonItalicProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonItalic = ({ className, children }: TextEditorMenuButtonItalicProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName = editor && editor.isActive("italic") ? twMerge(buttonStyle, activeButtonStyle) : buttonStyle;

  return (
    <Tooltip content={t("buttons.italics")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().toggleItalic().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}
      >
        {children ?? <BiItalic className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
