import { InformationCircleIcon } from "@heroicons/react/24/solid";

export interface InformationListItemProps {
  text: string;
}

export const InformationListItem = ({ text }: InformationListItemProps) => {
  return (
    <li className="flex items-center space-x-2">
      <InformationCircleIcon className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" />
      <span>{text}</span>
    </li>
  );
};
