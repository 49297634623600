import { RIGHT_BUTTON } from "@whyuz/data";
import { ButtonProps, Button as FlowbiteButton, Tooltip } from "flowbite-react";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export interface ButtonCustomProps extends ButtonProps {
  children?: React.ReactNode;
  tooltipText?: string;
  showText?: boolean;
  onPointerDown?: () => void;
  leftIcon?: ReactNode;
  color?: string;
  classname?: string;
  breakpointSM?: boolean;
  disabled?: boolean;
}

export const Button = ({
  children,
  showText = true,
  onPointerDown,
  leftIcon,
  tooltipText,
  color = "primaryLight",
  size = "sm",
  classname = "",
  breakpointSM = true,
  disabled,
  ...props
}: ButtonCustomProps) => {
  return (
    <Tooltip content={tooltipText} placement="bottom" className={showText ? "hidden" : undefined}>
      <FlowbiteButton
        {...props}
        disabled={disabled}
        size={size}
        color={color}
        theme={showText ? undefined : { size: { sm: "px-2.5 min-h-[32px]" } }}
        className={twMerge("flex px-0 whitespace-nowrap", showText ? "sm:px-0.5" : "", classname)}
        onPointerDown={(e: React.PointerEvent) => {
          if (e.button !== RIGHT_BUTTON && onPointerDown && !disabled) {
            onPointerDown();
          }
        }}>
        {leftIcon}
        {showText && children && (
          <span className={twMerge("sm:block", leftIcon ? "ml-2" : undefined, breakpointSM ? "hidden" : undefined)}>
            {children}
          </span>
        )}
      </FlowbiteButton>
    </Tooltip>
  );
};
