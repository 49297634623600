import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTableTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteTableThemes {
  default = "default",
}

const FlowbiteDefaultTableTheme = FlowbiteDefaultTheme.table;

export const FlowbiteTableThemes: Record<EnumFlowbiteTableThemes, RecursivePartial<FlowbiteTableTheme>> = {
  default: {
    root: {
      base: twMerge(FlowbiteDefaultTableTheme.root.base, "table-fixed"),
    },
    head: {
      cell: {
        base: twMerge(
          FlowbiteDefaultTableTheme.head.cell.base,
          // Here avoid use overflow hidden as the filters dropdown disappear
          "px-2 first:pl-4 last:pr-4",
        ),
      },
    },
    body: {
      base: twMerge(FlowbiteDefaultTableTheme.body.base, "divide-y border-b dark:border-gray-700"),
      cell: {
        base: twMerge(
          FlowbiteDefaultTableTheme.body.cell.base,
          "overflow-hidden text-ellipsis whitespace-nowrap px-2 first:pl-4 last:pr-4",
        ),
      },
    },
    row: {
      base: twMerge(FlowbiteDefaultTableTheme.row.base, "bg-white dark:border-gray-700 dark:bg-gray-800"),
    },
  },
};
