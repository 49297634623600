import { PhotoIcon } from "@heroicons/react/24/outline";
import { getFileSizeText, notifyError } from "@whyuz/utils";
import { useMemo } from "react";
import { DropzoneInputProps, DropzoneOptions, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

type DropProps = DropzoneInputProps & Omit<DropzoneOptions, "accept" | "onDrop" | "maxSize">;

export interface DropImageZoneProps extends Omit<DropProps, "maxSize"> {
  id?: string;
  imageURL?: string;
  alt?: string;
  maxSizeInBytes?: number;
  theme?: { container?: string; placeholder?: string; image?: string };
  onImageAdded: (fileAdded: File) => void;
}

export const DropImageZone = ({
  id = "dropzone-image",
  imageURL,
  alt,
  maxSizeInBytes = 1024 * 1024, // 1 MB
  theme,
  onImageAdded,
  multiple = false,
  ...props
}: DropImageZoneProps) => {
  const { t, i18n } = useTranslation();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    ...props,
    multiple,
    accept: {
      "image/bmp": [".bmp"],
      "image/gif": [".gif"],
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/svg+xml": [".svg"],
      "image/tiff": [".tif", ".tiff"],
    },
    // The beyond line is commented because if we check here the maxSize and some image exceed it, never enter in accecptedFiles in the onDrop function
    // maxSize: maxSizeInBytes,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((imageFile) => {
        if (!maxSizeInBytes || maxSizeInBytes > imageFile.size) {
          onImageAdded(imageFile);
        } else {
          notifyError(t("errors.maximumsizeexceeded"));
        }
      });
    },
  });

  const borderColor = useMemo(
    () =>
      isFocused
        ? "border-primary-500"
        : isDragAccept
          ? "border-primary-500"
          : isDragReject
            ? "border-red-600"
            : undefined,
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className={twMerge("flex items-center justify-center w-full outline-0", theme?.container)} {...getRootProps()}>
      <label
        htmlFor={id}
        className={twMerge(
          "flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600",
          theme?.placeholder,
          borderColor,
        )}>
        {imageURL ? (
          <img src={imageURL} alt={alt} className={twMerge("p-1 max-w-full max-h-full", theme?.image)} />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <PhotoIcon className="w-8 h-8 mb-4 text-gray-300 dark:text-gray-400" />
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <Trans
                i18nKey="placeholders.clicktouploadimageordraganddrop"
                components={{ semibold: <span className="font-semibold" /> }}
              />
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {t("placeholders.imagetypes")}
              {"<" + getFileSizeText(maxSizeInBytes, i18n.language)}
            </p>
          </div>
        )}
      </label>
      <input id={id} type="file" multiple={multiple} className="hidden" {...getInputProps} />
    </div>
  );
};
