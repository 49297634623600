import { twMerge } from "tailwind-merge";

export interface BannerTitleProps {
  title?: string;
  className?: string;
}

export const BannerTitle = ({ title, className }: BannerTitleProps) => {
  return <div className={twMerge("font-semibold text-sm", className)}>{title}</div>;
};
