import { Editor } from "@tiptap/react";
import { FILE_TYPE } from "@whyuz/data";
import { Asset, useServerFiles } from "@whyuz/services";
import { getFileSizeText, notifyError } from "@whyuz/utils";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { FileIcon, getFileTypeFromMimeType } from "../../File";
import { IMAGE_SIZE, IMAGE_SIZE_PROPERTIES } from "../components/extensions/ImageExtension";

export const useUploadFilesInTextEditor = () => {
  const { t, i18n } = useTranslation("textEditor");
  const serverFiles = useServerFiles();

  const insertImageTag = (
    editor: Editor,
    src: string,
    alt: string,
    title: string,
    withLink: boolean,
    imageSize: IMAGE_SIZE | undefined,
  ) => {
    if (editor) {
      const imageSizeProperties = imageSize ? IMAGE_SIZE_PROPERTIES[imageSize] : {};
      const commands = editor.chain().setImage({ src, alt, title, ...imageSizeProperties });
      if (withLink) {
        commands.selectParentNode().setLink({ href: src, target: "_blank" });
      }
      commands.focus("end").run();
    }
  };

  const uploadImage = (
    file: File | null | undefined,
    withLink = false,
    editor: Editor | null,
    disabled: boolean,
    uploadPublicFiles: boolean,
    imageSize?: IMAGE_SIZE,
  ) => {
    if (editor && file && !disabled) {
      if (getFileTypeFromMimeType(file.type) === FILE_TYPE.IMAGE) {
        if (uploadPublicFiles) {
          serverFiles
            .uploadPublicFileAndGetURL(file)
            .then((response) => {
              insertImageTag(editor, response, file.name, file.name, withLink, imageSize);
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                alert(t("errors.errorupladingimage"));
              }
            });
        } else {
          serverFiles
            .uploadFileAndGetURL(file, uploadPublicFiles)
            .then((response) => {
              insertImageTag(editor, response, file.name, file.name, withLink, imageSize);
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                alert(t("errors.errorupladingimage"));
              }
            });
        }
      } else {
        notifyError(t("errors.wrongimagetype"));
      }
    }
  };

  const insertFileCardTag = (editor: Editor, asset: Asset) => {
    if (editor) {
      const url = serverFiles.getFileURIByAssetToken(asset.accessToken as string);
      const fileName = asset.originalFilename as string;
      const fileSizeText = getFileSizeText(asset.fileSizeBytes as number, i18n.language);
      const fileExtension = asset.originalExtension as string;
      const iconString = renderToString(<FileIcon fileExtension={fileExtension} />);
      editor
        .chain()
        .insertContent(
          // `<FileCard id="${id}" fileName="${fileName}" fileSizeBytes="${fileSizeBytes}" url="${url}" fileExtension="${fileExtension}"></FileCard><br>`,
          // prettier-ignore
          `<div style="background-color: rgb(255,255,255); border: 1px solid rgb(209, 213, 219); border-radius: 0.25rem; display: flex; flex-direction: row; padding: 0.5rem; width: fit-content;">` +
            `<a href=${url} target="_blank">` +
              `<div style="display: flex; flex-direction: row; align-items: center; max-width: 300px">` +
                `<div style="flex: none">`+
                  `${iconString}` +
                `</div>`+
                `<div style="width: 100%; display: flex; flex-direction: column; margin-left: 0.5rem; max-width: -webkit-fill-available; overflow: auto">` +
                  `<div style="color: rgb(0,0,0); font-size: 0.875rem; line-height: 1.25rem; display: flex">` +
                    `<p style="color: rgb(0,0,0); width: fit-content; overflow: hidden; margin: 0px">${fileName}</p>` +
                  `</div>` +
                  `<div style="color: rgb(107,114,128); font-size: 0.75rem; line-height: 1rem">` +
                    `<p style="color: rgb(107,114,128); font-size: 0.75rem; line-height: 1rem; margin: 0px">${fileSizeText}</p>` +
                  `</div>` +
                `</div>` +
              `</div>` +
            `</a>` +
          `</div>`,
        )
        .focus("end")
        .run();
    }
  };

  const uploadFile = (
    file: File | null | undefined,
    editor: Editor | null,
    disabled: boolean,
    uploadPublicFiles: boolean,
  ) => {
    if (editor && file && !disabled) {
      if (uploadPublicFiles) {
        serverFiles
          .uploadPublicFileAndGetAsset(file)
          .then((response) => {
            insertFileCardTag(editor, response.data);
          })
          .catch((error) => {
            console.log(error);
            if (error) {
              alert(t("errors.errorupladingfile"));
            }
          });
      } else {
        serverFiles
          .uploadFileAndGetAsset(file, uploadPublicFiles)
          .then((response) => {
            insertFileCardTag(editor, response.data);
          })
          .catch((error) => {
            console.log(error);
            if (error) {
              alert(t("errors.errorupladingfile"));
            }
          });
      }
    }
  };

  return { uploadImage, uploadFile };
};
