import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";

export interface SuccessCaseContentEditorQuestionNavigationButtonsProps extends React.PropsWithChildren {
  enablePreviousQuestion: boolean;
  onPreviousQuestion: () => void;
  enableNextQuestion: boolean;
  onNextQuestion: () => void;
}

export const SuccessCaseContentEditorQuestionNavigationButtons = ({
  enablePreviousQuestion,
  onPreviousQuestion,
  enableNextQuestion,
  onNextQuestion,
}: SuccessCaseContentEditorQuestionNavigationButtonsProps) => {
  const { t: tSuccessCaseCE } = useTranslation("successcasecontenteditor");
  return (
    <div className="flex flex-none p-2 sm:py-6 sm:pr-8 items-center space-x-2 justify-end">
      <Tooltip content={tSuccessCaseCE("button.previousquestiontooltip")}>
        <button
          type="button"
          disabled={!enablePreviousQuestion}
          className="rounded-full bg-primary-600 dark:bg-primary-700 p-1.5 cursor-pointer disabled:bg-primary-700 disabled:cursor-not-allowed"
          onPointerDown={(e) => {
            if (enablePreviousQuestion && e.button !== RIGHT_BUTTON) {
              onPreviousQuestion();
            }
          }}>
          <ChevronUpIcon className="h-4 w-4 text-white stroke-2" />
        </button>
      </Tooltip>
      <Tooltip content={tSuccessCaseCE("button.nextquestiontooltip")}>
        <button
          type="button"
          disabled={!enableNextQuestion}
          className="rounded-full bg-primary-600 dark:bg-primary-700 p-1.5 cursor-pointer disabled:bg-primary-700 disabled:cursor-not-allowed"
          onPointerDown={(e) => {
            if (enableNextQuestion && e.button !== RIGHT_BUTTON) {
              onNextQuestion();
            }
          }}>
          <ChevronDownIcon className="h-4 w-4 text-white stroke-2" />
        </button>
      </Tooltip>
    </div>
  );
};
