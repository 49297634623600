import { useDebouncedText } from "@whyuz/hooks";
import { Textarea, TextareaProps } from "flowbite-react";

export interface DebouncedTextAreaProps extends Omit<TextareaProps, "value" | "onChange"> {
  value?: string | null;
  debounceTimeMS?: number;
  onChange: (debouncedText: string | null | undefined) => void;
  onUndebouncedChange?: (debouncedText: string | null | undefined) => void;
}

export const DebouncedTextArea = ({
  onChange,
  onUndebouncedChange,
  value,
  debounceTimeMS = 750,
  ...props
}: DebouncedTextAreaProps) => {
  const debouncedText = useDebouncedText(debounceTimeMS, value ?? undefined);

  debouncedText.setOnChange(onChange);
  if (onUndebouncedChange) {
    debouncedText.setOnUndebouncedChange(onUndebouncedChange);
  }

  return <Textarea value={debouncedText.undebouncedText ?? ""} onChange={debouncedText.handleTextChange} {...props} />;
};
