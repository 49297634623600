import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  ApprovalFlowExecution,
  ApprovalFlowExecutionPage,
  ApprovalFlowExecutionQueryVariables,
  ApprovalFlowExecutionsQueryVariables,
  ApprovalFlowExecutionUser,
  CancelApprovalFlowExecutionMutationVariables,
  DeleteApprovalFlowExecutionMutationVariables,
  MutationApproveFlowExecutionUserAndCheckExecutionArgs,
  MutationDeclineFlowExecutionUserAndCheckExecutionArgs,
  MutationLaunchApprovalFlowExecutionArgs,
  MutationProcessExecutionArgs,
  ResendApprovalFlowExecutionUsersEmailsMutationVariables,
  UpdateApprovalFlowExecutionMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowExecutionGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useCancelApprovalFlowExecutionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, CancelApprovalFlowExecutionMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecution, CancelApprovalFlowExecutionMutationVariables>(
    ApprovalFlowExecutionGQL.mutation.cancelApprovalFlowExecution,
    mutationContext,
  );

//This function ONLY change de appFlow from not_launched to launched and change the first step status
export const useLaunchApprovalFlowExecutionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, MutationLaunchApprovalFlowExecutionArgs>,
) =>
  useGQLMutation<ApprovalFlowExecution, MutationLaunchApprovalFlowExecutionArgs>(
    ApprovalFlowExecutionGQL.mutation.launchApprovalFlowExecution,
    mutationContext,
  );

export const useResendApprovalFlowExecutionUsersEmailsMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, ResendApprovalFlowExecutionUsersEmailsMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecution, ResendApprovalFlowExecutionUsersEmailsMutationVariables>(
    ApprovalFlowExecutionGQL.mutation.resendApprovalFlowExecutionUsersEmails,
    mutationContext,
  );

//Check what do this function, NOT ONLY LAUNCH but continue with the process
export const useLaunchExecutionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, MutationProcessExecutionArgs>,
) =>
  useGQLMutation<ApprovalFlowExecution, MutationProcessExecutionArgs>(
    ApprovalFlowExecutionGQL.mutation.processExecution,
    mutationContext,
  );

export const useApproveExecutionUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionUser, MutationApproveFlowExecutionUserAndCheckExecutionArgs>,
) =>
  useGQLMutation<ApprovalFlowExecutionUser, MutationApproveFlowExecutionUserAndCheckExecutionArgs>(
    ApprovalFlowExecutionGQL.mutation.approveUser,
    mutationContext,
  );

export const useDeclineExecutionUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionUser, MutationDeclineFlowExecutionUserAndCheckExecutionArgs>,
) =>
  useGQLMutation<ApprovalFlowExecutionUser, MutationDeclineFlowExecutionUserAndCheckExecutionArgs>(
    ApprovalFlowExecutionGQL.mutation.declineUser,
    mutationContext,
  );

export const useUpdateApprovalFlowExecutionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, UpdateApprovalFlowExecutionMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecution, UpdateApprovalFlowExecutionMutationVariables>(
    ApprovalFlowExecutionGQL.mutation.updateApprovalFlowExecution,
    mutationContext,
  );

export const useDeleteApprovalFlowExecutionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecution, DeleteApprovalFlowExecutionMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecution, DeleteApprovalFlowExecutionMutationVariables>(
    ApprovalFlowExecutionGQL.mutation.deleteApprovalFlowExecution,
    mutationContext,
  );

export const useApprovalFlowExecutionsLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionPage, ApprovalFlowExecutionsQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecutionPage, ApprovalFlowExecutionsQueryVariables>(
    ApprovalFlowExecutionGQL.query.approvalFlowExecutions,
    queryContext,
  );

export const useApprovalFlowExecutionsQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionPage, ApprovalFlowExecutionsQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecutionPage, ApprovalFlowExecutionsQueryVariables>(
    ApprovalFlowExecutionGQL.query.approvalFlowExecutions,
    queryContext,
  );

export const useApprovalFlowExecutionLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecution, ApprovalFlowExecutionQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecution, ApprovalFlowExecutionQueryVariables>(
    ApprovalFlowExecutionGQL.query.approvalFlowExecution,
    queryContext,
  );

export const useApprovalFlowExecutionQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecution, ApprovalFlowExecutionQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecution, ApprovalFlowExecutionQueryVariables>(
    ApprovalFlowExecutionGQL.query.approvalFlowExecution,
    queryContext,
  );
