import React from "react";
import { twMerge } from "tailwind-merge";
import { PageLoadingOrError } from "../Error/PageLoadingOrError.tsx";
import { PageAddEntityButton } from "./PageAddEntityButton.tsx";
import { PageButtonsContainer } from "./PageButtonsContainer.tsx";
import { PageCancelButton } from "./PageCancelButton.tsx";
import { PageDeleteEntityButton } from "./PageDeleteEntityButton.tsx";
import { PageDiscardChangesButton } from "./PageDiscardChangesButton.tsx";
import { PageEditEntityButton } from "./PageEditEntityButton.tsx";
import { PageFormContainer } from "./PageFormContainer.tsx";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField.tsx";
import { PageFormDateTimeInfoField } from "./PageFormDateTimeInfoField.tsx";
import { PageFormDropImageZone } from "./PageFormDropImageZone.tsx";
import { PageFormGrid } from "./PageFormGrid.tsx";
import { PageFormNumberInputField } from "./PageFormNumberInputField.tsx";
import { PageFormTextAreaField } from "./PageFormTextAreaField.tsx";
import { PageFormTextInfoField } from "./PageFormTextInfoField.tsx";
import { PageFormTextInputField } from "./PageFormTextInputField.tsx";
import { PageFormToggleField } from "./PageFormToggleField.tsx";
import { PageInfoButton } from "./PageInfoButton.tsx";
import { PageModalEntityInfoButton } from "./PageModalEntityInfoButton.tsx";
import { PageNavigationPanel } from "./PageNavigationPanel.tsx";
import { PagePanelContainer } from "./PagePanelContainer.tsx";
import { PagePanelFooterContainer } from "./PagePanelFooterContainer.tsx";
import { PageSaveButton } from "./PageSaveButton.tsx";
import { PageTitle } from "./PageTitle.tsx";
import { PageTitlePanel } from "./PageTitlePanel.tsx";
import { PageDeleteEntityMinimalisticButton } from "./PageDeleteEntityMinimalisticButton.tsx";

export interface PageProps {
  isLoading?: boolean;
  error?: unknown;
  onErrorClose?: () => void;
  children: React.ReactNode;
  className?: string;
}

export const PageComponent = ({ children, className, ...props }: PageProps) => {
  return (
    <PageLoadingOrError {...props}>
      <div
        className={twMerge(
          "bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg min-h-[calc(100vh-72px)]",
          className,
        )}>
        {children}
      </div>
    </PageLoadingOrError>
  );
};

PageComponent.displayName = "Page";

export const Page = Object.assign(PageComponent, {
  Title: PageTitle,
  TitlePanel: PageTitlePanel,
  PanelContainer: PagePanelContainer,
  ButtonsContainer: PageButtonsContainer,
  NavigationPanel: PageNavigationPanel,
  LoadingOrError: PageLoadingOrError,
  PanelFooterContainer: PagePanelFooterContainer,
  FormContainer: PageFormContainer,
  FormGrid: PageFormGrid,
  ModalEntityInfoButton: PageModalEntityInfoButton,
  FormDateTimeInfoField: PageFormDateTimeInfoField,
  FormTextInfoField: PageFormTextInfoField,
  FormCustomEditableField: PageFormCustomEditableField,
  FormDropImageZone: PageFormDropImageZone,
  FormNumberInputField: PageFormNumberInputField,
  FormTextInputField: PageFormTextInputField,
  FormTextAreaField: PageFormTextAreaField,
  FormToggleField: PageFormToggleField,
  InfoButton: PageInfoButton,
  AddEntityButton: PageAddEntityButton,
  EditEntityButton: PageEditEntityButton,
  DeleteEntityButton: PageDeleteEntityButton,
  DeleteEntityMinimalisticButton: PageDeleteEntityMinimalisticButton,
  DiscardChangesButton: PageDiscardChangesButton,
  SaveButton: PageSaveButton,
  CancelButton: PageCancelButton,
});
