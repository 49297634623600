import { useScreenSize } from "@whyuz/hooks";
import { GQLSort } from "@whyuz/services";
import { EntityDictionary } from "@whyuz/types";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_PAGINATION, Pagination } from "../../Pagination";
import { useSearch } from "../../Search";
import {
  AdvancedTableColumnConfiguration,
  AdvancedTableConfiguration,
} from "../components/AdvancedTableConfiguration.ts";
import { AdvancedTableState, EntityId } from "../components/AdvancedTableState.ts";

interface Props<T extends EntityId, Filter extends T> {
  tableConfiguration: AdvancedTableConfiguration<T, Filter>;
  defaultOrderBy?: GQLSort<T>;
  hideColumnsKeys?: string[];
}

export const useAdvancedTable = <T extends EntityId, Filter extends T>({
  tableConfiguration,
  defaultOrderBy,
  hideColumnsKeys = [],
}: Props<T, Filter>): AdvancedTableState<T, Filter> => {
  const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
  const { isMobileScreenSize, breakpoint: screenBreakpoint } = useScreenSize();
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const [selectedEntities, setSelectedEntities] = useState<EntityDictionary<T>>({});
  const [orderBy, setOrderBy] = useState<GQLSort<T> | undefined>(defaultOrderBy);
  const [filters, setFilters] = useState<Filter>({} as Filter);
  const [columns, setColumns] = useState<AdvancedTableColumnConfiguration<T, Filter>[]>(
    tableConfiguration.columnsDefinition?.filter((column) => !hideColumnsKeys?.includes(column.columnKey)),
  );
  const { searchText } = useSearch();

  const orderedColumns = useMemo(
    () =>
      columns
        .filter((currentColumn) => !hideColumnsKeys.includes(currentColumn.columnKey))
        .sort((column1, column2) => (column1.position < column2.position ? -1 : 1)),
    [columns, hideColumnsKeys],
  );

  const showMultipleSelect = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      tableConfiguration.enableMultipleSelection ? !(screenBreakpoint === "xs" || screenBreakpoint === "sm") : false,
    [screenBreakpoint, tableConfiguration.enableMultipleSelection],
  );

  useEffect(() => {
    if (tableConfiguration.cleanSelectedEntitiesOnFilterChange) {
      setSelectedEntities({});
    }
  }, [filters, tableConfiguration.cleanSelectedEntitiesOnFilterChange]);

  useEffect(() => {
    if (tableConfiguration.cleanSelectedEntitiesOnSearchChange) {
      setSelectedEntities({});
    }
  }, [searchText, tableConfiguration.cleanSelectedEntitiesOnSearchChange]);

  const cleanSelectedEntities = () => {
    setSelectedEntities({});
  };

  return {
    selectedEntities,
    setSelectedEntities,
    isShowingAllFilters: showMoreOptions,
    setShowMoreOptions,
    filters,
    setFilters,
    pagination,
    setPagination,
    tableConfiguration,
    columns: orderedColumns,
    setColumns,
    showMultipleSelect,
    orderBy,
    setOrderBy,
    isMobileScreenSize,
    cleanSelectedEntities,
  };
};
