import { SelectMenu, SelectMenuItem } from "@whyuz/components";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { FiAlignCenter, FiAlignJustify, FiAlignLeft, FiAlignRight } from "react-icons/fi";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTextAlignProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonTextAlign = ({
  hideOnMobile = true,
  children,
}: TextEditorMenuButtonTextAlignProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const handleTextAlignSelected = (item: SelectMenuItem<unknown>) => {
    if (editor) {
      editor.chain().focus().setTextAlign(item.id).run();
    }
  };

  return (
    <Tooltip content={t("buttons.textalign")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={alignMenuItems}
          itemNotSelected={children ?? <FiAlignLeft className={iconSelectStyle} />}
          onChange={handleTextAlignSelected}
          textPadding="my-1 p-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          textMargin=""
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={false}
          showChevron={false}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};

const alignMenuItems = [
  {
    id: "left",
    icon: <FiAlignLeft className="h-6 w-6" />,
  },
  {
    id: "justify",
    icon: <FiAlignJustify className="h-6 w-6" />,
  },
  {
    id: "center",
    icon: <FiAlignCenter className="h-6 w-6" />,
  },
  {
    id: "right",
    icon: <FiAlignRight className="h-6 w-6" />,
  },
];
