import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteDropdownTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteDropdownThemes {
  default = "default",
  underline = "underline",
  rightInputButton = "rightInputButton",
}

const FlowbiteDefaultDropdownTheme = FlowbiteDefaultTheme.dropdown;

const WhyUzDefaultDropdownTheme: RecursivePartial<FlowbiteDropdownTheme> = {
  floating: {
    base: twMerge(FlowbiteDefaultDropdownTheme.floating.base, "rounded-lg px-0"),
    target: twMerge(
      FlowbiteDefaultDropdownTheme.floating.target,
      "w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500",
    ),
  },
};

export const FlowbiteDropdownThemes: Record<EnumFlowbiteDropdownThemes, RecursivePartial<FlowbiteDropdownTheme>> = {
  default: WhyUzDefaultDropdownTheme,
  underline: {
    inlineWrapper: "flex items-center w-full focus:ring-transparent",
  },
  rightInputButton: {
    inlineWrapper:
      "flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 disabled:cursor-not-allowed disabled:opacity-50 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 disabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 disabled:dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600",
    ...WhyUzDefaultDropdownTheme,
  },
};
