import Link from "@tiptap/extension-link";

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      rel: {
        default: this.options.HTMLAttributes.rel,
      },
      class: {
        default: this.options.HTMLAttributes.class,
      },
      style: {
        default: this.options.HTMLAttributes.style,
      },
      textDecoration: {
        default: "underline",
        renderHTML: (attributes) => {
          return attributes.textDecoration
            ? {
                style: `text-decoration: ${attributes.textDecoration}`,
              }
            : undefined;
        },
      },
      color: {
        default: "#0085B1",
        renderHTML: (attributes) => {
          return attributes.color
            ? {
                style: `color: ${attributes.color}`,
              }
            : undefined;
        },
      },
      background: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.background
            ? {
                style: `background-color: ${attributes.background}`,
              }
            : undefined;
        },
      },
      border: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.border
            ? {
                style: `border: ${attributes.border}`,
              }
            : undefined;
        },
      },
      borderRadius: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.borderRadius
            ? {
                style: `border-radius: ${attributes.borderRadius}`,
              }
            : undefined;
        },
      },
      padding: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.padding
            ? {
                style: `padding: ${attributes.padding}`,
              }
            : undefined;
        },
      },
      display: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.display
            ? {
                style: `display: ${attributes.display}`,
              }
            : undefined;
        },
      },
      flexDirection: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.flexDirection
            ? {
                style: `flex-direction: ${attributes.flexDirection}`,
              }
            : undefined;
        },
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.width
            ? {
                style: `width: ${attributes.width}`,
              }
            : undefined;
        },
      },
    };
  },
});
