import { CheckCircleIcon } from "@heroicons/react/24/solid";

export interface SequenceStepProps {
  step: number;
  currentStep: number;
  isLastStep?: boolean;
  children?: React.ReactNode;
}

export const SequenceStep = ({ step, currentStep, isLastStep, children }: SequenceStepProps) => {
  if (isLastStep) {
    return (
      <li className="flex items-center sm:block">
        <div className="mr-2 sm:mb-2 sm:mx-auto">{step}</div>
        {children}
      </li>
    );
  }

  return currentStep <= step ? (
    <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-300 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
      <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-300 dark:after:text-gray-500">
        <div className="mr-2 sm:mb-2 sm:mx-auto">{step}</div>
        {children}
      </div>
    </li>
  ) : (
    <li className="flex items-center text-primary-600 dark:text-primary-500 sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-300 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
      <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-300 dark:after:text-gray-500">
        <CheckCircleIcon className="w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto" />
        {children}
      </div>
    </li>
  );
};
