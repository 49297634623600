import "../styles/tailwind.css";

import type { Placement } from "@floating-ui/core";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import { Label, Tooltip } from "flowbite-react";
import React, { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { SelectMenuItem } from ".";
import { ErrorMessage } from "../Error";
import { SelectMenuItemComponent } from "./SelectMenuItemComponent";

export interface MenuItem {
  id: string;
  name: React.ReactNode;
  imageURL?: string;
  className?: string;
}

export interface SelectMenuProps<T> {
  id?: string;
  label?: string;
  menuItems: SelectMenuItem<T>[];
  selectedItemId?: string;
  itemNotSelected?: React.ReactNode; // Shown when selectedItemId not found or undefined
  showSelectedOnButton?: boolean;
  showSelectedCheck?: boolean;
  showChevron?: boolean;
  fullWidth?: boolean;
  labelTextClassName?: string;
  textClassName?: string;
  textItemNotSelectedClassName?: string;
  borderClassName?: string;
  backgroundClassName?: string;
  textMargin?: string;
  textPadding?: string;
  disabled?: boolean;
  showBorderOnDisabled?: boolean;
  error?: { message?: ErrorMessageType; details?: GQLError };
  tooltipText?: string;
  tooltipPosition?: Placement;
  onChange?: (selectedItem: SelectMenuItem<T>) => void;
}

const getClassNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const SelectMenu = <T,>({
  id,
  label,
  menuItems,
  selectedItemId,
  showSelectedOnButton = true,
  itemNotSelected,
  showSelectedCheck = true,
  showChevron = true,
  showBorderOnDisabled = true,
  fullWidth = false,
  textClassName,
  textItemNotSelectedClassName,
  borderClassName,
  backgroundClassName,
  textPadding,
  disabled = false,
  error,
  tooltipText,
  tooltipPosition,
  onChange,
}: SelectMenuProps<T>) => {
  const handleOnChange = (selectedItem: SelectMenuItem<T>) => {
    // only real changes are notified
    if (selectedItem.id !== selectedItemId && onChange) {
      onChange(selectedItem);
    }
  };

  const getItemById = (itemId: string | undefined): SelectMenuItem<T> | null => {
    for (const currentItem of menuItems) {
      if (currentItem.id === itemId) return currentItem;
    }
    return null;
  };

  const selected = getItemById(selectedItemId);

  const selectListBoxButton = (
    <Listbox.Button
      id={id}
      type="button"
      className={`${fullWidth ? "w-full" : ""} ${textPadding ?? "px-3 py-2.5"}  ${
        borderClassName ??
        "border border-gray-300 rounded-lg dark:border-gray-600 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 focus:border-cyan-500 focus:ring-cyan-500"
      } ${
        backgroundClassName ?? "bg-gray-50 dark:bg-gray-700"
      } flex space-x-2 disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 dark:text-white dark:placeholder-gray-400 text-sm font-medium`}>
      {selected && showSelectedOnButton ? (
        <SelectMenuItemComponent selected={false} item={selected} />
      ) : (
        <span className={`${textItemNotSelectedClassName ?? "flex-1 text-left text-gray-400 truncate"}`}>
          {itemNotSelected ?? ""}
        </span>
      )}
      {showChevron && (!disabled || (disabled && showBorderOnDisabled)) && (
        <span className={`flex-none inset-y-0 right-0 flex items-center pointer-events-none`}>
          <ChevronDownIcon className={`h-5 w-5 ${textClassName ?? ""}`} aria-hidden="true" />
        </span>
      )}
    </Listbox.Button>
  );

  return (
    <ErrorMessage error={error?.message} details={error?.details}>
      <Listbox value={selected} disabled={disabled} onChange={handleOnChange}>
        {({ open }) => (
          <div className={`flex flex-col ${fullWidth ? "w-full" : ""}`}>
            {label && (
              <Label className="dark:text-white mb-2 text-sm font-semibold text-gray-900 select-none" htmlFor={id}>
                {label}
              </Label>
            )}
            <div className="relative">
              {/* Tooltip is placed here because the component affects the width of the Listbox.Button with width full */}
              {tooltipText ? (
                <Tooltip content={tooltipText} placement={tooltipPosition}>
                  {selectListBoxButton}
                </Tooltip>
              ) : (
                selectListBoxButton
              )}
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options
                  data-testid="flowbite-tooltip"
                  className={twMerge(
                    "absolute max-h-80 overflow-hidden overflow-y-auto mt-2 py-1 z-10 w-fit rounded-lg shadow transition-opacity duration-100 border border-gray-200 bg-white text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white",
                    fullWidth ? "w-full" : undefined,
                  )}>
                  {menuItems.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={() =>
                        getClassNames(
                          `block py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white`,
                        )
                      }
                      value={item}>
                      {({ selected }) => (
                        <SelectMenuItemComponent selected={showSelectedCheck && selected} item={item} />
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </ErrorMessage>
  );
};
