import { ViewColumnsIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Checkbox, Dropdown, Label, Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";

export interface AdvancedTableColumnsManagementDropdownProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
  showText?: boolean;
}

export const AdvancedTableColumnsManagementDropdown = <T extends EntityId, Filter extends T>({
  tableState,
  showText = true,
}: AdvancedTableColumnsManagementDropdownProps<T, Filter>) => {
  const { t } = useTranslation();

  return (
    <div className="hidden sm:block">
      {tableState.columns && (
        <Dropdown
          size="sm"
          color="light"
          theme={{ floating: { target: `px-0 ${showText ? "sm:px-0.5" : ""}` } }} //Pending to be able to access the button theme
          arrowIcon={false}
          label={
            <Tooltip
              content={t("buttons.managecolumns")}
              placement="bottom"
              className={showText ? "hidden" : undefined}>
              <div className={`flex h-full self-center`}>
                <ViewColumnsIcon className={`h-4 w-4 self-center`} />
                {showText && <span className="hidden sm:block ml-2">{t("buttons.managecolumns")}</span>}
              </div>
            </Tooltip>
          }>
          <Dropdown.Header>{t("word.columns")}</Dropdown.Header>
          {tableState.columns.map((column, index) => (
            <Dropdown.Item key={column.columnKey}>
              <Checkbox
                id={column.columnKey}
                className={`mr-2`}
                checked={column.isVisible}
                onChange={(e) => {
                  tableState?.setColumns((prev) => {
                    const newColumns = [...prev];
                    const arrayIdx = newColumns.findIndex((col) => col.columnKey === column.columnKey);
                    newColumns[arrayIdx] = { ...column, isVisible: e.target.checked };
                    return newColumns;
                  });
                }}
              />
              <Label
                className="cursor-pointer select-none"
                htmlFor={column.columnKey}
                onPointerDown={(e) => {
                  if (e.button !== RIGHT_BUTTON) {
                    tableState?.setColumns((prev) => {
                      const newColumns = [...prev];
                      const arrayIdx = newColumns.findIndex((col) => col.columnKey === column.columnKey);
                      newColumns[arrayIdx] = { ...column, isVisible: !prev[index].isVisible };
                      return newColumns;
                    });
                  }
                }}>
                {column.columnName}
              </Label>
            </Dropdown.Item>
          ))}
        </Dropdown>
      )}
    </div>
  );
};
