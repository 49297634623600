import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  AddApprovalFlowExecutionUserMutationVariables,
  ApprovalFlowExecutionUser,
  ApprovalFlowExecutionUserPage,
  ApprovalFlowExecutionUserQueryVariables,
  DeleteApprovalFlowExecutionUserMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowExecutionUserGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useAddApprovalFlowExecutionUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionUser, AddApprovalFlowExecutionUserMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionUser, AddApprovalFlowExecutionUserMutationVariables>(
    ApprovalFlowExecutionUserGQL.mutation.addApprovalFlowExecutionUser,
    mutationContext,
  );

export const useDeleteApprovalFlowExecutionUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionUser, DeleteApprovalFlowExecutionUserMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionUser, DeleteApprovalFlowExecutionUserMutationVariables>(
    ApprovalFlowExecutionUserGQL.mutation.deleteApprovalFlowExecutionUser,
    mutationContext,
  );

export const useApprovalFlowExecutionUsersLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionUserPage, ApprovalFlowExecutionUserQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecutionUserPage, ApprovalFlowExecutionUserQueryVariables>(
    ApprovalFlowExecutionUserGQL.query.approvalFlowExecutionUsers,
    queryContext,
  );

export const useApprovalFlowExecutionUsersQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionUserPage, ApprovalFlowExecutionUserQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecutionUserPage, ApprovalFlowExecutionUserQueryVariables>(
    ApprovalFlowExecutionUserGQL.query.approvalFlowExecutionUsers,
    queryContext,
  );

export const useApprovalFlowExecutionUserLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionUser, ApprovalFlowExecutionUserQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecutionUser, ApprovalFlowExecutionUserQueryVariables>(
    ApprovalFlowExecutionUserGQL.query.approvalFlowExecutionUser,
    queryContext,
  );

export const useApprovalFlowExecutionUserQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionUser, ApprovalFlowExecutionUserQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecutionUser, ApprovalFlowExecutionUserQueryVariables>(
    ApprovalFlowExecutionUserGQL.query.approvalFlowExecutionUser,
    queryContext,
  );
