import React, { useMemo } from "react";

export type ColoredTableCellColors =
  | "blue"
  | "gray"
  | "green"
  | "indigo"
  | "orange"
  | "pink"
  | "purple"
  | "red"
  | "yellow";

export interface ColoredTableCellProps {
  color?: ColoredTableCellColors;
  children: React.ReactNode;
}

export const ColoredTableCell = ({ color, children }: ColoredTableCellProps) => {
  const colorClassName = useMemo(() => {
    switch (color) {
      case "blue":
        return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300";
      case "gray":
        return "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300";
      case "green":
        return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
      case "indigo":
        return "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300";
      case "orange":
        return "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300";
      case "pink":
        return "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300";
      case "purple":
        return "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300";
      case "red":
        return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
      case "yellow":
        return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";
    }
    return "bg-primary-100 text-primary-800 dark:bg-primary-900 dark:text-primary-300";
  }, [color]);

  return children ? (
    <span className={`text-xs font-medium px-2 py-0.5 rounded ${colorClassName}`}>{children}</span>
  ) : undefined;
};
