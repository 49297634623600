import { Tooltip } from "flowbite-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineAttachFile } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { useUploadFilesInTextEditor } from "../../hooks/useUploadFilesInTextEditor";
import { buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonUploadFileProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonUploadFile = ({ className, children }: TextEditorMenuButtonUploadFileProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled, uploadPublicFiles } = useTextEditorContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadFile } = useUploadFilesInTextEditor();

  return (
    <Tooltip content={t("buttons.uploadfile")}>
      <button
        type="button"
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        className={twMerge(buttonStyle, className)}
        disabled={disabled}
      >
        {children ?? <MdOutlineAttachFile className={"h-6 w-6"} />}
      </button>
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        onChange={(e) => uploadFile(e.target.files?.item(0), editor, disabled, uploadPublicFiles)}
        disabled={disabled}
      />
    </Tooltip>
  );
};
