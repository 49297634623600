import { TrashIcon } from "@heroicons/react/24/outline";
import { ButtonProps } from "flowbite-react";
import { DeleteConfirmationModal, useModal } from "../Modal";
import { IconButton } from "../IconButton";

export interface PageDeleteEntityMinimalisticButtonProps extends ButtonProps {
  size?: "sm" | "xs";
  disabled?: boolean;
  onDeleteEntity: () => void;
}

export const PageDeleteEntityMinimalisticButton = ({
  disabled = false,
  onDeleteEntity,
}: PageDeleteEntityMinimalisticButtonProps) => {
  const { showModal, closeModal, modalProps } = useModal();

  return (
    <>
      <IconButton
        onPointerDown={() => {
          if (!disabled) {
            showModal();
          }
        }}
        icon={<TrashIcon className="block text-gray-500 h-4 w-4 my-0.5 stroke-2 self-center" />}
      />
      <DeleteConfirmationModal
        onAcceptDeletion={() => {
          closeModal();
          onDeleteEntity();
        }}
        {...modalProps}
      />
    </>
  );
};
