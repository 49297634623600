import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Button, ButtonProps, Tooltip } from "flowbite-react";

export interface PageInfoButtonProps extends ButtonProps {
  text: string;
  showText?: boolean;
  onInfoClick: () => void;
}

export const PageInfoButton = ({
  text,
  showText = true,
  onInfoClick,
  children,
  disabled,
  ...props
}: PageInfoButtonProps) => {
  return (
    <Tooltip content={text} placement="bottom" className={showText ? "hidden" : undefined}>
      <Button
        size="sm"
        color="primaryLight"
        theme={showText ? undefined : { size: { sm: "px-2.5 min-h-[32px]" } }}
        className={`px-0 ${showText ? "sm:px-0.5" : ""}`}
        onPointerDown={(e: React.PointerEvent) => {
          if (!disabled && e.button !== RIGHT_BUTTON) {
            onInfoClick();
          }
        }}
        disabled={disabled}
        {...props}>
        {children ?? (
          <>
            <InformationCircleIcon className="h-4 w-4 stroke-2 self-center" />
            {showText && <span className="hidden sm:block ml-2">{text}</span>}
          </>
        )}
      </Button>
    </Tooltip>
  );
};
