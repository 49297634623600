import { Banner as FlowbiteBanner, BannerComponentProps } from "flowbite-react";
import { twMerge } from "tailwind-merge";
import React from "react";
import { BannerTitle } from "./BannerTitle.tsx";

export interface BannerProps extends Omit<BannerComponentProps, "title"> {
  title?: string | React.ReactNode;
}

export const BannerComponent = ({ title, children, className, ...props }: BannerProps) => {
  return (
    <FlowbiteBanner
      {...props}
      className={twMerge(
        "flex w-full flex-col rounded bg-primary-50 dark:bg-primary-100 p-4 text-primary-700 space-y-2",
        className,
      )}>
      {children}
    </FlowbiteBanner>
  );
};

BannerComponent.displayName = "Banner";
export const Banner = Object.assign(BannerComponent, {
  Title: BannerTitle,
});
