import { MouseTool } from "@api.video/media-stream-composer";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiCog, BiEraser } from "react-icons/bi";
import { BsArrowsMove, BsCameraVideo, BsCameraVideoOff, BsPersonSquare } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { GoMirror } from "react-icons/go";
import { ImStop } from "react-icons/im";
import { MdOutlineDraw, MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { TbMicrophone, TbMicrophoneOff } from "react-icons/tb";

interface Props {
  isRecordingInProgress: boolean;
  isCameraEnabled: boolean;
  isMicrophoneEnabled: boolean;
  isScreenShared: boolean;
  isCameraShapeCircle: boolean;
  mouseTool: MouseTool;
  onToggleVideoRecording: () => void;
  onToggleCamera: () => void;
  onToggleMuteMicrophone: () => void;
  onToggleScreenShare: () => void;
  onToggleCameraShape: () => void;
  onToggleCameraMirror: () => void;
  onToggleMouseTool: () => void;
  onClearDrawings: () => void;
  onShowDeviceConfigurationModal: () => void;
}

export const ScreenAndCameraRecorderControls = ({
  isRecordingInProgress,
  isCameraEnabled,
  isMicrophoneEnabled,
  isScreenShared,
  isCameraShapeCircle,
  mouseTool,
  onToggleVideoRecording,
  onToggleCamera,
  onToggleMuteMicrophone,
  onToggleScreenShare,
  onToggleCameraShape,
  onToggleCameraMirror,
  onToggleMouseTool,
  onClearDrawings,
  onShowDeviceConfigurationModal,
}: Props) => {
  const { t } = useTranslation("screenAndCameraRecorder");

  const controlButtonStyle =
    "self-center items-center border-none outline-none bg-transparent text-2xl text-gray-100 hover:cursor-pointer hover:text-white hover:scale-125 z-10";

  return (
    <div className={`flex flex-col justify-center w-full`}>
      <div
        className={`flex gap-2 py-2 px-4 self-center items-center justify-center space-x-1 bg-black/50 w-fit rounded-full`}>
        <Tooltip content={t("buttons.togglevideorecording")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleVideoRecording}>
            {isRecordingInProgress ? <ImStop className="text-whyuzpurered" /> : <ImStop />}
          </button>
        </Tooltip>
        <Tooltip content={t("buttons.togglecamera")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleCamera}>
            {isCameraEnabled ? <BsCameraVideo /> : <BsCameraVideoOff className="text-whyuzpurered" />}
          </button>
        </Tooltip>
        <Tooltip content={t("buttons.togglemutemicrophone")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleMuteMicrophone}>
            {isMicrophoneEnabled ? <TbMicrophone /> : <TbMicrophoneOff className="text-whyuzpurered" />}
          </button>
        </Tooltip>
        {isScreenShared && (
          <Tooltip content={t("buttons.togglemouseaction")}>
            <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleMouseTool}>
              {mouseTool === "draw" ? <MdOutlineDraw /> : <BsArrowsMove />}
            </button>
          </Tooltip>
        )}
        <Tooltip content={t("buttons.cleardrawings")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onClearDrawings}>
            {<BiEraser />}
          </button>
        </Tooltip>
        <Tooltip content={t("buttons.screenshare")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleScreenShare}>
            {isScreenShared ? <MdOutlineStopScreenShare className="text-whyuzpurered" /> : <MdOutlineScreenShare />}
          </button>
        </Tooltip>
        <Tooltip content={t("buttons.togglecameramirror")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleCameraMirror}>
            {<GoMirror />}
          </button>
        </Tooltip>
        {isScreenShared && (
          <Tooltip content={t("buttons.togglecamerashape")}>
            <button type="button" className={`${controlButtonStyle}`} onPointerDown={onToggleCameraShape}>
              {isCameraShapeCircle ? <FaRegUserCircle /> : <BsPersonSquare />}
            </button>
          </Tooltip>
        )}
        <Tooltip content={t("buttons.deviceconfiguration")}>
          <button type="button" className={`${controlButtonStyle}`} onPointerDown={onShowDeviceConfigurationModal}>
            {<BiCog />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
