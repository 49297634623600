import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Button, Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { RIGHT_BUTTON } from "@whyuz/data";
import { PointerEvent } from "react";

export interface DiscardChangesConfirmationModalProps extends ModalProps {
  onDiscardChanges: () => void;
}

export const DiscardChangesConfirmationModal = ({
  onDiscardChanges,
  ...props
}: DiscardChangesConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal popup size="md" {...props}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <div className="flex justify-center items-center p-2 mx-auto mb-4 w-12 h-12 bg-gray-100 rounded-lg dark:bg-gray-700">
            <ExclamationTriangleIcon className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          </div>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {t("questions.discardchangesconfirmation")}
          </h3>
          <div className="flex justify-center gap-4">
            <Button
              color="failure"
              onPointerDown={(e: PointerEvent) => {
                if (e.button !== RIGHT_BUTTON) {
                  onDiscardChanges();
                }
              }}>
              {t("buttons.discardchanges")}
            </Button>
            <Button
              color="gray"
              onPointerDown={(e: PointerEvent) => {
                if (e.button !== RIGHT_BUTTON && props.onClose) {
                  props.onClose();
                }
              }}>
              {t("buttons.cancel")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
