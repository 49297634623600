import { DebouncedTextArea } from "@whyuz/components";
import { SuccessCaseContentItem, useCollaboratorUpdateContentOfSuccessCaseContentItemMutation } from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorQuestionContainer } from "./SuccessCaseContentEditorQuestionContainer";
import { SuccessCaseContentEditorQuestionInstructions } from "./SuccessCaseContentEditorQuestionInstructions";
import { SuccessCaseContentEditorQuestionTitle } from "./SuccessCaseContentEditorQuestionTitle";

export interface SuccessCaseContentEditorQuestionTextProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled?: boolean;
}

export const SuccessCaseContentEditorQuestionText = ({
  tenantId,
  question,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionTextProps) => {
  const { t: tContentItem } = useTranslation("contentItem");
  // const answerRef = useRef<string | undefined | null>(question.htmlTextContent);
  const [updateContentMutation] = useCollaboratorUpdateContentOfSuccessCaseContentItemMutation();
  const [letters, setLetters] = useState<number>(question?.htmlTextContent?.split("").length ?? 0);
  const updateContentItemTextContent = useCallback(
    (newText: string) => {
      updateContentMutation({
        variables: { contentItemId: question.id as string, content: newText, tenantId },
      })
        .then((updatedQuestion) => {
          onQuestionUpdated(updatedQuestion);
        })
        .catch((e) => {
          notifyError(tContentItem("errors.updatingcontentitem") + ": " + (e as string));
        });
    },
    [onQuestionUpdated, question.id, tContentItem, tenantId, updateContentMutation],
  );

  return (
    <SuccessCaseContentEditorQuestionContainer>
      <SuccessCaseContentEditorQuestionTitle title={question.title as string} />
      <SuccessCaseContentEditorQuestionInstructions instructions={question.htmlInstructions as string} />
      <div className="flex flex-col">
        <DebouncedTextArea
          maxLength={question.contentMaxSize ?? undefined}
          className="resize-y min-h-[300px]"
          value={question.htmlTextContent ?? ""}
          onChange={(text) => updateContentItemTextContent(text ?? "")}
          onUndebouncedChange={(text) => setLetters(text?.split("").length ?? 0)}
          disabled={disabled}
        />
        {question.contentMaxSize && (
          <span className="flex text-xs justify-end text-primary-400">{`${letters}/${question.contentMaxSize}`}</span>
        )}
      </div>
    </SuccessCaseContentEditorQuestionContainer>
  );
};
