import { Tooltip } from "flowbite-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsImage } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { useUploadFilesInTextEditor } from "../../hooks/useUploadFilesInTextEditor";
import { activeButtonStyle, buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonUploadImageProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonUploadImage = ({ className, children }: TextEditorMenuButtonUploadImageProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled, uploadPublicFiles, imagesWithLink, defaultImageSize } = useTextEditorContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useUploadFilesInTextEditor();

  const buttonClassName = editor && editor.isActive("image") ? activeButtonStyle : buttonStyle;

  return (
    <Tooltip content={t("buttons.uploadimage")}>
      <button
        type="button"
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}>
        {children ?? <BsImage className={"h-6 w-6"} />}
      </button>
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        accept=".bmp, .gif, .png, .jpeg, .jpg, .svg, .tif, .tiff"
        size={1024 * 1024} // 1 Mb as maximum size allowed
        onChange={(e) => {
          uploadImage(e.target.files?.item(0), imagesWithLink, editor, disabled, uploadPublicFiles, defaultImageSize);
        }}
        disabled={disabled}
      />
    </Tooltip>
  );
};
