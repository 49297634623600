import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteRadioTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteRadioThemes {
  default = "default",
}

const FlowbiteDefaultRadioTheme = FlowbiteDefaultTheme.radio;

export const FlowbiteRadioThemes: Record<EnumFlowbiteRadioThemes, RecursivePartial<FlowbiteRadioTheme>> = {
  default: {
    root: {
      base: twMerge(
        FlowbiteDefaultRadioTheme.root.base,
        "focus:ring-primary-500 dark:focus:bg-primary-600 dark:focus:ring-primary-600 text-primary-600",
      ),
    },
  },
};
