import { RIGHT_BUTTON } from "@whyuz/data";
import React from "react";
// import { useTranslation } from "react-i18next";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { TbDownload, TbPlayerPause, TbPlayerPlay, TbPlayerStop, TbVolume, TbVolumeOff } from "react-icons/tb";

interface Props {
  isVideoPlaying: boolean;
  isMuted: boolean;
  volume: number;
  videoTimeProgress: string | undefined;
  onToggleVideoPlay: () => void;
  onStopVideoPlay: () => void;
  onToggleMute: () => void;
  onVolumeChange: (volume: number) => void;
  onCaptureSnapshotAndDownload: () => void;
  onDownloadVideo: () => void;
}

export const VideoPlayerControls = ({
  isVideoPlaying,
  isMuted,
  volume,
  videoTimeProgress,
  onToggleVideoPlay,
  onStopVideoPlay,
  onToggleMute,
  onVolumeChange,
  onCaptureSnapshotAndDownload,
  onDownloadVideo,
}: Props) => {
  // const { t } = useTranslation("videoPlayer");
  const controlButtonStyle =
    "self-center items-center border-none outline-none bg-transparent text-2xl text-gray-100 hover:cursor-pointer hover:text-white hover:scale-125 z-10";

  const handleVolumeChange = (e: React.FormEvent<HTMLInputElement>) => {
    onVolumeChange(Number(e.currentTarget.value));
  };

  return (
    <div className={`flex flex-col justify-center w-full`}>
      <div className={`flex gap-2 py-2 px-4 self-center items-center justify-center bg-black/50 w-fit rounded-full`}>
        {/* <Tooltip content={t("buttons.playpausevideo")}> */}
        <button
          className={`${controlButtonStyle}`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onToggleVideoPlay();
          }}>
          {isVideoPlaying ? <TbPlayerPause /> : <TbPlayerPlay />}
        </button>
        {/* </Tooltip> */}
        {/* <Tooltip content={t("buttons.stopvideo")}> */}
        <button
          className={`${controlButtonStyle}`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onStopVideoPlay();
          }}>
          <TbPlayerStop />
        </button>
        {/* </Tooltip> */}
        {/* <Tooltip content={t("buttons.mute")}> */}
        <button
          className={`${controlButtonStyle}`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onToggleMute();
          }}>
          {isMuted ? <TbVolumeOff /> : <TbVolume />}
        </button>
        {/* </Tooltip> */}
        {/* <Tooltip content={t("buttons.volume")}> */}
        <input
          id="volume"
          type="range"
          min="0"
          max="1"
          step="any"
          value={volume}
          onChange={handleVolumeChange}
          className={`w-24 cursor-pointer`}
        />
        {/* </Tooltip> */}
        {/* <Tooltip content={t("buttons.capturethumbnail")}> */}
        <button
          className={`${controlButtonStyle}`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onCaptureSnapshotAndDownload();
          }}>
          <MdOutlinePhotoCamera />
        </button>
        {/* </Tooltip> */}
        {/* <Tooltip content={t("buttons.download")}> */}
        <button
          className={`${controlButtonStyle}`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onDownloadVideo();
          }}>
          <TbDownload />
        </button>
        {/* </Tooltip> */}
        {videoTimeProgress && (
          // <Tooltip content={t("buttons.timeprogress")}>
          <p className={`text-sm text-gray-100 hover:text-white truncate select-none`}>{videoTimeProgress}</p>
          // </Tooltip>
        )}
      </div>
    </div>
  );
};
