import Image from "@tiptap/extension-image";

export enum IMAGE_SIZE {
  ORIGINAL = "ORIGINAL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
  FULL_WIDTH = "FULL_WIDTH",
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imgage: {
      /**
       * Add an image
       */
      setImage: (options: { src: string; alt?: string; title?: string }) => ReturnType;

      /**
       * Set the image size
       */
      setImageSize: (size: IMAGE_SIZE) => ReturnType;
    };
  }
}

interface ImageSizeProperties {
  width: string | null;
  maxWidth: string;
}

export const IMAGE_SIZE_PROPERTIES: Record<IMAGE_SIZE, ImageSizeProperties> = {
  [IMAGE_SIZE.ORIGINAL]: { width: null, maxWidth: "100%" },
  [IMAGE_SIZE.SMALL]: { width: "200px", maxWidth: "100%" },
  [IMAGE_SIZE.MEDIUM]: { width: "400px", maxWidth: "100%" },
  [IMAGE_SIZE.LARGE]: { width: "600px", maxWidth: "100%" },
  [IMAGE_SIZE.EXTRA_LARGE]: { width: "800px", maxWidth: "100%" },
  [IMAGE_SIZE.FULL_WIDTH]: { width: "100%", maxWidth: "100%" },
};

export const CustomImage = Image.extend({
  addAttributes: () => {
    return {
      ...Image.config.addAttributes,
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      borderRadius: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.borderRadius
            ? {
                style: `border-radius: ${attributes.borderRadius}`,
              }
            : undefined;
        },
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.width
            ? {
                style: `width: ${attributes.width}`,
              }
            : undefined;
        },
      },
      maxWidth: {
        default: "100%",
        renderHTML: (attributes) => {
          return attributes.maxWidth
            ? {
                style: `max-width: ${attributes.maxWidth}`,
              }
            : undefined;
        },
      },
      margin: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.margin
            ? {
                style: `margin: ${attributes.margin}`,
              }
            : undefined;
        },
      },
      height: {
        default: "auto",
        renderHTML: (attributes) => {
          return attributes.height
            ? {
                style: `height: ${attributes.height}`,
              }
            : undefined;
        },
      },
      display: {
        default: "inline-block",
        renderHTML: (attributes) => {
          return attributes.display
            ? {
                style: `display: ${attributes.display}`,
              }
            : undefined;
        },
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
      setImageSize:
        (size) =>
        ({ commands }) => {
          switch (size) {
            case IMAGE_SIZE.ORIGINAL:
              return commands.updateAttributes("image", { width: null, maxWidth: "100%" });
            case IMAGE_SIZE.SMALL:
              return commands.updateAttributes("image", { width: "200px", maxWidth: "100%" });

            case IMAGE_SIZE.MEDIUM:
              return commands.updateAttributes("image", { width: "400px", maxWidth: "100%" });

            case IMAGE_SIZE.LARGE:
              return commands.updateAttributes("image", { width: "600px", maxWidth: "100%" });

            case IMAGE_SIZE.EXTRA_LARGE:
              return commands.updateAttributes("image", { width: "800px", maxWidth: "100%" });

            case IMAGE_SIZE.FULL_WIDTH:
              return commands.updateAttributes("image", { width: "100%", maxWidth: "100%" });
          }
        },
    };
  },
});
