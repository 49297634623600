import React from "react";
import { twMerge } from "tailwind-merge";

export interface PagePanelFooterContainerProps {
  hideOnMobileScreens?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const PagePanelFooterContainer = ({
  hideOnMobileScreens = false,
  children,
  className,
}: PagePanelFooterContainerProps) => {
  return (
    <div
      className={twMerge(
        `${
          hideOnMobileScreens ? "hidden md:flex" : "flex flex-col"
        }  md:flex-row items-start md:items-center md:space-x-4 space-y-4 md:space-y-0 p-4 dark:border-gray-700`,
        className,
      )}
    >
      {children}
    </div>
  );
};
