import { useDebouncedText } from "@whyuz/hooks";
import { TextInput, TextInputProps } from "flowbite-react";

export interface DebouncedTextInputProps extends Omit<TextInputProps, "value" | "onChange"> {
  value?: string | null;
  onChange: (text: string | null) => void;
  onUndebouncedChange?: (debouncedText: string | null | undefined) => void;
}

export const DebouncedTextInput = ({ onChange, onUndebouncedChange, value, ...props }: DebouncedTextInputProps) => {
  const debouncedText = useDebouncedText(750, value ?? undefined);

  debouncedText.setOnChange(onChange);
  if (onUndebouncedChange) {
    debouncedText.setOnUndebouncedChange(onUndebouncedChange);
  }

  return <TextInput value={debouncedText.undebouncedText ?? ""} onChange={debouncedText.handleTextChange} {...props} />;
};
