import { useEffect, useRef, useState } from "react";
import { useDebounce } from "./useDebounce.ts";

export type DebouncedTextEventListener = (debouncedText: string | null) => void;

export const useDebouncedText = (debounceTimeMS = 750, initialText?: string) => {
  const [text, setText] = useState<string | null>(initialText ?? null);
  const onChangeRef = useRef<DebouncedTextEventListener | null>(null);
  const onUndebouncedChangeRef = useRef<DebouncedTextEventListener | null>(null);

  const setOnChange = (onChange: DebouncedTextEventListener | null) => {
    onChangeRef.current = onChange;
  };

  const setOnUndebouncedChange = (onUndebouncedChange: DebouncedTextEventListener | null) => {
    onUndebouncedChangeRef.current = onUndebouncedChange;
  };

  const handleTextChangeRef = useRef(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      let textValue: string | null = e.target.value;
      if (textValue === "") {
        textValue = null;
      }

      if (text !== textValue) {
        setText(textValue);
        if (onUndebouncedChangeRef.current) {
          onUndebouncedChangeRef.current(textValue);
        }
      }
    },
  );

  const debouncedText = useDebounce<string | null>(text, debounceTimeMS);

  useEffect(() => {
    if (onChangeRef.current) {
      if (debouncedText) {
        setText(debouncedText.trim());
      }
      onChangeRef.current(debouncedText ? debouncedText.trim() : null);
    }
  }, [debouncedText]);

  return {
    debouncedText,
    undebouncedText: text,
    setText,
    setOnChange,
    setOnUndebouncedChange,
    handleTextChange: handleTextChangeRef.current,
  };
};
