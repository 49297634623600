import { useForwardRef } from "@whyuz/hooks";
import { GQLError } from "@whyuz/services";
import { Label, TextInput, TextInputProps } from "flowbite-react";
import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormMessage } from "./ErrorFormMessage.tsx";

export interface InputFormProps extends Omit<TextInputProps, "onChange" | "onKeyDown"> {
  label?: string;
  validation?: {
    required?: boolean;
    custom?: (model: Record<string, any>) => string | boolean;
  };
  error: GQLError | undefined;
}

export const InputForm = forwardRef<HTMLInputElement, InputFormProps>(
  ({ id, type = "text", label, validation, sizing = "md", error, ...props }: InputFormProps, ref) => {
    const { t } = useTranslation();

    const inputRef = useForwardRef<HTMLInputElement>(ref);
    const methods = useFormContext();
    return (
      <>
        <Controller
          rules={{
            required: validation?.required ? t("errors.fieldrequired") : false,
            validate: () => (validation?.custom ? validation?.custom(methods.getValues()) : undefined),
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              {label && (
                <Label htmlFor={id} className="mb-2 text-xs font-normal text-gray-900 select-none">
                  {label}
                </Label>
              )}
              <TextInput
                {...props}
                id={id}
                ref={inputRef}
                sizing={sizing}
                value={value as string}
                onChange={(e) => onChange(e)}
              />
            </div>
          )}
          name={id as string}
          control={methods.control}
        />
        <ErrorFormMessage errors={{ formErrors: methods.formState.errors, GQLerrors: error }} name={id as string} />
      </>
    );
  },
);
