export type Pagination = {
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  numberOfElements: number;
};

export const DEFAULT_PAGINATION: Pagination = {
  number: 0,
  size: 25,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
};
