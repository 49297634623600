import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { ImStrikethrough } from "react-icons/im";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyle, buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonStrikeProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonStrike = ({ className, children }: TextEditorMenuButtonStrikeProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName = editor && editor.isActive("strike") ? activeButtonStyle : buttonStyle;

  return (
    <Tooltip content={t("buttons.strikethrough")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().toggleStrike().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}>
        {children ?? <ImStrikethrough className="h-5 w-5" />}
      </button>
    </Tooltip>
  );
};
