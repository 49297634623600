import { Button as FlowbiteButton, Tooltip } from "flowbite-react";
import { RIGHT_BUTTON } from "@whyuz/data";
import { ReactNode } from "react";

export interface IconButtonProps {
  tooltipText?: string;
  onPointerDown?: () => void;
  icon: ReactNode;
}

export const IconButton = ({ onPointerDown, icon, tooltipText }: IconButtonProps) => {
  return (
    <Tooltip content={tooltipText} placement="bottom" className={!tooltipText ? "hidden" : undefined}>
      <FlowbiteButton
        size="sm"
        color="primaryLight"
        theme={tooltipText ? undefined : { size: { sm: "px-2.5 min-h-[32px]" } }}
        className={`flex px-0 ${tooltipText ? "sm:px-0.5" : ""} border-0`}
        onPointerDown={(e: React.PointerEvent) => {
          if (e.button !== RIGHT_BUTTON && onPointerDown) {
            onPointerDown();
          }
        }}>
        {icon}
      </FlowbiteButton>
    </Tooltip>
  );
};
