// Due to tailwind restrictions this has to be done like this instead of concat strings as it does not import unused classes
export const getColSpan = (columns?: number, breakpointSM?: boolean) => {
  if (!columns || columns <= 1) {
    return undefined;
  }
  switch (columns) {
    case 2:
      return breakpointSM ? "sm:col-span-2" : "col-span-2";
    case 3:
      return breakpointSM ? "sm:col-span-3" : "col-span-3";
    case 4:
      return breakpointSM ? "sm:col-span-4" : "col-span-4";
    case 5:
      return breakpointSM ? "sm:col-span-5" : "col-span-5";
    case 6:
      return breakpointSM ? "sm:col-span-6" : "col-span-6";
    case 7:
      return breakpointSM ? "sm:col-span-7" : "col-span-7";
    case 8:
      return breakpointSM ? "sm:col-span-8" : "col-span-8";
    case 9:
      return breakpointSM ? "sm:col-span-9" : "col-span-9";
    default:
      return breakpointSM ? "sm:col-span-10" : "col-span-10";
  }
};

// Due to tailwind restrictions this has to be done like this instead of concat strings as it does not import unused classes
export const getGridCols = (columns?: number, breakpointSM?: boolean) => {
  if (!columns || columns <= 1) {
    return undefined;
  }
  switch (columns) {
    case 2:
      return breakpointSM ? "sm:grid-cols-2" : "grid-cols-2";
    case 3:
      return breakpointSM ? "sm:grid-cols-3" : "grid-cols-3";
    case 4:
      return breakpointSM ? "sm:grid-cols-4" : "grid-cols-4";
    case 5:
      return breakpointSM ? "sm:grid-cols-5" : "grid-cols-5";
    case 6:
      return breakpointSM ? "sm:grid-cols-6" : "grid-cols-6";
    case 7:
      return breakpointSM ? "sm:grid-cols-7" : "grid-cols-7";
    case 8:
      return breakpointSM ? "sm:grid-cols-8" : "grid-cols-8";
    case 9:
      return breakpointSM ? "sm:grid-cols-9" : "grid-cols-9";
    default:
      return breakpointSM ? "sm:grid-cols-10" : "grid-cols-10";
  }
};

// Due to tailwind restrictions this has to be done like this instead of concat strings as it does not import unused classes
export const getWidthCalculatedForNumberOfColumns = (columns?: number, breakpointSM?: boolean) => {
  if (!columns || columns < 1) {
    return undefined;
  }
  switch (columns) {
    case 1:
      return breakpointSM ? "sm:w-[21rem]" : "w-[21rem]";
    case 2:
      return breakpointSM ? "sm:w-[42rem]" : "w-[42rem]";
    case 3:
      return breakpointSM ? "sm:w-[63rem]" : "w-[63rem]";
    case 4:
      return breakpointSM ? "sm:w-[84rem]" : "w-[84rem]";
    default:
      return undefined;
  }
};

export type FontSize = "text-xs" | "text-sm" | "text-base" | "text-lg" | "text-xl";
