export const isBrowser =
  typeof window !== "undefined" && typeof document !== "undefined" && typeof navigator !== "undefined";

export const navigatorIsSafari = () => {
  return (
    navigator.userAgent.toLowerCase().indexOf("safari/") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("applewebkit/") > -1 ||
    (navigator.vendor.match("/apple/i") || "").length > 0
  );
};
