import { graphql } from "../../codegen/gql";

const approvalFlowExecutionStep = graphql(`
  query approvalFlowExecutionStep($id: UUID!, $tenantId: UUID) {
    approvalFlowExecutionStep(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

const approvalFlowExecutionSteps = graphql(`
  query approvalFlowExecutionSteps(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowExecutionSteps(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        stepNumber
        requiredAll
        messageHtml
        subjectHtml
        launchedDate
        status
        users {
          id
          name
          email
          status
          sentDate
          updatedOn
          user {
            id
            mail
          }
        }
      }
    }
  }
`);

const addApprovalFlowExecutionStep = graphql(`
  mutation addApprovalFlowExecutionStep($approvalFlowExecutionStep: ApprovalFlowExecutionStepInput!, $tenantId: UUID) {
    addApprovalFlowExecutionStep(approvalFlowExecutionStep: $approvalFlowExecutionStep, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowExecutionStepPosition = graphql(`
  mutation updateApprovalFlowExecutionStepPosition(
    $approvalFlowExecutionStepId: UUID!
    $newPosition: Int!
    $tenantId: UUID
  ) {
    updateApprovalFlowExecutionStepPosition(
      approvalFlowExecutionStepId: $approvalFlowExecutionStepId
      newPosition: $newPosition
      tenantId: $tenantId
    ) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowExecutionSteps = graphql(`
  mutation updateApprovalFlowExecutionSteps($steps: [ApprovalFlowExecutionStepUpdateManyInput!]!) {
    updateApprovalFlowExecutionSteps(steps: $steps) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowExecutionStep = graphql(`
  mutation updateApprovalFlowExecutionStep(
    $id: UUID!
    $approvalFlowExecutionStep: ApprovalFlowExecutionStepInput!
    $tenantId: UUID
  ) {
    updateApprovalFlowExecutionStep(
      id: $id
      approvalFlowExecutionStep: $approvalFlowExecutionStep
      tenantId: $tenantId
    ) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

const deleteApprovalFlowExecutionStep = graphql(`
  mutation deleteApprovalFlowExecutionStep($id: UUID!, $tenantId: UUID) {
    deleteApprovalFlowExecutionStep(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      launchedDate
      status
      users {
        id
        name
        email
        status
        sentDate
        updatedOn
        user {
          id
          mail
        }
      }
    }
  }
`);

export const ApprovalFlowExecutionStepGQL = {
  query: { approvalFlowExecutionStep, approvalFlowExecutionSteps },
  mutation: {
    addApprovalFlowExecutionStep,
    updateApprovalFlowExecutionStep,
    deleteApprovalFlowExecutionStep,
    updateApprovalFlowExecutionSteps,
    updateApprovalFlowExecutionStepPosition,
  },
};
