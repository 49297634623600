import { useContext } from "react";
import { WebPageNavigationContext } from "..";
import { WebPageNavigationContextType } from "../types/WebPageNavigationTypes";

export const useWebPageNavigation = () => {
  const context = useContext(WebPageNavigationContext) as WebPageNavigationContextType;

  if (!context) {
    throw new Error("useWebPageNavigation must be used within a WebPageNavigationContextProvider");
  }

  return context;
};
