import { Button, Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { HiTrash } from "react-icons/hi";
import { UnorderedList } from "../List";
import { InformationListItem } from "../List";
import { useMemo } from "react";
import { RIGHT_BUTTON } from "@whyuz/data";

export interface DeleteConfirmationModalProps extends ModalProps {
  itemsDescription?: string[];
  onAcceptDeletion: () => void;
}

export const DeleteConfirmationModal = ({
  onAcceptDeletion,
  itemsDescription,
  ...props
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();

  const itemsToShow = useMemo(() => {
    if (!itemsDescription || (itemsDescription && itemsDescription.length <= 4)) {
      return itemsDescription;
    } else {
      const firstThree = itemsDescription.slice(0, 3);
      const remainingCount = itemsDescription.length - 3;
      const remainingString = `... +${remainingCount} ${t("word.more")}`;
      return [...firstThree, remainingString];
    }
  }, [itemsDescription, t]);

  return (
    <Modal popup size="md" {...props}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <HiTrash className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {itemsToShow && itemsToShow.length > 1
              ? t("questions.deletemultipleitemsconfirmation")
              : t("questions.deleteitemconfirmation")}
          </h3>
          {itemsToShow && itemsToShow.length > 0 && (
            <div className="px-4">
              <UnorderedList>
                {itemsToShow.map((currentItemDescription, index) => (
                  <InformationListItem key={"ili" + String(index)} text={currentItemDescription} />
                ))}
              </UnorderedList>
            </div>
          )}
          <div className="flex justify-center gap-4">
            <Button
              color="failure"
              onPointerDown={(e: React.PointerEvent) => {
                if (e.button !== RIGHT_BUTTON) {
                  onAcceptDeletion();
                }
              }}>
              {t("buttons.delete")}
            </Button>
            <Button
              color="gray"
              onPointerDown={(e: React.PointerEvent) => {
                if (e.button !== RIGHT_BUTTON && props.onClose) {
                  props.onClose();
                }
              }}>
              {t("buttons.cancel")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
