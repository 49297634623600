import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { getDaysInMonth, getFirstWeekDayOfMonth } from "@whyuz/utils";
import { PointerEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/tailwind.css";

export interface DatePickerProps {
  show: boolean;
  onChange: (value: Date) => void;
  onCancel: () => void;
}

export const DatePicker = ({ show, onChange, onCancel }: DatePickerProps) => {
  const { t, i18n } = useTranslation("common");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const today = new Date();
  const [viewMonth, setViewMonth] = useState<number>(today.getMonth()); // Current month
  const [viewYear, setViewYear] = useState<number>(today.getFullYear()); // Current year

  const getCurrentMonthName = () => {
    switch (viewMonth) {
      case 0:
        return t("dates.january");
      case 1:
        return t("dates.february");
      case 2:
        return t("dates.march");
      case 3:
        return t("dates.april");
      case 4:
        return t("dates.may");
      case 5:
        return t("dates.june");
      case 6:
        return t("dates.july");
      case 7:
        return t("dates.august");
      case 8:
        return t("dates.september");
      case 9:
        return t("dates.october");
      case 10:
        return t("dates.november");
      case 11:
        return t("dates.december");
    }
    return t("errors.notfound");
  };

  const handleDecrementMonth = () => {
    if (viewMonth === 0) {
      setViewMonth(11);
      setViewYear(viewYear - 1);
    } else {
      setViewMonth(viewMonth - 1);
    }
  };

  const handleIncrementMonth = () => {
    if (viewMonth === 11) setViewYear(viewYear + 1);
    setViewMonth((viewMonth + 1) % 12);
  };

  const handleDaySelected = (e: PointerEvent<HTMLButtonElement>) => {
    if (e.button !== RIGHT_BUTTON) {
      const selectedDayButton = e.target as HTMLButtonElement;
      const selectedDayNumber = Number(selectedDayButton.textContent);
      const newSelectedDate = new Date(viewYear, viewMonth, selectedDayNumber);
      setSelectedDate(newSelectedDate);
    }
  };

  const handleAccept = () => {
    if (selectedDate && onChange) onChange(selectedDate);
  };

  const isWeekStartingOnMonday = () => {
    return !i18n.language.startsWith("en");
  };

  const getNumberOfEmptyDaysConsideringWeekDayStart = () => {
    const firstWeekDayOfMonth = getFirstWeekDayOfMonth(viewYear, viewMonth); // 0 is Sunday
    if (isWeekStartingOnMonday()) {
      // In case the first day of the month is Sunday and the week starts on Monday
      if (firstWeekDayOfMonth === 0) return 6;
      else return firstWeekDayOfMonth - 1;
    }
    return firstWeekDayOfMonth;
  };

  const weekdayStyle = "flex items-center justify-center m-[1px] w-7 h-7 font-semibold rounded-lg";
  const emptyDayStyle = "flex items-center justify-center m-[1px] w-7 h-7 rounded-lg";
  const dayStyle = `flex items-center justify-center m-[1px] w-7 h-7 rounded-full cursor-pointer hover:bg-primary-50 focus-visible:text-white focus-visible:bg-primary-500`;
  const todayStyle = `border border-gray-400`;
  const selectedDayStyle = `flex items-center justify-center m-[1px] w-7 h-7 rounded-full cursor-pointer text-white bg-primary-500`;

  // Style inspired on https://github.com/tailwindcollections/tailwind-calendar/blob/main/src/App.vue
  return (
    <>
      {show && (
        <div className="relative inline mt-10">
          <div className={`absolute mt-2 z-10 flex items-center justify-center justify-items-center`}>
            <div className="flex justify-center bg-white shadow-lg rounded-xl text-xs w-64 border border-gray-100">
              <div className="flex flex-col">
                <div className="flex divide-x">
                  <div className="flex flex-col px-2 sm:px-6 pt-4 pb-2 border-b border-gray-100">
                    <div className="flex items-center justify-between">
                      <button
                        type="button"
                        onPointerDown={handleDecrementMonth}
                        className="flex items-center justify-center p-2 rounded-full text-gray-900 hover:bg-primary-500 hover:text-white">
                        <ChevronLeftIcon className="w-5 h-5" />
                      </button>
                      <div className="text-sm font-semibold">{viewYear.toString() + " - " + getCurrentMonthName()}</div>
                      <button
                        type="button"
                        onPointerDown={handleIncrementMonth}
                        className="flex items-center justify-center p-2 rounded-full text-gray-900 hover:bg-primary-500 hover:text-white">
                        <ChevronRightIcon className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="grid grid-cols-7 text-xs text-center justify-items-center text-gray-900">
                      {isWeekStartingOnMonday() ? (
                        <></>
                      ) : (
                        <span key="sunday" className={weekdayStyle}>
                          {t("dates.sundayshort")}
                        </span>
                      )}
                      <span key="monday" className={weekdayStyle}>
                        {t("dates.mondayshort")}
                      </span>
                      <span key="tuesday" className={weekdayStyle}>
                        {t("dates.tuesdayshort")}
                      </span>
                      <span key="wednesday" className={weekdayStyle}>
                        {t("dates.wednesdayshort")}
                      </span>
                      <span key="thursday" className={weekdayStyle}>
                        {t("dates.thursdayshort")}
                      </span>
                      <span key="friday" className={weekdayStyle}>
                        {t("dates.fridayshort")}
                      </span>
                      <span key="saturday" className={weekdayStyle}>
                        {t("dates.saturdayshort")}
                      </span>
                      {isWeekStartingOnMonday() ? (
                        <span key="sunday" className={weekdayStyle}>
                          {t("dates.sundayshort")}
                        </span>
                      ) : (
                        <></>
                      )}
                      {
                        // First empty weekdays of the current month
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        [...Array(getNumberOfEmptyDaysConsideringWeekDayStart())].map((_e, i) => (
                          <span key={`emptyDay-${i}`} className={emptyDayStyle}></span>
                        ))
                      }
                      {
                        // First empty weekdays of the current month
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        [...Array(getDaysInMonth(viewYear, viewMonth))].map((_e, day) => (
                          <button
                            key={`${day + 1}/${viewMonth}/${viewYear}`}
                            type="button"
                            onPointerDown={handleDaySelected}
                            className={`${
                              viewYear === today?.getFullYear() &&
                              viewMonth === today?.getMonth() &&
                              day + 1 === today?.getDate()
                                ? todayStyle
                                : ""
                            } ${
                              viewYear === selectedDate?.getFullYear() &&
                              viewMonth === selectedDate?.getMonth() &&
                              day + 1 === selectedDate?.getDate()
                                ? selectedDayStyle
                                : dayStyle
                            }`}>
                            {day + 1}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between justify-items-center space-x-2 py-4 px-8">
                  <button
                    key="todayBtn"
                    type="button"
                    className="sm:pl-4 hover:text-primary-500 focus-visible:text-primary-500"
                    onPointerDown={() => {
                      setViewYear(today.getFullYear());
                      setViewMonth(today.getMonth());
                      setSelectedDate(today);
                    }}>
                    {t("dates.today")}
                  </button>
                  <div className="flex justify-items-end space-x-4 sm:pr-2">
                    <button
                      key="cancelBtn"
                      type="button"
                      className="hover:text-primary-500 focus-visible:text-primary-500"
                      onPointerDown={onCancel}>
                      {t("buttons.cancel")}
                    </button>
                    <button
                      key="acceptBtn"
                      type="button"
                      className="hover:text-primary-500 focus-visible:text-primary-500"
                      onPointerDown={handleAccept}>
                      {t("buttons.accept")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
