import { RIGHT_BUTTON } from "@whyuz/data";
import { UserRole } from "@whyuz/services";
import { Dropdown, DropdownProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { useUserRoleTranslations } from "./hooks/useUserRoleTranslations";

export interface UserRoleDropdownProps extends Omit<DropdownProps, "onChange"> {
  showItemAll?: boolean;
  onChangeUserRole: (selectedType: UserRole | undefined) => void;
  placeholder?: React.ReactNode;
}

export const UserRolesDropdown = ({
  showItemAll = false,
  onChangeUserRole,
  color = "light",
  label,
  placeholder,
  ...props
}: UserRoleDropdownProps) => {
  const { t } = useTranslation();
  const userRoleTranslations = useUserRoleTranslations();

  return (
    <Dropdown label={label ?? placeholder ?? <div className="w-full" />} color={color} {...props}>
      {showItemAll && (
        <Dropdown.Item
          onPointerDown={(e: React.PointerEvent) => {
            if (e.button !== RIGHT_BUTTON) {
              onChangeUserRole(undefined);
            }
          }}>
          {t("filters.cleanfilter")}
        </Dropdown.Item>
      )}
      <Dropdown.Item
        onPointerDown={(e: React.PointerEvent) => {
          if (e.button !== RIGHT_BUTTON) {
            onChangeUserRole(UserRole.Administrator);
          }
        }}>
        {userRoleTranslations[UserRole.Administrator]}
      </Dropdown.Item>
      <Dropdown.Item
        onPointerDown={(e: React.PointerEvent) => {
          if (e.button !== RIGHT_BUTTON) {
            onChangeUserRole(UserRole.User);
          }
        }}>
        {userRoleTranslations[UserRole.User]}
      </Dropdown.Item>
    </Dropdown>
  );
};
