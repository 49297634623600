import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { getDateOneMonthAgo, getDateOneWeekAgo, getDateOneYearAgo } from "@whyuz/utils";
import { RIGHT_BUTTON } from "@whyuz/data";
import { FilterDate } from "./FilterDate.ts";

export interface TimeFilterProps {
  filterDate?: FilterDate;
  onChange: (filterSinceDate: string | undefined, filterDate: FilterDate) => void;
}

const selectedButtonClassname = "font-medium text-primary-600 dark:text-primary-500";

export const TimeFilter = ({ filterDate, onChange }: TimeFilterProps) => {
  const { t } = useTranslation();
  const [internalFilterDate, setInternalFilterDate] = useState<FilterDate>(filterDate ?? FilterDate.ALWAYS);

  useEffect(() => {
    setInternalFilterDate(filterDate ?? FilterDate.ALWAYS);
  }, [filterDate]);

  const selectedDateFilterText = useMemo(() => {
    switch (internalFilterDate) {
      case FilterDate.ALWAYS:
        return t("filters.time.always");
      case FilterDate.LAST_YEAR:
        return t("filters.time.lastyear");
      case FilterDate.LAST_MONTH:
        return t("filters.time.lastmonth");
      case FilterDate.LAST_WEEK:
        return t("filters.time.last7days");
    }
    return t("filters.time.always");
  }, [t, internalFilterDate]);

  const filterAlways = (e: React.PointerEvent) => {
    if (e.button !== RIGHT_BUTTON) {
      if (!filterDate) {
        setInternalFilterDate(FilterDate.ALWAYS);
      }
      onChange(undefined, FilterDate.ALWAYS);
    }
  };

  const filterLastYear = (e: React.PointerEvent) => {
    if (e.button !== RIGHT_BUTTON) {
      const oneYearAgo = getDateOneYearAgo();
      if (!filterDate) {
        setInternalFilterDate(FilterDate.LAST_YEAR);
      }
      onChange(oneYearAgo.toISOString(), FilterDate.LAST_YEAR);
    }
  };

  const filterLastMonth = (e: React.PointerEvent) => {
    if (e.button !== RIGHT_BUTTON) {
      const oneMonthAgo = getDateOneMonthAgo();
      if (!filterDate) {
        setInternalFilterDate(FilterDate.LAST_MONTH);
      }
      onChange(oneMonthAgo.toISOString(), FilterDate.LAST_MONTH);
    }
  };

  const filterLastWeek = (e: React.PointerEvent) => {
    if (e.button !== RIGHT_BUTTON) {
      const oneWeekAgo = getDateOneWeekAgo();
      if (!filterDate) {
        setInternalFilterDate(FilterDate.LAST_WEEK);
      }
      onChange(oneWeekAgo.toISOString(), FilterDate.LAST_WEEK);
    }
  };

  return (
    <>
      <div className="hidden sm:block">
        <Button.Group>
          <Button size="sm" color="light" onPointerDown={filterLastYear}>
            <span className={internalFilterDate === FilterDate.LAST_YEAR ? selectedButtonClassname : undefined}>
              {t("filters.time.lastyear")}
            </span>
          </Button>
          <Button size="sm" color="light" onPointerDown={filterLastMonth}>
            <span className={internalFilterDate === FilterDate.LAST_MONTH ? selectedButtonClassname : undefined}>
              {t("filters.time.last30days")}
            </span>
          </Button>
          <Button size="sm" color="light" onPointerDown={filterLastWeek}>
            <span className={internalFilterDate === FilterDate.LAST_WEEK ? selectedButtonClassname : undefined}>
              {t("filters.time.last7days")}
            </span>
          </Button>
          <Button size="sm" color="light" onPointerDown={filterAlways}>
            <span className={internalFilterDate === FilterDate.ALWAYS ? selectedButtonClassname : undefined}>
              {t("filters.time.always")}
            </span>
          </Button>
        </Button.Group>
      </div>
      <div className="block sm:hidden w-full relative">
        <Dropdown
          label={selectedDateFilterText}
          color="light"
          className={"w-full"}
          theme={{ floating: { target: "w-full" } }}
        >
          <Dropdown.Item onPointerDown={filterLastYear}>{t("filters.time.lastyear")}</Dropdown.Item>
          <Dropdown.Item onPointerDown={filterLastMonth}>{t("filters.time.last30days")}</Dropdown.Item>
          <Dropdown.Item onPointerDown={filterLastWeek}>{t("filters.time.last7days")}</Dropdown.Item>
          <Dropdown.Item onPointerDown={filterAlways}>{t("filters.time.always")}</Dropdown.Item>
        </Dropdown>
      </div>
    </>
  );
};
