import { FlowbiteFloatingTheme } from "flowbite-react/components/Floating";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteFloatingThemes {
  default = "default",
  fit = "fit",
  hidden = "hidden",
}

const defaultFloatingTheme: FlowbiteFloatingTheme = {
  target: "",
  animation: "transition-opacity",
  arrow: {
    base: "absolute z-10 h-2 w-2 rotate-45",
    style: {
      dark: "bg-gray-900 dark:bg-gray-700",
      light: "bg-white",
      auto: "bg-white dark:bg-gray-700",
    },
    placement: "-4px",
  },
  base: "absolute inline-block z-10 rounded-lg text-sm font-medium shadow-sm",
  hidden: "invisible opacity-0",
  style: {
    dark: "bg-gray-900 text-white dark:bg-gray-700",
    light: "border border-gray-200 bg-white text-gray-900",
    auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
  },
  content: "relative z-20",
};

export const FlowbiteFloatingThemes: Record<EnumFlowbiteFloatingThemes, FlowbiteFloatingTheme> = {
  default: defaultFloatingTheme,
  fit: {
    ...defaultFloatingTheme,
    base: twMerge(defaultFloatingTheme.base, "p-0"),
  },
  hidden: {
    ...defaultFloatingTheme,
    base: "hidden",
  },
};
