import { createContext, useContext, useReducer, useRef } from "react";
import { PageState, PagesState, PagesStateActions, pagesStateReducer } from "./PageReducer.ts";

export type PagesDispatchActions = {
  setPageState: (pageUrl: string, pageState: PageState) => void;
  setPageProperty: (pageUrl: string, propertyKey: string, propertyValue: string | number) => void;
  deletePageProperty: (pageUrl: string, propertyKey: string) => void;
};

export type PagesContextType = {
  state: PagesState;
  dispatchActions: PagesDispatchActions;
};

const PagesContext = createContext<PagesContextType | null>(null);

export const usePages = () => useContext(PagesContext) as PagesContextType;

const initialPagesState: PagesState = {
  pages: {},
};

export interface PagesContextProviderProps {
  children: React.ReactNode;
}

export const PagesContextProvider = ({ children }: PagesContextProviderProps) => {
  const [pagesState, dispatchPagesState] = useReducer(pagesStateReducer, initialPagesState);

  const dispatchActionsRef = useRef<PagesDispatchActions>({
    setPageState: (pageUrl: string, pageState: PageState) =>
      dispatchPagesState(new PagesStateActions.ActionSetPageState(pageUrl, pageState)),
    setPageProperty: (pageUrl: string, propertyKey: string, propertyValue: string | number) =>
      dispatchPagesState(new PagesStateActions.ActionSetPageStateProperty(pageUrl, propertyKey, propertyValue)),
    deletePageProperty: (pageUrl: string, propertyKey: string) =>
      dispatchPagesState(new PagesStateActions.ActionDeletePageStateProperty(pageUrl, propertyKey)),
  });

  return (
    <PagesContext.Provider
      value={
        {
          state: pagesState,
          dispatchActions: dispatchActionsRef.current,
        } as PagesContextType
      }
    >
      {children}
    </PagesContext.Provider>
  );
};
