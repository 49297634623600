import { toast } from "react-toastify";

export const notifyInfo = (message: string) =>
  toast.info(message, {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  });

export const notifyError = (error: string) =>
  toast.error(error, {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  });
