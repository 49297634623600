import { useContext } from "react";
import { KeycloakContext, KeycloakContextType } from "../context";

export const useKeycloak = () => {
  const context = useContext(KeycloakContext) as KeycloakContextType;

  if (!context) {
    throw new Error("useKeycloak must be used within a KeycloakContextProvider");
  }

  return context;
};
