import { FlowbiteAvatarThemes } from "@whyuz/components";
import { ChatMember, Contact, User, UserLicensePersonalInformation } from "@whyuz/services";
import { getPropertyByPath, getStringHashCode } from "@whyuz/utils";
import { Avatar, AvatarProps, Tooltip } from "flowbite-react";
import { useMemo, useState } from "react";
import { getUserFullName, getUserInitials } from ".";

export interface UserAvatarProps extends AvatarProps {
  user?: User | UserLicensePersonalInformation | Contact | ChatMember | null;
  showTooltip?: boolean;
}

const getRandomInitialsColorTheme = (initials: string | undefined) => {
  if (!initials) {
    return undefined;
  }

  // Generate a hash code based on the user initials
  const hashCode = getStringHashCode(initials);

  // Get the list of color keys from the FlowbiteColors interface
  const userInitialsThemes = [
    FlowbiteAvatarThemes.blueInitials,
    FlowbiteAvatarThemes.fuchsiaInitials,
    FlowbiteAvatarThemes.greenInitials,
    FlowbiteAvatarThemes.limeInitials,
    FlowbiteAvatarThemes.orangeInitials,
    FlowbiteAvatarThemes.pinkInitials,
    FlowbiteAvatarThemes.purpleInitials,
    FlowbiteAvatarThemes.redInitials,
    FlowbiteAvatarThemes.tealInitials,
    FlowbiteAvatarThemes.yellowInitials,
  ];

  // Use the hash code to index into the color keys and get a random color
  return userInitialsThemes[hashCode % userInitialsThemes.length];
};

export const UserAvatar = ({
  user,
  showTooltip = true,
  size = "sm",
  rounded = true,
  img,
  theme,
  ...props
}: UserAvatarProps) => {
  // This boolean is to control thie case when the picture URL is defined, but loading the image is not possible, then we only render the initials
  const [errorImageURL, setErrorImageURL] = useState(false);

  const userImage = useMemo(() => {
    if (img) {
      return img;
    }

    if (user) {
      return getPropertyByPath(user, "pictureURL") as string;
    }

    return undefined;
  }, [user, img]);

  const userInitials = useMemo(() => (user ? getUserInitials(user) : "NN"), [user]);

  const userInitialsTheme = useMemo(() => {
    if (theme) {
      return theme;
    } else {
      return getRandomInitialsColorTheme(userInitials);
    }
  }, [theme, userInitials]);

  return showTooltip && user ? (
    <Tooltip content={getUserFullName(user)}>
      <Avatar
        img={errorImageURL ? undefined : userImage}
        onError={() => setErrorImageURL(true)}
        placeholderInitials={userInitials}
        size={size}
        theme={theme ?? userInitialsTheme}
        rounded
        {...props}
      />
    </Tooltip>
  ) : (
    <Avatar
      img={errorImageURL ? undefined : userImage}
      onError={() => setErrorImageURL(true)}
      placeholderInitials={userInitials}
      size={size}
      theme={theme ?? userInitialsTheme}
      rounded
      {...props}
    />
  );
};
