import { API_REST_ENDPOINT } from "@whyuz/data";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";
import { Asset } from "../codegen/graphql";
import { useAxios } from "./useAxios";

export const useServerFiles = () => {
  const assetsAPI = useAxios<Asset, FormData>({
    endpoint: API_REST_ENDPOINT.ASSETS,
    contentType: "multipart/form-data",
  });

  const getFileURIByAssetToken = useCallback(
    (token: string) => {
      return assetsAPI.GETURI({ queryParameters: "token=" + token });
    },
    [assetsAPI],
  );

  const getFileByAssetToken = useCallback(
    (token: string) => {
      return assetsAPI.GET({ queryParameters: "token=" + token });
    },
    [assetsAPI],
  );

  const uploadFileAndGetAsset = useCallback(
    (
      file: File | Blob,
      isPublicReadAllowed: boolean,
      tenantId?: string,
      onUploadProgress?: (loaded: number, percentCompleted?: number) => void,
    ) => {
      const formData = new FormData();
      formData.append("file", file, file instanceof File ? file.name : String(new Date()));
      formData.append("isPublicReadAllowed", isPublicReadAllowed ? "true" : "false");
      if (tenantId) {
        formData.append("tenantId", tenantId);
      }

      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            const percentCompleted = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : undefined;
            onUploadProgress(progressEvent.loaded, percentCompleted);
          }
        },
      };

      return assetsAPI.POST(formData, config);
    },
    [assetsAPI],
  );

  const uploadPublicFileAndGetAsset = useCallback(
    (file: File | Blob, tenantId?: string, onUploadProgress?: (loaded: number, percentCompleted?: number) => void) =>
      uploadFileAndGetAsset(file, true, tenantId, onUploadProgress),
    [uploadFileAndGetAsset],
  );

  const uploadFileAndGetURL = useCallback(
    (
      file: File | Blob,
      isPublicReadAllowed: boolean,
      tenantId?: string,
      onUploadProgress?: (loaded: number, percentCompleted?: number) => void,
    ) => {
      return new Promise<string>((resolve, reject) => {
        uploadFileAndGetAsset(file, isPublicReadAllowed, tenantId, onUploadProgress)
          ?.then((value) => resolve(API_REST_ENDPOINT.ASSETS + "?token=" + value.data.accessToken))
          .catch((e) => reject(e as string));
      });
    },
    [uploadFileAndGetAsset],
  );

  const uploadPublicFileAndGetURL = useCallback(
    (file: File | Blob, tenantId?: string) => uploadFileAndGetURL(file, true, tenantId),
    [uploadFileAndGetURL],
  );

  return {
    getFileURIByAssetToken,
    getFileByAssetToken,
    uploadFileAndGetAsset,
    uploadPublicFileAndGetAsset,
    uploadFileAndGetURL,
    uploadPublicFileAndGetURL,
  };
};
