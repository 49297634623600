import {
  ApprovalFlowExecution,
  ApprovalFlowExecutionStatus,
  ApprovalFlowExecutionStep,
  ApprovalFlowExecutionUser,
  Error_Code,
  GQLError,
  GQLErrorStatusCode,
  useAddApprovalFlowExecutionStepMutation,
  useAddApprovalFlowExecutionUserMutation,
  useApprovalFlowExecutionLazyQuery,
  useCancelApprovalFlowExecutionMutation,
  useDeleteApprovalFlowExecutionMutation,
  useDeleteApprovalFlowExecutionStepMutation,
  useDeleteApprovalFlowExecutionUserMutation,
  useResendApprovalFlowExecutionUsersEmailsMutation,
  useUpdateApprovalFlowExecutionMutation,
  useUpdateApprovalFlowExecutionStepMutation,
  useUpdateApprovalFlowExecutionStepPositionMutation,
} from "@whyuz/services";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { isEmail, notifyError, notifyInfo } from "@whyuz/utils";
import { AddUserProps } from "../ApprovalFlows/components/ApprovalFlowStep/ApprovalFlowStep.tsx";
import { useDebounce } from "@whyuz/hooks";

export interface IApprovalFlowExecutionBuilder {
  approvalFlowExecutionId?: string;
  approvalFlowExecution?: ApprovalFlowExecution;
  approvalFlowExecutionSteps?: ApprovalFlowExecutionStep[];
  setSteps: Dispatch<SetStateAction<ApprovalFlowExecutionStep[]>>;
  consolidateSteps: (stepToChangePosition: ApprovalFlowExecutionStep, newPosition: number) => void;
  addStep: () => void;
  updateStep: (step: ApprovalFlowExecutionStep) => void;
  deleteStep: (id: string) => void;
  addUser: (props: AddUserProps) => void;
  deleteUser: (user: ApprovalFlowExecutionUser, stepId: string) => void;
  error?: GQLError;
  readOnly: boolean;
  resendEmailsAppFlow: () => void;
  hasEverBeenExecuted: boolean;
  isLoading: boolean;
  deleteApprovalFlowExecution: () => void;
  cancelApprovalFlowExecution: (reason: string) => void;
  setError: Dispatch<SetStateAction<GQLError | undefined>>;
  approvalName: string | undefined;
  setApprovalName: (e: string | undefined) => void;
  approvalMailAccountId: string | undefined;
  setApprovalMailAccountId: (mailAccountId: string | undefined) => void;
  onUseMailTemplate: (messageHtml: string, subjectHtml: string) => void;
}

const ApprovalFlowBuilderContext = createContext<IApprovalFlowExecutionBuilder | null>(null);

export const useApprovalFlowExecutionBuilderContext = () => {
  const context = useContext(ApprovalFlowBuilderContext) as IApprovalFlowExecutionBuilder;
  if (!context) {
    throw new Error("useApprovalFlowBuilder must be used within an ApprovalFlowBuilderProvider");
  }
  return context;
};

interface ApprovalFlowBuilderProviderProps {
  children: ReactNode;
  approvalFlowExecutionId?: string;
  tenantId?: string;
}
export const ApprovalFlowExecutionBuilderProvider = ({
  approvalFlowExecutionId,
  children,
  tenantId,
}: ApprovalFlowBuilderProviderProps) => {
  const [approvalFlowExecutionData, setApprovalFlowExecutionData] = useState<ApprovalFlowExecution>();
  const [approvalSteps, setApprovalSteps] = useState<ApprovalFlowExecutionStep[]>([]);
  const [error, setError] = useState<GQLError>();
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const [keyEditor, setKeyEditor] = useState<number>(0);
  const [approvalName, setApprovalName] = useState<string | undefined>();
  const [mailAccountId, setMailAccountId] = useState<string | undefined>();
  const debouncedEditor = useDebounce<number>(keyEditor, 5000);
  const areUpdatingsPendings = useRef(false);

  const handleChangeName = (e: string | undefined) => {
    setApprovalName(e);
    setKeyEditor(Date.now());
    areUpdatingsPendings.current = true;
  };

  //! Mutations and queries
  const [approvalLazyQuery, { hasEverBeenExecuted, isLoading }] = useApprovalFlowExecutionLazyQuery();
  const [mutatePosition] = useUpdateApprovalFlowExecutionStepPositionMutation();
  const [mutateStep] = useUpdateApprovalFlowExecutionStepMutation();
  const [addApprovalExecutionStep] = useAddApprovalFlowExecutionStepMutation();
  const [deleteApprovalExecutionStep] = useDeleteApprovalFlowExecutionStepMutation();
  const [addApprovalExecutionUser] = useAddApprovalFlowExecutionUserMutation();
  const [deleteApprovalExecutionUser] = useDeleteApprovalFlowExecutionUserMutation();
  const [resendEmailsAppFlowMutation] = useResendApprovalFlowExecutionUsersEmailsMutation();
  const [cancelApprovalFlowMutation] = useCancelApprovalFlowExecutionMutation();
  const [deleteApprovalFlowExecution] = useDeleteApprovalFlowExecutionMutation();
  const [updateApprovalFlowExecutionMutation] = useUpdateApprovalFlowExecutionMutation();

  const [readOnly, setReadonly] = useState<boolean>(false);
  const handleLoadApprovalFlow = useCallback(() => {
    approvalLazyQuery({ variables: { id: approvalFlowExecutionId, tenantId } })
      .then((approval) => {
        if (approval) {
          setApprovalFlowExecutionData(approval);
          const stepsList = (approval?.steps ?? []) as ApprovalFlowExecutionStep[];
          const steps = stepsList.sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          setApprovalSteps(steps);
          setReadonly(approval.status !== ApprovalFlowExecutionStatus.NotLaunched);
          setApprovalName(approval.name as string);
          setMailAccountId(approval.mailAccount?.id as string);
        } else {
          setError({
            isUncontrolledError: true,
            statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
            message: tApproval("approvals") + " " + String(approvalFlowExecutionId),
            fieldErrors: {},
          });
        }
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.httperror400detail"));
      });
  }, [approvalLazyQuery, approvalFlowExecutionId, tApproval, t, tenantId]);

  useEffect(() => {
    if (approvalFlowExecutionId) {
      handleLoadApprovalFlow();
    }
  }, [handleLoadApprovalFlow, approvalFlowExecutionId]);

  //! Steps mutations and another logic
  const onStepsMutationsSuccess = (response: ApprovalFlowExecutionStep) => {
    setApprovalSteps((prev) => {
      return prev
        .reduce((acc, next) => {
          if (next.id === response.id) {
            return [...acc, response];
          } else {
            return [...acc, next];
          }
        }, [] as ApprovalFlowExecutionStep[])
        .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
    });
  };
  const handleConsolidateSteps = (stepToChangePosition: ApprovalFlowExecutionStep, newPosition: number) => {
    if (approvalSteps && approvalSteps?.length > 1) {
      mutatePosition({
        variables: {
          approvalFlowExecutionStepId: stepToChangePosition.id as string,
          newPosition,
          tenantId,
        },
      })
        .then((res) => {
          onStepsMutationsSuccess(res);
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.updatingentity"));
          setApprovalSteps((approvalFlowExecutionData?.steps as ApprovalFlowExecutionStep[]) || []);
        });
    }
  };
  const handleUpdateStep = (step: ApprovalFlowExecutionStep) => {
    mutateStep({
      variables: {
        id: step?.id as string,
        approvalFlowExecutionStep: {
          stepNumber: step?.stepNumber,
          approvalFlowExecutionId: approvalFlowExecutionId,
          requiredAll: step?.requiredAll,
          messageHtml: step?.messageHtml,
          subjectHtml: step?.subjectHtml,
        },
        tenantId,
      },
    })
      .then((res) => {
        onStepsMutationsSuccess(res);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.updatingentity"));
        setApprovalSteps((approvalFlowExecutionData?.steps as ApprovalFlowExecutionStep[]) || []);
      });
  };
  const handleAddStep = () => {
    addApprovalExecutionStep({
      variables: {
        approvalFlowExecutionStep: {
          stepNumber: approvalSteps?.length,
          messageHtml: null,
          subjectHtml: null,
          requiredAll: true,
          approvalFlowExecutionId: approvalFlowExecutionId,
        },
        tenantId,
      },
    })
      .then((res) => {
        setApprovalSteps((prev) => [...prev, res]);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.addingentity"));
      });
  };
  const handleAddUser = ({ name, userId, stepId, email }: AddUserProps) => {
    if (email && !isEmail(email)) {
      setError({
        message: t("errors.validationfailed"),
        fieldErrors: {
          userId: {
            entity: "approvalFlowUser",
            field: "userId",
            error: Error_Code.IncorrectValue,
          },
        },
        isUncontrolledError: false,
      });
    } else {
      addApprovalExecutionUser({
        variables: {
          approvalFlowExecutionUser: {
            name: name,
            userId: userId,
            approvalFlowExecutionStepId: stepId,
            email,
          },
          tenantId,
        },
      })
        .then((res) => {
          setError(undefined);
          setApprovalSteps((prev) => {
            return prev
              .reduce((acc, next) => {
                if (next.id === res.approvalFlowExecutionStep?.id) {
                  return [...acc, { ...next, users: [...(next?.users || []), res] }];
                } else {
                  return [...acc, next];
                }
              }, [] as ApprovalFlowExecutionStep[])
              .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          });
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.updatingentity"));
        });
    }
  };
  const handleDeleteUser = (user: ApprovalFlowExecutionUser, stepId: string) => {
    deleteApprovalExecutionUser({
      variables: {
        id: user.id as string,
        tenantId,
      },
    })
      .then((res) => {
        setApprovalSteps((prev) => {
          return prev
            .reduce((acc, next) => {
              if (next.id === stepId) {
                const updatedUsers = next.users?.filter((u) => u?.id !== res.id);
                return [...acc, { ...next, users: updatedUsers }];
              } else {
                return [...acc, next];
              }
            }, [] as ApprovalFlowExecutionStep[])
            .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
        });
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.updatingentity"));
      });
  };
  const handleDeleteStep = (id: string) => {
    if (approvalSteps.length > 1) {
      deleteApprovalExecutionStep({
        variables: {
          id,
          tenantId,
        },
      })
        .then((res) => {
          setApprovalSteps((prev) => {
            return prev
              .filter((step) => step.id !== res.id)
              .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          });
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.deletingentity"));
        });
    }
  };

  //! Approval mutations and another logic
  const resendEmailsAppFlow = () => {
    resendEmailsAppFlowMutation({ variables: { id: approvalFlowExecutionId, tenantId } })
      .then(() => {
        notifyInfo(tApproval("resendinginternalappflowemails"));
        setReadonly(true);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(tApproval("errors.resendinginternalappflowemails"));
      });
  };

  const cancelApproval = (reason: string) => {
    cancelApprovalFlowMutation({ variables: { approvalFlowExecutionId: approvalFlowExecutionId, reason, tenantId } })
      .then((res) => {
        notifyInfo(tApproval("canceledapproval"));
        setApprovalFlowExecutionData(res);
        setReadonly(true);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(tApproval("errors.cancelingapproval"));
      });
  };

  const deleteApproval = () => {
    deleteApprovalFlowExecution({ variables: { id: approvalFlowExecutionId, tenantId } })
      .then(() => {
        notifyInfo(t("message.entitydeletedsuccessfully"));
      })
      .catch((error: GQLError) => {
        setError(error);
      });
  };

  const updateApprovalRef = useRef<((idMailAccount?: string) => void) | null>(null);
  updateApprovalRef.current = (idMailAccount?: string) => {
    updateApprovalFlowExecutionMutation({
      variables: {
        id: approvalFlowExecutionId,
        approvalFlowExecution: {
          name: approvalName,
          type: approvalFlowExecutionData?.type,
          mailAccountId: idMailAccount ?? mailAccountId,
          approvalFlowTemplateId: approvalFlowExecutionData?.approvalFlowTemplate?.id as string,
        },
        tenantId,
      },
    })
      .then((res) => {
        setApprovalFlowExecutionData(res);
      })
      .catch((error) => {
        setError(error as GQLError);
        setApprovalFlowExecutionData({ ...approvalFlowExecutionData } as ApprovalFlowExecution);
        setApprovalName(approvalFlowExecutionData?.name as string);
        setMailAccountId(approvalFlowExecutionData?.mailAccount?.id as string);
        notifyError(t("errors.updatingentity"));
      })
      .finally(() => (areUpdatingsPendings.current = false));
  };

  const handleChangeMailAccountId = (mailAccountId: string | undefined) => {
    setMailAccountId(mailAccountId);
    if (updateApprovalRef.current) {
      updateApprovalRef.current(mailAccountId);
    }
  };

  useEffect(() => {
    if (debouncedEditor > 0 && updateApprovalRef.current) {
      updateApprovalRef.current();
    }
  }, [debouncedEditor]);

  useEffect(() => {
    return () => {
      if (areUpdatingsPendings.current && updateApprovalRef.current) {
        updateApprovalRef.current();
      }
    };
  }, []);
  const updateApprovalMailUsingTemplate = (messageHtml: string, subjectHtml: string) => {
    updateApprovalFlowExecutionMutation({
      variables: {
        id: approvalFlowExecutionId,
        approvalFlowExecution: {
          name: approvalFlowExecutionData?.name,
          messageHtml,
          subjectHtml,
          type: approvalFlowExecutionData?.type,
          approvalFlowTemplateId: approvalFlowExecutionData?.approvalFlowTemplate?.id as string,
          mailAccountId: approvalFlowExecutionData?.mailAccount?.id as string,
        },
      },
    })
      .then((res) => {
        setApprovalFlowExecutionData(res);
      })
      .catch((error) => {
        setError(error as GQLError);
        setApprovalFlowExecutionData({ ...approvalFlowExecutionData } as ApprovalFlowExecution);
        notifyError(t("errors.updatingentity"));
      });
  };

  if (approvalFlowExecutionId && !approvalFlowExecutionData) {
    return null;
  }

  const contextValue: IApprovalFlowExecutionBuilder = {
    approvalFlowExecutionId: approvalFlowExecutionId,
    approvalFlowExecution: approvalFlowExecutionData,
    approvalFlowExecutionSteps: approvalSteps,
    setSteps: setApprovalSteps,
    consolidateSteps: handleConsolidateSteps,
    addStep: handleAddStep,
    updateStep: handleUpdateStep,
    addUser: handleAddUser,
    deleteStep: handleDeleteStep,
    deleteUser: handleDeleteUser,
    error,
    readOnly,
    resendEmailsAppFlow,
    hasEverBeenExecuted,
    isLoading,
    cancelApprovalFlowExecution: cancelApproval,
    deleteApprovalFlowExecution: deleteApproval,
    setError,
    onUseMailTemplate: updateApprovalMailUsingTemplate,
    setApprovalName: handleChangeName,
    approvalName,
    approvalMailAccountId: mailAccountId,
    setApprovalMailAccountId: handleChangeMailAccountId,
  };

  return <ApprovalFlowBuilderContext.Provider value={contextValue}>{children}</ApprovalFlowBuilderContext.Provider>;
};
