import React from "react";
import { AdvancedTable } from "../AdvancedTable.tsx";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { Page } from "../../Page";

export interface AdvancedTablePanelTimeFilterProps<T extends EntityId, Filter extends T> {
  tableState?: AdvancedTableState<T, Filter>;
  children?: React.ReactNode;
}

export const AdvancedTablePanelTimeFilter = <T extends EntityId, Filter extends T>({
  tableState,
  children,
}: AdvancedTablePanelTimeFilterProps<T, Filter>) => {
  return (
    <>
      {tableState && (
        <div className={tableState.isShowingAllFilters ? "block" : "hidden sm:block"}>
          <Page.PanelContainer>
            <AdvancedTable.FilterContainer tableState={tableState}>
              <AdvancedTable.TimeFilter tableState={tableState} />
              <AdvancedTable.ShowMoreOptions tableState={tableState} />
            </AdvancedTable.FilterContainer>
            {children}
          </Page.PanelContainer>
        </div>
      )}
    </>
  );
};
