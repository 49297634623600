import { EntityDictionary } from "@whyuz/types";
import { useMemo } from "react";
import { useKeycloak } from "./useKeycloak";
import { useSession } from "./useSession";

export const useHeaders = () => {
  const { keycloak } = useKeycloak();
  const { sessionToken } = useSession();
  const headers = useMemo((): EntityDictionary<string> | undefined => {
    if (keycloak.token && sessionToken) {
      return {
        Authorization: `Bearer ${keycloak.token}`,
        "X-SESSION-TOKEN": sessionToken ?? "",
      };
    }

    if (keycloak.token && !sessionToken) {
      return {
        Authorization: `Bearer ${keycloak.token}`,
      };
    }

    if (!keycloak.token && sessionToken) {
      return {
        "X-SESSION-TOKEN": sessionToken ?? "",
      };
    }

    return undefined;
  }, [keycloak.token, sessionToken]);
  return headers;
};
