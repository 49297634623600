/**
 * Check if provided parameter is plain object
 * @param item
 * @returns boolean
 */
function isObject(item: unknown): item is Record<string, unknown> {
  return item !== null && typeof item === "object" && item.constructor === Object;
}

function cloneDeep<T>(source: T) {
  if (!isObject(source)) {
    return source;
  }

  const output = { ...source };

  Object.keys(source).forEach((key) => {
    (output as Record<string, unknown>)[key] = cloneDeep(source[key]);
  });
  return output;
}

/**
 * Merge and deep copy the values of all of the enumerable own properties of target object from source object to a new object
 * @param target The target object to get properties from.
 * @param source The source object from which to copy properties.
 * @return A new merged and deep copied object.
 */
export function mergeDeep<T extends object, S extends object>(target: T, source: S): T & S {
  if (isObject(source) && Object.keys(source).length === 0) {
    return cloneDeep({ ...target, ...source });
  }

  const output = { ...target, ...source };

  if (isObject(source) && isObject(target)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key]) && key in target && isObject(target[key])) {
        (output as Record<string, unknown>)[key] = mergeDeep(target[key] as object, source[key] as object);
      } else {
        (output as Record<string, unknown>)[key] = isObject(source[key]) ? cloneDeep(source[key]) : source[key];
      }
    });
  }

  return output;
}

type Decr = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export type DeepKeyOf<T, Depth extends number = 3> = T extends object
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends any[]
    ? never
    : {
        [K in keyof T]: `${K & string}` | (Depth extends 1 ? never : `${K & string}.${DeepKeyOf<T[K], Decr[Depth]>}`);
      }[keyof T]
  : never;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const getPropertyByPath = <T extends object>(entity: T, propertyPath: string): unknown => {
  if (!entity || !(entity instanceof Object)) {
    return undefined;
  }

  let properties = propertyPath.split(".");
  if (!properties[0]) {
    return undefined;
  }

  const currentPropertyName = properties[0].toLowerCase();
  const matchingKey = Object.keys(entity).find((key) => key.toLowerCase() === currentPropertyName);

  if (!matchingKey) {
    return undefined;
  }

  const currentProperty = entity[matchingKey as keyof T];
  if (!currentProperty || properties.length === 1) {
    return currentProperty;
  } else {
    // Recursive step
    properties = properties.slice(1);
    return getPropertyByPath(currentProperty as object, properties.join("."));
  }
};

export const assignPropertyByPath = <T extends object>(entity: T, propertyPath: string, value: unknown) => {
  if (!entity || !(entity instanceof Object)) {
    return;
  }

  let properties = propertyPath.split(".");
  if (!properties[0]) {
    return;
  }

  const currentPropertyName = properties[0];
  if (properties.length === 1) {
    (entity as Record<string, unknown>)[currentPropertyName] = value;
  } else {
    const record = entity as Record<string, unknown>;

    // In case the property is empty, we initialize it with an empty object
    if (record[currentPropertyName] === null || record[currentPropertyName] === undefined) {
      record[currentPropertyName] = {};
    }

    // Recursive step
    properties = properties.slice(1);
    const currentProperty = entity[currentPropertyName as keyof T];
    assignPropertyByPath(currentProperty as object, properties.join("."), value);
  }
};

export const deepCloneAndAssignPropertyByPath = <T extends object>(
  entity: T,
  propertyPath: string,
  value: unknown,
): T => {
  const clon = cloneDeep(entity);
  assignPropertyByPath(clon, propertyPath, value);
  return clon;
};
