import React from "react";

// Saves incoming handler to the ref in order to avoid "useCallback hell"
export const useEventCallback = <T>(handler?: (value: T) => void): (value: T) => void => {
	const callbackRef = React.useRef(handler)

	React.useEffect(() => {
		callbackRef.current = handler
	})

	return React.useCallback((value: T) => callbackRef.current && callbackRef.current(value), [])
}

