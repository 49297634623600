import { useContext } from "react";
import { CountriesContext, CountriesContextType } from "../context/CountryContext";

export const useCountries = () => {
  const context = useContext(CountriesContext) as CountriesContextType;

  if (!context) {
    throw new Error("useCountries must be used within a CountriesContextProvider");
  }

  return context;
};
