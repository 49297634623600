import { ENDPOINT, RIGHT_BUTTON, WHYUZ_FRONTEND_URL } from "@whyuz/data";
import { GQLError } from "@whyuz/services";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorDetails } from "./index.ts";

export interface GenericErrorProps {
  errorNumber?: number;
  title: string;
  description: string;
  error?: GQLError;
  onClose?: () => void;
}

export const GenericError = ({ errorNumber, title, description, error, onClose }: GenericErrorProps) => {
  const { t } = useTranslation();
  const [isDetailVisible, setDetailVisible] = useState<boolean>(false);
  const isDetailAvailable = (error?.fieldErrors && Object.keys(error?.fieldErrors).length > 0) || error?.message;

  return (
    <section className="bg-white dark:bg-gray-900 h-[calc(100vh-72px)] flex items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
            {errorNumber ? errorNumber : t("word.error")}
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">{title}</p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">{description}</p>
          <div className="flex space-x-2 justify-center">
            {onClose && (
              <button
                type="button"
                className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                onPointerDown={(e) => {
                  if (e.button !== RIGHT_BUTTON) {
                    onClose();
                  }
                }}>
                {t("buttons.close")}
              </button>
            )}
            <a
              href={ENDPOINT.REACT_APP + WHYUZ_FRONTEND_URL.HOME}
              className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
              {t("buttons.backhomepage")}
            </a>
            {isDetailAvailable && (
              <>
                <button
                  type="button"
                  className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                  onPointerDown={(e) => {
                    if (e.button !== RIGHT_BUTTON) {
                      setDetailVisible(true);
                    }
                  }}>
                  {t("word.details")}
                </button>
                <ErrorDetails error={error} show={isDetailVisible} onClose={() => setDetailVisible(false)} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
