import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useUser } from "@whyuz/services";
import { BaseEntity } from "@whyuz/types";
import { Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { Page } from "../Page";
import { PageFormGrid } from "../Page/PageFormGrid.tsx";
import { PageFormTextInfoField } from "../Page/PageFormTextInfoField.tsx";
import { PageFormDateTimeInfoField } from "../Page/PageFormDateTimeInfoField.tsx";
import { PageFormToggleField } from "../Page/PageFormToggleField.tsx";

export interface PageModalEntityInfoButtonProps extends ModalProps {
  entity: BaseEntity;
  detail: React.ReactNode;
}

export const EntityInfoModal = ({ entity, detail, ...props }: PageModalEntityInfoButtonProps) => {
  const { t } = useTranslation();
  const { isUserSuperAdmin } = useUser();

  return (
    <Modal popup {...props}>
      <Modal.Header className="pt-8 px-10">
        <Page.Title
          icon={<InformationCircleIcon className="w-8 h-8 text-primary-600" />}
          title={t("entities.entityinformation")}
          detail={detail}
        />
      </Modal.Header>
      <Modal.Body>
        <Page.FormContainer>
          <PageFormGrid columns={2} columnsBreakpointSM={true}>
            {entity.id && <PageFormTextInfoField id="entityId" label={t("entities.id")} text={entity.id} columns={2} />}
            {entity.createdOn && (
              <PageFormDateTimeInfoField
                id="entityCreationDate"
                label={t("entities.creationdate")}
                dateStringGMT00={entity.createdOn}
              />
            )}
            {entity.createdBy && (
              <PageFormTextInfoField id="entityCreatedBy" label={t("entities.createdby")} text={entity.createdBy} />
            )}
            {entity.updatedOn && (
              <PageFormDateTimeInfoField
                id="entityLastUpdateDate"
                label={t("entities.lastupdatedate")}
                dateStringGMT00={entity.updatedOn}
              />
            )}
            {entity.updatedBy && (
              <PageFormTextInfoField id="entityUpdatedBy" label={t("entities.updatedby")} text={entity.updatedBy} />
            )}
            {isUserSuperAdmin && (
              <PageFormToggleField
                id="deletedentity"
                label={t("word.deleted")}
                checked={entity.deleted ?? false}
                labelBold={false}
              />
            )}
          </PageFormGrid>
        </Page.FormContainer>
      </Modal.Body>
    </Modal>
  );
};
