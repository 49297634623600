import { ComboboxAutocomplete, ComboboxAutocompleteProps, ComboboxItemDictionary } from "@whyuz/components";
import { Country } from "@whyuz/services";
import { useCallback, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useCountries } from "../hooks";

export interface CountryComboboxProps extends Omit<ComboboxAutocompleteProps<Country>, "items"> {
  showFlags?: boolean;
  showCountryCode?: boolean;
}

export const CountryCombobox = ({ showFlags = true, showCountryCode = true, ...props }: CountryComboboxProps) => {
  const [countryItems, setCountryItems] = useState<ComboboxItemDictionary<Country>>({});
  const { searchCountries, countryTranslation } = useCountries();

  const onCountrySearchTextChange = useCallback(
    (searchText: string | null) => {
      const countriesFound = searchCountries(searchText);
      const items: ComboboxItemDictionary<Country> = countriesFound.reduce(
        (prev, country) =>
          country
            ? {
                ...prev,
                [country.id]: {
                  id: country.id,
                  name: (showCountryCode ? country.id + "-" : "") + countryTranslation(country),
                  icon: showFlags ? (
                    <ReactCountryFlag svg countryCode={country.id} style={{ width: "1.25rem", height: "1.25rem" }} />
                  ) : undefined,
                  entity: country,
                },
              }
            : prev,
        {} as ComboboxItemDictionary<Country>,
      );
      setCountryItems(items);
    },
    [countryTranslation, searchCountries, showCountryCode, showFlags],
  );

  return <ComboboxAutocomplete items={countryItems} onSearchTextChange={onCountrySearchTextChange} {...props} />;
};
