import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { useTranslation } from "react-i18next";
import { useWebPageNavigation } from "..";
import { DiscardChangesConfirmationModal, useModal } from "../Modal";

export interface PageNavigationPanelProps {
  requestDiscardChanges?: boolean;
  onBack?: () => void;
}

export const PageNavigationPanel = ({ requestDiscardChanges, onBack }: PageNavigationPanelProps) => {
  const { t } = useTranslation();
  const webPageNavigation = useWebPageNavigation();
  const { showModal, closeModal, modalProps } = useModal();

  const back = () => {
    if (onBack) {
      onBack();
    } else {
      webPageNavigation.navigateBack();
    }
  };

  return webPageNavigation.isPossibleNavigateBack ? (
    <>
      <div
        className="flex text-primary-700 text-sm font-medium px-4 pt-2 items-center cursor-pointer"
        onPointerDown={(e) => {
          if (e.button !== RIGHT_BUTTON) {
            if (requestDiscardChanges) {
              showModal();
            } else {
              back();
            }
          }
        }}>
        <ArrowLongLeftIcon className="w-5 h-5 mr-2" />
        {t("buttons.back")}
      </div>
      {requestDiscardChanges && (
        <DiscardChangesConfirmationModal
          onDiscardChanges={() => {
            closeModal();
            back();
          }}
          {...modalProps}
        />
      )}
    </>
  ) : undefined;
};
