import { ChatMessage, UserContextType } from "@whyuz/services";
import { getLocaleDateString } from "@whyuz/utils";

export const groupMessagesByDates = (chatMessages: ChatMessage[], locale: Intl.LocalesArgument) => {
  const groupedDates: { [key: string]: ChatMessage[] } = {};

  chatMessages.forEach((msg) => {
    const key = getLocaleDateString(locale, new Date(msg.createdOn as string));
    if (!groupedDates[key]) {
      groupedDates[key] = [];
    }
    groupedDates[key].push(msg);
  });

  const dateComparator = (a: [string, ChatMessage[]], b: [string, ChatMessage[]]) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA.valueOf() - dateB.valueOf();
  };
  return Object.entries(groupedDates).sort(dateComparator);
};

export const dateMessageComparator = (a: ChatMessage, b: ChatMessage) => {
  const dateA = new Date(a.createdOn as string);
  const dateB = new Date(b.createdOn as string);
  return dateA.valueOf() - dateB.valueOf();
};

export const isMyMessage = (chatMessage: ChatMessage, userCtx: UserContextType): boolean => {
  return chatMessage.chatMember?.mail === userCtx.userLicense?.mail;
};
