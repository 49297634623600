export const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
});

export const isEmpty = (string: string | undefined | null): boolean => {
  return !string || string.trim() === "";
};

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const specialChar = ["ñ", "ü"];
export const isEmail = (email: string) =>
  email !== undefined &&
  email !== null &&
  email !== "" &&
  emailRe.test(String(email).toLowerCase()) &&
  !specialChar.some((char) => email.toLowerCase().includes(char));

// djb2 algorithm
export const getStringHashCode = (input: string): number => {
  let hash = 5381;

  for (let i = 0; i < input.length; i++) {
    hash = (hash * 33) ^ input.charCodeAt(i);
  }

  return hash >>> 0; // Ensure the result is a non-negative integer
};
