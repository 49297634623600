import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { getFileSizeText, notifyError } from "@whyuz/utils";
import { useMemo } from "react";
import { DropzoneInputProps, DropzoneOptions, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

type DropProps = DropzoneInputProps & Omit<DropzoneOptions, "onDrop" | "maxSize"> & React.PropsWithChildren;

export interface DropFileZoneProps extends Omit<DropProps, "maxSize"> {
  maxSizeInBytes?: number;
  onFileAdded?: (fileAdded: File) => void;
  theme?: { container?: string; placeholder?: string };
}

export const DropFileZone = ({
  id = "dropzone-files",
  onFileAdded,
  multiple = false,
  maxSizeInBytes = 1024 * 1024, // 1 MB
  theme,
  children,
  ...props
}: DropFileZoneProps) => {
  const { t, i18n } = useTranslation();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    ...props,
    multiple,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((imageFile) => {
        if (!maxSizeInBytes || maxSizeInBytes > imageFile.size) {
          if (onFileAdded) {
            acceptedFiles.forEach((currentFile) => {
              onFileAdded(currentFile);
            });
          }
        } else {
          notifyError(t("errors.maximumsizeexceeded"));
        }
      });
    },
  });

  const borderColor = useMemo(
    () =>
      isFocused
        ? "border-primary-500"
        : isDragAccept
          ? "border-primary-500"
          : isDragReject
            ? "border-red-600"
            : undefined,
    [isFocused, isDragAccept, isDragReject],
  );

  const extensionsAcceptedText = useMemo(() => {
    if (!props.accept) {
      return undefined;
    }
    const extensions = Object.values(props.accept)
      .slice(0, 5)
      .flatMap((ext) => ext);
    return extensions.slice(0, 5).join(" · ");
  }, [props.accept]);

  return (
    <div className={twMerge("flex items-center justify-center w-full outline-0", theme?.container)} {...getRootProps()}>
      <label
        htmlFor={id}
        className={twMerge(
          "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600",
          theme?.placeholder,
          borderColor,
        )}>
        {children ?? (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <CloudArrowUpIcon className="w-8 h-8 mb-4 text-gray-300 dark:text-gray-400" />
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <Trans
                i18nKey="placeholders.clicktouploadfileordraganddrop"
                components={{ semibold: <span className="font-semibold" /> }}
              />
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {extensionsAcceptedText ? extensionsAcceptedText + " " : ""}
              {"<" + getFileSizeText(maxSizeInBytes, i18n.language)}
            </p>
          </div>
        )}
      </label>
      <input id={id} type="file" multiple={multiple} className="hidden" {...getInputProps} />
    </div>
  );
};
