import { InfiniteScrollPaginationCallbackProps, useInfiniteScroll } from "@whyuz/components";
import { ChatMessage, useUser } from "@whyuz/services";
import { EntityDictionary } from "@whyuz/types";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ChatMessageBubble } from ".";
import { dateMessageComparator, groupMessagesByDates } from "../utils";

interface ChatMessagesContainerProps {
  messages: EntityDictionary<ChatMessage>;
  onUpdateMessage: (messageId: string, textHTML: string) => void;
  onDeleteMessage: (messageId: string) => void;
  onScrollReachEnd: (props: InfiniteScrollPaginationCallbackProps) => void;
  disabled?: boolean;
}

export const ChatMessagesContainer = ({
  messages,
  onUpdateMessage,
  onDeleteMessage,
  onScrollReachEnd,
  disabled,
}: ChatMessagesContainerProps) => {
  const { i18n } = useTranslation();
  const messagesGroupedByDay = useMemo(
    () => groupMessagesByDates(Object.values(messages), i18n.language),
    [i18n.language, messages],
  );
  const userCtx = useUser();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const dayMessagesContainerRef = useRef<HTMLDivElement>(null);

  const { lastHTMLElementRef, setPagination } = useInfiniteScroll<HTMLDivElement>({
    onScrollReachEnd,
  });

  useEffect(() => {
    if (dayMessagesContainerRef.current) {
      const lastMessage = dayMessagesContainerRef.current.lastChild as HTMLElement;
      if (lastMessage) {
        lastMessage.scrollIntoView();
      }
    }
  }, [messages]);

  // Initial load
  useEffect(() => {
    onScrollReachEnd({ pageNumber: 0, pageSize: 20, setPagination });
  }, [onScrollReachEnd, setPagination]);

  return (
    <div
      className="flex flex-col flex-1 mb-1 -mr-4 overflow-hidden overflow-y-scroll scroll-smooth relative"
      ref={messagesContainerRef}>
      {messagesGroupedByDay.map(([key, value], index) => (
        <div
          key={key}
          className="pt-2 space-y-2"
          ref={messagesGroupedByDay.length === index + 1 ? dayMessagesContainerRef : undefined}>
          <p
            className="font-normal text-sm text-center dark:text-white"
            ref={index === 0 ? lastHTMLElementRef : undefined}>
            {key}
          </p>
          {value.sort(dateMessageComparator).map((msg) => (
            <div
              key={msg.id as string}
              className={twMerge(
                "flex px-2",
                msg.chatMember?.mail === userCtx.userLicense?.mail ? "justify-end" : "justify-start",
              )}>
              <ChatMessageBubble
                position={msg.chatMember?.mail === userCtx.userLicense?.mail ? "right" : "left"}
                color={msg.chatMember?.mail === userCtx.userLicense?.mail ? "blue" : "gray"}
                chatMessage={msg}
                onUpdateMessage={onUpdateMessage}
                onDeleteMessage={onDeleteMessage}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
