import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { useTranslation } from "react-i18next";

export interface AdvancedTableShowMoreOptionsProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
}

export const AdvancedTableShowMoreOptions = <T extends EntityId, Filter extends T>({
  tableState,
}: AdvancedTableShowMoreOptionsProps<T, Filter>) => {
  const { t } = useTranslation();

  return (
    <span
      className={`hidden sm:flex items-center flex-shrink-0 text-sm font-medium text-primary-600 dark:text-primary-500 hover:underline cursor-pointer select-none`}
      onPointerDown={(e) => {
        if (e.button !== RIGHT_BUTTON) {
          tableState.setShowMoreOptions(!tableState.isShowingAllFilters);
        }
      }}>
      <PlusCircleIcon className="h-3.5 w-3.5 mr-2" />
      {t("filters.moreoptions")}
    </span>
  );
};
