import { renderToString } from "react-dom/server";
import { FileCard } from "../../File";

export const CustomHTMLEditorParser = (html: string) => {
  const createMarkup = () => {
    const parsedHTML = html.replace(/<filecard\s*([^>]*)><\/filecard>/g, (_match, p1) => {
      const attributes = p1
        .match(/(\S+?=".*?"|\S+)/g)
        .reduce((acc: { [key: string]: string | boolean }, attr: string) => {
          const firstEqualIndex = attr.indexOf("=");

          if (firstEqualIndex !== -1) {
            const key = attr.slice(0, firstEqualIndex);
            const value = attr.slice(firstEqualIndex + 1);

            acc[key] = value ? value.replace(/["']/g, "") : true;
          } else {
            // If there is no equal sign, treat the whole attribute as a key with a boolean value
            acc[attr] = true;
          }

          return acc;
        }, {});

      return renderToString(<FileCard {...attributes} />);
    });

    return { __html: parsedHTML };
  };
  createMarkup();
  return <div dangerouslySetInnerHTML={createMarkup()} />;
};
