import { ErrorMessageType, GQLError } from "@whyuz/services";
import { getColSpan, getPropertyByPath } from "@whyuz/utils";
import { Label } from "flowbite-react";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { ErrorMessage } from "../Error";

export interface PageFormCustomEditableFieldProps {
  id: string;
  label?: string;
  labelBold?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  children: React.ReactNode;
  error: GQLError | undefined;
}

export const PageFormCustomEditableField = ({
  id,
  label,
  labelBold = true,
  columns,
  columnsBreakpointSM = true,
  children,
  error,
}: PageFormCustomEditableFieldProps) => {
  // GetPropertyByPath used to avoid case sensitive field name errors
  const fieldErrorMessage = useMemo(
    () => (error ? (getPropertyByPath(error.fieldErrors, id) as ErrorMessageType) : undefined),
    [error, id],
  );

  return (
    <div className={twMerge("flex flex-col", getColSpan(columns, columnsBreakpointSM))}>
      {label && (
        <Label
          htmlFor={id}
          className={twMerge(
            "mb-2 text-gray-900 select-none",
            labelBold ? "text-sm font-semibold" : "text-xs font-normal",
          )}>
          {label}
        </Label>
      )}
      <ErrorMessage error={fieldErrorMessage} details={error}>
        {children}
      </ErrorMessage>
    </div>
  );
};
