import { ENDPOINT, KEYCLOAK } from "@whyuz/data";
import { GraphQLClient } from "graphql-request";
import { jwtDecode } from "jwt-decode";
import { createContext, useMemo, useState } from "react";
import { UserLicenseGQL, useKeycloak } from "..";

export interface SessionContextProps {
  sessionToken: string | undefined;
  setSessionToken: (newToken: string | undefined) => void;
  isTokenExpired: () => boolean;
  decodedSessionToken: SessionToken | undefined;
  isCollab: boolean;
}

export const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export interface SessionToken {
  exp: number;
}

export const SessionContextProvider = ({ children }: React.PropsWithChildren) => {
  const [token, setToken] = useState<string | undefined>();
  const [decodedSessionToken, setDecodedSessionToken] = useState<SessionToken | undefined>();
  const { keycloak } = useKeycloak();
  const isCollab = useMemo(() => keycloak.clientId === KEYCLOAK.COLLAB_CLIENT_ID, [keycloak.clientId]);

  const setSessionToken = (newToken: string | undefined) => {
    console.log("Session token assigned");
    setToken(newToken);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const newDecodedToken: SessionToken | undefined = newToken ? jwtDecode(newToken) : undefined;
      setDecodedSessionToken(newDecodedToken);

      // Once the token is assigned, an scheduler is started to request automatically a new session token before it expires
      if (newDecodedToken) {
        const expirationTime = newDecodedToken.exp * 1000;
        const remainingTime = expirationTime - Date.now();
        console.log(`Session token expires in ${remainingTime / 1000}s`);

        setTimeout(
          () => {
            // Request to the server the refresh of the session token
            const sessionRefreshQuery = new GraphQLClient(ENDPOINT.GRAPHQL, {
              headers: {
                Authorization: `Bearer ${keycloak.token ?? ""}`,
                "X-SESSION-TOKEN": token ?? "",
              },
            });

            sessionRefreshQuery
              .request(UserLicenseGQL.query.authenticatedUserSession, {})
              .then((result) => {
                setSessionToken(result.authenticatedUserSession?.sessionToken as string);
              })
              .catch((error) => console.error("Error refreshing session token", error));

            if (process.env.NODE_ENV === "development") {
              console.log("Session token refresh");
            }
          },
          // Refresh the session token 15 minutes before it expires
          Math.max(remainingTime - 15 * 60 * 1000, 0),
        );
      }
    } catch (error) {
      console.error("Error deconding token", newToken, error);
    }
  };

  const isTokenExpired = () => {
    try {
      if (!decodedSessionToken) {
        return true;
      }
      // Check if the expiration time is in the past
      return decodedSessionToken.exp * 1000 < Date.now();
    } catch (error) {
      // Handle invalid tokens or other decoding errors
      console.error("Error decoding or processing the token:", error);
      return true; // Consider the token expired if there's an error
    }
  };

  return (
    <SessionContext.Provider
      value={{
        sessionToken: token,
        setSessionToken,
        isTokenExpired,
        decodedSessionToken,
        isCollab,
      }}>
      {children}
    </SessionContext.Provider>
  );
};
