import { useScreenSize } from "@whyuz/hooks";
import { PropsWithChildren, createContext, useEffect, useState } from "react";

export interface SidebarContextProps {
  isOpenOnSmallScreens: boolean;
  isPageWithSidebar: boolean;
  setIsPageWithSidebar: (isPageWithSidebar: boolean) => void;
  setOpenOnSmallScreens: (isOpen: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextProps | null>(null);

export const SidebarContextProvider = ({ children }: PropsWithChildren) => {
  const { isBrowser, isMobileScreenSize } = useScreenSize();
  const location = isBrowser ? window.location.pathname : "/";
  const [isOpen, setOpen] = useState(isBrowser ? window.localStorage.getItem("isSidebarOpen") === "true" : false);
  const [isPageWithSidebar, setIsPageWithSidebar] = useState<boolean>(true);

  // Save latest state to localStorage
  useEffect(() => {
    window.localStorage.setItem("isSidebarOpen", isOpen.toString());
  }, [isOpen]);

  // Close Sidebar on page change on mobile
  useEffect(() => {
    if (isMobileScreenSize) {
      setOpen(false);
    }
  }, [isMobileScreenSize, location]);

  // Close Sidebar on mobile tap inside main content
  useEffect(() => {
    function handleMobileTapInsideMain(event: MouseEvent) {
      const main = document.querySelector("main");
      const isClickInsideMain = main?.contains(event.target as Node);

      if (isMobileScreenSize && isClickInsideMain) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleMobileTapInsideMain);
    return () => {
      document.removeEventListener("mousedown", handleMobileTapInsideMain);
    };
  }, [isMobileScreenSize]);

  return (
    <SidebarContext.Provider
      value={{
        isOpenOnSmallScreens: isOpen,
        isPageWithSidebar,
        setIsPageWithSidebar,
        setOpenOnSmallScreens: setOpen,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};
