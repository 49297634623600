import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { TbSuperscript } from "react-icons/tb";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyleHiddenOnMobile, buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonSuperscriptProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonSuperscript = ({ className, children }: TextEditorMenuButtonSuperscriptProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName =
    editor && editor.isActive("superscript") ? activeButtonStyleHiddenOnMobile : buttonStyleHiddenOnMobile;

  return (
    <Tooltip content={t("buttons.superscript")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().toggleSuperscript().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}
      >
        {children ?? <TbSuperscript className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
