import { ExclamationTriangleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Button, ButtonProps, Tooltip } from "flowbite-react";
import { DeleteConfirmationModal, useModal } from "../Modal";

export interface PageDeleteEntityButtonProps extends ButtonProps {
  text: string;
  showText?: boolean;
  size?: "sm" | "xs";
  hiddenOnMobile?: boolean;
  disabled?: boolean;
  onDeleteEntity: () => void;
}

export const PageDeleteEntityButton = ({
  text,
  showText = true,
  size = "sm",
  disabled = false,
  hiddenOnMobile = false,
  onDeleteEntity,
  ...props
}: PageDeleteEntityButtonProps) => {
  const { showModal, closeModal, modalProps } = useModal();

  return (
    <>
      <Tooltip content={text} placement="bottom" className={showText ? "hidden" : undefined}>
        <Button
          size={size}
          color="failure"
          disabled={disabled}
          className={`${hiddenOnMobile ? "hidden sm:block" : ""} px-0`}
          onPointerDown={(e: React.PointerEvent) => {
            if (!disabled && e.button !== RIGHT_BUTTON) {
              showModal();
            }
          }}
          {...props}>
          <TrashIcon className={`block ${showText ? "sm:hidden" : ""} h-4 w-4 my-0.5 stroke-2 self-center`} />
          {showText && (
            <>
              <ExclamationTriangleIcon className={`hidden sm:block h-4 w-4 stroke-2 self-center`} />
              <span className="hidden sm:block ml-2">{text}</span>
            </>
          )}
        </Button>
      </Tooltip>
      <DeleteConfirmationModal
        onAcceptDeletion={() => {
          closeModal();
          onDeleteEntity();
        }}
        {...modalProps}
      />
    </>
  );
};
