import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import Color from "@tiptap/extension-color";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import FontFamily from "@tiptap/extension-font-family";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Heading } from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Text } from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { Slice } from "@tiptap/pm/model";
import { EditorView } from "@tiptap/pm/view";
import { AnyExtension, Content, Editor, useEditor } from "@tiptap/react";
import { Editor as CoreEditor } from "@tiptap/core";
import { FILE_TYPE, MB_bytes } from "@whyuz/data";
import { UserLicensePersonalInformation, useUser } from "@whyuz/services";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { getFileTypeFromMimeType } from "../../File/index.ts";
import { Div } from "../components/extensions/DivExtension.ts";
import { EmojiReplacer } from "../components/extensions/EmojiReplacer.ts";
import FileCardExtension from "../components/extensions/FileCard/FileCardExtension.ts";
import { FontSize } from "../components/extensions/FontSizeExtension.ts";
import { CustomImage, IMAGE_SIZE } from "../components/extensions/ImageExtension.ts";
import { LineHeight } from "../components/extensions/LineHeightExtension.ts";
import { CustomLink } from "../components/extensions/LinkExtension.ts";
import { CustomParagraph } from "../components/extensions/ParagraphExtension.ts";
import { PasteFile } from "../components/extensions/PasteFileExtension.ts";
import { Path } from "../components/extensions/PathExtension.ts";
import { Svg } from "../components/extensions/SvgExtension.ts";
import TrackChangeExtension from "../components/extensions/TrackChangesExtension.ts";
import { useUploadFilesInTextEditor } from "./useUploadFilesInTextEditor.tsx";
import { Transaction } from "@tiptap/pm/state";
// import { EditorEvents } from "@tiptap/core";

export interface useTextEditorProps {
  autofocus?: boolean;
  disabled?: boolean;
  content?: Content;
  placeholder?: string;
  placeHolderInEachNewLine?: boolean;
  allowDropFiles?: boolean;
  handleKeyDown?: (view: EditorView, e: KeyboardEvent) => boolean | void;
  additionalExtensions?: AnyExtension[];
  uploadPublicFiles?: boolean;
  defaultImageSize?: IMAGE_SIZE;
  imagesWithLink?: boolean;
  contentClassName?: string;
  onChange?: (editor: CoreEditor, transaction: Transaction) => void;
}

const MAX_FILESIZE_MB = 10;

export const useTextEditor = ({
  autofocus = false,
  disabled = false,
  content,
  placeholder,
  placeHolderInEachNewLine = false,
  allowDropFiles = true,
  handleKeyDown,
  additionalExtensions = [],
  uploadPublicFiles = false,
  defaultImageSize,
  imagesWithLink = false,
  contentClassName,
  onChange,
}: useTextEditorProps) => {
  const { t: tTextEditor } = useTranslation("textEditor");
  const editorRef = useRef<Editor | null>(null);
  const textEditorFileUploader = useUploadFilesInTextEditor();
  const userCtx = useUser();

  const handleFileDropped = useCallback(
    (file: File) => {
      const filesize = file.size / MB_bytes; // get the filesize in MB
      // Check the file size
      if (filesize < MAX_FILESIZE_MB) {
        const fileType = getFileTypeFromMimeType(file.type);
        if (fileType === FILE_TYPE.IMAGE) {
          textEditorFileUploader.uploadImage(
            file,
            imagesWithLink,
            editorRef.current,
            disabled,
            uploadPublicFiles,
            defaultImageSize,
          );
        } else {
          textEditorFileUploader.uploadFile(file, editorRef.current, disabled, uploadPublicFiles);
        }
      } else {
        alert(tTextEditor("errors.filesizeexceeded", { filesize: filesize.toFixed(2), maxfilesize: MAX_FILESIZE_MB }));
      }
    },
    [textEditorFileUploader, imagesWithLink, disabled, uploadPublicFiles, defaultImageSize, tTextEditor],
  );

  const handleFilesDropped = useCallback(
    (_view: EditorView, event: DragEvent, _slice: Slice, moved: boolean) => {
      if (
        allowDropFiles &&
        !disabled &&
        !moved &&
        event.dataTransfer &&
        event.dataTransfer.files &&
        event.dataTransfer.files[0]
      ) {
        const files = event.dataTransfer.files;
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            // if dropping external files
            const file = files[i]; // the dropped file
            handleFileDropped(file);
          }
          return true; // handled by our application
        }
      }
      return false; // not handled use default behaviour (usually open the file in a new tab)
    },
    [allowDropFiles, disabled, handleFileDropped],
  );

  editorRef.current = useEditor({
    editable: !disabled,
    extensions: [
      Blockquote,
      Bold.configure({
        HTMLAttributes: {
          class: "font-bold",
          style: "font-weight: 700",
        },
      }),
      BulletList,
      Code,
      CodeBlock,
      // Find more extensions here: https://tiptap.dev/extensions
      Color, // https://tiptap.dev/api/extensions/color
      CustomImage.configure({
        inline: true,
      }), // https://tiptap.dev/api/nodes/image
      CustomLink, // https://tiptap.dev/api/marks/link
      CustomParagraph,
      Div,
      Document,
      Dropcursor,
      EmojiReplacer,
      FileCardExtension,
      FontFamily, // https://tiptap.dev/api/extensions/font-family
      FontSize,
      Gapcursor,
      HardBreak,
      Heading,
      Highlight.configure({
        // https://tiptap.dev/api/marks/highlight
        multicolor: true, // Default false
      }),
      History,
      HorizontalRule,
      Italic,
      LineHeight,
      ListItem,
      OrderedList,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        emptyNodeClass: placeHolderInEachNewLine ? "is-empty" : "",
        placeholder,
      }),
      PasteFile.configure({
        onFilePasted: handleFileDropped,
      }),
      Path,
      Strike,
      Subscript,
      Superscript,
      Svg,
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
      Text,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }), // https://tiptap.dev/api/extensions/text-align
      TextStyle,
      TrackChangeExtension.configure({
        enabled: false,
        dataOpUserId: (userCtx.userLicense?.id as string) ?? "UNKNOWN",
        dataOpUserNickname: userCtx.userLicense?.personalInfo
          ? getUserFullName(userCtx.userLicense.personalInfo)
          : "UNKNOWN",
        // onStatusChange(status: boolean) {
        //   // myTrackChangeEnabled = status;
        // },
      }),
      Typography, // https://tiptap.dev/api/extensions/typography
      Underline, // https://tiptap.dev/api/marks/underline
      ...additionalExtensions,
    ],
    editorProps: {
      attributes: {
        // https://tiptap.dev/guide/styling#with-tailwind-css
        // See also @tailwindcss/typography here: https://tailwindcss.com/docs/typography-plugin
        class:
          // "prose prose-sm lg:prose-base xl:prose-lg " +
          twMerge(
            "p-4 min-h-[inherit] focus-visible:outline-none max-w-none text-sm dark:text-white",
            contentClassName,
          ),
      },
      handleDrop: handleFilesDropped,
      handleKeyDown,
    },
    autofocus: !disabled && autofocus,
    injectCSS: true,
    content,
    onUpdate: ({ editor, transaction }) => {
      if (onChange && transaction.docChanged) {
        onChange(editor, transaction);
      }
    },
  });

  editorRef.current?.setEditable(!disabled);

  // WARNING: as editor is a class and not implements an interface, there is no other way to add properties without adding an strange parent structure
  if (editorRef.current) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (editorRef.current as any).uploadPublicFiles = uploadPublicFiles;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (editorRef.current as any).defaultImageSize = defaultImageSize;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (editorRef.current as any).imagesWithLink = imagesWithLink;
  }

  return editorRef.current;
};

export const getUserFullName = (user: UserLicensePersonalInformation): string | undefined => {
  const firstName = user.firstName;
  const lastName = user.lastName;
  if (!firstName && !lastName) {
    return undefined;
  } else if (firstName && lastName) {
    return firstName + " " + lastName;
  } else if (firstName) {
    return firstName;
  } else {
    return lastName as string;
  }
};
