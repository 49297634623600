import { getWidthCalculatedForNumberOfColumns } from "@whyuz/utils";
import { twMerge } from "tailwind-merge";

export interface PageFormContainerProps {
  columnsToCalculateWidth?: number;
  className?: string;
  children?: React.ReactNode;
}

export const PageFormContainer = ({ columnsToCalculateWidth, className, children }: PageFormContainerProps) => {
  return (
    <div
      className={twMerge(
        `flex items-center mx-auto px-4 pt-4 pb-8 ${
          getWidthCalculatedForNumberOfColumns(columnsToCalculateWidth, true) ?? ""
        }`,
        className,
      )}
    >
      <div className="w-full">{children}</div>
    </div>
  );
};
