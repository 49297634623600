import { useState } from "react";
import { ModalProps } from "flowbite-react";

export const useModal = (showInitially?: boolean) => {
  const [show, setShow] = useState<boolean>(showInitially ?? false);
  const showModal = () => setShow(true);
  const onClose = () => setShow(false);
  const modalProps: ModalProps = { show, onClose };
  return { showModal, closeModal: onClose, modalProps };
};
