import { Error404PageNotFound, Page } from "@whyuz/components";
import { WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import {
  GQLError,
  GQLErrorStatusCode,
  SuccessCase,
  useCollaboratorSuccessCaseLazyQuery,
  useUser,
} from "@whyuz/services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { SuccessCaseContentEditor } from ".";

export const SuccessCaseContentEditorManager = () => {
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get(WHYUZ_URL_PARAMETERS.TENANT_ID);
  const userCtx = useUser();
  const [error, setError] = useState<GQLError>();
  const [successCase, setSuccessCase] = useState<SuccessCase>();
  const [successCaseCollaborationLazyQuery, { hasEverBeenExecuted, isLoading }] = useCollaboratorSuccessCaseLazyQuery();

  useEffect(() => {
    if (id && tenantId) {
      successCaseCollaborationLazyQuery({ variables: { id, tenantId } })
        .then((successCase) =>
          successCase
            ? setSuccessCase(successCase)
            : setError({
                isUncontrolledError: true,
                statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
                message: tSuccessCase("successCaseTemplate") + " " + String(id),
                fieldErrors: {},
              }),
        )
        .catch((error: GQLError) => setError(error));
    }
  }, [successCaseCollaborationLazyQuery, id, tenantId, tSuccessCase]);

  if (!id || !tenantId) {
    return <Error404PageNotFound />;
  }

  if (hasEverBeenExecuted && (!successCase || (userCtx.isUserSuperAdmin && successCase.deleted))) {
    return <Error404PageNotFound />;
  }

  return (
    <Page className="flex flex-col" isLoading={isLoading} error={error} onErrorClose={() => setError(undefined)}>
      {successCase && (
        <SuccessCaseContentEditor
          successCase={successCase}
          tenantId={tenantId}
          onQuestionUpdated={(updatedQuestion) => {
            setSuccessCase((prev) => {
              if (prev?.contentItems) {
                const questionIdx = prev.contentItems.findIndex((ci) => ci?.id === updatedQuestion.id);
                if (questionIdx >= 0) {
                  const newContentItems = [...prev.contentItems];
                  newContentItems[questionIdx] = updatedQuestion;
                  return { ...prev, contentItems: newContentItems };
                }
              }
              return prev;
            });
          }}
        />
      )}
    </Page>
  );
};
