import { useState, useEffect, useCallback, useMemo } from "react";

export enum BreakPoint {
  xs = "xs", // < 640px
  sm = "sm", // > 640px
  md = "md", // > 768px
  lg = "lg", // > 1024px
  xl = "xl", // > 1280px
}

const getBreakpoint = (width: number) => {
  if (width < 640) {
    return BreakPoint.xs;
  } else if (width >= 640 && width < 768) {
    return BreakPoint.sm;
  } else if (width >= 768 && width < 1024) {
    return BreakPoint.md;
  } else if (width >= 1024 && width < 1280) {
    return BreakPoint.lg;
  } else {
    return BreakPoint.xl;
  }
};

export const useScreenSize = () => {
  const isBrowser = useMemo(() => typeof window !== "undefined", []);

  const getSize = useCallback(() => {
    const width = isBrowser ? window.innerWidth : 0;
    const height = isBrowser ? window.innerHeight : 0;
    const breakpoint = getBreakpoint(width);
    const isMobileScreenSize = breakpoint === BreakPoint.xs;
    return {
      width,
      height,
      breakpoint,
      isMobileScreenSize,
    };
  }, [isBrowser]);

  const [screenSize, setScreenSize] = useState(getSize);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleResize = () => {
      setScreenSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return { ...screenSize, isBrowser };
};
