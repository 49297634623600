import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteModalTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteModalThemes {
  default = "default",
  complete = "complete",
  complete_full_height = "complete_full_height",
}

const FlowbiteDefaultModalTheme = FlowbiteDefaultTheme.modal;

export const FlowbiteModalThemes: Record<EnumFlowbiteModalThemes, RecursivePartial<FlowbiteModalTheme>> = {
  default: {
    header: {
      base: twMerge(FlowbiteDefaultModalTheme.header.base, "flex items-center"),
      popup: twMerge(FlowbiteDefaultModalTheme.header.popup, "py-4 px-6"),
      title: twMerge(FlowbiteDefaultModalTheme.header.title, "w-11/12 lg:w-full mr-2 border-none p-0 "),
    },
  },
  complete: {
    header: {
      base: twMerge(FlowbiteDefaultModalTheme.header.base, "flex items-center"),
      popup: twMerge(FlowbiteDefaultModalTheme.header.popup, "py-4 px-6"),
      title: twMerge(FlowbiteDefaultModalTheme.header.title, "w-11/12 lg:w-full mr-2 border-none p-0"),
    },
    content: { inner: twMerge(FlowbiteDefaultModalTheme.content.inner, "h-full md:max-h-[90vh]") },
  },
  complete_full_height: {
    header: {
      base: twMerge(FlowbiteDefaultModalTheme.header.base, "flex items-center"),
      popup: twMerge(FlowbiteDefaultModalTheme.header.popup, "py-4 px-6"),
      title: twMerge(FlowbiteDefaultModalTheme.header.title, "w-11/12 lg:w-full mr-2 border-none p-0"),
    },
    content: { inner: twMerge(FlowbiteDefaultModalTheme.content.inner, "h-[90vh]") },
    body: {
      base: twMerge(FlowbiteDefaultModalTheme.body.base, "flex"),
    },
    footer: {
      base: twMerge(FlowbiteDefaultModalTheme.footer.base, "flex pt-0 justify-end"),
    },
  },
};
