import { twMerge } from "tailwind-merge";

export const buttonStyle =
  "flex items-center justify-center w-8 h-8 rounded-md text-gray-500 dark:text-gray-400 enabled:hover:bg-gray-200 enabled:dark:hover:bg-gray-600 enabled:dark:hover:text-white w-8 h-8 disabled:text-gray-300 disabled:dark:text-gray-500 cursor-pointer disabled:cursor-not-allowed";

export const activeButtonStyle = twMerge(buttonStyle, "bg-gray-200 dark:bg-gray-500");

export const buttonStyleHiddenOnMobile = twMerge(buttonStyle, "hidden sm:flex");

export const activeButtonStyleHiddenOnMobile = twMerge(activeButtonStyle, "hidden sm:flex");

export const iconSelectStyle = "m-0.5 h-5 w-5 text-gray-500 dark:text-gray-400 dark:hover:text-white";
