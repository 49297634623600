import { CheckIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { ComboboxItem, ComboboxItemDefaultTheme, ComboboxItemTheme } from ".";

export interface ComboboxItemProps<T> {
  item: ComboboxItem<T>;
  selected: boolean;
  theme?: ComboboxItemTheme;
}

export const ComboboxItemComponent = <T,>({ selected, item, theme }: ComboboxItemProps<T>) => {
  return (
    <div className={theme?.base ?? ComboboxItemDefaultTheme.base}>
      {item.icon && <span className={theme?.icon ?? ComboboxItemDefaultTheme.icon}>{item.icon}</span>}
      {item.name && (
        <span
          className={twMerge(
            theme?.name ?? ComboboxItemDefaultTheme.name,
            selected ? theme?.selected : theme?.notSelected,
          )}>
          {item.name}
        </span>
      )}
      {selected && <CheckIcon className={theme?.check ?? ComboboxItemDefaultTheme.check} aria-hidden="true" />}
    </div>
  );
};
