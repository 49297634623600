import { PencilIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Button, ButtonProps, Tooltip } from "flowbite-react";

export interface PageEditEntityButtonProps extends ButtonProps {
  text: string;
  showText?: boolean;
  onEditEntity: () => void;
}

export const PageEditEntityButton = ({
  text,
  showText = true,
  onEditEntity,
  disabled,
  ...props
}: PageEditEntityButtonProps) => {
  return (
    <Tooltip content={text} placement="bottom" className={showText ? "hidden" : undefined}>
      <Button
        size="sm"
        color="primary"
        className={`px-0 ${showText ? "sm:px-0.5" : ""}`}
        onPointerDown={(e: React.PointerEvent) => {
          if (!disabled && e.button !== RIGHT_BUTTON) {
            onEditEntity();
          }
        }}
        disabled={disabled}
        {...props}>
        <PencilIcon className={`h-4 w-4 self-center`} />
        {showText && <span className="hidden sm:block ml-2">{text}</span>}
      </Button>
    </Tooltip>
  );
};
