import { ErrorMessageType, GQLError } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { TextInput } from "flowbite-react";
import { ChangeEvent, useMemo } from "react";
import { getErrorMessageTextColor } from "../Error";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField.tsx";

export interface PageFormNumberInputFieldProps {
  id: string;
  label: string;
  labelBold?: boolean;
  placeholder?: string;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  error: GQLError | undefined;
  onChange: (newValue: number | undefined, event: ChangeEvent<HTMLInputElement>) => void;
}

export const PageFormNumberInputField = ({
  id,
  label,
  labelBold = true,
  placeholder,
  value,
  min,
  max,
  step,
  disabled,
  columns,
  columnsBreakpointSM = true,
  error,
  onChange,
}: PageFormNumberInputFieldProps) => {
  // GetPropertyByPath used to avoid case sensitive field name errors
  const fieldErrorMessage = useMemo(
    () => (error ? (getPropertyByPath(error.fieldErrors, id) as ErrorMessageType) : undefined),
    [error, id],
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === "" ? undefined : event.target.valueAsNumber, event);
  };

  return (
    <PageFormCustomEditableField
      id={id}
      label={label}
      labelBold={labelBold}
      error={error}
      columns={columns}
      columnsBreakpointSM={columnsBreakpointSM}>
      <TextInput
        id={id}
        type="number"
        sizing="md"
        color={getErrorMessageTextColor(fieldErrorMessage)}
        placeholder={placeholder}
        value={value ?? ""}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={(e) => handleOnChange(e)}
      />
    </PageFormCustomEditableField>
  );
};
