import { Currency } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useCurrencyTranslation = () => {
  const { i18n } = useTranslation();

  return useCallback((currency: Currency) => getPropertyByPath(currency, i18n.language) as string, [i18n.language]);
};
