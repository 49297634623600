import "./styles/Placeholder.css";
import "./styles/Table.css";
import "./styles/TrackChanges.css";
import "./styles/VariablesList.css";

import { Editor } from "@tiptap/react";
import { createContext } from "react";
import { TextEditorContainer } from "./components/TextEditorContainer";
import { TextEditorContent } from "./components/TextEditorContent";
import {
  TextEditorMenu,
  TextEditorMenuButtonBackgroundColor,
  TextEditorMenuButtonBold,
  TextEditorMenuButtonDeleteFormat,
  TextEditorMenuButtonDivider,
  TextEditorMenuButtonEmojiPicker,
  TextEditorMenuButtonFont,
  TextEditorMenuButtonFontColor,
  TextEditorMenuButtonFontSize,
  TextEditorMenuButtonHeading,
  TextEditorMenuButtonImageSize,
  TextEditorMenuButtonItalic,
  TextEditorMenuButtonLink,
  TextEditorMenuButtonOrderedList,
  TextEditorMenuButtonPanel,
  TextEditorMenuButtonQuote,
  TextEditorMenuButtonRedo,
  TextEditorMenuButtonStrike,
  TextEditorMenuButtonSubscript,
  TextEditorMenuButtonSuperscript,
  TextEditorMenuButtonTable,
  TextEditorMenuButtonTextAlign,
  TextEditorMenuButtonTrackChanges,
  TextEditorMenuButtonTrackChangesAccept,
  TextEditorMenuButtonTrackChangesAcceptAll,
  TextEditorMenuButtonTrackChangesReject,
  TextEditorMenuButtonTrackChangesRejectAll,
  TextEditorMenuButtonUnderline,
  TextEditorMenuButtonUndo,
  TextEditorMenuButtonUnorderedList,
  TextEditorMenuButtonUploadFile,
  TextEditorMenuButtonUploadImage,
  TextEditorMenuButtonVariables,
} from "./components/TextEditorMenu";
import { TextEditorMenuButtonLineHeight } from "./components/TextEditorMenu/TextEditorMenuButtonLineHeight";
import { TextEditorWithMenu } from "./components/TextEditorWithMenu";
import { IMAGE_SIZE } from "./components/extensions/ImageExtension";

export type TextEditorContextType = {
  defaultImageSize?: IMAGE_SIZE;
  imagesWithLink: boolean;
  uploadPublicFiles: boolean;
  editor: Editor | null;
  disabled: boolean;
};

export const TextEditorContext = createContext<TextEditorContextType | null>(null);

export interface TextEditorProps extends React.PropsWithChildren {
  editor: Editor | null;
  disabled?: boolean;
}

export const TextEditorComponent = ({ children, editor, disabled = false }: TextEditorProps) => {
  editor?.setEditable(!disabled);
  return (
    <TextEditorContext.Provider
      value={{
        editor: editor,
        disabled,
        // WARNING: Enriched information of Editor class done in useTextEditor, but included in the Context for general use
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        uploadPublicFiles: (editor as any)?.uploadPublicFiles ?? false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        defaultImageSize: (editor as any)?.defaultImageSize,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        imagesWithLink: (editor as any)?.imagesWithLink ?? false,
      }}
    >
      {children}
    </TextEditorContext.Provider>
  );
};

TextEditorComponent.displayName = "TextEditor";

export const TextEditor = Object.assign(TextEditorComponent, {
  ButtonBackgroundColor: TextEditorMenuButtonBackgroundColor,
  ButtonBold: TextEditorMenuButtonBold,
  ButtonDeleteFormat: TextEditorMenuButtonDeleteFormat,
  ButtonDivider: TextEditorMenuButtonDivider,
  ButtonEmojis: TextEditorMenuButtonEmojiPicker,
  ButtonFont: TextEditorMenuButtonFont,
  ButtonFontColor: TextEditorMenuButtonFontColor,
  ButtonFontSize: TextEditorMenuButtonFontSize,
  ButtonHeading: TextEditorMenuButtonHeading,
  ButtonItalic: TextEditorMenuButtonItalic,
  ButtonImageSize: TextEditorMenuButtonImageSize,
  ButtonLink: TextEditorMenuButtonLink,
  ButtonOrderedList: TextEditorMenuButtonOrderedList,
  ButtonQuote: TextEditorMenuButtonQuote,
  ButtonRedo: TextEditorMenuButtonRedo,
  ButtonStrike: TextEditorMenuButtonStrike,
  ButtonSubscript: TextEditorMenuButtonSubscript,
  ButtonSuperscript: TextEditorMenuButtonSuperscript,
  ButtonTable: TextEditorMenuButtonTable,
  ButtonTrackChanges: TextEditorMenuButtonTrackChanges,
  ButtonTrackChangesAccept: TextEditorMenuButtonTrackChangesAccept,
  ButtonTrackChangesAcceptAll: TextEditorMenuButtonTrackChangesAcceptAll,
  ButtonTrackChangesReject: TextEditorMenuButtonTrackChangesReject,
  ButtonTrackChangesRejectAll: TextEditorMenuButtonTrackChangesRejectAll,
  ButtonTextAlign: TextEditorMenuButtonTextAlign,
  ButtonLineHeight: TextEditorMenuButtonLineHeight,
  ButtonUnderline: TextEditorMenuButtonUnderline,
  ButtonUndo: TextEditorMenuButtonUndo,
  ButtonUnorderedList: TextEditorMenuButtonUnorderedList,
  ButtonUploadFile: TextEditorMenuButtonUploadFile,
  ButtonUploadImage: TextEditorMenuButtonUploadImage,
  ButtonVariables: TextEditorMenuButtonVariables,
  Container: TextEditorContainer,
  ContentEditor: TextEditorContent,
  ContentEditorWithMenu: TextEditorWithMenu,
  Menu: TextEditorMenu,
  MenuButtonPanel: TextEditorMenuButtonPanel,
});
