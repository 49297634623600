import { SuccessCaseContentEditorQuestionNavigationButtons } from "./SuccessCaseContentEditorQuestionNavigationButtons";

export interface SuccessCaseContentEditorQuestionsEditorProps extends React.PropsWithChildren {
  enablePreviousQuestion: boolean;
  onPreviousQuestion: () => void;
  enableNextQuestion: boolean;
  onNextQuestion: () => void;
}

export const SuccessCaseContentEditorQuestionsEditor = ({
  children,
  ...props
}: SuccessCaseContentEditorQuestionsEditorProps) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex-1 flex flex-col items-center justify-center overflow-hidden overflow-y-auto space-y-48">
        {children}
      </div>
      <SuccessCaseContentEditorQuestionNavigationButtons {...props} />
    </div>
  );
};
