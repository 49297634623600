import { graphql } from "../../codegen/gql";

const collaboratorSuccessCase = graphql(`
  query collaboratorSuccessCase($id: UUID!, $tenantId: UUID!) {
    collaboratorSuccessCase(id: $id, tenantId: $tenantId) {
      id
      title
      status
      customerDescription
      customerApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
      customerContentApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
      mainContact {
        id
        mail
      }
      customerReward {
        id
        name
        type
        additionalInfo
      }
      mainContactReward {
        id
        name
        type
        additionalInfo
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          createdOn
          updatedOn
          createdBy
          updatedBy
          deleted
          contentType
          originalFilename
          originalExtension
          fileSizeBytes
          accessToken
          url
          isPublicReadAllowed
        }
      }
      chat {
        id
        name
        chatMembers {
          id
          mail
          pictureURL
          firstName
          lastName
        }
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
      }
    }
  }
`);

const launchCustomerSuccessCaseAppFlow = graphql(`
  mutation launchCustomerSuccessCaseAppFlow($id: UUID!, $tenantId: UUID!) {
    launchCustomerSuccessCaseAppFlow(id: $id, tenantId: $tenantId) {
      id
      title
      status
      customerDescription
      customerApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          createdOn
          updatedOn
          createdBy
          updatedBy
          deleted
          contentType
          originalFilename
          originalExtension
          fileSizeBytes
          accessToken
          url
          isPublicReadAllowed
        }
      }
      chat {
        id
        name
        chatMembers {
          id
          mail
          pictureURL
          firstName
          lastName
        }
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
      }
    }
  }
`);

const collaboratorSuccessCaseAskForReview = graphql(`
  mutation collaboratorSuccessCaseAskForReview($id: UUID!, $tenantId: UUID!) {
    collaboratorSuccessCaseAskForReview(id: $id, tenantId: $tenantId) {
      id
      status
    }
  }
`);

const collaboratorNeedsContentApprovalSuccessCase = graphql(`
  mutation collaboratorNeedsContentApprovalSuccessCase($id: UUID!, $tenantId: UUID!) {
    collaboratorNeedsContentApprovalSuccessCase(id: $id, tenantId: $tenantId) {
      id
      status
      customerContentApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
    }
  }
`);

const collaboratorFinishTheSuccessCaseWithoutApproval = graphql(`
  mutation collaboratorFinishTheSuccessCaseWithoutApproval($id: UUID!, $tenantId: UUID!) {
    collaboratorFinishTheSuccessCaseWithoutApproval(id: $id, tenantId: $tenantId) {
      id
      status
      customerContentApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
    }
  }
`);
export const CollaboratorSuccessCaseGQL = {
  query: { collaboratorSuccessCase },
  mutation: {
    launchCustomerSuccessCaseAppFlow,
    collaboratorSuccessCaseAskForReview,
    collaboratorNeedsContentApprovalSuccessCase,
    collaboratorFinishTheSuccessCaseWithoutApproval,
  },
};
