import { useTranslation } from "react-i18next";
import { Pagination } from "@whyuz/components";

export interface PaginationInformationProps {
  pagination: Pagination;
}

export const PaginationInformation = ({ pagination }: PaginationInformationProps) => {
  const { t } = useTranslation();

  return (
    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 select-none">
      {t("pagination.showing") + " "}
      {pagination.totalElements > 0 ? (
        <>
          <span className="font-semibold text-gray-900 dark:text-white">
            {pagination.number * pagination.size + 1}-
            {pagination.number * pagination.size + pagination.numberOfElements}
          </span>
          {" " + t("pagination.showingof") + " "}
          <span className="font-semibold text-gray-900 dark:text-white">{pagination.totalElements}</span>
        </>
      ) : (
        <>
          <span className="font-semibold text-gray-900 dark:text-white">0 </span>
          {t("word.results").toLocaleLowerCase()}
        </>
      )}
    </span>
  );
};
