import { useTranslation } from "react-i18next";
import { GenericError } from "./GenericError.tsx";

export interface Error401AccessDeniedProps {
  onClose?: () => void;
}

export const Error401AccessDenied = ({ onClose }: Error401AccessDeniedProps) => {
  const { t } = useTranslation("common");

  return (
    <GenericError
      errorNumber={401}
      title={t("errors.httperror401")}
      description={t("errors.httperror401detail")}
      onClose={onClose}
    />
  );
};
