import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTabsTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteTabsThemes {
  default = "default",
}

const FlowbiteDefaultTabsTheme = FlowbiteDefaultTheme.tabs;

export const FlowbiteTabsThemes: Record<EnumFlowbiteTabsThemes, RecursivePartial<FlowbiteTabsTheme>> = {
  default: {
    tablist: {
      tabitem: {
        base: "flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
        styles: {
          default: {
            active: {
              on: twMerge(
                FlowbiteDefaultTabsTheme.tablist.tabitem.styles.default.active.on,
                "text-primary-600 dark:text-primary-500",
              ),
            },
          },
          underline: {
            active: {
              on: twMerge(
                FlowbiteDefaultTabsTheme.tablist.tabitem.styles.underline.active.on,
                "text-primary-600 border-primary-600 dark:text-primary-500 dark:border-primary-500",
              ),
            },
          },
        },
      },
    },
  },
};
