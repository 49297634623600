import { getLocaleDateTimeString } from "@whyuz/utils";
import { useTranslation } from "react-i18next";
import { PageFormTextInfoField } from "./PageFormTextInfoField.tsx";

export interface PageFormTextInfoFieldProps {
  id: string;
  label: string;
  dateStringGMT00?: string;
  columns?: number;
  columnsBreakpointSM?: boolean;
  placeholder?: string;
  className?: string;
}

export const PageFormDateTimeInfoField = ({
  id,
  label,
  dateStringGMT00,
  columns,
  placeholder,
  columnsBreakpointSM = true,
  className,
}: PageFormTextInfoFieldProps) => {
  const { i18n } = useTranslation();

  return (
    <PageFormTextInfoField
      id={id}
      label={label}
      text={
        dateStringGMT00 ? getLocaleDateTimeString(i18n.language, new Date(dateStringGMT00)) : placeholder || undefined
      }
      columns={columns}
      columnsBreakpointSM={columnsBreakpointSM}
      className={className}
    />
  );
};
