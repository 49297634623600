import "../styles/tailwind.css";

import { RIGHT_BUTTON } from "@whyuz/data";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import React, { PointerEvent, forwardRef } from "react";
import { ErrorMessage } from "..";

export interface CheckboxProps {
  id?: string;
  name: string;
  label?: string;
  labelPosition?: "top" | "right";
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  error?: { message?: ErrorMessageType; details?: GQLError };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPointerDown?: (e: PointerEvent) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      label,
      labelPosition = "right",
      checked,
      defaultChecked,
      disabled,
      error,
      onChange,
      onPointerDown,
    }: CheckboxProps,
    ref,
  ) => {
    return (
      <ErrorMessage error={error?.message} details={error?.details}>
        <div>
          {label && labelPosition === "top" && (
            <label htmlFor={id} className={`block text-sm font-medium text-gray-800 pt-2 pb-2 select-none truncate`}>
              {label}
            </label>
          )}
          <div className={`relative flex flex-wrap items-stretch ${labelPosition === "top" ? "pt-0.5 pb-1" : ""}`}>
            <div className="flex items-center">
              <input
                id={id}
                name={name}
                ref={ref}
                type="checkbox"
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onPointerDown={(e: PointerEvent) => {
                  if (onPointerDown && e.button !== RIGHT_BUTTON) {
                    onPointerDown(e);
                  }
                }}
                className={`h-4 w-4 accent-white border-gray-500 rounded ${
                  disabled ? "" : "cursor-pointer"
                } outline-none focus-visible:ring-primary-500 focus-visible:ring-2 focus-visible:ring-offset-2`}
              />
              {label && labelPosition === "right" && (
                <label htmlFor={id} className="ml-2 block text-sm text-gray-900 cursor-pointer">
                  {label}
                </label>
              )}
            </div>
          </div>
        </div>
      </ErrorMessage>
    );
  },
);
