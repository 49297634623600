import { RIGHT_BUTTON } from "@whyuz/data";
import { ErrorMessageType, GQLError, useErrorTranslation } from "@whyuz/services";
import { HelperText } from "flowbite-react";
import React, { PointerEvent, useState } from "react";
import { ErrorDetails } from "./ErrorDetails.tsx";
import { getErrorMessageTextColor } from "./index.ts";

export interface ErrorMessageProps {
  error?: ErrorMessageType;
  details?: GQLError;
  showIcon?: boolean;
  children: React.ReactNode;
}

export const ErrorMessage = ({ error, details, children }: ErrorMessageProps) => {
  const { getMessageFromErrorCode } = useErrorTranslation();
  const [isDetailVisible, setDetailVisible] = useState<boolean>(false);

  return error ? (
    <div className="space-y-3 h-full">
      {children}
      {error && (
        <>
          <HelperText
            className={details ? "cursor-pointer" : ""}
            color={getErrorMessageTextColor(error)}
            onPointerDown={(e: PointerEvent) => {
              if (details && e.button !== RIGHT_BUTTON) {
                setDetailVisible(() => true);
              }
            }}>
            {getMessageFromErrorCode(error)}
          </HelperText>
          <ErrorDetails error={details} show={isDetailVisible} onClose={() => setDetailVisible(() => false)} />
        </>
      )}
    </div>
  ) : (
    children
  );
};
