import { SelectMenu, SelectMenuItem } from "@whyuz/components";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BsImage } from "react-icons/bs";
import { FaPhotoFilm } from "react-icons/fa6";
import { MdOutlinePhotoSizeSelectLarge } from "react-icons/md";
import { TbArrowAutofitWidth } from "react-icons/tb";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { IMAGE_SIZE, IMAGE_SIZE_PROPERTIES } from "../extensions/ImageExtension";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonImageSizeProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonImageSize = ({
  hideOnMobile = true,
  children,
}: TextEditorMenuButtonImageSizeProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  if (!(editor && editor.isActive("image"))) {
    return null;
  }

  const handleImageSizeSelected = (item: SelectMenuItem<unknown>) => {
    if (editor) {
      const imageSize = item.id as IMAGE_SIZE;
      const imageSizeProperties = IMAGE_SIZE_PROPERTIES[imageSize];
      editor.chain().focus().updateAttributes("image", imageSizeProperties).run();
    }
  };

  const alignMenuItems = [
    {
      id: IMAGE_SIZE.ORIGINAL,
      icon: <FaPhotoFilm className="h-6 w-6" />,
    },
    {
      id: IMAGE_SIZE.SMALL,
      icon: <BsImage className="h-3 w-3 m-1.5" />,
    },
    {
      id: IMAGE_SIZE.MEDIUM,
      icon: <BsImage className="h-4 w-4 m-1" />,
    },
    {
      id: IMAGE_SIZE.LARGE,
      icon: <BsImage className="h-5 w-5 m-0.5" />,
    },
    {
      id: IMAGE_SIZE.EXTRA_LARGE,
      icon: <BsImage className="h-6 w-6" />,
    },
    {
      id: IMAGE_SIZE.FULL_WIDTH,
      icon: <TbArrowAutofitWidth className="h-6 w-6" />,
    },
  ];

  return (
    <Tooltip content={t("buttons.imagesize")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={alignMenuItems}
          itemNotSelected={
            children ?? <MdOutlinePhotoSizeSelectLarge className={twMerge(iconSelectStyle, "h-6 w-6 -mt-0.5")} />
          }
          onChange={handleImageSizeSelected}
          textPadding="my-1 py-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          textMargin=""
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={true}
          showChevron={false}
          disabled={disabled || !(editor && editor.isActive("image"))}
        />
      </div>
    </Tooltip>
  );
};
