import { Country, useCountriesLazyQuery, useKeycloak } from "@whyuz/services";
import { getPropertyByPath, notifyError } from "@whyuz/utils";
import { createContext, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCountryTranslation } from "..";

export type CountriesContextType = {
  countries: Country[];
  searchCountries: (searchText: string | undefined | null) => Country[];
  countryTranslation: (country: Country) => string;
};

export const CountriesContext = createContext<CountriesContextType | null>(null);

export const CountriesContextProvider = ({ children }: React.PropsWithChildren) => {
  const [countriesQuery] = useCountriesLazyQuery();
  const countriesRef = useRef<Country[]>([]);
  const { i18n } = useTranslation();
  const countryTranslation = useCountryTranslation();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (countriesRef.current.length === 0 && keycloak.authenticated) {
      countriesQuery() // Query execution to get all the countries in memory
        .then((page) => {
          if (page.content) {
            countriesRef.current = page.content as Country[];
          }
        })
        .catch((e) => {
          notifyError(String(e));
        });
    }
  }, [countriesQuery, keycloak.authenticated]);

  const searchCountries = useCallback(
    (searchText: string | undefined | null) => {
      const countryFieldToFilter = i18n.language === "en" ? "name" : i18n.language;

      if (!searchText) {
        return countriesRef.current;
      }

      return countriesRef.current
        .filter((country) => {
          const translatedCountryName = getPropertyByPath(country, countryFieldToFilter) as string;
          const lowerCaseSearchText = searchText.toLowerCase();
          return (
            country.id.toLowerCase().includes(lowerCaseSearchText) ||
            (translatedCountryName && translatedCountryName.toLowerCase().includes(lowerCaseSearchText)) ||
            (country.isoCode3 && country.isoCode3.toLowerCase().includes(lowerCaseSearchText))
          );
        })
        .sort((lang1, lang2) => {
          const translatedCountryName1 = getPropertyByPath(lang1, countryFieldToFilter) as string;
          const translatedCountryName2 = getPropertyByPath(lang2, countryFieldToFilter) as string;
          if (!translatedCountryName1) {
            return -1;
          }
          if (!translatedCountryName2) {
            return 1;
          }
          return translatedCountryName1.localeCompare(translatedCountryName2);
        });
    },
    [i18n.language],
  );

  return (
    <CountriesContext.Provider value={{ countries: countriesRef.current, searchCountries, countryTranslation }}>
      {children}
    </CountriesContext.Provider>
  );
};
