import { graphql } from "../../codegen/gql";

const addAssetToSuccessCaseContentItem = graphql(`
  mutation collaboratorAddAssetToSuccessCaseContentItem($tenantId: UUID!, $contentItemId: UUID!, $assetId: UUID!) {
    collaboratorAddAssetToSuccessCaseContentItem(
      tenantId: $tenantId
      contentItemId: $contentItemId
      assetId: $assetId
    ) {
      id
      title
      htmlInstructions
      contentType
      contentMaxSizeUnitOfMeasure
      deleted
      position
      contentMaxSize
      htmlTextContent
      assets {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        contentType
        originalFilename
        originalExtension
        fileSizeBytes
        accessToken
        url
        isPublicReadAllowed
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const removeAssetFromSuccessCaseContentItem = graphql(`
  mutation collaboratorRemoveAssetFromSuccessCaseContentItem(
    $tenantId: UUID!
    $contentItemId: UUID!
    $assetId: UUID!
    $deleteAsset: Boolean!
  ) {
    collaboratorRemoveAssetFromSuccessCaseContentItem(
      tenantId: $tenantId
      contentItemId: $contentItemId
      assetId: $assetId
      deleteAsset: $deleteAsset
    ) {
      id
      title
      htmlInstructions
      contentType
      contentMaxSizeUnitOfMeasure
      deleted
      position
      contentMaxSize
      htmlTextContent
      assets {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        contentType
        originalFilename
        originalExtension
        fileSizeBytes
        accessToken
        url
        isPublicReadAllowed
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const updateContentOfSuccessCaseContentItem = graphql(`
  mutation collaboratorUpdateContentOfSuccessCaseContentItem(
    $tenantId: UUID!
    $contentItemId: UUID!
    $content: String!
  ) {
    collaboratorUpdateContentOfSuccessCaseContentItem(
      tenantId: $tenantId
      contentItemId: $contentItemId
      content: $content
    ) {
      id
      title
      htmlInstructions
      contentType
      contentMaxSizeUnitOfMeasure
      deleted
      position
      contentMaxSize
      htmlTextContent
      assets {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        contentType
        originalFilename
        originalExtension
        fileSizeBytes
        accessToken
        url
        isPublicReadAllowed
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

export const CollaboratorSuccessCaseContentItemGQL = {
  query: {},
  mutation: {
    addAssetToSuccessCaseContentItem,
    removeAssetFromSuccessCaseContentItem,
    updateContentOfSuccessCaseContentItem,
  },
};
