import { Tooltip } from "flowbite-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MdLink } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyleHiddenOnMobile, buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonLinkProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonLink = ({ className, children }: TextEditorMenuButtonLinkProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName =
    editor && editor.isActive("link") ? activeButtonStyleHiddenOnMobile : buttonStyleHiddenOnMobile;

  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const previousUrl = editor.getAttributes("link").href;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const url = window.prompt(t("messages.enterURL"), previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url.trim() === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor, t]);

  return (
    <Tooltip content={t("buttons.link")}>
      <button type="button" onClick={setLink} className={twMerge(buttonClassName, className)} disabled={disabled}>
        {children ?? <MdLink className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
