import { useCallback, useState } from "react";

import { InfiniteScrollPaginationCallbackProps, Pagination } from "@whyuz/components";
import {
  Chat,
  ChatMember,
  ChatMessage,
  FilterOperator,
  SortDirection,
  useChatMessagesLazyQuery,
  useHeaders,
} from "@whyuz/services";
import { EntityDictionary } from "@whyuz/types";
import { notifyError } from "@whyuz/utils";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ChatHeader } from "./components/ChatHeader.tsx";
import { ChatMessagesContainer } from "./components/ChatMessagesContainer.tsx";
import { ChatSendMessageBar } from "./components/index.ts";
import { useStompForChat } from "./hooks/useStompForChat.tsx";

interface ChatComponentProps {
  tenantId?: string;
  chat: Chat;
  className?: string;
  disabled?: boolean;
}

export const ChatComponent = ({ tenantId, chat, className, disabled }: ChatComponentProps) => {
  const { t: tChat } = useTranslation("chat");
  const [messages, setMessages] = useState<EntityDictionary<ChatMessage>>({});
  const [currentMembers, setCurrentMembers] = useState<EntityDictionary<ChatMember>>(
    chat.chatMembers
      ? chat.chatMembers.reduce((prev, next) => {
          prev[next?.id as string] = { ...next } as ChatMember;
          return prev;
        }, {} as EntityDictionary<ChatMember>)
      : {},
  );
  const [chatMessagesLazyQuery] = useChatMessagesLazyQuery();
  const headers = useHeaders();

  const stompClient = useStompForChat({
    chatId: chat.id as string,
    onNewMessage: (newMessage: ChatMessage) => {
      setMessages((prevMessages) => ({ ...prevMessages, [newMessage.id]: { ...newMessage } }));
    },
    onUpdatedMessage: (updatedMessage: ChatMessage) => {
      setMessages((prevMessages) => ({ ...prevMessages, [updatedMessage.id]: { ...updatedMessage } }));
    },
    onDeletedMessage: (deletedMessage: ChatMessage) => {
      setMessages((prevMessages) => ({ ...prevMessages, [deletedMessage.id]: { ...deletedMessage } }));
    },
    onNewMember: (newMember: ChatMember) => {
      setCurrentMembers((prevState) => ({ ...prevState, [newMember.id]: { ...newMember } }));
    },
    onDeletedMember: (deletedMember: ChatMember) => {
      setCurrentMembers((prevState) => {
        const aux = { ...prevState };
        delete aux[deletedMember.id as string];
        return aux;
      });
    },
  });

  const loadChatMessagesPage = useCallback(
    ({ pageNumber, pageSize, setPagination }: InfiniteScrollPaginationCallbackProps) => {
      chatMessagesLazyQuery({
        variables: {
          tenantId,
          filterExpression: {
            fields: [{ field: "chat.id", operator: FilterOperator.Equal, value: chat.id as string }],
          },
          page: pageNumber,
          pageSize: pageSize,
          sortField: "createdOn",
          sortDirection: SortDirection.Desc,
        },
      })
        .then((messagesPage) => {
          setPagination(messagesPage as Pagination);
          setMessages((existingMsg) => {
            if (messagesPage.content) {
              return messagesPage.content.reduce(
                (prev, next) => {
                  prev[next?.id as string] = { ...next } as ChatMessage;
                  return prev;
                },
                { ...existingMsg },
              );
            } else {
              return existingMsg ?? {};
            }
          });
        })
        .catch((error) => {
          console.log(error);
          notifyError(tChat("errorretrievingchat"));
        });
    },
    [chat.id, chatMessagesLazyQuery, tChat, tenantId],
  );

  const sendMessage = useCallback(
    (messageHTML: string) => {
      if (stompClient && stompClient.connected) {
        const message = {
          textHtml: messageHTML,
          chatId: chat.id as string,
          tenantId,
        };
        try {
          stompClient.publish({ destination: "/app/chat/sendMessage", headers, body: JSON.stringify(message) });
        } catch (error) {
          console.error("Error sending message: ", error);
        }
      } else {
        notifyError(tChat("errorconnectingtoserver"));
      }
    },
    [chat.id, stompClient, headers, tChat, tenantId],
  );

  const updateMessage = useCallback(
    (messageId: string, textHtml: string) => {
      if (stompClient && stompClient.connected) {
        const updateMessage = {
          id: messageId,
          chatId: chat.id as string,
          tenantId,
          textHtml,
        };
        try {
          stompClient.publish({
            destination: "/app/chat/updateMessage",
            headers,
            body: JSON.stringify(updateMessage),
          });
        } catch (error) {
          console.error("Error updating message: ", error);
        }
      } else {
        notifyError(tChat("errorconnectingtoserver"));
      }
    },
    [stompClient, chat.id, headers, tChat, tenantId],
  );

  const deleteMessage = useCallback(
    (messageId: string) => {
      if (stompClient && stompClient.connected) {
        const deleteMessage = {
          id: messageId,
          chatId: chat.id as string,
          tenantId,
        };
        try {
          stompClient.publish({
            destination: "/app/chat/deleteMessage",
            headers,
            body: JSON.stringify(deleteMessage),
          });
        } catch (error) {
          console.error("Error deleting message: ", error);
        }
      } else {
        notifyError(tChat("errorconnectingtoserver"));
      }
    },
    [stompClient, chat.id, headers, tChat, tenantId],
  );

  const addMember = useCallback(
    (userId: string, mail: string) => {
      if (stompClient && stompClient.connected) {
        try {
          const member = {
            mail: mail === "" ? null : mail,
            userId: userId === "" ? null : userId,
            chatId: chat.id as string,
            tenantId,
          };
          stompClient.publish({ destination: "/app/chat/addMember", headers, body: JSON.stringify(member) });
        } catch (error) {
          console.error("Error adding member: ", error);
        }
      } else {
        notifyError(tChat("errorconnectingtoserver"));
      }
    },
    [stompClient, chat.id, headers, tChat, tenantId],
  );

  const deleteMember = useCallback(
    (memberId: string) => {
      if (stompClient && stompClient.connected) {
        const member = {
          id: memberId,
          chatId: chat.id as string,
          tenantId,
        };
        try {
          stompClient.publish({ destination: "/app/chat/deleteMember", headers, body: JSON.stringify(member) });
        } catch (error) {
          console.error("Error deleting member: ", error);
        }
      } else {
        notifyError(tChat("errorconnectingtoserver"));
      }
    },
    [stompClient, chat.id, headers, tChat, tenantId],
  );

  return (
    <div
      className={twMerge(
        "flex flex-col bg-white dark:bg-gray-800 overflow-hidden max-h-[calc(100vh-72px)]",
        className,
      )}>
      <ChatHeader
        chat={chat}
        chatMembers={currentMembers}
        addMember={addMember}
        deleteMember={deleteMember}
        disabled={disabled}
      />
      <ChatMessagesContainer
        disabled={disabled}
        messages={messages}
        onUpdateMessage={updateMessage}
        onDeleteMessage={deleteMessage}
        onScrollReachEnd={loadChatMessagesPage}
      />
      <ChatSendMessageBar onSendMessage={sendMessage} disabled={disabled} />
    </div>
  );
};
