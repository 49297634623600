import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { ContentType } from "@whyuz/services";
import { useMemo } from "react";

export interface SuccessCaseContentEditorSidebarQuestionCardProps {
  type: ContentType;
  title: string;
  isSelected: boolean;
  onSelected: () => void;
}

export const SuccessCaseContentEditorSidebarQuestionCard = ({
  type,
  title,
  isSelected,
  onSelected,
}: SuccessCaseContentEditorSidebarQuestionCardProps) => {
  const icon = useMemo(() => {
    switch (type) {
      case ContentType.Image:
        return <PhotoIcon className="h-8 w-8 text-primary-700 dark:text-primary-700 stroke-2" />;
      case ContentType.Text:
        return <DocumentTextIcon className="h-8 w-8 text-primary-700 dark:text-primary-700 stroke-2" />;
      case ContentType.Video:
        return <VideoCameraIcon className="h-8 w-8 text-primary-700 dark:text-primary-700 stroke-2" />;
      case ContentType.Files:
        return <CloudArrowUpIcon className="h-8 w-8 text-primary-700 dark:text-primary-700 stroke-2" />;
      case ContentType.Free:
      default:
        return <PencilSquareIcon className="h-8 w-8 text-primary-700 dark:text-primary-700 stroke-2" />;
    }
  }, [type]);

  return (
    <div
      className={`flex flex-row p-4 w-80 h-20 max-h-20 space-x-2 items-center ${isSelected ? "bg-gray-100 dark:bg-gray-700" : "cursor-pointer"}`}
      onPointerDown={(e) => {
        if (!isSelected && e.button !== RIGHT_BUTTON) {
          onSelected();
        }
      }}>
      <div className="flex-none">{icon}</div>
      <span
        className={`text-sm text-gray-500 dark:text-gray-300 overflow-hidden text-wrap text-ellipsis max-h-10 select-none ${isSelected ? "font-bold" : "font-medium"}`}>
        {title}
      </span>
    </div>
  );
};
