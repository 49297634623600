import { XMarkIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { MARK_DELETION, MARK_INSERTION } from "../extensions";
import { buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTrackChangesRejectProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonTrackChangesReject = ({
  className,
  children,
}: TextEditorMenuButtonTrackChangesRejectProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  if (!editor || (!editor.isActive(MARK_DELETION) && !editor.isActive(MARK_INSERTION))) {
    return null;
  }

  return (
    <Tooltip content={t("buttons.rejectchange")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().rejectChange().focus().run();
          }
        }}
        className={twMerge(buttonStyle, className)}
        disabled={disabled}
      >
        {children ?? <XMarkIcon className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
