import {
  FILE_EXTENSION,
  FILE_EXTENSION_MIME_TYPE,
  FILE_TYPE,
  FILE_TYPE_BY_EXTENSION,
  FILE_TYPE_BY_MIME_TYPE,
} from "@whyuz/data";
import { CSSProperties } from "react";

export const getFileTypeFromMimeType = (fileMimeType: string) => {
  const fileMimeTypeLowerCase = fileMimeType.toLowerCase();
  const fileType = FILE_TYPE_BY_MIME_TYPE[fileMimeTypeLowerCase];
  if (!fileType) {
    const mimeProperties = fileMimeTypeLowerCase.split("/");
    if (mimeProperties && mimeProperties.length > 0) {
      const internalType = mimeProperties[0];
      if (internalType !== null) {
        switch (internalType) {
          case "video":
            return FILE_TYPE.VIDEO;
          case "image":
            return FILE_TYPE.IMAGE;
          default:
            return undefined;
        }
      }
    }
  }
  return fileType;
};

export const getFileExtensionFromMimeType = (mimeType?: string): string | undefined => {
  for (const [extension, mime] of Object.entries(FILE_EXTENSION_MIME_TYPE)) {
    // Check if the MIME type matches the input
    if (mime === mimeType) {
      return extension; // Return the corresponding extension
    }
  }
  return undefined; // Return undefined if no matching extension is found
};

export const getFileTypeFromExtension = (fileExtension?: string) => {
  return FILE_TYPE_BY_EXTENSION[(fileExtension ?? "").toLowerCase() as FILE_EXTENSION];
};

export const getFileIconColorClassNameFromExtension = (fileExtension?: string): string | undefined => {
  const fileType = getFileTypeFromExtension(fileExtension);
  switch (fileType) {
    case FILE_TYPE.WORD:
      return "text-primary-600 dark:text-primary-600 dark:bg-gray-200";
    case FILE_TYPE.PDF:
      return "text-red-700 dark:text-red-700 dark:bg-gray-200";
    case FILE_TYPE.EXCEL:
      return "text-green-500 dark:text-green-500 dark:bg-gray-200";
    case FILE_TYPE.IMAGE:
      return "text-primary-700 dark:text-primary-700 dark:bg-gray-200";
    default:
      return undefined;
  }
};

export const getFileIconColorStyleFromExtension = (fileExtension?: string): CSSProperties | undefined => {
  const fileType = getFileTypeFromExtension(fileExtension);
  switch (fileType) {
    case FILE_TYPE.WORD:
      return { color: "rgb(0,152,203)" };
    case FILE_TYPE.PDF:
      return { color: "rgb(185,28,28)" };
    case FILE_TYPE.EXCEL:
      return { color: "rgb(34,197,94)" };
    default:
      return { color: "rgb(0,148,194)" };
    // default:
    //   return undefined;
  }
};
