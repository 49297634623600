import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteBadgeTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteBadgeThemes {
  default = "default",
}

const FlowbiteDefaultBadgeTheme = FlowbiteDefaultTheme.badge;

export const FlowbiteBadgeThemes: Record<EnumFlowbiteBadgeThemes, RecursivePartial<FlowbiteBadgeTheme>> = {
  default: {
    root: {
      color: {
        blue: twMerge(
          FlowbiteDefaultBadgeTheme.root.color.blue,
          "bg-primary-100 text-primary-800 group-hover:bg-primary-200 dark:bg-primary-200 dark:text-primary-900 dark:group-hover:bg-primary-300",
        ),
      },
    },
  },
};
