import "../styles/VariablesList.css";

import { SuggestionProps } from "@tiptap/suggestion";
import { KEY_NAME, RIGHT_BUTTON } from "@whyuz/data";
import { PointerEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { VariableSuggestionType } from "../types/variableSuggestion";

export type VariablesListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

type VariableListProps = Pick<SuggestionProps<VariableSuggestionType>, "items" | "command">;
export const VariablesList = forwardRef<VariablesListRef, VariableListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();
  const selectedItemRef = useRef<HTMLButtonElement>(null);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.id, name: item.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView();
    }
  }, [selectedIndex]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === KEY_NAME.UP) {
        upHandler();
        return true;
      }

      if (event.key === KEY_NAME.DOWN) {
        downHandler();
        return true;
      }

      if (event.key === KEY_NAME.ENTER) {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="rounded-lg shadow-lg py-2 relative border border-solid border-gray-200 dark:border-gray-600 dark:bg-gray-600 dark:text-white text-xs text-gray-700 cursor-pointer">
      <div className="overflow-auto max-h-[112px] px-1">
        {props.items.length ? (
          props.items.map((item, index) => (
            <button
              ref={index === selectedIndex ? selectedItemRef : null}
              className={twMerge(
                "bg-transparent block p-1 text-left w-full",
                index === selectedIndex
                  ? "text-white bg-gray-400 dark:bg-gray-800"
                  : "text-gray-800 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white",
              )}
              key={index}
              onPointerDown={(e: PointerEvent<HTMLButtonElement>) => {
                if (e.button !== RIGHT_BUTTON) {
                  selectItem(index);
                }
              }}
            >
              {item.name}
            </button>
          ))
        ) : (
          <div className="bg-transparent block px-4 text-left w-full">{t("errors.notfound")}</div>
        )}
      </div>
    </div>
  );
});
