import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@whyuz/hooks";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import { RecursivePartial, getColSpan, getPropertyByPath, isEmpty } from "@whyuz/utils";
import { Label } from "flowbite-react";
import { Fragment, useEffect, useId, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ComboboxDefaultTheme, ComboboxItem, ComboboxItemDictionary, ComboboxTheme } from ".";
import { ErrorMessage, SpinnerCard, getErrorMessageTextColor } from "..";
import { ComboboxItemComponent } from "./ComboboxItemComponent";

export interface ComboboxAutocompleteProps<T> {
  id: string;
  label?: string;
  labelBold?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  placeholder?: string;
  items: ComboboxItemDictionary<T>;
  selectedItemId?: string | number;
  showChevron?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  spellCheck?: boolean;
  autoComplete?: string;
  showBorderOnDisabled?: boolean;
  textPadding?: string;
  addTextOption?: boolean;
  error: GQLError | undefined;
  theme?: RecursivePartial<ComboboxTheme>;
  onSearchTextChange?: (searchText: string | null) => void;
  onChange?: (entity: T, selectedItem: ComboboxItem<T> | null) => void;
  onManualText?: (text: string) => void;
}

export const ComboboxAutocomplete = <T,>({
  id,
  label,
  labelBold = true,
  columns,
  columnsBreakpointSM = true,
  placeholder = "",
  items,
  selectedItemId,
  showChevron = true,
  fullWidth = false,
  disabled = false,
  isLoading = false,
  spellCheck = false,
  autoComplete = "off",
  error,
  theme,
  addTextOption,
  onSearchTextChange,
  onChange,
  onManualText,
}: ComboboxAutocompleteProps<T>) => {
  const [searchText, setSearchText] = useState<string | null>(null);
  const debouncedSearchText = useDebounce<string | null>(searchText, 300);
  const { t } = useTranslation();
  const componentId = useId();

  const fieldErrorMessage = useMemo(
    () => (error ? (getPropertyByPath(error.fieldErrors, id) as ErrorMessageType) : undefined),
    [error, id],
  );

  const color = useMemo(() => getErrorMessageTextColor(fieldErrorMessage), [fieldErrorMessage]);

  const filteredItems = useMemo(
    () =>
      onSearchTextChange || isEmpty(debouncedSearchText)
        ? Object.values(items)
        : Object.values(items).filter((item) => {
            return (item?.name ?? "")
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes((debouncedSearchText ?? "").toLowerCase().replace(/\s+/g, ""));
          }),
    [debouncedSearchText, items, onSearchTextChange],
  );

  useEffect(() => {
    if (onSearchTextChange) {
      onSearchTextChange(debouncedSearchText);
    }
  }, [debouncedSearchText, onSearchTextChange]);

  const selectedItem = useMemo(() => {
    // We do not return undefined because this component should be alwasy controlled and not uncontrolled
    const calculatedSelectedItem =
      selectedItemId !== null && selectedItemId !== undefined ? items[selectedItemId] : undefined;
    return calculatedSelectedItem ?? { id: "comoboboxemptyvalue" + componentId, name: selectedItemId };
  }, [items, selectedItemId, componentId]);

  const onChangeOrOnManualText = (value: ComboboxItem<T> | null) => {
    if (value?.id !== undefined && value?.id !== null && onChange) {
      onChange(value.entity, value);
    } else {
      if (value?.name && onManualText) {
        onManualText(value.name);
      }
    }
  };

  return (
    <div
      className={`flex flex-col space-y-2 ${getColSpan(columns, columnsBreakpointSM) ?? ""} ${fullWidth ? "w-full" : ""}`}>
      {label && (
        <Label
          htmlFor={id}
          className={twMerge("text-gray-900 select-none", labelBold ? "text-sm font-semibold" : "text-xs font-normal")}>
          {label}
        </Label>
      )}
      <Combobox value={selectedItem} onChange={onChangeOrOnManualText} disabled={disabled} nullable>
        <div className="relative">
          <ErrorMessage error={fieldErrorMessage} details={error}>
            <div className={fullWidth ? "w-full" : undefined}>
              <SpinnerCard showSpinner={isLoading}>
                <div className={twMerge(theme?.base ?? ComboboxDefaultTheme.base, getInputColor(color, theme))}>
                  <Combobox.Input
                    id={id}
                    key={"input" + componentId}
                    autoComplete={autoComplete}
                    spellCheck={spellCheck}
                    className={theme?.input?.base ?? ComboboxDefaultTheme.input?.base}
                    displayValue={(item: ComboboxItem<T>) => item?.name ?? ""}
                    placeholder={placeholder}
                    onChange={(event) => setSearchText(() => event.target.value)}
                  />
                  {showChevron && !disabled ? (
                    <Combobox.Button
                      key={"button" + componentId}
                      className={theme?.button ?? ComboboxDefaultTheme.button}>
                      <ChevronDownIcon
                        className={theme?.buttonIcon ?? ComboboxDefaultTheme.buttonIcon}
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  ) : null}
                </div>
              </SpinnerCard>
            </div>
          </ErrorMessage>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchText(() => "")}>
            <Combobox.Options className={theme?.floating?.menu ?? ComboboxDefaultTheme.floating?.menu}>
              {addTextOption && searchText && (
                <Combobox.Option
                  className={({ active }) =>
                    twMerge(
                      theme?.floating?.option?.item?.freeText ?? ComboboxDefaultTheme.floating?.option?.item?.freeText,
                      active
                        ? theme?.floating?.option?.active ?? ComboboxDefaultTheme.floating?.option?.active
                        : theme?.floating?.option?.inactive ?? ComboboxDefaultTheme.floating?.option?.inactive,
                    )
                  }
                  key={searchText}
                  value={{
                    name: searchText,
                  }}>
                  {t("buttons.add")} {`"${searchText}"`}
                </Combobox.Option>
              )}
              <SpinnerCard showSpinner={isLoading}>
                {filteredItems.length === 0 ? (
                  <div
                    className={
                      theme?.floating?.option?.item?.notFound ?? ComboboxDefaultTheme.floating?.option?.item?.notFound
                    }>
                    {t("errors.notfound")}
                  </div>
                ) : (
                  filteredItems.map((item: ComboboxItem<T>) => (
                    <Combobox.Option
                      key={item.id}
                      className={({ active }) =>
                        twMerge(
                          theme?.floating?.option?.item?.base ?? ComboboxDefaultTheme.floating?.option?.item?.base,
                          active
                            ? theme?.floating?.option?.active ?? ComboboxDefaultTheme.floating?.option?.active
                            : theme?.floating?.option?.inactive ?? ComboboxDefaultTheme.floating?.option?.inactive,
                        )
                      }
                      value={item}>
                      {({ selected }) => <ComboboxItemComponent item={item} selected={selected} />}
                    </Combobox.Option>
                  ))
                )}
              </SpinnerCard>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

const getInputColor = (color: "failure" | "warning" | "info" | undefined, theme: ComboboxTheme | undefined) => {
  switch (color) {
    case "failure":
      return theme?.input?.color?.failure ?? ComboboxDefaultTheme.input?.color?.failure;
    case "info":
      return theme?.input?.color?.info ?? ComboboxDefaultTheme.input?.color?.info;
    case "warning":
      return theme?.input?.color?.warning ?? ComboboxDefaultTheme.input?.color?.warning;
    default:
      return theme?.input?.color?.primary ?? ComboboxDefaultTheme.input?.color?.primary;
  }
};
