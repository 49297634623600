import { XCircleIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Button, ButtonProps, Tooltip } from "flowbite-react";
import { DiscardChangesConfirmationModal, useModal } from "../Modal";

export interface PageDiscardChangesButtonProps extends ButtonProps {
  text: string;
  showText?: boolean;
  size?: "sm" | "xs";
  onDiscardChanges: () => void;
}

export const PageDiscardChangesButton = ({
  text,
  showText = true,
  size = "sm",
  onDiscardChanges,
  disabled,
  ...props
}: PageDiscardChangesButtonProps) => {
  const { showModal, closeModal, modalProps } = useModal();

  return (
    <>
      <Tooltip content={text} placement="auto" className={showText ? "hidden" : undefined}>
        <Button
          size={size}
          color="failure"
          theme={{ size: { sm: "text-sm px-2.5 py-1.5" } }}
          className={showText ? "px-0 sm:px-0.5" : "px-0 "}
          onPointerDown={(e: React.PointerEvent) => {
            if (!disabled && e.button !== RIGHT_BUTTON) {
              showModal();
            }
          }}
          disabled={disabled}
          {...props}>
          <XCircleIcon
            className={`block ${showText ? "sm:hidden" : ""} h-4 w-4 stroke-2 ${showText ? "sm:mr-2" : ""} self-center`}
          />
          {showText && (
            <>
              <XCircleIcon className={`hidden sm:block h-4 w-4 stroke-2 ${showText ? "sm:mr-2" : ""} self-center`} />
              <span className="hidden sm:block">{text}</span>
            </>
          )}
        </Button>
      </Tooltip>
      <DiscardChangesConfirmationModal
        onDiscardChanges={() => {
          closeModal();
          onDiscardChanges();
        }}
        {...modalProps}
      />
    </>
  );
};
