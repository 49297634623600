import React, { PropsWithChildren } from "react";

interface ErrorBoundaryProps extends PropsWithChildren {
  fallback: (error: Error, errorInfo: React.ErrorInfo) => React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  override state = {
    hasError: false,
    error: {} as Error,
    errorInfo: {} as React.ErrorInfo,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.state = { ...this.state, error, errorInfo };
    console.log(error, errorInfo);
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback(this.state.error, this.state.errorInfo);
    } else {
      return this.props.children;
    }
  }
}
