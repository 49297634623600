import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useClickOutside, useForwardRef } from "@whyuz/hooks";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import { getLocaleDateString, getLocaleDateTimeString } from "@whyuz/utils";
import React, { forwardRef, useRef, useState } from "react";
import { RgbaColor } from "react-colorful";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { DatePicker } from "../Date";
import { ErrorMessage } from "../Error";
import "../styles/tailwind.css";

export interface InputProps {
  id?: string;
  name?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  type?:
    | "email"
    | "file"
    | "multiple-files"
    | "number"
    | "password"
    | "range"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "date"
    | "datetime-local"
    | "color";
  disabled?: boolean;
  error?: { message?: ErrorMessageType; details?: GQLError };
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  pattern?: string;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  showRingWhenSelected?: boolean;
  showBorder?: boolean;
  spellCheck?: boolean;
  min?: string;
  max?: string;
  step?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (e: Date) => void;
  onColorChange?: (e: RgbaColor) => void;
  children?: React.ReactNode;
  childrenPosition?: "right" | "left" | "none"; // not applicable for type==="file"
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      value,
      defaultValue,
      type = "text",
      disabled = false,
      required = false,
      error,
      autoComplete = "off",
      autoFocus = false,
      pattern,
      label,
      placeholder,
      fullWidth = false,
      min,
      max,
      step,
      onChange,
      onDateChange,
      // onColorChange,
      children,
      childrenPosition = "none",
      showRingWhenSelected = true,
      showBorder = true,
      spellCheck = false,
    }: InputProps,
    ref,
  ) => {
    const inputRef = useForwardRef<HTMLInputElement>(ref);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    // const [selectedColor, setSelectedColor] = useState<RgbaColor>({ r: 0, g: 0, b: 0, a: 0 });
    const componentDivRef = useRef<HTMLDivElement>(null);
    const { i18n } = useTranslation();

    const handleOnClickOutside = () => {
      switch (type) {
        case "color":
          setShowColorPicker(false);
          break;
        case "date":
          setShowDatePicker(false);
          break;
      }
    };
    useClickOutside(componentDivRef, handleOnClickOutside);

    const handleOnPointerDown = (e: React.MouseEvent<HTMLInputElement>) => {
      if (type === "date" || type === "color") {
        // Avoid showing the browser datepicker or colorpicker
        e.preventDefault();
        switch (type) {
          case "date":
            setShowDatePicker(!showDatePicker);
            break;
          case "color":
            setShowColorPicker(!showColorPicker);
            break;
        }
      }
    };

    const handleDateChanged = (date: Date) => {
      const newDate = getLocaleDateString(i18n.language, date);
      if (inputRef.current) inputRef.current.value = newDate;
      setShowDatePicker(false);
      if (onDateChange) onDateChange(date);
    };

    // const handleColorChanged = (color: RgbaColor) => {
    //   setSelectedColor(color);
    //   if (inputRef.current) inputRef.current.value = "#" + rgb2Hex(color);
    //   if (onColorChange) onColorChange(color);
    // };

    if (type === "search") {
      childrenPosition = "left";
      children = (
        <>
          <MagnifyingGlassIcon className="text-gray-800" />
          {children}
        </>
      );
    }

    if (type === "date") {
      if (value && value.toString().length > 10) {
        value = getLocaleDateString(i18n.language, new Date(value.toString()));
      }
      if (defaultValue && defaultValue.toString().length > 10) {
        defaultValue = getLocaleDateString(i18n.language, new Date(defaultValue.toString()));
      }
    }

    if (type === "datetime-local") {
      if (value && value.toString().length > 23) {
        // Conversion to local time, avoiding less than seconds information
        value = getLocaleDateTimeString(i18n.language, new Date(value.toString()));
      }
      if (defaultValue && defaultValue.toString().length > 23) {
        // Conversion to local time, avoiding less than seconds information
        defaultValue = getLocaleDateTimeString(i18n.language, new Date(defaultValue.toString()));
      }
    }

    return (
      <ErrorMessage error={error?.message} details={error?.details}>
        <div>
          {label && (
            <label htmlFor={id} className={`block text-sm font-medium text-gray-800 pt-2 pb-1 select-none truncate`}>
              {label}
            </label>
          )}
          <div className={`relative flex flex-wrap items-stretch`}>
            {children && childrenPosition === "left" && (
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-1">
                {children}
              </span>
            )}
            <div ref={componentDivRef} className={`${fullWidth ? "w-full" : ""}`}>
              <input
                id={id}
                name={name}
                value={value}
                defaultValue={defaultValue}
                ref={inputRef}
                type={type === "multiple-files" ? "file" : type}
                multiple={type === "multiple-files"}
                min={min}
                max={max}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                required={required}
                placeholder={disabled ? "" : placeholder}
                pattern={pattern}
                onChange={onChange}
                disabled={disabled}
                spellCheck={spellCheck}
                step={step}
                onClick={(e) => {
                  if (type === "color" || type === "date") e.preventDefault();
                }}
                onPointerDown={handleOnPointerDown}
                className={`relative self-center bg-white ${
                  type === "date" || type === "color" ? "cursor-pointer" : "cursor-text"
                } ${type === "color" ? "h-10 py-1" : "py-1"} ${fullWidth ? "w-full" : ""} ${
                  type === "file" || type === "multiple-files" ? "hidden" : "block"
                } ${
                  children
                    ? childrenPosition === "right"
                      ? "pr-10 pl-3"
                      : "pr-3 pl-10"
                    : disabled && !showBorder
                      ? ""
                      : "px-3"
                } ${
                  showBorder ? "border " + (disabled ? "border-gray-300" : "border-gray-500") : "border-none"
                } placeholder-gray-400 ${
                  disabled ? "text-gray-500" + (showBorder ? " bg-gray-100" : " bg-transparent") : "text-gray-800"
                } rounded-md focus-visible:outline-none ${
                  showRingWhenSelected
                    ? " focus-visible:ring-primary-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                    : ""
                } sm:text-sm text-ellipsis`}
              />
              {type === "file" || type === "multiple-files" ? (
                <Button color={showBorder ? "blue" : "none"} onPointerDown={() => inputRef.current?.click()}>
                  {children}
                </Button>
              ) : null}
              {showDatePicker && (
                <DatePicker
                  show={showDatePicker}
                  onCancel={() => setShowDatePicker(false)}
                  onChange={handleDateChanged}
                />
              )}
              {/* {showColorPicker && (
                <ColorPicker show={showColorPicker} selectedColor={selectedColor} onChange={handleColorChanged} />
              )} */}
            </div>
            {children && childrenPosition === "right" && (
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-1">
                {children}
              </span>
            )}
          </div>
        </div>
      </ErrorMessage>
    );
  },
);
