import { UserMinusIcon } from "@heroicons/react/24/outline";
import { Page } from "@whyuz/components";
import { ChatMember } from "@whyuz/services";
import { Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { Member } from "./Member.tsx";

interface DeleteMemberModalProps {
  modalProps: ModalProps;
  deleteMember: (memberId: string) => void;
  members: ChatMember[];
  onClose: () => void;
}

export const DeleteMemberModal = ({ modalProps, members, onClose, deleteMember }: DeleteMemberModalProps) => {
  const { t: tChat } = useTranslation("chat");
  return (
    <Modal popup {...modalProps} onClose={onClose}>
      <Modal.Header className="p-6">
        <Page.Title title={tChat("deletemember")} icon={<UserMinusIcon className="h-4 w-4" />} />
      </Modal.Header>
      <Modal.Body>
        {members.map((el) => (
          <Member key={el.id as string} member={el} onDeleteMember={(memberId) => deleteMember(memberId)} />
        ))}
      </Modal.Body>
    </Modal>
  );
};
