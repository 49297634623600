import {
  AcceptTermsOfServiceMutationVariables,
  AddUserLicenseMutationVariables,
  AuthenticatedUserSessionQueryVariables,
  DeleteUserLicenseMutationVariables,
  DeleteUserLicensesMutationVariables,
  LoginAuthenticatedUserLicenseMutationVariables,
  LogoutAuthenticatedUserLicenseMutationVariables,
  Session,
  UpdateUserLicenseLocaleMutationVariables,
  UpdateUserLicenseMutationVariables,
  UserLicense,
  UserLicensePage,
  UserLicenseQueryVariables,
  UserLicensesQueryVariables,
} from "../../codegen/graphql.ts";
import { UserLicenseGQL } from "../../queries";
import { GQLQueryContext } from "../../types";
import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../useGQLQuery.ts";

export const useLoginAuthenticatedUserLicenseMutation = (
  mutationContext?: GQLQueryContext<Session, LoginAuthenticatedUserLicenseMutationVariables>,
) =>
  useGQLMutation<Session, LoginAuthenticatedUserLicenseMutationVariables>(
    UserLicenseGQL.mutation.loginAuthenticatedUserLicense,
    mutationContext,
  );

export const useLogoutAuthenticatedUserLicenseMutation = (
  mutationContext?: GQLQueryContext<UserLicense, LogoutAuthenticatedUserLicenseMutationVariables>,
) =>
  useGQLMutation<UserLicense, LogoutAuthenticatedUserLicenseMutationVariables>(
    UserLicenseGQL.mutation.logoutAuthenticatedUserLicense,
    mutationContext,
  );

export const useAddUserLicenseMutation = (
  mutationContext?: GQLQueryContext<UserLicense, AddUserLicenseMutationVariables>,
) =>
  useGQLMutation<UserLicense, AddUserLicenseMutationVariables>(UserLicenseGQL.mutation.addUserLicense, mutationContext);

export const useUpdateUserLicenseMutation = (
  mutationContext?: GQLQueryContext<UserLicense, UpdateUserLicenseMutationVariables>,
) =>
  useGQLMutation<UserLicense, UpdateUserLicenseMutationVariables>(
    UserLicenseGQL.mutation.updateUserLicense,
    mutationContext,
  );

export const useUpdateUserLicenseLocaleMutation = (
  mutationContext?: GQLQueryContext<UserLicense, UpdateUserLicenseLocaleMutationVariables>,
) =>
  useGQLMutation<UserLicense, UpdateUserLicenseLocaleMutationVariables>(
    UserLicenseGQL.mutation.updateUserLicenseLocale,
    mutationContext,
  );

export const useAcceptTermsOfServiceMutation = (
  mutationContext?: GQLQueryContext<UserLicense, AcceptTermsOfServiceMutationVariables>,
) =>
  useGQLMutation<UserLicense, AcceptTermsOfServiceMutationVariables>(
    UserLicenseGQL.mutation.acceptTermsOfService,
    mutationContext,
  );

export const useDeleteUserLicenseMutation = (
  mutationContext?: GQLQueryContext<UserLicense, DeleteUserLicenseMutationVariables>,
) =>
  useGQLMutation<UserLicense, DeleteUserLicenseMutationVariables>(
    UserLicenseGQL.mutation.deleteUserLicense,
    mutationContext,
  );

export const useDeleteUserLicensesMutation = (
  mutationContext?: GQLQueryContext<UserLicense, DeleteUserLicensesMutationVariables>,
) =>
  useGQLMutation<UserLicense, DeleteUserLicensesMutationVariables>(
    UserLicenseGQL.mutation.deleteUserLicenses,
    mutationContext,
  );

export const useUserLicensesLazyQuery = (queryContext?: GQLQueryContext<UserLicensePage, UserLicensesQueryVariables>) =>
  useGQLLazyQuery<UserLicensePage, UserLicensesQueryVariables>(UserLicenseGQL.query.userLicenses, queryContext);

export const useUserLicenseLazyQuery = (queryContext?: GQLQueryContext<UserLicense, UserLicenseQueryVariables>) =>
  useGQLLazyQuery<UserLicense, UserLicenseQueryVariables>(UserLicenseGQL.query.userLicense, queryContext);

export const useUserLicenseQuery = (queryContext?: GQLQueryContext<UserLicense, UserLicenseQueryVariables>) =>
  useGQLQuery<UserLicense, UserLicenseQueryVariables>(UserLicenseGQL.query.userLicense, queryContext);

export const useUserLicensesQuery = (queryContext?: GQLQueryContext<UserLicensePage, UserLicensesQueryVariables>) =>
  useGQLQuery<UserLicensePage, UserLicensesQueryVariables>(UserLicenseGQL.query.userLicenses, queryContext);

export const useAuthenticatedUserSessionLazyQuery = (
  queryContext?: GQLQueryContext<Session, AuthenticatedUserSessionQueryVariables>,
) =>
  useGQLLazyQuery<Session, AuthenticatedUserSessionQueryVariables>(
    UserLicenseGQL.query.authenticatedUserSession,
    queryContext,
  );

export const useAuthenticatedUserSessionQuery = (
  queryContext?: GQLQueryContext<Session, AuthenticatedUserSessionQueryVariables>,
) =>
  useGQLQuery<Session, AuthenticatedUserSessionQueryVariables>(
    UserLicenseGQL.query.authenticatedUserSession,
    queryContext,
  );
