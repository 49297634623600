import { ComboboxAutocomplete, ComboboxAutocompleteProps, ComboboxItemDictionary } from "@whyuz/components";
import {
  FilterExpression,
  FilterExpressionOperator,
  FilterOperator,
  GQLError,
  SortDirection,
  User,
  UserPage,
  useUsersLazyQuery,
} from "@whyuz/services";
import { getColSpan, isEmpty, notifyError } from "@whyuz/utils";
import { HelperText } from "flowbite-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAvatar } from ".";
import { formatUserName } from "./utils";

export const UserCombobox = ({
  columns,
  columnsBreakpointSM,
  ...props
}: Omit<ComboboxAutocompleteProps<User>, "items">) => {
  const { t: tUser } = useTranslation("user");
  const [userError, setUserError] = useState<GQLError>();
  const [userItems, setUserItems] = useState<ComboboxItemDictionary<User>>({});
  const [usersQuery, { isLoading: isLoadingUsers }] = useUsersLazyQuery();

  const onUserSearchTextChange = useCallback(
    (searchText: string | null) => {
      const filterExpression: FilterExpression | undefined = isEmpty(searchText)
        ? undefined
        : ({
            operator: FilterExpressionOperator.Or,
            fields: [
              { field: "id", operator: FilterOperator.Like, value: "%" + searchText + "%" },
              { field: "mail", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
              { field: "firstName", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
              { field: "lastName", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
            ],
          } as FilterExpression);

      usersQuery({
        variables: {
          filterExpression,
          pageSize: 20,
          sortField: "lastName",
          sortDirection: SortDirection.Asc,
        },
      })
        .then((page: UserPage) => {
          const items = page?.content
            ? page.content.reduce(
                (prev, user) =>
                  user
                    ? ({
                        ...prev,
                        [user.id]: {
                          id: user.id as string,
                          name: formatUserName(user),
                          icon: <UserAvatar user={user} size="xs" showTooltip={false} />,
                          entity: user,
                        },
                      } as ComboboxItemDictionary<User>)
                    : prev,
                {} as ComboboxItemDictionary<User>,
              )
            : {};
          setUserItems(items);
          setUserError(undefined);
        })
        .catch((usersError: GQLError) => {
          setUserError(() => usersError);
          notifyError(tUser("errors.retrievingusers"));
        });
    },
    [tUser, usersQuery],
  );

  return (
    <div
      className={`flex flex-col ${getColSpan(columns, columnsBreakpointSM) ?? ""} ${props.fullWidth ? "w-full" : ""} space-y-3`}>
      <ComboboxAutocomplete
        items={userItems}
        onSearchTextChange={onUserSearchTextChange}
        isLoading={isLoadingUsers}
        {...props}
      />
      {userError && <HelperText color="failure">{userError.message}</HelperText>}
    </div>
  );
};
