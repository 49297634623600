import { XMarkIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { PageButtonsContainer } from "./PageButtonsContainer";
import { PageTitle } from "./PageTitle";

export interface PageTitlePanelProps {
  icon?: React.ReactNode;
  title: string;
  detail?: React.ReactNode;
  bottomBorder?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

export const PageTitlePanel = ({
  icon,
  title,
  detail,
  bottomBorder = true,
  children,
  onClose,
}: PageTitlePanelProps) => {
  return (
    <div
      className={`flex flex-col md:flex-row items-start md:items-center justify-between md:space-x-4 ${
        children ? "space-y-2" : "space-y-0"
      } md:space-y-0 p-4 ${bottomBorder ? "border-b dark:border-gray-700" : ""}`}
    >
      <div className="w-full flex items-center justify-between">
        <PageTitle icon={icon} title={title} detail={detail} />
        {onClose && (
          <XMarkIcon
            className="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600 block sm:hidden"
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) {
                onClose();
              }
            }}
          />
        )}
      </div>
      <PageButtonsContainer>
        {children}
        {onClose && (
          <XMarkIcon
            className="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600 hidden sm:block"
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) {
                onClose();
              }
            }}
          />
        )}
      </PageButtonsContainer>
    </div>
  );
};
