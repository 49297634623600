import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { FileCardNodeViewComponent } from "./FileCardNodeViewComponent";

export default Node.create({
  name: "FileCard",

  group: "block",

  atom: true,

  addAttributes() {
    return {
      id: {
        default: "",
      },
      url: {
        default: "",
      },
      fileSizeBytes: {
        default: "",
      },
      fileName: {
        default: "",
      },
      fileExtension: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "FileCard",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["FileCard", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileCardNodeViewComponent);
  },
});
