import { RIGHT_BUTTON } from "@whyuz/data";
import { useEffect, useRef, useState } from "react";

interface Props {
  videoProgress: number;
  onVideoProgressChange: (videoProgress: number) => void;
}

export const VideoTimelineControl = ({ videoProgress, onVideoProgressChange }: Props) => {
  const timelineDivRef = useRef<HTMLDivElement>(null);
  const timelineProgressDivRef = useRef<HTMLDivElement>(null);
  const progressButtonDivRef = useRef<HTMLDivElement>(null);
  const [isPointerDown, setPointerDown] = useState(false);

  useEffect(() => {
    const handleTimelineUpdate = (e: PointerEvent) => {
      if (e.button !== RIGHT_BUTTON) {
        setPointerDown(true);
        if (!timelineDivRef.current) return;
        const rect = timelineDivRef.current.getBoundingClientRect();
        const newVideoProgress = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
        onVideoProgressChange(newVideoProgress);
      }
    };

    const timelineDiv = timelineDivRef.current;
    if (timelineDiv) {
      timelineDiv.addEventListener("pointerdown", handleTimelineUpdate);
      return () => timelineDiv.removeEventListener("pointerdown", handleTimelineUpdate);
    }
    return undefined;
  }, [onVideoProgressChange]);

  useEffect(() => {
    const handlePointerUp = (e: PointerEvent) => {
      if (e.button !== RIGHT_BUTTON) {
        setPointerDown(false);
      }
    };

    window.addEventListener("pointerup", handlePointerUp);
    return () => window.removeEventListener("pointerup", handlePointerUp);
  }, []);

  useEffect(() => {
    const handleMoveProgressButton = (e: PointerEvent) => {
      if (!timelineDivRef.current || !isPointerDown) return;
      const rect = timelineDivRef.current.getBoundingClientRect();
      const newVideoProgress = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
      onVideoProgressChange(newVideoProgress);
    };

    window.addEventListener("pointermove", handleMoveProgressButton, true);
    return () => {
      window.removeEventListener("pointermove", handleMoveProgressButton, true);
    };
  }, [isPointerDown, onVideoProgressChange]);

  useEffect(() => {
    if (timelineProgressDivRef.current) {
      timelineProgressDivRef.current.style.width = (videoProgress * 100).toString() + "%";
    }
  }, [videoProgress]);

  return (
    <div className={`flex flex-col justify-center mb-2 w-full px-5`}>
      <div ref={timelineDivRef} className={`relative flex items-center h-2 cursor-pointer`}>
        <div className={`h-1 w-full bg-opacity-50 bg-gray-500`}></div>
        <div
          ref={timelineProgressDivRef}
          className={`absolute flex items-center w-0 h-1 bg-primary-600 left-0 hover:bg-primary-500 `}>
          <div
            ref={progressButtonDivRef}
            className={`absolute w-3 h-3 hover:h-4 hover:w-4 bg-inherit translate-x-[+50%] right-0 rounded-full`}></div>
        </div>
      </div>
    </div>
  );
};
