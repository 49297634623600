import React from "react";
// import { Loading } from "../../components/Loading";
import { GQLError } from "@whyuz/services";
import { ErrorPage } from "../Error/ErrorPage.tsx";

export interface PageLoadingOrErrorProps {
  isLoading?: boolean;
  error?: unknown;
  onErrorClose?: () => void;
  children?: React.ReactNode;
}

export const PageLoadingOrError = ({ isLoading, error, onErrorClose, children }: PageLoadingOrErrorProps) => {
  const isControlledGQLError = (error?: unknown): boolean => {
    return (
      (error as GQLError) &&
      (error as GQLError).message !== undefined &&
      (error as GQLError).fieldErrors !== undefined &&
      (error as GQLError).isUncontrolledError === false
    );
  };

  return error && !isControlledGQLError(error) ? (
    <ErrorPage error={error} onClose={onErrorClose} />
  ) : isLoading ? (
    /*<Loading />*/ children
  ) : (
    children
  );
};
