import { ErrorMessageType, GQLError } from "@whyuz/services";
import { Label } from "flowbite-react";
import { ErrorMessage } from "../../Error";
import { useTextEditorContext } from "../hooks/useTextEditorContext";
import { TextEditorContainer } from "./TextEditorContainer";
import { TextEditorContent } from "./TextEditorContent";
import { TextEditorMenu, TextEditorMenuActions } from "./TextEditorMenu";

export interface TextEditorProps {
  id?: string;
  label?: string;
  showBorder?: boolean;
  hideMenuOnDisabled?: boolean;
  menuPosition?: "top" | "bottom" | "hidden";
  error?: { message?: ErrorMessageType; details?: GQLError };
  actions?: TextEditorMenuActions[];
  className?: string;
  deleteBgAndBorder?: boolean;
}

export const TextEditorWithMenu = ({
  id,
  label,
  showBorder = true,
  menuPosition = "top",
  hideMenuOnDisabled = true,
  error,
  actions,
  className,
}: TextEditorProps) => {
  const { editor, disabled } = useTextEditorContext();

  return (
    <ErrorMessage error={error?.message} details={error?.details}>
      <div className="h-full space-y-0">
        {label && (
          <Label htmlFor={id} className="dark:text-white mb-2 text-sm font-semibold text-gray-900 select-none">
            {label}
          </Label>
        )}
        <TextEditorContainer showBorder={showBorder}>
          {editor && menuPosition === "top" && !(disabled && hideMenuOnDisabled) && (
            <TextEditorMenu actions={actions} />
          )}
          <TextEditorContent id={id} className={className} />
          {editor && menuPosition === "bottom" && !(disabled && hideMenuOnDisabled) && (
            <TextEditorMenu actions={actions} />
          )}
        </TextEditorContainer>
      </div>
    </ErrorMessage>
  );
};
