import { useTranslation } from "react-i18next";
import { GenericError } from "./GenericError.tsx";

export interface Error400BadRequestProps {
  onClose?: () => void;
}

export const Error400BadRequest = ({ onClose }: Error400BadRequestProps) => {
  const { t } = useTranslation("common");

  return (
    <GenericError
      errorNumber={400}
      title={t("errors.httperror400")}
      description={t("errors.httperror400detail")}
      onClose={onClose}
    />
  );
};
