import { TrashIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { getFileSizeText, openFileInNewTabFromURI } from "@whyuz/utils";
import { useTranslation } from "react-i18next";
import { FileIcon } from "./FileIcon.tsx";

export interface FileCardProps {
  url: string;
  fileName: string;
  fileExtension?: string;
  fileSizeBytes: number;
  showIcon?: boolean;
  showFileName?: boolean;
  showSize?: boolean;
  showRemoveButton?: boolean;
  onRemove?: (fileName: string, url: string) => void;
}

export const FileCard = ({
  url,
  fileName,
  fileExtension,
  fileSizeBytes,
  showIcon = true,
  showFileName = true,
  showSize = true,
  onRemove,
}: FileCardProps) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`group flex flex-row justify-between bg-white border border-gray-300 dark:border-gray-800 dark:bg-gray-600 p-2 rounded items-center overflow-hidden`}>
      <a
        onPointerDown={(e) => {
          if (e.button !== RIGHT_BUTTON) {
            openFileInNewTabFromURI(url, fileName);
          }
        }}
        href={url}
        target="_blank"
        className="flex flex-row items-center max-w-[300px]"
        rel="noreferrer">
        {showIcon && (
          <div className="flex-none">
            <FileIcon fileExtension={fileExtension} />
          </div>
        )}
        {(showFileName || showSize) && (
          <div className="w-full flex flex-col flex-1 ml-2">
            {showFileName && (
              <div className="text-black dark:text-white text-sm">
                <p className="text-black dark:text-white text-sm w-fit text-wrap m-0">{fileName}</p>
              </div>
            )}
            {showSize && (
              <div className="text-gray-500 dark:text-gray-300 text-xs">
                <p className="text-gray-500 dark:text-gray-300 text-xs w-fit overflow-hidden m-0 select-none">
                  {getFileSizeText(fileSizeBytes, i18n.language)}
                </p>
              </div>
            )}
          </div>
        )}
      </a>
      {onRemove && (
        <button
          type="button"
          className="sm:invisible sm:group-hover:visible"
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) {
              onRemove(fileName, url);
            }
          }}>
          {<TrashIcon className="p-0.5 h-4 w-4 rounded-full text-gray-400 hover:bg-gray-100 hover:text-gray-800" />}
        </button>
      )}
    </div>
  );
};
