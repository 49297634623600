import { useForwardRef } from "@whyuz/hooks";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { TextInput } from "flowbite-react";
import { ChangeEvent, forwardRef, KeyboardEvent, useMemo } from "react";
import { getErrorMessageTextColor } from "../Error";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField.tsx";

export interface PageFormTextInputFieldProps {
  id: string;
  label?: string;
  labelBold?: boolean;
  placeholder?: string;
  value?: string;
  password?: boolean;
  disabled?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  error: GQLError | undefined;
  onChange?: (newValue: string, event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  className?: string;
}

export const PageFormTextInputField = forwardRef<HTMLInputElement, PageFormTextInputFieldProps>(
  (
    {
      id,
      label,
      labelBold = true,
      placeholder,
      value,
      password = false,
      disabled,
      columns,
      columnsBreakpointSM = true,
      error,
      onChange,
      onKeyDown,
      autoFocus = false,
      className,
    }: PageFormTextInputFieldProps,
    ref,
  ) => {
    const inputRef = useForwardRef<HTMLInputElement>(ref);
    // GetPropertyByPath used to avoid case sensitive field name errors
    const fieldErrorMessage = useMemo(
      () => (error ? (getPropertyByPath(error.fieldErrors, id) as ErrorMessageType) : undefined),
      [error, id],
    );

    return (
      <PageFormCustomEditableField
        id={id}
        label={label}
        labelBold={labelBold}
        error={error}
        columns={columns}
        columnsBreakpointSM={columnsBreakpointSM}>
        <TextInput
          id={id}
          ref={inputRef}
          type={password ? "password" : "text"}
          sizing="md"
          color={getErrorMessageTextColor(fieldErrorMessage)}
          placeholder={placeholder}
          value={value ?? ""}
          autoComplete="off"
          disabled={disabled}
          onChange={onChange ? (e) => onChange(e.target.value, e) : undefined}
          onKeyDown={onKeyDown ? (e) => onKeyDown(e) : undefined}
          autoFocus={autoFocus}
          className={className}
        />
      </PageFormCustomEditableField>
    );
  },
);
