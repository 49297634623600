export interface AdvancedTablePanelFooterProps {
  children?: React.ReactNode;
}

export const AdvancedTablePanelFooter = ({ children }: AdvancedTablePanelFooterProps) => {
  return (
    <nav className="flex-row space-y-4 sm:space-y-0 sm:flex items-center justify-between p-4" aria-label="Table footer">
      {children}
    </nav>
  );
};
