import { Loading, PageLoadingOrError } from "@whyuz/components";
import { useKeycloak } from "@whyuz/services";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const Login = () => {
  const location = useLocation();
  const currentLocationPathname = location.pathname || "/";
  const { initialized, keycloak } = useKeycloak();
  const [error, setError] = useState<unknown>();

  if (!initialized) {
    return <Loading />;
  }

  if (keycloak?.authenticated) {
    return <Navigate to={currentLocationPathname} />;
  }

  keycloak.login().catch((e) => setError(e));

  return <PageLoadingOrError isLoading={!error} error={error} onErrorClose={() => setError(undefined)} />;
};
