import { CheckIcon } from "@heroicons/react/24/outline";
import { SelectMenuItem } from ".";

export interface SelectMenuItemComponentProps<T> {
  item: SelectMenuItem<T>;
  selected: boolean;
}

export const SelectMenuItemComponent = <T,>({ selected, item }: SelectMenuItemComponentProps<T>) => {
  return (
    <div className="flex flex-1 items-center space-x-2 w-full">
      {item.icon && <span className="flex-none flex items-center">{item.icon}</span>}
      {item.name && (
        <span className={`block truncate flex-1 text-left ${selected ? "font-semibold" : "font-normal"}`}>
          {item.name}
        </span>
      )}
      {selected && <CheckIcon className="h-5 w-5 flex-none" aria-hidden="true" />}
    </div>
  );
};
