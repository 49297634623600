import { User, UserPage, UserQueryVariables, UsersQueryVariables } from "../../codegen/graphql.ts";
import { UserGQL } from "../../queries";
import { GQLQueryContext } from "../../types";
import { useGQLLazyQuery, useGQLQuery } from "../useGQLQuery.ts";

export const useUsersLazyQuery = (queryContext?: GQLQueryContext<UserPage, UsersQueryVariables>) =>
  useGQLLazyQuery<UserPage, UsersQueryVariables>(UserGQL.query.users, queryContext);

export const useUsersQuery = (queryContext?: GQLQueryContext<UserPage, UsersQueryVariables>) =>
  useGQLQuery<UserPage, UsersQueryVariables>(UserGQL.query.users, queryContext);

export const useUserLazyQuery = (queryContext?: GQLQueryContext<User, UserQueryVariables>) =>
  useGQLLazyQuery<User, UserQueryVariables>(UserGQL.query.user, queryContext);

export const useUserQuery = (queryContext?: GQLQueryContext<User, UserQueryVariables>) =>
  useGQLQuery<User, UserQueryVariables>(UserGQL.query.user, queryContext);
