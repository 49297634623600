import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../hooks/useTextEditorContext";

export interface TextEditorContainerProps extends React.PropsWithChildren {
  showBorder?: boolean;
  className?: string;
  deleteBorder?: boolean;
}

export const TextEditorContainer = ({ showBorder = true, className, children }: TextEditorContainerProps) => {
  const { disabled } = useTextEditorContext();

  return (
    <div
      className={twMerge(
        "h-full border rounded-md  flex flex-col",
        showBorder
          ? "border " + (disabled ? "border-gray-100 dark:border-gray-600" : "border-gray-150 dark:border-gray-600")
          : "border-none",
        disabled ? "pointer-events-none" : "",
        className,
      )}>
      {children}
    </div>
  );
};
