import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTextInputTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteTextInputThemes {
  default = "default",
  underlined = "underlined",
}

const FlowbiteDefaultTextInputTheme = FlowbiteDefaultTheme.textInput;

export const FlowbiteTextInputThemes: Record<EnumFlowbiteTextInputThemes, RecursivePartial<FlowbiteTextInputTheme>> = {
  default: {
    field: {
      input: {
        colors: {
          transparent: "border-none focus:ring-transparent bg-transparent placeholder-gray-400",
          gray: twMerge(
            FlowbiteDefaultTextInputTheme.field.input.colors.gray,
            "focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500",
          ),
          info: "border-primary-500 bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 focus:ring-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500",
        },
      },
    },
  },
  underlined: {
    field: {
      input: {
        base: twMerge(FlowbiteDefaultTextInputTheme.field.input.base, "border-0 border-b-2"),
        sizes: {
          sm: twMerge(FlowbiteDefaultTextInputTheme.field.input.sizes.sm, "py-2 px-0"),
          md: twMerge(FlowbiteDefaultTextInputTheme.field.input.sizes.md, "py-2.5 px-0"),
          lg: twMerge(FlowbiteDefaultTextInputTheme.field.input.sizes.lg, "py-4 px-0"),
        },
        colors: {
          gray: twMerge(
            FlowbiteDefaultTextInputTheme.field.input.colors.gray,
            "focus:border-primary-500 focus:ring-transparent focus-visible:ring-transparent dark:focus:border-primary-500 dark:focus:ring-transparent dark:focus-visible:ring-transparent",
          ),
          info: "border-primary-500 bg-primary-50 text-primary-900 placeholder-primary-700 dark:border-primary-400 dark:bg-primary-100 focus:border-primary-500 focus:ring-transparent focus-visible:ring-transparent dark:focus:border-primary-500 dark:focus:ring-transparent dark:focus-visible:ring-transparent",
          failure:
            "border-red-500 bg-red-50 text-red-900 placeholder-red-700 dark:border-red-400 dark:bg-red-100 focus:border-red-500 focus:ring-transparent focus-visible:ring-transparent dark:focus:border-red-500 dark:focus:ring-transparent dark:focus-visible:ring-transparent",
          warning:
            "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 dark:border-yellow-400 dark:bg-yellow-100 focus:border-yellow-500 focus:ring-transparent focus-visible:ring-transparent dark:focus:border-yellow-500 dark:focus:ring-transparent dark:focus-visible:ring-transparent",
          success:
            "border-green-500 bg-green-50 text-green-900 placeholder-green-700 dark:border-green-400 dark:bg-green-100 focus:border-green-500 focus:ring-transparent focus-visible:ring-transparent dark:focus:border-green-500 dark:focus:ring-transparent dark:focus-visible:ring-transparent",
        },
        withAddon: {
          on: "rounded-none",
          off: "rounded-none",
        },
      },
    },
  },
};
