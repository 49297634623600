import "../styles/tailwind.css";

import { twMerge } from "tailwind-merge";

export interface DividerProps {
  text?: string;
  boldText?: boolean;
  lineClassName?: string;
  textClassName?: string;
}

export const Divider = ({ text, boldText = false, lineClassName, textClassName }: DividerProps) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className={twMerge(lineClassName, "w-full border-b border-gray-300 dark:border-gray-600")} />
      </div>
      {text && (
        <div className="relative flex justify-center">
          <span className={twMerge("bg-gray-50 px-4 pb-[1px] text-sm text-gray-500", textClassName)}>
            {boldText ? <b>{text}</b> : text}
          </span>
        </div>
      )}
    </div>
  );
};
