import { RecursivePartial } from "@whyuz/utils";
import { FlowbiteAvatarTheme, theme as FlowbiteDefaultTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteAvatarThemes {
  default = "default",
  blueInitials = "blueInitials",
  greenInitials = "greenInitials",
  pinkInitials = "pinkInitials",
  purpleInitials = "purpleInitials",
  yellowInitials = "yellowInitials",
  redInitials = "redInitials",
  orangeInitials = "orangeInitials",
  limeInitials = "limeInitials",
  tealInitials = "tealInitials",
  fuchsiaInitials = "fuchsiaInitials",
}

const FlowbiteDefaultAvatarTheme = FlowbiteDefaultTheme.avatar;

export const FlowbiteAvatarThemes: Record<EnumFlowbiteAvatarThemes, RecursivePartial<FlowbiteAvatarTheme>> = {
  default: {
    ...FlowbiteDefaultAvatarTheme,
  },
  blueInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-primary-500 dark:bg-primary-500"),
      },
    },
  },
  greenInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-green-500 dark:bg-green-500"),
      },
    },
  },
  pinkInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-pink-500 dark:bg-pink-500"),
      },
    },
  },
  purpleInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-purple-500 dark:bg-purple-500"),
      },
    },
  },
  yellowInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-yellow-500 dark:bg-yellow-500"),
      },
    },
  },
  redInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-red-500 dark:bg-red-500"),
      },
    },
  },
  orangeInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-orange-500 dark:bg-orange-500"),
      },
    },
  },
  limeInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-lime-500 dark:bg-lime-500"),
      },
    },
  },
  tealInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-teal-500 dark:bg-teal-500"),
      },
    },
  },
  fuchsiaInitials: {
    root: {
      initials: {
        text: twMerge(FlowbiteDefaultAvatarTheme.root.initials.text, "font-bold text-white dark:text-white"),
        base: twMerge(FlowbiteDefaultAvatarTheme.root.initials.base, "bg-fuchsia-500 dark:bg-fuchsia-500"),
      },
    },
  },
};
