import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { FaCheckDouble } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { MARK_DELETION, MARK_INSERTION, getTrackChangesExtension } from "../extensions";
import { buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTrackChangesAcceptAllProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonTrackChangesAcceptAll = ({
  className,
  children,
}: TextEditorMenuButtonTrackChangesAcceptAllProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  if (!editor) {
    return null;
  }

  const extension = getTrackChangesExtension(editor);
  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (!extension || !extension.options.enabled) &&
    !editor.isActive(MARK_DELETION) &&
    !editor.isActive(MARK_INSERTION)
  ) {
    return null;
  }

  return (
    <Tooltip content={t("buttons.acceptallchanges")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().acceptAllChanges().focus().run();
          }
        }}
        className={twMerge(buttonStyle, className)}
        disabled={disabled}
      >
        {children ?? <FaCheckDouble className="h-6 w-6 stroke-1" />}
      </button>
    </Tooltip>
  );
};
