import { ArrowUpTrayIcon, CameraIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { useTranslation } from "react-i18next";

export interface SuccessCaseContentEditorQuestionVideoCameraOrFileProps {
  onUseCamera: () => void;
  onUploadFile: () => void;
}

export const SuccessCaseContentEditorQuestionVideoCameraOrFile = ({
  onUseCamera,
  onUploadFile,
}: SuccessCaseContentEditorQuestionVideoCameraOrFileProps) => {
  const { t } = useTranslation("successcasecontenteditor");
  return (
    <div className="flex flex-col space-y-8">
      <p className="text-center text-sm font-normal text-gray-500 p-5">{t("video.selecthowyouwanttocreatethevideo")}</p>
      <div className="flex flex-row justify-center space-x-6">
        <button
          type="button"
          className="flex flex-col items-center justify-center text-primary-700 border-2 rounded-lg border-primary-700 p-6 w-48 h-32 space-y-2"
          onPointerDown={(e: React.PointerEvent) => {
            if (e.button !== RIGHT_BUTTON) {
              onUseCamera();
            }
          }}>
          <CameraIcon className="h-12 w-12" />
          <span className="leading-tight text-lg text-gray-500 font-semibold text-center">
            {t("video.button.camera")}
          </span>
        </button>
        <button
          type="button"
          className="flex flex-col items-center justify-center text-primary-700 border-2 rounded-lg border-primary-700 p-6 w-48 h-32 space-y-2"
          onPointerDown={(e: React.PointerEvent) => {
            if (e.button !== RIGHT_BUTTON) {
              onUploadFile();
            }
          }}>
          <ArrowUpTrayIcon className="h-12 w-12" />
          <span className="leading-tight text-lg text-gray-500 font-semibold text-center">
            {t("video.button.upload")}
          </span>
        </button>
      </div>
    </div>
  );
};
