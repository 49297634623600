import { MenuItem, VariableSuggestionType } from "@whyuz/components";
import { MailTemplateType } from "@whyuz/services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useMailTemplateTypes = (): MenuItem[] => {
  const { t: tMailTemplateTypes } = useTranslation("mailTemplateTypesAndVariables");
  return useMemo(() => {
    return [
      {
        id: MailTemplateType.ApprovalSuccessCaseExecution,
        name: tMailTemplateTypes("types.APPROVAL_SUCCESS_CASE_EXECUTION"),
      },
      { id: MailTemplateType.ApprovalContent, name: tMailTemplateTypes("types.APPROVAL_CONTENT") },
      { id: MailTemplateType.SuccessCaseGreetings, name: tMailTemplateTypes("types.SUCCESS_CASE_GREETINGS") },
      { id: MailTemplateType.SuccessCaseInvitation, name: tMailTemplateTypes("types.SUCCESS_CASE_INVITATION") },
    ];
  }, [tMailTemplateTypes]);
};

export const useMailTemplateTypesTranslations = (): { [key in MailTemplateType]: string } => {
  const { t: tMailTemplateTypes } = useTranslation("mailTemplateTypesAndVariables");
  return useMemo(() => {
    return {
      [MailTemplateType.ApprovalSuccessCaseExecution]: tMailTemplateTypes("types.APPROVAL_SUCCESS_CASE_EXECUTION"),
      [MailTemplateType.ApprovalContent]: tMailTemplateTypes("types.APPROVAL_CONTENT"),
      [MailTemplateType.SuccessCaseGreetings]: tMailTemplateTypes("types.SUCCESS_CASE_GREETINGS"),
      [MailTemplateType.SuccessCaseInvitation]: tMailTemplateTypes("types.SUCCESS_CASE_INVITATION"),
      [MailTemplateType.SuccessCaseChangesRequested]: tMailTemplateTypes("types.SUCCESS_CASE_CHANGES_REQUESTED"),
      [MailTemplateType.ApprovalCollabParticipation]: tMailTemplateTypes("types.APPROVAL_COLLAB_PARTICIPATION"),
      [MailTemplateType.SuccessCaseAnswersAccepted]: tMailTemplateTypes("types.SUCCESS_CASE_ANSWERS_ACCEPTED"),
      [MailTemplateType.ApprovalCollabContent]: tMailTemplateTypes("types.APPROVAL_COLLAB_CONTENT"),
      [MailTemplateType.SuccessCaseUseAnswersAuthorizationRejected]: tMailTemplateTypes(
        "types.APPROVAL_COLLAB_CONTENT",
      ),
      [MailTemplateType.SuccessCaseFinished]: tMailTemplateTypes("types.APPROVAL_COLLAB_CONTENT"),
    };
  }, [tMailTemplateTypes]);
};

export const useMailTemplateVariablesToSuggest = (): { [key in MailTemplateType]: VariableSuggestionType[] } => {
  const { t: tMailTemplateTypes } = useTranslation("mailTemplateTypesAndVariables");
  return useMemo(() => {
    return {
      [MailTemplateType.ApprovalSuccessCaseExecution]: [
        {
          id: "userRequestedName",
          name: tMailTemplateTypes("variables.APPROVAL_SUCCESS_CASE_EXECUTION.userRequestedName"),
        },
        { id: "userName", name: tMailTemplateTypes("variables.APPROVAL_SUCCESS_CASE_EXECUTION.userName") },
        { id: "customerName", name: tMailTemplateTypes("variables.APPROVAL_SUCCESS_CASE_EXECUTION.customerName") },
        { id: "contactName", name: tMailTemplateTypes("variables.APPROVAL_SUCCESS_CASE_EXECUTION.contactName") },
        { id: "companyName", name: tMailTemplateTypes("variables.APPROVAL_SUCCESS_CASE_EXECUTION.companyName") },
      ],
      [MailTemplateType.ApprovalContent]: [
        { id: "userRequestedName", name: tMailTemplateTypes("variables.APPROVAL_CONTENT.userRequestedName") },
        { id: "userName", name: tMailTemplateTypes("variables.APPROVAL_CONTENT.userName") },
        { id: "companyName", name: tMailTemplateTypes("variables.APPROVAL_CONTENT.companyName") },
      ],
      [MailTemplateType.SuccessCaseGreetings]: [
        { id: "contactName", name: tMailTemplateTypes("variables.SUCCESS_CASE_GREETINGS.contactName") },
        { id: "customerName", name: tMailTemplateTypes("variables.SUCCESS_CASE_GREETINGS.customerName") },
        { id: "companyName", name: tMailTemplateTypes("variables.SUCCESS_CASE_GREETINGS.companyName") },
      ],
      [MailTemplateType.SuccessCaseInvitation]: [
        { id: "contactName", name: tMailTemplateTypes("variables.SUCCESS_CASE_INVITATION.contactName") },
        { id: "userName", name: tMailTemplateTypes("variables.SUCCESS_CASE_INVITATION.userName") },
        { id: "companyName", name: tMailTemplateTypes("variables.SUCCESS_CASE_INVITATION.companyName") },
      ],
      [MailTemplateType.ApprovalCollabParticipation]: [],
      [MailTemplateType.ApprovalCollabContent]: [],
      [MailTemplateType.SuccessCaseAnswersAccepted]: [],
      [MailTemplateType.SuccessCaseChangesRequested]: [],
      [MailTemplateType.SuccessCaseUseAnswersAuthorizationRejected]: [],
      [MailTemplateType.SuccessCaseFinished]: [],
    };
  }, [tMailTemplateTypes]);
};
