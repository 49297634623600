import { useContext } from "react";
import { TextEditorContext, TextEditorContextType } from "../TextEditor";

export const useTextEditorContext = () => {
  const context = useContext(TextEditorContext) as TextEditorContextType;

  if (!context) {
    throw new Error("useTextEditorContent must be used within a TextEditor");
  }

  return context;
};
