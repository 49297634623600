import { CustomFlowbiteTheme } from "flowbite-react";
import { FlowbiteAvatarThemes } from "./FlowbiteAvatarThemes.ts";
import { FlowbiteButtonThemes } from "./FlowbiteButtonThemes";
import { FlowbiteCheckboxThemes } from "./FlowbiteCheckboxThemes";
import { FlowbiteDropdownThemes } from "./FlowbiteDropdownThemes";
import { FlowbiteModalThemes } from "./FlowbiteModalThemes.ts";
import { FlowbiteNavbarThemes } from "./FlowbiteNavbarThemes";
import { FlowbiteRadioThemes } from "./FlowbiteRadioThemes";
import { FlowbiteSidebarThemes } from "./FlowbiteSidebarThemes";
import { FlowbiteSpinnerThemes } from "./FlowbiteSpinnerThemes";
import { FlowbiteTableThemes } from "./FlowbiteTableThemes";
import { FlowbiteTabsThemes } from "./FlowbiteTabsThemes";
import { FlowbiteTextAreaThemes } from "./FlowbiteTextAreaThemes.ts";
import { FlowbiteTextInputThemes } from "./FlowbiteTextInputThemes";
import { FlowbiteTimelineThemes } from "./FlowbiteTimelineThemes.ts";
import { FlowbiteToggleSwitchThemes } from "./FlowbiteToggleSwitchThemes";
import { FlowbiteTooltipThemes } from "./FlowbiteTooltipThemes";
import { FlowbiteBadgeThemes } from "./FlowbiteBadgeThemes.ts";

export const WhyUzFlowbiteTheme: CustomFlowbiteTheme = {
  avatar: FlowbiteAvatarThemes.default,
  button: FlowbiteButtonThemes.default,
  checkbox: FlowbiteCheckboxThemes.default,
  dropdown: FlowbiteDropdownThemes.default,
  navbar: FlowbiteNavbarThemes.default,
  radio: FlowbiteRadioThemes.default,
  sidebar: FlowbiteSidebarThemes.default,
  spinner: FlowbiteSpinnerThemes.default,
  table: FlowbiteTableThemes.default,
  tabs: FlowbiteTabsThemes.default,
  textarea: FlowbiteTextAreaThemes.default,
  textInput: FlowbiteTextInputThemes.default,
  timeline: FlowbiteTimelineThemes.default,
  toggleSwitch: FlowbiteToggleSwitchThemes.default,
  tooltip: FlowbiteTooltipThemes.default,
  modal: FlowbiteModalThemes.default,
  badge: FlowbiteBadgeThemes.default,
};
