import { MdDragIndicator } from "react-icons/md";
import React from "react";
import { twMerge } from "tailwind-merge";
import { PageTitle } from "../Page/PageTitle.tsx";
import { PageButtonsContainer } from "../Page/PageButtonsContainer.tsx";

export interface CardHeaderProps {
  children?: React.ReactNode;
  isDraggable?: boolean;
  title?: string;
  icon?: React.ReactNode;
  titleStyles?: string;
  detail?: React.ReactNode;
}

export const CardHeader = ({ children, isDraggable = false, icon, title, titleStyles, detail }: CardHeaderProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col md:flex-row items-start md:items-center justify-between md:space-x-4 md:space-y-0",
        children ? "space-y-2" : "space-y-0",
      )}>
      <div className="flex flex-row w-full items-center space-x-2">
        {isDraggable && <MdDragIndicator className="w-6 h-6 text-gray-500 dark:text-gray-400" />}
        <PageTitle icon={icon} title={title ?? ""} detail={detail} classname={titleStyles} />
      </div>
      <PageButtonsContainer>{children}</PageButtonsContainer>
    </div>
  );
};
