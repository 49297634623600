import { useContext } from "react";
import { StompContext } from "../context/StompContext";

export const useStomp = () => {
  const context = useContext(StompContext);
  if (!context) {
    throw new Error("useStomp must be used within a StompContextProvider");
  }
  return context;
};
