import { getPropertyByPath } from "@whyuz/utils";
import { Table, TableCellProps } from "flowbite-react";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { AdvancedTableColumnConfiguration } from "./AdvancedTableConfiguration.ts";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { ColoredTableCell, ColoredTableCellColors } from "./ColoredTableCell.tsx";

export interface AdvancedTableCellProps<T extends EntityId, Filter extends T> extends TableCellProps {
  tableState: AdvancedTableState<T, Filter>;
  columnConfiguration: AdvancedTableColumnConfiguration<T, Filter>;
  entity?: T;
  enableSelectText?: boolean;
  boldText?: boolean;
  coloredTableCellColor?: ColoredTableCellColors;
  className?: string;
  children?: React.ReactNode;
}

export const AdvancedTableCell = <T extends EntityId, Filter extends T>({
  tableState,
  columnConfiguration,
  entity,
  enableSelectText,
  boldText,
  coloredTableCellColor,
  className,
  children,
  ...props
}: AdvancedTableCellProps<T, Filter>) => {
  const hidden = useMemo(
    () => !columnConfiguration.isVisible || (tableState.isMobileScreenSize && !columnConfiguration.isMobileVisible),
    [columnConfiguration.isMobileVisible, columnConfiguration.isVisible, tableState.isMobileScreenSize],
  );

  const cellContent =
    children ??
    (entity && columnConfiguration.cellContent
      ? columnConfiguration.cellContent(entity)
      : entity && columnConfiguration.entityField
        ? String(getPropertyByPath<T>(entity, columnConfiguration.entityField) ?? "")
        : null);

  if (!columnConfiguration.isVisible || (tableState.isMobileScreenSize && !columnConfiguration.isMobileVisible))
    return undefined;

  return (
    <Table.Cell
      key={columnConfiguration.columnKey + (entity?.id?.toString() ?? "")}
      hidden={hidden}
      className={props.onPointerDown ? twMerge("cursor-pointer", className) : className}
      {...props}>
      <span
        className={`${enableSelectText ? "" : "select-none"} dark:text-white ${
          boldText ? "font-medium text-gray-900" : ""
        }`}>
        {coloredTableCellColor ? (
          <ColoredTableCell color={coloredTableCellColor}>{cellContent}</ColoredTableCell>
        ) : (
          cellContent
        )}
      </span>
    </Table.Cell>
  );
};
