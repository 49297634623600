import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { EditorView } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";
import { Divider, IMAGE_SIZE, TextEditor, useTextEditor } from "@whyuz/components";
import { KEY_NAME, RIGHT_BUTTON } from "@whyuz/data";
import { getPropertyByPath } from "@whyuz/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export interface ChatSendMessageBarProps {
  onSendMessage: (messageHTML: string) => void;
  className?: string;
  disabled?: boolean;
}

export const ChatSendMessageBar = ({ onSendMessage, className, disabled }: ChatSendMessageBarProps) => {
  const { t: tChat } = useTranslation("chat");

  const sendMessage = useCallback(
    (editor: Editor | null) => {
      if (editor) {
        const messageHTML = editor.getHTML();
        if (messageHTML !== "<p></p>") {
          onSendMessage(messageHTML);
          editor?.commands.clearContent();
        }
      }
    },
    [onSendMessage],
  );

  const handleKeyDown = useCallback(
    (_view: EditorView, e: KeyboardEvent): boolean => {
      if (e.code === KEY_NAME.ENTER) {
        if (e.altKey) {
          if (e.target) {
            // Alt + Enter: Prints the enter
            const ed: Editor = getPropertyByPath(e.target, "editor") as Editor;
            ed.commands.setHardBreak();
          }
        } else {
          if (e.target) {
            // Enter: Sends the message
            const ed: Editor = getPropertyByPath(e.target, "editor") as Editor;
            sendMessage(ed);
          }
        }
        return true; // Do print the key
      }
      return false; // Prints the key
    },
    [sendMessage],
  );

  const chatEditor = useTextEditor({
    placeholder: tChat("placeholdermessage"),
    contentClassName: "py-3 pr-1",
    defaultImageSize: IMAGE_SIZE.SMALL,
    imagesWithLink: true,
    handleKeyDown,
  });

  return (
    <div className={twMerge("p-4", className)}>
      <TextEditor editor={chatEditor} disabled={disabled}>
        <TextEditor.Container className="max-h-52 border-gray-300 bg-white dark:bg-gray-800">
          <TextEditor.MenuButtonPanel className="bg-gray-50 dark:bg-gray-700">
            <TextEditor.ButtonUndo />
            <TextEditor.ButtonRedo />
            <TextEditor.ButtonBold />
            <TextEditor.ButtonItalic />
            <TextEditor.ButtonUnderline />
          </TextEditor.MenuButtonPanel>
          <Divider />
          <div className="flex flex-row overflow-y-auto items-center ml-2">
            <TextEditor.ButtonEmojis />
            <TextEditor.ContentEditor className="flex-1 overflow-y-auto -mx-2 max-h-40" />
            <button
              type="button"
              onPointerDown={(e) => {
                if (e.button !== RIGHT_BUTTON) {
                  sendMessage(chatEditor);
                }
              }}
              className="flex m-1 self-end justify-center rounded-md text-primary-600 dark:text-primary-500 enabled:hover:bg-gray-200 enabled:dark:hover:bg-gray-600 enabled:dark:hover:text-white w-8 h-8 disabled:text-gray-300 disabled:dark:text-gray-500 cursor-pointer disabled:cursor-not-allowed">
              <PaperAirplaneIcon className="h-4 w-4 self-center" />
            </button>
          </div>
        </TextEditor.Container>
      </TextEditor>
    </div>
  );
};
