import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { MdFormatQuote } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyleHiddenOnMobile, buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonQuoteProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonQuote = ({ className, children }: TextEditorMenuButtonQuoteProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName =
    editor && editor.isActive("blockquote") ? activeButtonStyleHiddenOnMobile : buttonStyleHiddenOnMobile;

  return (
    <Tooltip content={t("buttons.quote")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().toggleBlockquote().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}
      >
        {children ?? <MdFormatQuote className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
