import type { Placement } from "@floating-ui/core";
import { rgb2Rgba } from "@whyuz/utils";
import { Floating } from "flowbite-react/components/Floating";
import { observer } from "mobx-react";
import { RgbaColor, RgbaColorPicker, RgbColor, RgbColorPicker } from "react-colorful";
import { FlowbiteFloatingThemes } from "../FlowbiteThemes";
import "../styles/tailwind.css";
import "./assets/css/colorpicker.css";
import { DiscreteColorPicker } from "./components/DiscreteColorPicker.tsx";
import { HexRgbaBox } from "./components/HexRgbaBox.tsx";

export interface ColorPickerProps {
  selectedColor?: RgbaColor;
  show: boolean;
  showRgbColorPicker?: boolean;
  showRgbNumbers?: boolean;
  showTransparency?: boolean;
  className?: string;
  placement?: "auto" | Placement;
  onChange: (color: RgbaColor) => void;
  children: React.ReactNode; // TODO: Force children
}

export const ColorPicker = observer(
  ({
    className,
    selectedColor = { r: 0, g: 0, b: 0, a: 1 },
    showTransparency = false,
    showRgbColorPicker = true,
    showRgbNumbers = false,
    placement = "auto",
    onChange,
    children,
  }: ColorPickerProps) => {
    return (
      <Floating
        arrow={false}
        placement={placement}
        theme={FlowbiteFloatingThemes.fit}
        // eslint-disable-next-line react/style-prop-object
        style="auto"
        trigger={"click"}
        className={className}
        content={
          <div className={`flex items-center justify-center justify-items-center`}>
            <div className="p-3 bg-white rounded-md border border-gray-200 shadow-lg -left-28">
              {!showRgbColorPicker ? undefined : showTransparency ? (
                <RgbaColorPicker
                  className="custom-pointers"
                  color={selectedColor}
                  onChange={(rgba: RgbaColor) => onChange(rgba)}
                />
              ) : (
                <RgbColorPicker
                  className="custom-pointers"
                  color={selectedColor}
                  onChange={(rgb: RgbColor) => onChange(rgb2Rgba(rgb))}
                />
              )}
              <div className="mt-3">
                {showRgbNumbers && (
                  <HexRgbaBox selectedColor={selectedColor} showTransparency={showTransparency} onChange={onChange} />
                )}
              </div>
              <div className={`${showRgbColorPicker || showRgbNumbers ? "mt-3" : "mt-1"}`}>
                <DiscreteColorPicker selectedColor={selectedColor} onChange={onChange} />
              </div>
            </div>
          </div>
        }>
        {children}
      </Floating>
    );
  },
);
