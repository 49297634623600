import { Card } from "@whyuz/components";
import { ApprovalFlowTemplate } from "@whyuz/services";
import { getLocaleDateTimeString } from "@whyuz/utils";
import React, { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ApprovalFlowTemplateCardProps extends React.HTMLAttributes<HTMLDivElement> {
  approvalFlowTemplate: ApprovalFlowTemplate;
}

export const ApprovalFlowTemplateCardModal = forwardRef<HTMLDivElement, ApprovalFlowTemplateCardProps>(
  ({ approvalFlowTemplate, ...props }: ApprovalFlowTemplateCardProps, ref) => {
    const { i18n, t } = useTranslation();
    return (
      <Card ref={ref} {...props}>
        <div className="text-primary-600 font-bold text-base">{approvalFlowTemplate.name}</div>
        <div className="text-xs text-gray-500 dark:text-gray-400">
          <Trans
            i18nKey={"entities.updatedonbyupdatedby"}
            t={t}
            values={{
              date: approvalFlowTemplate.updatedOn
                ? getLocaleDateTimeString(i18n.language, new Date(approvalFlowTemplate.updatedOn as string), {
                    month: "long",
                  })
                : "",
              user: approvalFlowTemplate.updatedBy,
            }}
          />
        </div>
      </Card>
    );
  },
);
