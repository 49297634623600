import { RecursivePartial } from "@whyuz/utils";
import { theme as FlowbiteDefaultTheme, FlowbiteTimelineTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

export interface FlowbiteTimelinePointIconTheme {
  base: string;
  wrapper: string;
}

enum FlowbiteTimelinePointIconIconColors {
  grayIcon = "grayIcon",
  greenIcon = "greenIcon",
  redIcon = "redIcon",
  primaryIcon = "primaryIcon",
}

const FlowbiteDefaultTimelineTheme = FlowbiteDefaultTheme.timeline;

export const FlowbiteTimelineIconTheme: Record<FlowbiteTimelinePointIconIconColors, FlowbiteTimelinePointIconTheme> = {
  [FlowbiteTimelinePointIconIconColors.grayIcon]: {
    base: twMerge(FlowbiteDefaultTimelineTheme.item.point.marker.icon.base, "text-gray-600 dark:text-gray-300"),
    wrapper: twMerge(
      FlowbiteDefaultTimelineTheme.item.point.marker.icon.wrapper,
      "bg-gray-200 ring-transparent dark:bg-gray-800 dark:ring-transparent",
    ),
  },
  [FlowbiteTimelinePointIconIconColors.greenIcon]: {
    base: twMerge(FlowbiteDefaultTimelineTheme.item.point.marker.icon.base, "text-green-600 dark:text-green-300"),
    wrapper: twMerge(
      FlowbiteDefaultTimelineTheme.item.point.marker.icon.wrapper,
      "bg-green-200 ring-transparent dark:bg-green-800 dark:ring-transparent",
    ),
  },
  [FlowbiteTimelinePointIconIconColors.redIcon]: {
    base: twMerge(FlowbiteDefaultTimelineTheme.item.point.marker.icon.base, "text-red-600 dark:text-red-300"),
    wrapper: twMerge(
      FlowbiteDefaultTimelineTheme.item.point.marker.icon.wrapper,
      "bg-red-200 ring-transparent dark:bg-red-800 dark:ring-transparent",
    ),
  },
  [FlowbiteTimelinePointIconIconColors.primaryIcon]: {
    base: twMerge(FlowbiteDefaultTimelineTheme.item.point.marker.icon.base, "text-primary-600 dark:text-primary-300"),
    wrapper: twMerge(
      FlowbiteDefaultTimelineTheme.item.point.marker.icon.wrapper,
      "bg-primary-200 ring-transparent dark:bg-primary-800 dark:ring-transparent",
    ),
  },
};

enum EnumFlowbiteTimelineThemes {
  default = "default",
}

export const FlowbiteTimelineThemes: Record<EnumFlowbiteTimelineThemes, RecursivePartial<FlowbiteTimelineTheme>> = {
  default: {
    item: {
      point: {
        marker: {
          icon: FlowbiteTimelineIconTheme.primaryIcon,
        },
      },
      content: {
        root: {
          base: "items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:bg-gray-700 dark:border-gray-600",
        },
        time: {
          base: twMerge(FlowbiteDefaultTimelineTheme.item.content.time.base, "text-xs sm:order-last"),
        },
        body: {
          base: twMerge(FlowbiteDefaultTimelineTheme.item.content.body.base, "text-sm"),
        },
      },
    },
  },
};
