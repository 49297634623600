import { GQLError, ErrorMessageType } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";

export const getErrorMessageTextColor = (error?: ErrorMessageType) => {
  if (error) {
    const errorType = error?.type ?? "error";
    switch (errorType) {
      case "error":
        return "failure";
      case "warning":
        return "warning";
      case "info":
        return "info";
    }
  }
  return undefined;
};

export const getGQLErrorFieldTextColor = (error: GQLError | undefined, fieldName: string) => {
  return getErrorMessageTextColor(error ? (getPropertyByPath(error, fieldName) as ErrorMessageType) : undefined);
};
