import { SelectActionableMenuItem, SelectMenu } from "@whyuz/components";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BsTable } from "react-icons/bs";
import {
  RiDeleteColumn,
  RiDeleteRow,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
} from "react-icons/ri";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTableProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonTable = ({ hideOnMobile = true, children }: TextEditorMenuButtonTableProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const tableActionsMenuItems: SelectActionableMenuItem<unknown>[] = [
    {
      id: "inserttable",
      icon: <BsTable className="h-4 w-4 m-1" />,
      name: t("buttons.inserttable"),
      action: () => {
        if (editor) {
          editor.chain().focus().insertTable({ rows: 4, cols: 3, withHeaderRow: true }).run();
        }
      },
    },
    // {
    //   id: "deletetable",
    //   name: (
    //     <div className="flex">
    //       <BsTable className="h-4 w-4 m-1 text-red-500" />
    //       <p className="pl-1">{t("buttons.deletetable")}</p>
    //     </div>
    //   ),
    //   action: () => editor.chain().focus().deleteTable().run(),
    // },
    {
      id: "insertcolumnleft",
      icon: <RiInsertColumnLeft className="h-6 w-6" />,
      name: t("buttons.insertcolumnleft"),
      action: () => {
        if (editor) {
          editor.chain().focus().addColumnBefore().run();
        }
      },
    },
    {
      id: "insertcolumnright",
      icon: <RiInsertColumnRight className="h-6 w-6" />,
      name: t("buttons.insertcolumnright"),
      action: () => {
        if (editor) {
          editor.chain().focus().addColumnAfter().run();
        }
      },
    },
    {
      id: "deletecolumn",
      icon: <RiDeleteColumn className="h-6 w-6" />,
      name: t("buttons.deletecolumn"),
      action: () => {
        if (editor) {
          editor.chain().focus().deleteColumn().run();
        }
      },
    },
    {
      id: "insertrowtop",
      icon: <RiInsertRowTop className="h-6 w-6" />,
      name: t("buttons.insertrowtop"),
      action: () => {
        if (editor) {
          editor.chain().focus().addRowBefore().run();
        }
      },
    },
    {
      id: "insertrowbottom",
      icon: <RiInsertRowBottom className="h-6 w-6" />,
      name: t("buttons.insertrowbottom"),
      action: () => {
        if (editor) {
          editor.chain().focus().addRowAfter().run();
        }
      },
    },
    {
      id: "deleterow",
      icon: <RiDeleteRow className="h-6 w-6" />,
      name: t("buttons.deleterow"),
      action: () => {
        if (editor) {
          editor.chain().focus().deleteRow().run();
        }
      },
    },
    {
      id: "mergecells",
      icon: <RiMergeCellsHorizontal className="h-6 w-6" />,
      name: t("buttons.mergecells"),
      action: () => {
        if (editor) {
          editor.chain().focus().mergeCells().run();
        }
      },
    },
    {
      id: "splitcells",
      icon: <RiSplitCellsHorizontal className="h-6 w-6" />,
      name: t("buttons.splitcells"),
      action: () => {
        if (editor) {
          editor.chain().focus().splitCell().run();
        }
      },
    },
    /* <Tooltip text={t("buttons.inserttable")}>
        <button type="button" onClick={() => editor.chain().focus().toggleHeaderColumn().run()} className={buttonStyle} disabled={disabled}>
          toggleHeaderColumn
        </button>
      </Tooltip>
      <Tooltip text={t("buttons.inserttable")}>
        <button type="button" onClick={() => editor.chain().focus().toggleHeaderRow().run()} className={buttonStyle} disabled={disabled}>
          toggleHeaderRow
        </button>
      </Tooltip>
      <Tooltip text={t("buttons.inserttable")}>
        <button type="button" onClick={() => editor.chain().focus().toggleHeaderCell().run()} className={buttonStyle} disabled={disabled}>
          toggleHeaderCell
        </button>
          </Tooltip>
      <Tooltip text={t("buttons.inserttable")}>
        <button type="button" onClick={() => editor.chain().focus().mergeOrSplit().run()} className={buttonStyle} disabled={disabled}>
          mergeOrSplit
        </button>
          </Tooltip>*/
    /* <Tooltip text={t("buttons.inserttable")}>
        <button
          type="button"
          onClick={() => editor.chain().focus().setCellAttribute("backgroundColor", "#333").run()}
          className={buttonStyle}
          disabled={disabled}
        >
          setCellAttribute
        </button>
      </Tooltip> */
    /* <Tooltip text={t("buttons.inserttable")}>
        <button onClick={() => editor.chain().focus().fixTables().run()} className={buttonStyle} disabled={disabled}>
          fixTables
        </button>
      </Tooltip> */
  ];

  return (
    <Tooltip content={t("buttons.table")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={tableActionsMenuItems}
          itemNotSelected={children ?? <BsTable className={iconSelectStyle} />}
          onChange={(selectedItem) => (selectedItem as SelectActionableMenuItem<unknown>).action()}
          textPadding="my-1 p-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={false}
          showChevron={false}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};
