import { ENDPOINT } from "@whyuz/data";
import { getLocaleSupported } from "@whyuz/utils";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CorporationLicense,
  Session,
  Tenant,
  UserLicense,
  UserLicenseInput,
  UserRole,
  UserTenantRole,
} from "../codegen/graphql.ts";
import {
  useAcceptTermsOfServiceMutation,
  useAuthenticatedUserSessionLazyQuery,
  useKeycloak,
  useLoginAuthenticatedUserLicenseMutation,
  useLogoutAuthenticatedUserLicenseMutation,
  useSession,
  useUpdateUserLicenseLocaleMutation,
  useUpdateUserLicenseMutation,
} from "../hooks";
import { GQLError } from "../types";

const isNewUserWithoutCompaniesCallback = (ul: UserLicense | null | undefined) => {
  return (
    (!ul?.administeredCorporations || Object.keys(ul.administeredCorporations).length === 0) &&
    (!ul?.roles || ul.roles.length === 0)
  );
};

export type UserContextType = {
  userLicense?: UserLicense;
  sessionToken: string | undefined;
  isUserAuthenticated: boolean; // The user has the token Id but is not working with any tenant
  isUserLoggedIn: boolean; // The user has the token Id and has logged in the server without a tenant
  isUserLoggedInTenant: boolean; // The user has the token Id and has logged in the server with a tenant
  isUserSuperAdmin: boolean;
  isNewUserWithoutCompanies: boolean;
  isNewUserWithoutCompaniesCallback: (userLicense: UserLicense) => boolean;
  isUserCorporationAdministrator: (corporation?: CorporationLicense) => boolean;
  isUserTenantAdministrator: (tenant?: Tenant) => boolean;
  userHasAdministeredCorporations: boolean;
  userHasSeveralAdministeredCorporations: boolean;
  userHasSeveralAdministeredTenants: boolean;
  userHasAccessToTenant: (tenantId: string) => boolean;
  isUserCurrentCorporationAdministrator: boolean;
  isUserCurrentTenantAdministrator: boolean;
  changeLanguage: (locale: string) => void;
  login: {
    execute: (tenantId: string | undefined) => Promise<UserLicense>;
    data: Session | undefined;
    error: GQLError | undefined;
    isLoading: boolean;
  };
  logout: {
    execute: () => void;
    error: GQLError | undefined;
    isLoading: boolean;
  };
  getUserSession: {
    execute: () => Promise<Session>;
    data: Session | undefined;
    error: GQLError | undefined;
    isLoading: boolean;
  };
  updateUserLicense: {
    execute: (userLicenseInput: UserLicenseInput) => Promise<UserLicense>;
    data: UserLicense | undefined;
    error: GQLError | undefined;
    isLoading: boolean;
  };
  acceptTermsOfService: {
    execute: () => Promise<UserLicense>;
    data: UserLicense | undefined;
    error: GQLError | undefined;
    isLoading: boolean;
  };
  userFullName: string | undefined;
};

export const UserContext = createContext<UserContextType | null>(null);

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const { i18n } = useTranslation();
  const { initialized, keycloak } = useKeycloak();
  const [user, setUser] = useState<UserLicense>();
  const { sessionToken, setSessionToken } = useSession();
  const [loginMutation, { data: loginData, error: loginError, isLoading: isLoadingLogin }] =
    useLoginAuthenticatedUserLicenseMutation();
  const [logoutMutation, { error: logoutError, isLoading: isLoadingLogout }] =
    useLogoutAuthenticatedUserLicenseMutation();
  const [
    authenticatedUserLicenseQuery,
    { data: userLicenseData, error: userLicenseError, isLoading: isLoadingUserLicense },
  ] = useAuthenticatedUserSessionLazyQuery();
  const [updateUserLicenseLocaleMutation] = useUpdateUserLicenseLocaleMutation();
  const [
    acceptTermsOfServiceMutation,
    { data: userLicenseTermsData, error: userLicenseTermsError, isLoading: isLoadingUserLicenseTerms },
  ] = useAcceptTermsOfServiceMutation();
  const [
    updateUserLicenseMutation,
    { data: updatedUserLicenseData, error: updateUserLicenseError, isLoading: isLoadingUpdateUserLicense },
  ] = useUpdateUserLicenseMutation();

  const isUserAuthenticated = useMemo(() => {
    return initialized && keycloak?.authenticated && keycloak?.token ? true : false;
  }, [initialized, keycloak?.authenticated, keycloak?.token]);

  const isUserLoggedIn = useMemo(() => (isUserAuthenticated && user ? true : false), [isUserAuthenticated, user]);

  const isUserLoggedInTenant = useMemo(
    () => (isUserLoggedIn && user?.currentRole?.tenant ? true : false),
    [isUserLoggedIn, user?.currentRole?.tenant],
  );

  const isUserSuperAdmin = useMemo(
    () => isUserAuthenticated && keycloak.hasRealmRole("WhyUz SuperAdmin"),
    [isUserAuthenticated, keycloak],
  );

  const userHasAdministeredCorporations = useMemo(() => {
    if (user?.administeredCorporations) {
      const administeredCorporations = user.administeredCorporations as CorporationLicense[];
      if (administeredCorporations) {
        return administeredCorporations.length > 0;
      }
    }
    return false;
  }, [user?.administeredCorporations]);

  const userHasSeveralAdministeredCorporations = useMemo(() => {
    if (user?.administeredCorporations) {
      const administeredCorporations = user.administeredCorporations as CorporationLicense[];
      if (administeredCorporations) {
        return administeredCorporations.length > 1;
      }
    }
    return false;
  }, [user?.administeredCorporations]);

  const userHasAccessToTenant = useCallback(
    (tenantId: string) => {
      if (!user?.roles) {
        return false;
      }

      return user.roles.find((role) => role?.tenant?.id === tenantId) !== undefined;
    },
    [user?.roles],
  );

  const isUserCorporationAdministrator = useCallback(
    (corporation?: CorporationLicense) => {
      if (corporation && user?.administeredCorporations) {
        const administeredCorporations = user.administeredCorporations as CorporationLicense[];
        if (administeredCorporations) {
          return (
            administeredCorporations.find((currentCorporation) => currentCorporation.id === corporation.id) !==
            undefined
          );
        }
      }
      return false;
    },
    [user?.administeredCorporations],
  );

  const isUserCurrentCorporationAdministrator = useMemo(() => {
    if (isUserLoggedInTenant && user?.currentRole?.tenant?.corporationLicense) {
      return isUserCorporationAdministrator(user.currentRole.tenant.corporationLicense);
    }
    return false;
  }, [isUserCorporationAdministrator, isUserLoggedInTenant, user?.currentRole?.tenant?.corporationLicense]);

  const userHasSeveralAdministeredTenants = useMemo(() => {
    if (userHasSeveralAdministeredCorporations) {
      return true;
    }

    if (
      user?.administeredCorporations &&
      user.administeredCorporations.length === 1 &&
      user.administeredCorporations[0]?.tenants &&
      user.administeredCorporations[0].tenants.length > 1
    ) {
      return true;
    }

    if (user?.roles && user.roles.filter((role) => role?.role === UserRole.Administrator).length > 1) {
      return true;
    }

    return false;
  }, [user?.administeredCorporations, user?.roles, userHasSeveralAdministeredCorporations]);

  const isUserTenantAdministrator = useCallback(
    (tenant?: Tenant) => {
      if (tenant) {
        if (isUserCorporationAdministrator(tenant?.corporationLicense as CorporationLicense)) {
          return true;
        }

        if (user?.roles) {
          const roles = user.roles as UserTenantRole[];
          return (
            roles.find(
              (currentRole) => currentRole.tenant?.id === tenant.id && currentRole.role === UserRole.Administrator,
            ) !== undefined
          );
        }
      }

      return false;
    },
    [isUserCorporationAdministrator, user?.roles],
  );

  const isUserCurrentTenantAdministrator = useMemo(() => {
    if (isUserCurrentCorporationAdministrator) {
      return true;
    }

    if (isUserLoggedInTenant && user?.currentRole?.role) {
      return user.currentRole.role === UserRole.Administrator;
    }

    return false;
  }, [isUserCurrentCorporationAdministrator, isUserLoggedInTenant, user?.currentRole?.role]);

  const changeLanguage = useCallback(
    (locale: string) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      i18n.changeLanguage(getLocaleSupported(locale));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateUserLicenseLocaleMutation({ variables: { locale } }).then((updatedUserLicense) => {
        setUser(updatedUserLicense);
      });
    },
    [i18n, updateUserLicenseLocaleMutation],
  );

  const acceptTermsOfService = useCallback(() => {
    return new Promise((resolve: (data: UserLicense) => void, reject: (error: GQLError) => void) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      acceptTermsOfServiceMutation()
        .then((updatedUserLicense) => {
          setUser(updatedUserLicense);
          resolve(updatedUserLicense);
        })
        .catch((error: GQLError) => {
          if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
            console.log("Error during terms of service acceptance", {
              error,
            });
          }
          reject(error);
        });
    });
  }, [acceptTermsOfServiceMutation]);

  const logout = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logoutMutation().finally(() => {
      setUser(undefined);
      const redirectUri = ENDPOINT.REACT_APP;
      // Logout from the identity management anc closing the session
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      keycloak.logout({ redirectUri });
    });
  }, [keycloak, logoutMutation]);

  const login = useCallback(
    (tenantId: string | undefined) => {
      return new Promise((resolve: (data: UserLicense) => void, reject: (error: GQLError) => void) => {
        if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
          console.log("Requesting login in tenant " + (tenantId ?? ""));
        }
        loginMutation({
          variables: {
            tenantId,
          },
        })
          .then((userSession) => {
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
              console.log("User login succeeded", { userSession });
            }

            setUser(userSession.userLicense);
            setSessionToken(userSession.sessionToken as string);

            const currentLocale = getLocaleSupported(i18n.language);
            if (!userSession.userLicense?.locale) {
              // In case the user hadn't a locale specified, we send it to the server
              changeLanguage(currentLocale);
            } else if (currentLocale !== userSession.userLicense.locale) {
              // In case the user has a different locale than the one detected by i18n, the server value is the one used
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              i18n.changeLanguage(userSession.userLicense.locale);
            }

            resolve(userSession.userLicense);
          })
          .catch((error: GQLError) => {
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
              console.log("Error during login", { error });
            }
            reject(error);
          });
      });
    },
    [changeLanguage, i18n, loginMutation, setSessionToken],
  );

  const refreshUserLicense = useCallback(
    (userLicense: UserLicense) => {
      setUser(userLicense);

      const currentLocale = getLocaleSupported(i18n.language);
      if (!userLicense?.locale) {
        // In case the user hadn't a locale specified, we send it to the server
        changeLanguage(currentLocale);
      } else if (currentLocale !== userLicense.locale) {
        // In case the user has a different locale than the one detected by i18n, the server value is the one used
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(userLicense.locale);
      }
    },
    [changeLanguage, i18n],
  );

  const updateAuthenticatedUserLicense = useCallback(
    (userLicenseInput: UserLicenseInput) => {
      return new Promise((resolve: (data: UserLicense) => void, reject: (error: GQLError) => void) => {
        if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
          console.log("Updating authenticated user license");
        }
        updateUserLicenseMutation({
          variables: {
            id: user?.id as string,
            userLicense: userLicenseInput,
          },
        })
          .then((userLicense) => {
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
              console.log("User license", { userLicense });
            }

            refreshUserLicense(userLicense);

            resolve(userLicense);
          })
          .catch((error: GQLError) => {
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
              console.log("Error retrieving user license", { error });
            }
            reject(error);
          });
      });
    },
    [refreshUserLicense, updateUserLicenseMutation, user],
  );

  const getAuthenticatedUserSession = useCallback(() => {
    return new Promise((resolve: (data: Session) => void, reject: (error: GQLError) => void) => {
      if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
        console.log("Requesting authenticated user license");
      }
      authenticatedUserLicenseQuery()
        .then((userSession) => {
          if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
            console.log("User license", {
              userLicense: userSession.userLicense,
            });
          }

          refreshUserLicense(userSession.userLicense);
          setSessionToken(userSession.sessionToken as string);

          resolve(userSession);
        })
        .catch((error: GQLError) => {
          if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
            console.log("Error retrieving user license", { error });
          }
          reject(error);
        });
    });
  }, [authenticatedUserLicenseQuery, refreshUserLicense, setSessionToken]);

  const isNewUserWithoutCompanies = useMemo(() => {
    return isNewUserWithoutCompaniesCallback(user);
  }, [user]);

  const userFullName = useMemo(() => {
    if (!user) {
      return undefined;
    }
    const firstName = user.personalInfo?.firstName;
    const lastName = user.personalInfo?.lastName;
    if (!firstName && !lastName) {
      return undefined;
    } else if (firstName && lastName) {
      return firstName + " " + lastName;
    } else if (firstName) {
      return firstName;
    } else {
      return lastName as string;
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        userLicense: user,
        sessionToken,
        isUserAuthenticated,
        isUserLoggedIn,
        isUserLoggedInTenant,
        isUserSuperAdmin,
        isNewUserWithoutCompanies,
        isNewUserWithoutCompaniesCallback,
        userHasAdministeredCorporations,
        userHasAccessToTenant,
        userHasSeveralAdministeredCorporations,
        userHasSeveralAdministeredTenants,
        isUserCorporationAdministrator,
        isUserTenantAdministrator,
        isUserCurrentCorporationAdministrator,
        isUserCurrentTenantAdministrator,
        changeLanguage,
        login: {
          execute: login,
          data: loginData,
          error: loginError,
          isLoading: isLoadingLogin,
        },
        logout: {
          execute: logout,
          error: logoutError,
          isLoading: isLoadingLogout,
        },
        acceptTermsOfService: {
          execute: acceptTermsOfService,
          data: userLicenseTermsData,
          error: userLicenseTermsError,
          isLoading: isLoadingUserLicenseTerms,
        },
        getUserSession: {
          execute: getAuthenticatedUserSession,
          data: userLicenseData,
          error: userLicenseError,
          isLoading: isLoadingUserLicense,
        },
        updateUserLicense: {
          execute: updateAuthenticatedUserLicense,
          data: updatedUserLicenseData,
          error: updateUserLicenseError,
          isLoading: isLoadingUpdateUserLicense,
        },
        userFullName,
      }}>
      {children}
    </UserContext.Provider>
  );
};
