import { VideoRecorder, getFileExtensionFromMimeType } from "@whyuz/components";
import { blobToFile } from "@whyuz/utils";
import { RIGHT_BUTTON } from "@whyuz/data";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface SuccessCaseContentEditorQuestionVideoRecorderProps {
  onVideoAdded: (fileAdded: File) => void;
  disabled?: boolean;
  maxSizeInSeconds?: number;
  onCancel: () => void;
}

export const SuccessCaseContentEditorQuestionVideoRecorder = ({
  onVideoAdded,
  disabled,
  maxSizeInSeconds,
  onCancel,
}: SuccessCaseContentEditorQuestionVideoRecorderProps) => {
  if (disabled) {
    return undefined;
  }

  return (
    <div className="flex flex-col">
      <div className="w-full flex justify-end">
        <button
          type="button"
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON) onCancel();
          }}>
          <XMarkIcon className="h-6 w-6 text-gray-300 hover:text-gray-400 dark:text-gray-600 dark:hover:text-gray-500" />
        </button>
      </div>

      <VideoRecorder
        onVideoRecorded={(videoBlob) => {
          onVideoAdded(
            blobToFile(videoBlob, Date.now().toString() + "." + getFileExtensionFromMimeType(videoBlob.type)),
          );
        }}
        maxRecordingTimeMS={maxSizeInSeconds ? maxSizeInSeconds * 1000 : undefined}
      />
    </div>
  );
};
