import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import "../styles/tailwind.css";

export interface ModalProps {
  title?: string;
  text?: string;
  type: "none" | "info" | "warning" | "error";
  buttonType?: "none" | "accept" | "action";
  textCloseButton?: string;
  colorCloseButton?: "none" | "gray" | "orange" | "red" | "blue";
  styleCloseButton?: "solid" | "outline";
  textAcceptButton?: string;
  colorAcceptButton?: "none" | "gray" | "orange" | "red" | "blue";
  styleAcceptButton?: "solid" | "outline";
  open?: boolean;
  children?: React.ReactNode;
  onAccept?: () => void;
  onClose: () => void;
}

export const Modal = ({
  title,
  text,
  type,
  open,
  buttonType,
  textCloseButton: textcloseButton,
  colorCloseButton = "gray",
  textAcceptButton,
  colorAcceptButton,
  children,
  onAccept,
  onClose,
}: ModalProps) => {
  const { t } = useTranslation("common");
  if (!open) {
    return undefined;
  }

  const getIcon = () => {
    switch (type) {
      case "info":
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 sm:mr-2">
            <InformationCircleIcon className="h-7 w-7 text-primary-600" aria-hidden="true" />
          </div>
        );
      case "warning":
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 sm:mr-2">
            <ExclamationTriangleIcon className="h-7 w-7 text-orange-600" aria-hidden="true" />
          </div>
        );
      case "error":
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 sm:mr-2">
            <ExclamationTriangleIcon className="h-7 w-7 text-red-600" aria-hidden="true" />
          </div>
        );
    }
    // Default "none"
    return undefined;
  };

  const getAcceptButtonColor = () => {
    if (colorAcceptButton) return colorAcceptButton;

    switch (type) {
      case "info":
        return "blue";
      case "warning":
        return "orange";
      case "error":
        return "red";
    }
    return "gray";
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-white dark:border-gray-700 dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 min-w-[40%] sm:max-w-[90%]">
                <button
                  title={t("buttons.close")}
                  onPointerDown={onClose}
                  className="absolute top-0 right-0 m-4 sm:mr-5 text-gray-500 text-2xl float-right border-none outline-none bg-transparent rounded-sm hover:text-primary-500 hover:cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-500">
                  <XMarkIcon className="h-6 w-6" />
                </button>
                <div className="bg-white px-4 pt-8 pb-6 sm:p-10">
                  <div>
                    <div className="sm:flex sm:items-start items-center">
                      {getIcon()}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left sm:self-center sm:flex sm:items-center">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                          {title}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{text}</p>
                    </div>
                  </div>
                  {children}
                </div>
                {buttonType !== "none" && (
                  <div className={`bg-gray-50 px-3 pb-2 pt-1 text-center sm:pt-2 sm:px-5 sm:flex sm:flex-row-reverse`}>
                    <div className={`mx-1 my-2 ${buttonType !== "action" ? "hidden" : ""}`}>
                      <Button onPointerDown={onAccept} color={getAcceptButtonColor()}>
                        {textAcceptButton ?? "Add textAcceptButton"}
                      </Button>
                    </div>
                    <div className="mx-1 my-2">
                      <Button onPointerDown={onClose} color={colorCloseButton}>
                        {textcloseButton ?? (buttonType !== "action" ? t("buttons.accept") : t("buttons.cancel"))}
                      </Button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
