import { RecursivePartial } from "@whyuz/utils";
import { twMerge } from "tailwind-merge";

enum EnumComboboxThemes {
  default = "default",
  underline = "underline",
  clean = "clean",
}

export type ComboboxItemTheme = {
  base?: string;
  icon?: string;
  name?: string;
  selected?: string;
  notSelected?: string;
  check?: string;
};

export type ComboboxTheme = {
  label?: {
    normal?: string;
    bold?: string;
  };
  base?: string;
  input?: {
    base?: string;
    color?: {
      primary?: string;
      failure?: string;
      info?: string;
      warning?: string;
    };
  };
  button?: string;
  buttonIcon?: string;
  floating?: {
    menu?: string;
    option?: {
      item?: {
        base?: string;
        freeText?: string;
        notFound?: string;
        content?: ComboboxItemTheme;
      };
      active?: string;
      inactive?: string;
    };
  };
};

export const ComboboxItemDefaultTheme: ComboboxItemTheme = {
  base: "flex items-center space-x-2",
  icon: "flex-none flex items-center",
  name: "block truncate flex-1",
  selected: "font-semibold",
  notSelected: "font-normal",
  check: "h-5 w-5 flex-none",
};

export const ComboboxDefaultTheme: ComboboxTheme = {
  label: {
    normal: "text-xs font-normal text-gray-900 select-none",
    bold: "text-sm font-semibold text-gray-900 select-none",
  },
  base: "w-full flex space-x-1 ",
  input: {
    base: "px-2.5 py-2.5 flex-1 border-none leading-5 text-sm font-medium bg-transparent block truncate focus:ring-transparent disabled:cursor-not-allowed ",
    color: {
      primary:
        "rounded-lg border focus:ring-primary-500 disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500",
      failure:
        "rounded-lg border focus:ring-red-500 border-red-500 disabled:cursor-not-allowed bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500  dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
      info: "rounded-lg border focus:ring-primary-500 border-primary-500 disabled:cursor-not-allowed bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500",
      warning:
        "rounded-lg border focus:ring-yellow-500 border-yellow-500 disabled:cursor-not-allowed bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500  dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
    },
  },
  button: "flex-none pr-1 rounded-lg inset-y-0 right-0 flex items-center cursor-pointer",
  buttonIcon: "h-5 w-5 mr-2 text-gray-900 dark:text-white",
  floating: {
    menu: "absolute mt-2 py-1 z-10 max-h-60 w-full overflow-auto rounded-lg shadow focus:outline-none transition-opacity duration-100 border border-gray-200 bg-white text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white",
    option: {
      item: {
        base: "relative cursor-pointer select-none py-2 pl-4 pr-2 text-sm",
        freeText: "relative cursor-pointer select-none py-2 pl-7 pr-2 text-sm",
        notFound: "relative cursor-default select-none py-1 pl-7 text-gray-400 text-sm",
        content: ComboboxItemDefaultTheme,
      },
      active: "text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white",
      inactive: "text-gray-900 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white",
    },
  },
};

export const ComboboxThemes: Record<EnumComboboxThemes, RecursivePartial<ComboboxTheme>> = {
  default: ComboboxDefaultTheme,
  underline: {
    input: {
      base: twMerge(ComboboxDefaultTheme.input?.base, "px-0"),
      color: {
        primary:
          "border-0 border-b-2 focus:ring-transparent disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 text-gray-900 focus:border-primary-500  dark:border-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500",
        failure:
          "border-0 border-b-2 focus:ring-transparent border-red-500 disabled:cursor-not-allowed bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500  dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        info: "border-0 border-b-2 focus:ring-transparent border-primary-500 disabled:cursor-not-allowed bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500",
        warning:
          "border-0 border-b-2 focus:ring-transparent border-yellow-500 disabled:cursor-not-allowed bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500  dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
      },
    },
  },
  clean: {
    input: {
      base: twMerge(ComboboxDefaultTheme.input?.base, "px-0 py-0"),
      color: {
        primary:
          "border-0 focus:ring-transparent disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 text-gray-900 focus:border-primary-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500",
        failure:
          "border-0 focus:ring-transparent border-red-500 disabled:cursor-not-allowed bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500  dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        info: "border-0 focus:ring-transparent border-primary-500 disabled:cursor-not-allowed bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500",
        warning:
          "border-0 focus:ring-transparent border-yellow-500 disabled:cursor-not-allowed bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500  dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
      },
    },
  },
};
