import { graphql } from "../../codegen/gql";

const approvalFlowExecutionUser = graphql(`
  query approvalFlowExecutionUser($id: UUID!, $tenantId: UUID) {
    approvalFlowExecutionUser(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      sentDate
      status
      user {
        id
      }
      approvalFlowExecutionStep {
        id
        status
        approvalFlowExecution {
          id
          type
          entityId
          status
        }
      }
    }
  }
`);

const approvalFlowExecutionUsers = graphql(`
  query approvalFlowExecutionUsers(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowExecutionUsers(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        name
        email
        sentDate
        status
        user {
          id
        }
        approvalFlowExecutionStep {
          id
        }
      }
    }
  }
`);

const addApprovalFlowExecutionUser = graphql(`
  mutation addApprovalFlowExecutionUser($approvalFlowExecutionUser: ApprovalFlowExecutionUserInput!, $tenantId: UUID) {
    addApprovalFlowExecutionUser(approvalFlowExecutionUser: $approvalFlowExecutionUser, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      sentDate
      status
      user {
        id
      }
      approvalFlowExecutionStep {
        id
      }
    }
  }
`);

const deleteApprovalFlowExecutionUser = graphql(`
  mutation deleteApprovalFlowExecutionUser($id: UUID!, $tenantId: UUID) {
    deleteApprovalFlowExecutionUser(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      sentDate
      status
      user {
        id
      }
    }
  }
`);

export const ApprovalFlowExecutionUserGQL = {
  query: { approvalFlowExecutionUser, approvalFlowExecutionUsers },
  mutation: { addApprovalFlowExecutionUser, deleteApprovalFlowExecutionUser },
};
