import { ErrorMessageType, GQLError } from "@whyuz/services";
import React, { FormEventHandler, forwardRef } from "react";
import { ErrorMessage } from "../Error";
import { SpinnerCard } from "../Spinner";
import { Form, FormBackgroundColorType } from "./Form.tsx";
import { ActionButtonType, FormActionCancelButtons } from "./components/FormActionCancelButtons.tsx";

export interface FormActionCancelProps {
  id: string;
  actionButton: ActionButtonType;
  showButtons?: boolean;
  bgColor?: FormBackgroundColorType;
  className?: string;
  showActionInProgress?: boolean;
  error?: { message?: ErrorMessageType; details?: GQLError };
  children?: React.ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  onCancel?: () => void;
}

export const FormActionCancel = forwardRef<HTMLFormElement, FormActionCancelProps>(
  (
    {
      id,
      actionButton,
      showButtons = true,
      showActionInProgress = false,
      error,
      children,
      bgColor,
      className,
      onSubmit,
      onCancel,
    }: FormActionCancelProps,
    ref,
  ) => {
    return (
      <SpinnerCard showSpinner={showActionInProgress}>
        <Form key={"Form" + id} id={id} ref={ref} className={className} bgColor={bgColor} onSubmit={onSubmit}>
          <ErrorMessage error={error?.message} details={error?.details}>
            {children}
          </ErrorMessage>
          {showButtons && (
            <div className="pt-2">
              <FormActionCancelButtons
                key={"FormActionCancel" + id}
                formId={id}
                disabled={showActionInProgress}
                actionButton={actionButton}
                onCancel={onCancel}
              />
            </div>
          )}
        </Form>
      </SpinnerCard>
    );
  },
);
