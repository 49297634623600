import { HttpStatusCode } from "@whyuz/data";
import { GQLError, GQLErrorStatusCode, useErrorTranslation } from "@whyuz/services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ControlledError } from "./ControledError.tsx";
import { Error400BadRequest } from "./Error400BadRequest";
import { Error401AccessDenied } from "./Error401AccessDenied.tsx";
import { Error403Forbidden } from "./Error403Forbidden";
import { Error404PageNotFound } from "./Error404PageNotFound";
import { Error500InternalServerError } from "./Error500InternalServerError";
import { GenericError } from "./GenericError.tsx";
import { ErrorEntityNotFound } from "./index.ts";

export interface ErrorPageProps {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error: GQLError | unknown;
  onClose?: () => void;
}

export const ErrorPage = ({ error, onClose }: ErrorPageProps) => {
  const errorTranslation = useErrorTranslation();
  const { t } = useTranslation();

  const gqlError: GQLError = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const isGQLError = (error: GQLError | unknown | undefined): boolean => {
      return (
        (error as GQLError) &&
        (error as GQLError).message !== undefined &&
        (error as GQLError).fieldErrors !== undefined
      );
    };

    if (error && !isGQLError(error)) {
      console.log("Not GQLError shown", { error });
      return { fieldErrors: {}, isUncontrolledError: true, message: String(error) };
    } else {
      return error as GQLError;
    }
  }, [error]);

  const getUncontroledErrorPage = (uncontroledError: GQLError) => {
    const uncontroledErrorMessage = errorTranslation.getErrorMessage(uncontroledError);
    switch (uncontroledError.statusCode) {
      case HttpStatusCode.BAD_REQUEST:
        return <Error400BadRequest onClose={onClose} />;
      case HttpStatusCode.UNAUTHORIZED:
        return <Error401AccessDenied onClose={onClose} />;
      case HttpStatusCode.FORBIDDEN:
        return <Error403Forbidden onClose={onClose} />;
      case HttpStatusCode.NOT_FOUND:
        return <Error404PageNotFound onClose={onClose} />;
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
        return <Error500InternalServerError onClose={onClose} />;
      case GQLErrorStatusCode.DATA_INTEGRITY_ERROR:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.dataintegrityerror")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
      case GQLErrorStatusCode.MAIL_AUTHENTICATION_ERROR:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.mailauthenticationerror")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
      case GQLErrorStatusCode.TENANT_UNKNOWN:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.tenantunknown")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
      case GQLErrorStatusCode.TOKEN_EXPIRED:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.tokenexpired")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
      case GQLErrorStatusCode.VALIDATION_ERROR:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.validationfailed")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
      case GQLErrorStatusCode.ENTITY_NOT_FOUND:
        return <ErrorEntityNotFound description={uncontroledError.message} onClose={onClose} />;
      default:
        return (
          <GenericError
            errorNumber={uncontroledError.statusCode as number}
            title={t("errors.unknown")}
            description={uncontroledErrorMessage.message ?? uncontroledError.message}
            error={uncontroledError}
            onClose={onClose}
          />
        );
    }
  };

  return gqlError.isUncontrolledError ? (
    getUncontroledErrorPage(gqlError)
  ) : (
    <ControlledError error={gqlError} onClose={onClose} />
  );
};
