import { ChatMember, Contact, User, UserLicensePersonalInformation } from "@whyuz/services";
import { isEmpty } from "@whyuz/utils";

export const formatUserName = (
  user: User | UserLicensePersonalInformation | Contact | ChatMember | undefined,
  includeMail = false,
): string => {
  if (!user) {
    return "NN";
  }
  const isLastNameEmpty = isEmpty(user.lastName);
  const isFirstNameEmpty = isEmpty(user.firstName);
  if (!isLastNameEmpty && !isFirstNameEmpty) {
    return String(user.lastName) + ", " + String(user.firstName) + (includeMail ? " (" + String(user.mail) + ")" : "");
  } else {
    return user.mail ?? "NN"; // UNKNOWN should never happen
  }
};

export const getUserFullNameAndEmail = (
  user: User | UserLicensePersonalInformation | Contact | ChatMember,
): React.ReactNode => {
  const firstName = user.firstName;
  const lastName = user.lastName;
  if (!firstName && !lastName) {
    return <div className="text-xs text-gray-400">{`<${user.mail}>`}</div>;
  } else if (firstName && lastName) {
    return (
      <div className="flex space-x-1 items-center">
        <div className="text-sm font-semibold dark:text-white">{firstName + " " + lastName}</div>
        <div className="text-xs text-gray-400">{`<${user.mail}>`}</div>
      </div>
    );
  } else if (firstName) {
    return (
      <div className="flex space-x-1 items-center">
        <div className="text-sm font-semibold dark:text-white">{firstName}</div>
        <div className="text-xs text-gray-400">{`<${user.mail}>`}</div>
      </div>
    );
  } else {
    return (
      <div className="flex space-x-1">
        <div className="text-sm font-semibold dark:text-white">{lastName}</div>
        <div className="text-xs text-gray-400">{`<${user.mail}>`}</div>
      </div>
    );
  }
};
export const getUserFullName = (
  user: User | UserLicensePersonalInformation | Contact | ChatMember,
): string | undefined => {
  const firstName = user.firstName;
  const lastName = user.lastName;
  if (!firstName && !lastName) {
    return user.mail as string;
  } else if (firstName && lastName) {
    return firstName + " " + lastName;
  } else if (firstName) {
    return firstName;
  } else {
    return lastName as string;
  }
};

export const getUserInitials = (
  user: User | UserLicensePersonalInformation | Contact | ChatMember,
): string | undefined => {
  const firstName = user.firstName;
  const lastName = user.lastName;
  if (isEmpty(firstName) && isEmpty(lastName)) {
    if (user.mail && !isEmpty(user.mail)) {
      const emailParts = user.mail.split("@");
      const [username] = emailParts;
      const initials = username
        .split(".")
        .map((word) => word.charAt(0))
        .join("")
        .toLocaleUpperCase();
      return initials;
    } else {
      return undefined;
    }
  } else if (firstName && lastName && !isEmpty(firstName) && !isEmpty(lastName)) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  } else if (firstName && !isEmpty(firstName)) {
    return firstName.charAt(0).toUpperCase();
  } else {
    return lastName?.charAt(0).toUpperCase();
  }
};
