import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  AddApprovalFlowTemplateMutationVariables,
  ApprovalFlowTemplate,
  ApprovalFlowTemplatePage,
  ApprovalFlowTemplateQueryVariables,
  ApprovalFlowTemplatesQueryVariables,
  DeleteApprovalFlowTemplateMutationVariables,
  UpdateApprovalFlowTemplateMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowTemplateGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useAddApprovalFlowTemplateMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplate, AddApprovalFlowTemplateMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplate, AddApprovalFlowTemplateMutationVariables>(
    ApprovalFlowTemplateGQL.mutation.addApprovalFlowTemplate,
    mutationContext,
  );

export const useUpdateApprovalFlowTemplateMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplate, UpdateApprovalFlowTemplateMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplate, UpdateApprovalFlowTemplateMutationVariables>(
    ApprovalFlowTemplateGQL.mutation.updateApprovalFlowTemplate,
    mutationContext,
  );

export const useDeleteApprovalFlowTemplateMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplate, DeleteApprovalFlowTemplateMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplate, DeleteApprovalFlowTemplateMutationVariables>(
    ApprovalFlowTemplateGQL.mutation.deleteApprovalFlowTemplate,
    mutationContext,
  );

export const useApprovalFlowTemplatesLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplatePage, ApprovalFlowTemplatesQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplatePage, ApprovalFlowTemplatesQueryVariables>(
    ApprovalFlowTemplateGQL.query.approvalFlowTemplates,
    queryContext,
  );

export const useApprovalFlowTemplatesQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplatePage, ApprovalFlowTemplatesQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplatePage, ApprovalFlowTemplatesQueryVariables>(
    ApprovalFlowTemplateGQL.query.approvalFlowTemplates,
    queryContext,
  );

export const useApprovalFlowTemplateLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplate, ApprovalFlowTemplateQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplate, ApprovalFlowTemplateQueryVariables>(
    ApprovalFlowTemplateGQL.query.approvalFlowTemplate,
    queryContext,
  );

export const useApprovalFlowTemplateQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplate, ApprovalFlowTemplateQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplate, ApprovalFlowTemplateQueryVariables>(
    ApprovalFlowTemplateGQL.query.approvalFlowTemplate,
    queryContext,
  );
