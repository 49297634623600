import { ErrorMessage as ErrorMessageComponent } from "@hookform/error-message";
import { RIGHT_BUTTON } from "@whyuz/data";
import { ErrorMessageType, GQLError, useErrorTranslation } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { HelperText } from "flowbite-react";
import { PointerEvent, useMemo, useState } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { ErrorDetails, getErrorMessageTextColor } from "../../Error";

interface ErrorMessageProps {
  errors: {
    formErrors: FieldErrorsImpl<{ [p: string]: any }>;
    GQLerrors: GQLError | undefined;
  };
  name: string;
}

export function ErrorFormMessage({ errors, name }: ErrorMessageProps) {
  const { getMessageFromErrorCode } = useErrorTranslation();
  const [isDetailVisible, setDetailVisible] = useState<boolean>(false);

  const fieldErrorMessage = useMemo(
    () => (errors.GQLerrors ? (getPropertyByPath(errors.GQLerrors.fieldErrors, name) as ErrorMessageType) : undefined),
    [errors.GQLerrors, name],
  );

  return (
    <>
      <ErrorMessageComponent
        errors={errors.formErrors}
        name={name}
        render={({ message }) => <HelperText color="failure">{message}</HelperText>}
      />
      {fieldErrorMessage && !errors.formErrors[name] && (
          <HelperText
            className={errors.GQLerrors ? "cursor-pointer" : ""}
            color={getErrorMessageTextColor(fieldErrorMessage)}
            onPointerDown={(e: PointerEvent) => {
              if (errors.GQLerrors && e.button !== RIGHT_BUTTON) {
                setDetailVisible(() => true);
              }
            }}
          >
            <ErrorDetails error={errors.GQLerrors} show={isDetailVisible} onClose={() => setDetailVisible(() => false)} />
            {getMessageFromErrorCode(fieldErrorMessage)}
          </HelperText>
      )}
    </>
  );
}
