import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import SockJS from "sockjs-client";

type SockJSContextProps = {
  socket?: WebSocket | null;
  connect: () => void;
  disconnect: () => void;
};

export const SockJSContext = createContext<SockJSContextProps | undefined>(undefined);

export interface SockJSContextProviderProps extends React.PropsWithChildren {
  endpoint: string;
}

export const SockJSContextProvider = ({ endpoint, children }: SockJSContextProviderProps) => {
  // Warning: usage of useRef and useState for socket: Ref for internal state, state for external dependencies when socket changes
  const socketRef = useRef<WebSocket | null>(null);
  const [socket, setSocket] = useState<WebSocket>();
  const reconnectionAttemptsRef = useRef<number>(0);
  const isConnectingRef = useRef<boolean>(false);

  const connect = useCallback(
    (forceReconnection = false) => {
      if (isConnectingRef.current && !forceReconnection) {
        return;
      }

      isConnectingRef.current = true;

      // Check if a socket is already open
      if (socketRef.current && socketRef.current.readyState === SockJS.OPEN) {
        return socketRef.current;
      }

      const newSocket = new SockJS(endpoint, null, {
        transports: ["websocket"],
      });
      socketRef.current = newSocket;
      setSocket(newSocket);

      newSocket.onopen = () => {
        if (process.env.NODE_ENV === "development") {
          console.log("SockJS connected");
        }
        isConnectingRef.current = false;
        reconnectionAttemptsRef.current = 0; // Reset reconnection attempts on successful connection
      };

      newSocket.onclose = () => {
        if (process.env.NODE_ENV === "development") {
          console.log("SockJS disconnected");
        }
        socketRef.current = null;
        setSocket(undefined);

        // Reconnect with a delay (e.g., 2^reconnectionAttempts seconds, with a maximum of 60 seconds)
        const reconnectDelay = Math.min(Math.pow(2, reconnectionAttemptsRef.current) * 1000, 60000);
        setTimeout(() => {
          connect(true);
          reconnectionAttemptsRef.current += 1;
          if (process.env.NODE_ENV === "development") {
            console.log("SockJS reconnection attempt: " + reconnectionAttemptsRef.current);
          }
        }, reconnectDelay);
      };

      return newSocket;
    },
    [endpoint],
  );

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setSocket(undefined);
    }
  }, []);

  useEffect(() => {
    // Open the socket connection when the component mounts
    connect();

    return () => {
      // Close the socket connection when the component unmounts
      disconnect();
    };
  }, [connect, disconnect]);

  return <SockJSContext.Provider value={{ socket, connect, disconnect }}>{children}</SockJSContext.Provider>;
};
