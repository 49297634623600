import { SelectMenu, SelectMenuItem } from "@whyuz/components";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { ImFontSize } from "react-icons/im";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTitleProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonHeading = ({ hideOnMobile = true, children }: TextEditorMenuButtonTitleProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const textTypeMenuItems = [
    {
      id: "h1",
      name: <p className="text-4xl font-bold mx-3 block truncate">{t("textTypes.title1")}</p>,
    },
    {
      id: "h2",
      name: <p className="text-2xl font-bold mx-3 block truncate">{t("textTypes.title2")}</p>,
    },
    {
      id: "h3",
      name: <p className="text-xl font-bold mx-3 block truncate">{t("textTypes.title3")}</p>,
    },
    {
      id: "h4",
      name: <p className="text-lg font-bold mx-3 block truncate">{t("textTypes.title4")}</p>,
    },
    {
      id: "p",
      name: <p className="text-base mx-3 block truncate">{t("textTypes.normal")}</p>,
    },
  ];

  const getSelectedHeadingTypeSelectedOnFocus = () => {
    if (editor) {
      const headingLevel = editor.getAttributes("heading").level;
      switch (headingLevel) {
        case 1:
          return "h1";
        case 2:
          return "h2";
        case 3:
          return "h3";
        case 4:
          return "h4";
      }
    }

    return "p";
  };

  const handleHeadingTypeSelected = (item: SelectMenuItem<unknown>) => {
    if (editor) {
      switch (item.id) {
        case "h1":
          editor.chain().focus().toggleHeading({ level: 1 }).run();
          break;
        case "h2":
          editor.chain().focus().toggleHeading({ level: 2 }).run();
          break;
        case "h3":
          editor.chain().focus().toggleHeading({ level: 3 }).run();
          break;
        case "h4":
          editor.chain().focus().toggleHeading({ level: 4 }).run();
          break;
        case "p":
          editor.chain().focus().setParagraph().run();
          break;
        default:
          throw new Error("Text type not expected");
      }
    }
  };

  return (
    <Tooltip content={t("buttons.title")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={textTypeMenuItems}
          selectedItemId={getSelectedHeadingTypeSelectedOnFocus()}
          showSelectedOnButton={false}
          itemNotSelected={children ?? <ImFontSize className={iconSelectStyle} />}
          onChange={handleHeadingTypeSelected}
          textPadding="my-1 p-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          textMargin=""
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={true}
          showChevron={false}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};
