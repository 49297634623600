import { createContext, useCallback, useMemo, useState } from "react";
import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";
import { WebPage, WebPageNavigationContextType } from "./types/WebPageNavigationTypes";

const MAX_PAGES_HISTORY = 100;

export const WebPageNavigationContext = createContext<WebPageNavigationContextType | null>(null);

export const WebPageNavigationContextProvider = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [webPageHistory, setWebPageHistory] = useState<WebPage[]>([{ to: location.pathname }]);

  const navigateTo = useCallback(
    (to: To, options?: NavigateOptions) => {
      setWebPageHistory((prev) => {
        prev.push({ to, options });
        if (prev.length > MAX_PAGES_HISTORY) {
          prev.shift();
        }
        return prev;
      });
      navigate(to, options);
    },
    [navigate],
  );

  const removeLastNPagesFromHistory = useCallback(
    (delta: number) => {
      if (webPageHistory.length > 1) {
        setWebPageHistory((prev) => {
          if (Math.abs(delta) >= prev.length) {
            prev.splice(prev.length - 1); // At least we keep the first page
          } else {
            prev.splice(delta);
          }
          return prev;
        });
      }
    },
    [webPageHistory.length],
  );

  const navigateDelta = useCallback(
    (delta: number) => {
      if (webPageHistory.length > 1) {
        removeLastNPagesFromHistory(delta);
        // WARNING: This is not compatible with searchParams changing and replacing the last page
        // const targetWebPage: WebPage = webPageHistory[webPageHistory.length - 1];
        // navigate(targetWebPage.to, targetWebPage.options);
        navigate(delta);
      }
    },
    [navigate, removeLastNPagesFromHistory, webPageHistory],
  );

  const navigateBack = useCallback(() => navigateDelta(-1), [navigateDelta]);

  const isPossibleNavigateBack = useMemo(() => webPageHistory.length > 1, [webPageHistory.length]);

  function navigateFunction(to: To, options?: NavigateOptions): void;
  function navigateFunction(delta: number): void;
  function navigateFunction(toOrDelta: To | number, options?: NavigateOptions): void {
    if (typeof toOrDelta === "number") {
      navigateDelta(toOrDelta);
    } else {
      navigateTo(toOrDelta, options);
    }
  }

  return (
    <WebPageNavigationContext.Provider
      value={{
        navigate: navigateFunction,
        navigateBack,
        webPageHistory,
        isPossibleNavigateBack,
        removeLastNPagesFromHistory,
      }}>
      {children}
    </WebPageNavigationContext.Provider>
  );
};
