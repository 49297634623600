import { useForwardRef } from "@whyuz/hooks";
import { GQLError } from "@whyuz/services";
import { Label, Textarea, TextareaProps } from "flowbite-react";
import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormMessage } from "./ErrorFormMessage.tsx";

export interface TextAreaFormProps extends Omit<TextareaProps, "onChange" | "onKeyDown"> {
  label?: string;
  id: string;
  validation?: {
    required?: boolean;
    custom?: (model: Record<string, any>) => string | boolean;
  };
  error: GQLError | undefined;
}

export const TextAreaForm = forwardRef<HTMLTextAreaElement, TextAreaFormProps>(
  ({ id, disabled, label, validation, error, className, ...props }: TextAreaFormProps, ref) => {
    const { t } = useTranslation();

    const textAreaRef = useForwardRef<HTMLTextAreaElement>(ref);
    const methods = useFormContext();
    return (
      <div className={className}>
        <Controller
          rules={{
            required: validation?.required ? t("errors.fieldrequired") : false,
            validate: () => (validation?.custom ? validation?.custom(methods.getValues()) : undefined),
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              {label && (
                <Label htmlFor={id} className="mb-2 text-xs font-normal text-gray-900 select-none">
                  {label}
                </Label>
              )}
              <Textarea {...props} ref={textAreaRef} value={value as string} onChange={(e) => onChange(e)} />
            </div>
          )}
          name={id}
          control={methods.control}
        />
        <ErrorFormMessage errors={{ formErrors: methods.formState.errors, GQLerrors: error }} name={id} />
      </div>
    );
  },
);
