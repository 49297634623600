import { graphql } from "../../codegen/gql";

const approvalFlowTemplate = graphql(`
  query approvalFlowTemplate($id: UUID!) {
    approvalFlowTemplate(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      mailAccount {
        id
      }
      steps {
        id
        stepNumber
        requiredAll
        messageHtml
        subjectHtml
        users {
          id
          name
          email
          user {
            id
            mail
          }
        }
      }
    }
  }
`);

const approvalFlowTemplates = graphql(`
  query approvalFlowTemplates(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowTemplates(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        name
        type
        messageHtml
        subjectHtml
        mailAccount {
          id
        }
        steps {
          id
          stepNumber
          requiredAll
          messageHtml
          subjectHtml
          users {
            id
            name
            user {
              id
              mail
            }
            email
          }
        }
      }
    }
  }
`);

const addApprovalFlowTemplate = graphql(`
  mutation addApprovalFlowTemplate($approvalFlowTemplate: ApprovalFlowTemplateInput!) {
    addApprovalFlowTemplate(approvalFlowTemplate: $approvalFlowTemplate) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      mailAccount {
        id
      }
      steps {
        id
        stepNumber
        requiredAll
        messageHtml
        subjectHtml
        users {
          id
          name
          user {
            id
            mail
          }
          email
        }
      }
    }
  }
`);

const updateApprovalFlowTemplate = graphql(`
  mutation updateApprovalFlowTemplate($id: UUID!, $approvalFlowTemplate: ApprovalFlowTemplateInput!) {
    updateApprovalFlowTemplate(id: $id, approvalFlowTemplate: $approvalFlowTemplate) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      messageHtml
      subjectHtml
      mailAccount {
        id
      }
    }
  }
`);

const deleteApprovalFlowTemplate = graphql(`
  mutation deleteApprovalFlowTemplate($id: UUID!) {
    deleteApprovalFlowTemplate(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      type
      mailAccount {
        id
      }
      messageHtml
      subjectHtml
    }
  }
`);

export const ApprovalFlowTemplateGQL = {
  query: { approvalFlowTemplate, approvalFlowTemplates },
  mutation: {
    addApprovalFlowTemplate,
    updateApprovalFlowTemplate,
    deleteApprovalFlowTemplate,
  },
};
