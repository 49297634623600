import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { ChatBubble, CustomHTMLEditorParser, DeleteConfirmationModal, useModal } from "@whyuz/components";
import { RIGHT_BUTTON } from "@whyuz/data";
import { ChatMember, ChatMessage } from "@whyuz/services";
import { getLocalesTimeStringHHMM } from "@whyuz/utils";
import { Dropdown } from "flowbite-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UpdateMessageModal } from ".";
import { UserAvatar, formatUserName } from "../../Users";

export interface ChatMessageBubbleProps {
  chatMessage: ChatMessage;
  position: "left" | "right";
  color: "blue" | "gray";
  onUpdateMessage: (messageId: string, textHTML: string) => void;
  onDeleteMessage: (messageId: string) => void;
  disabled?: boolean;
}

export const ChatMessageBubble = ({
  position,
  color,
  chatMessage,
  onUpdateMessage,
  onDeleteMessage,
  disabled,
}: ChatMessageBubbleProps) => {
  const { t: tChat } = useTranslation("chat");

  const {
    showModal: showUpdateMessageModal,
    closeModal: closeUpdateMessageModal,
    modalProps: updateMessageModalProps,
  } = useModal();

  const {
    showModal: showDeleteMessageModal,
    closeModal: closeDeleteMessageModal,
    modalProps: deleteMessageModalProps,
  } = useModal();

  const onUpdateMessageModal = useCallback(
    (newMessage: string) => {
      onUpdateMessage(chatMessage.id as string, newMessage);
      closeUpdateMessageModal();
    },
    [chatMessage.id, closeUpdateMessageModal, onUpdateMessage],
  );

  const onAcceptDeleteMessageModal = useCallback(() => {
    onDeleteMessage(chatMessage.id as string);
    closeDeleteMessageModal();
  }, [chatMessage.id, closeDeleteMessageModal, onDeleteMessage]);

  const messageActionsDropdown = useMemo(
    () => (
      <div className="inline-flex self-center items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600">
        {deleteMessageModalProps.show && (
          <DeleteConfirmationModal onAcceptDeletion={onAcceptDeleteMessageModal} {...deleteMessageModalProps} />
        )}
        {updateMessageModalProps.show && (
          <UpdateMessageModal
            modalProps={updateMessageModalProps}
            onSave={onUpdateMessageModal}
            message={chatMessage}
            onClose={closeUpdateMessageModal}
          />
        )}
        <Dropdown
          arrowIcon={false}
          inline={true}
          disabled={disabled}
          label={
            <span className="inline-flex self-center items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-800 dark:focus:ring-gray-600">
              <span className="sr-only">Chat menu</span>
              <EllipsisVerticalIcon className="h-4 w-4 stroke-2 self-center" />
            </span>
          }
          className="select-none">
          <Dropdown.Item
            className="w-24"
            onPointerDown={(e: React.PointerEvent) => {
              if (e.button !== RIGHT_BUTTON) {
                showUpdateMessageModal();
              }
            }}>
            {tChat("updatemessage")}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="w-24"
            onPointerDown={(e: React.PointerEvent) => {
              if (e.button !== RIGHT_BUTTON) {
                showDeleteMessageModal();
              }
            }}>
            {tChat("deletemessage")}
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
    [
      chatMessage,
      closeUpdateMessageModal,
      deleteMessageModalProps,
      disabled,
      onAcceptDeleteMessageModal,
      onUpdateMessageModal,
      showDeleteMessageModal,
      showUpdateMessageModal,
      tChat,
      updateMessageModalProps,
    ],
  );

  return (
    <ChatBubble
      position={position}
      color={color}
      userAvatar={<UserAvatar user={chatMessage.chatMember} />}
      userName={formatUserName(chatMessage.chatMember as ChatMember)}
      hourHHMM={getLocalesTimeStringHHMM(chatMessage.createdOn as string)}
      additionalInformation={
        chatMessage.deleted ? tChat("deletedmessage") : chatMessage.updated ? tChat("updatedmessage") : undefined
      }
      actionButton={chatMessage.deleted ? undefined : messageActionsDropdown}>
      {chatMessage.deleted ? undefined : CustomHTMLEditorParser(chatMessage.textHtml || "")}
    </ChatBubble>
  );
};
