import { SelectMenu, SelectMenuItem } from "@whyuz/components";
import { Tooltip } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CiLineHeight } from "react-icons/ci";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyle, iconSelectStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonLineHeightProps {
  hideOnMobile?: boolean;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonLineHeight = ({
  hideOnMobile = true,
  children,
}: TextEditorMenuButtonLineHeightProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const handleLineHeightSelected = (item: SelectMenuItem<unknown>) => {
    if (editor) {
      editor.chain().focus().setLineHeight(item.id).run();
    }
  };

  const alignMenuItems: SelectMenuItem<unknown>[] = useMemo(
    () => [
      {
        id: "100%",
        name: t("lineheight.normal"),
      },
      // {
      //   id: "125%",
      //   name: t("lineheight.115"),
      //   className: "px-2",
      // },
      {
        id: "150%",
        name: t("lineheight.15"),
      },
      {
        id: "200%",
        name: t("lineheight.double"),
      },
      {
        id: "300%",
        name: t("lineheight.triple"),
      },
    ],
    [t],
  );

  return (
    <Tooltip content={t("buttons.lineheight")}>
      <div className={`mr-[1px] ${hideOnMobile ? "hidden sm:block" : ""}`}>
        <SelectMenu
          menuItems={alignMenuItems}
          itemNotSelected={children ?? <CiLineHeight className={twMerge(iconSelectStyle, "stroke-1")} />}
          onChange={handleLineHeightSelected}
          textPadding="my-1 p-1"
          borderClassName="border-none focus-visible:outline-none focus-visible:ring-gray-800 focus-visible:ring-2"
          textMargin=""
          backgroundClassName={buttonStyle}
          textItemNotSelectedClassName=""
          showSelectedCheck={false}
          showChevron={false}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};
