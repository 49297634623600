/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mergeAttributes, Node } from "@tiptap/core";
import { getNodeContent } from "./extensionUtils";

export interface SvgOptions {
  HTMLAttributes: Record<string, any>;
}

export interface SvgAttributes {
  class?: string;
  style?: string;
  fill?: string;
  height?: string;
  stroke?: string;
  "stroke-width"?: string;
  version?: string;
  viewBox?: string;
  width?: string;
  xmlns?: string;
  "aria-hidden"?: string;
}

export const Svg = Node.create<SvgOptions>({
  name: "svg",
  group: "block",
  atom: true,
  // draggable: true,
  content: "paths*",
  // selectable: true,
  // isolating: true,
  // allowGapCursor: true,
  // defining: true,

  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML: (attributes) => {
          return attributes.class
            ? {
                style: attributes.class,
              }
            : undefined;
        },
      },
      style: {
        default: this.options.HTMLAttributes.style,
      },
      fill: {
        default: this.options.HTMLAttributes.fill,
      },
      height: {
        default: this.options.HTMLAttributes.height,
      },
      stroke: {
        default: this.options.HTMLAttributes.stroke,
      },
      "stroke-width": {
        default: this.options.HTMLAttributes["stroke-width"],
      },
      "aria-hidden": {
        default: this.options.HTMLAttributes["aria-hidden"],
      },
      version: {
        default: this.options.HTMLAttributes.version,
      },
      viewBox: {
        default: this.options.HTMLAttributes.viewBox,
      },
      width: {
        default: this.options.HTMLAttributes.width,
      },
      xmlns: {
        default: this.options.HTMLAttributes.xmlns,
      },
    };
  },

  parseHTML: () => {
    return [
      {
        tag: "svg",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const content = getNodeContent(node);
    return ["svg", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ...content];
  },

  parseDOM: [{ tag: "svg" }],

  toDOM(node: { attrs: Record<string, any> }) {
    return ["svg", mergeAttributes(this.options.HTMLAttributes, node.attrs), 0];
  },

  addOptions: () => {
    return {
      HTMLAttributes: {},
    };
  },
});
