import { useTranslation } from "react-i18next";
import { Button } from "@whyuz/components";

export type ActionButtonType = "accept" | "add" | "create" | "delete" | "edit" | "remove" | "save";

export interface FormActionCancelButtonsProps {
  formId?: string;
  actionButton?: ActionButtonType;
  disabled: boolean;
  onCancel?: (formId?: string) => void;
}

export const FormActionCancelButtons = ({ formId, actionButton, onCancel }: FormActionCancelButtonsProps) => {
  const { t } = useTranslation();

  const getActionButtonText = () => {
    switch (actionButton) {
      case "accept":
        return t("buttons.accept");
      case "add":
        return t("buttons.add");
      case "create":
        return t("buttons.create");
      case "delete":
        return t("buttons.delete");
      case "edit":
        return t("buttons.edit");
      case "remove":
        return t("buttons.remove");
      case "save":
        return t("buttons.save");
      default:
        return t("buttons.accept");
    }
  };

  return (
    <div className="flex space-x-2 justify-center">
      <Button key={"ActionButtonForm" + (formId ?? "")}>
        {getActionButtonText()}
      </Button>
      <Button
        key={"CancelButtonForm" + (formId ?? "")}
        onPointerDown={ () => {
          if (onCancel) {
            onCancel(formId);
          }
        }}>
        {t("buttons.cancel")}
      </Button>
    </div>
  );
};
