import React from "react";
import { SpinnerColorType, SpinnerIcon } from "./SpinnerIcon.tsx";

export interface SpinnerCardProps {
  showSpinner: boolean;
  color?: SpinnerColorType;
  children: React.ReactNode;
}

export const SpinnerCard = ({ showSpinner, color, children }: SpinnerCardProps) => {
  return (
    <div className={`${showSpinner ? "relative block select-none rounded-lg pointer-events-none" : ""}`}>
      <div className={`${showSpinner ? "bg-gray-200 opacity-30" : ""}`}>{children}</div>
      <div
        role="status"
        className={`${showSpinner ? "absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" : ""}`}>
        {showSpinner && <SpinnerIcon color={color} />}
      </div>
    </div>
  );
};
