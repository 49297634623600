import { AdvancedTableState } from "../index.ts";
import { EntityDictionary } from "@whyuz/types";
import { Page } from "../../Page";
import { EntityId } from "./AdvancedTableState.ts";

export interface AdvancedTableDeleteEntitiesButtonProps<T extends EntityId, Filter extends T> {
  text: string;
  showText?: boolean;
  hiddenOnMobile?: boolean;
  tableState: AdvancedTableState<T, Filter>;
  onDelete: (entitiesToDelete: EntityDictionary<T>) => void;
}

export const AdvancedTableDeleteEntitiesButton = <T extends EntityId, Filter extends T>({
  text,
  showText,
  hiddenOnMobile = true,
  tableState,
  onDelete,
}: AdvancedTableDeleteEntitiesButtonProps<T, Filter>) => {
  return (
    <Page.DeleteEntityButton
      text={text}
      showText={showText}
      disabled={Object.keys(tableState.selectedEntities).length === 0}
      hiddenOnMobile={hiddenOnMobile}
      onDeleteEntity={() => onDelete(tableState.selectedEntities)}
    />
  );
};
