import { BaseEntity } from "@whyuz/types";
import { ButtonProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { EntityInfoModal, useModal } from "../Modal/index.ts";
import { PageInfoButton } from "./PageInfoButton.tsx";

export interface PageModalEntityInfoButtonProps extends ButtonProps {
  entity: BaseEntity;
  detail: React.ReactNode;
}

export const PageModalEntityInfoButton = ({ entity, detail, ...props }: PageModalEntityInfoButtonProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <>
      <PageInfoButton
        text={t("buttons.additionalinformation")}
        showText={false}
        onInfoClick={() => modal.showModal()}
        {...props}
      />
      <EntityInfoModal entity={entity} detail={detail} {...modal} />
    </>
  );
};
