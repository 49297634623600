import { Spinner } from "flowbite-react";
import "../styles/tailwind.css";

export const Loading = () => (
  <section className="bg-white dark:bg-gray-900 h-[calc(100vh-72px)] flex items-center">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <Spinner />
    </div>
  </section>
);
