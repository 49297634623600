import { RecursivePartial } from "@whyuz/utils";
import { FlowbiteCheckboxTheme, theme as FlowbiteDefaultTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteCheckboxThemes {
  default = "default",
}

const FlowbiteDefaultCheckboxTheme = FlowbiteDefaultTheme.checkbox;

export const FlowbiteCheckboxThemes: Record<EnumFlowbiteCheckboxThemes, RecursivePartial<FlowbiteCheckboxTheme>> = {
  default: {
    root: {
      base: twMerge(
        FlowbiteDefaultCheckboxTheme.root.base,
        "text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 cursor-pointer",
      ),
      color: {
        default: twMerge(
          FlowbiteDefaultCheckboxTheme.root.color.default,
          "focus:ring-primary-600 dark:focus:ring-primary-600 text-primary-600",
        ),
      },
    },
  },
};
