import { ArchiveBoxIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Dropdown } from "flowbite-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeleteConfirmationModal, useModal } from "../../Modal";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";

export interface MultipleSelectionActionDropdownProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
  onEdit?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
}

export const AdvancedTableMultipleSelectionActionDropdown = <T extends EntityId, Filter extends T>({
  tableState,
  onEdit,
  onArchive,
  onDelete,
  children,
}: MultipleSelectionActionDropdownProps<T, Filter>) => {
  const { t } = useTranslation();
  const {
    showModal: showDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
    modalProps: deleteConfirmationModalProps,
  } = useModal();

  const itemsDescription = useMemo(() => {
    return Object.values(tableState.selectedEntities).map((entity) =>
      tableState.tableConfiguration ? tableState.tableConfiguration.entityDescription(entity) : t("errors.unknown"),
    );
  }, [t, tableState.selectedEntities, tableState.tableConfiguration]);

  return (
    <div className="hidden md:block">
      {(onEdit || onArchive || onDelete || children) && (
        <Dropdown
          disabled={Object.keys(tableState.selectedEntities).length === 0}
          arrowIcon={false}
          label={
            <span className="inline-flex">
              <ChevronDownIcon className="h-5 w-5 mr-2" />
              {t("word.actions")}
            </span>
          }
          color="light"
          size="sm">
          {onEdit && (
            <Dropdown.Item
              onPointerDown={(e: React.PointerEvent) => {
                if (onEdit && e.button !== RIGHT_BUTTON) {
                  onEdit();
                }
              }}>
              <PencilSquareIcon className="h-5 w-5 mr-2" />
              {t("buttons.edit")}
            </Dropdown.Item>
          )}
          {onArchive && (
            <Dropdown.Item
              onPointerDown={(e: React.PointerEvent) => {
                if (onArchive && e.button !== RIGHT_BUTTON) {
                  onArchive();
                }
              }}>
              <ArchiveBoxIcon className="h-5 w-5 mr-2" />
              {t("buttons.archive")}
            </Dropdown.Item>
          )}
          {onDelete && (
            <Dropdown.Item
              className="text-red-600"
              onPointerDown={(e: React.PointerEvent) => {
                if (e.button !== RIGHT_BUTTON) {
                  showDeleteConfirmationModal();
                }
              }}>
              <TrashIcon className="h-5 w-5 mr-2" />
              {t("buttons.delete")}
            </Dropdown.Item>
          )}
          {children}
        </Dropdown>
      )}
      {onDelete && (
        <DeleteConfirmationModal
          itemsDescription={itemsDescription}
          onAcceptDeletion={() => {
            closeDeleteConfirmationModal();
            onDelete();
          }}
          {...deleteConfirmationModalProps}
        />
      )}
    </div>
  );
};
