import { Language, useKeycloak, useLanguagesLazyQuery } from "@whyuz/services";
import { getPropertyByPath, notifyError } from "@whyuz/utils";
import { createContext, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTranslations } from "..";

export type LanguagesContextType = {
  languages: Language[];
  searchLanguages: (searchText: string | undefined | null) => Language[];
  languageTranslation: (language: Language) => string;
};

export const LanguagesContext = createContext<LanguagesContextType | null>(null);

export const LanguagesContextProvider = ({ children }: React.PropsWithChildren) => {
  const [languagesQuery] = useLanguagesLazyQuery();
  const languagesRef = useRef<Language[]>([]);
  const { i18n } = useTranslation();
  const languageTranslation = useLanguageTranslations();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (languagesRef.current.length === 0 && keycloak.authenticated) {
      languagesQuery() // Query execution to get all the languages in memory
        .then((page) => {
          if (page.content) {
            languagesRef.current = page.content as Language[];
          }
        })
        .catch((e) => notifyError(String(e)));
    }
  }, [keycloak.authenticated, languagesQuery]);

  const searchLanguages = useCallback(
    (searchText: string | undefined | null) => {
      const languageFieldToFilter = i18n.language === "en" ? "name" : i18n.language;

      if (!searchText) {
        return languagesRef.current;
      }

      return languagesRef.current
        .filter((language) => {
          const translatedLanguageName = getPropertyByPath(language, languageFieldToFilter) as string;
          const lowerCaseSearchText = searchText.toLowerCase();
          return (
            language.id.toLowerCase().includes(lowerCaseSearchText) ||
            (translatedLanguageName && translatedLanguageName.toLowerCase().includes(lowerCaseSearchText))
          );
        })
        .sort((lang1, lang2) => {
          const translatedLanguageName1 = getPropertyByPath(lang1, languageFieldToFilter) as string;
          const translatedLanguageName2 = getPropertyByPath(lang2, languageFieldToFilter) as string;
          if (!translatedLanguageName1) {
            return -1;
          }
          if (!translatedLanguageName2) {
            return 1;
          }
          return translatedLanguageName1.localeCompare(translatedLanguageName2);
        });
    },
    [i18n.language],
  );

  return (
    <LanguagesContext.Provider value={{ languages: languagesRef.current, searchLanguages, languageTranslation }}>
      {children}
    </LanguagesContext.Provider>
  );
};
