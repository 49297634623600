import { ToggleSwitch } from "flowbite-react";
import { GQLError } from "@whyuz/services";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField.tsx";

export interface PageFormToggleFieldProps {
  id: string;
  label: string;
  labelBold?: boolean;
  checked: boolean;
  disabled?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  error?: GQLError;
  onChange?: (checked: boolean) => void;
}

export const PageFormToggleField = ({
  id,
  label,
  labelBold = true,
  checked,
  disabled,
  columns,
  columnsBreakpointSM = true,
  error,
  onChange,
}: PageFormToggleFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyDummyFunction = () => {}; // This is needed until ToggleSwitch onChange will be optional

  return (
    <PageFormCustomEditableField
      id={id}
      label={label}
      labelBold={labelBold}
      error={error}
      columns={columns}
      columnsBreakpointSM={columnsBreakpointSM}>
      <div className={labelBold ? "py-2" : undefined}>
        <ToggleSwitch
          id={id}
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={onChange ?? emptyDummyFunction}
        />
      </div>
    </PageFormCustomEditableField>
  );
};
