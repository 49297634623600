import Paragraph from "@tiptap/extension-paragraph";

export const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...Paragraph.config.addAttributes,
      class: { default: this.options.HTMLAttributes.class },
      style: { default: this.options.HTMLAttributes.style },
    };
  },
});
