import { COLOROBJECTSRGB } from "@whyuz/data";
import { equalRgbColors, rgba2RgbaString } from "@whyuz/utils";
import { RgbaColor } from "react-colorful";

interface Props {
  selectedColor: RgbaColor;
  onChange: (color: RgbaColor) => void;
}

export const DiscreteColorPicker = ({ selectedColor, onChange }: Props) => {
  return (
    <div className="flex flex-wrap w-[200px]">
      {COLOROBJECTSRGB.map((color) => {
        const isSelectedColor = equalRgbColors(color.rgba, selectedColor);
        return (
          <div className="relative inline-block" key={color.id}>
            <button
              className={`w-4 h-4 m-[2px] shadow-sm select-none border hover:border-black ${
                isSelectedColor ? "ring-black ring-1" : ""
              }`}
              style={{ background: rgba2RgbaString(color.rgba) }}
              onPointerDown={() => onChange(color.rgba)}
            />
          </div>
        );
      })}
    </div>
  );
};
