import { useGQLLazyQuery, useGQLQuery } from "../";
import { CountriesQueryVariables, CountryPage } from "../../codegen/graphql.ts";
import { CountryGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useCountriesLazyQuery = (queryContext?: GQLQueryContext<CountryPage, CountriesQueryVariables>) =>
  useGQLLazyQuery<CountryPage, CountriesQueryVariables>(CountryGQL.query.countries, queryContext);

export const useCountriesQuery = (queryContext?: GQLQueryContext<CountryPage, CountriesQueryVariables>) =>
  useGQLQuery<CountryPage, CountriesQueryVariables>(CountryGQL.query.countries, queryContext);
