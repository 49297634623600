import { graphql } from "../../codegen/gql";

const authenticatedUserSession = graphql(`
  query authenticatedUserSession {
    authenticatedUserSession {
      sessionToken
      userLicense {
        id
        mail
        personalInfo {
          firstName
          lastName
          pictureURL
          mail
          phone
        }
        country {
          id
          name
          es
          it
          fr
          de
          pt
        }
        allowInformationMails
        acceptanceDateTermsOfService
        locale
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        currentRole {
          id
          lastLogin
          lastLogout
          role
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          tenant {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
            corporationLicense {
              id
              name
              domain
              deleted
              createdOn
              updatedOn
              createdBy
              updatedBy
            }
          }
        }
        roles {
          id
          lastLogin
          lastLogout
          role
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          tenant {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
            corporationLicense {
              id
              name
              domain
              deleted
              createdOn
              updatedOn
              createdBy
              updatedBy
            }
          }
        }
        administeredCorporations {
          id
          name
          domain
          createdOn
          updatedOn
          createdBy
          updatedBy
          deleted
          administrators {
            id
            mail
            locale
            enabled
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
          tenants {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
    }
  }
`);

const loginAuthenticatedUserLicense = graphql(`
  mutation loginAuthenticatedUserLicense($tenantId: UUID) {
    loginAuthenticatedUserLicense(tenantId: $tenantId) {
      sessionToken
      userLicense {
        id
        mail
        personalInfo {
          firstName
          lastName
          pictureURL
          mail
          phone
        }
        country {
          id
          name
          es
          it
          fr
          de
          pt
        }
        allowInformationMails
        acceptanceDateTermsOfService
        locale
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        currentRole {
          id
          lastLogin
          lastLogout
          role
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          tenant {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
            corporationLicense {
              id
              name
              domain
              deleted
              createdOn
              updatedOn
              createdBy
              updatedBy
            }
          }
        }
        roles {
          id
          lastLogin
          lastLogout
          role
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          tenant {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
            corporationLicense {
              id
              name
              domain
              deleted
              createdOn
              updatedOn
              createdBy
              updatedBy
            }
          }
        }
        administeredCorporations {
          id
          name
          domain
          createdOn
          updatedOn
          createdBy
          updatedBy
          deleted
          administrators {
            id
            mail
            locale
            enabled
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
          tenants {
            id
            name
            logoAssetToken
            country {
              id
              name
              es
              it
              fr
              de
              pt
              region {
                id
                name
              }
            }
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
    }
  }
`);

const logoutAuthenticatedUserLicense = graphql(`
  mutation logoutAuthenticatedUserLicense {
    logoutAuthenticatedUserLicense {
      id
      mail
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
    }
  }
`);

const acceptTermsOfService = graphql(`
  mutation acceptTermsOfService {
    acceptTermsOfService {
      id
      mail
      personalInfo {
        firstName
        lastName
        pictureURL
        mail
        phone
      }
      country {
        id
        name
        es
        it
        fr
        de
        pt
      }
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
      currentRole {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      roles {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      administeredCorporations {
        id
        name
        domain
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        administrators {
          id
          mail
          locale
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
        tenants {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
      }
    }
  }
`);

const updateUserLicenseLocale = graphql(`
  mutation updateUserLicenseLocale($locale: String!) {
    updateUserLicenseLocale(locale: $locale) {
      id
      mail
      personalInfo {
        firstName
        lastName
        pictureURL
        mail
        phone
      }
      country {
        id
        name
        es
        it
        fr
        de
        pt
      }
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
      currentRole {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      roles {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      administeredCorporations {
        id
        name
        domain
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        administrators {
          id
          mail
          locale
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
        tenants {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
      }
    }
  }
`);

const userLicenses = graphql(`
  query userLicenses(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    userLicenses(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        mail
        personalInfo {
          firstName
          lastName
          pictureURL
          mail
          phone
        }
        country {
          id
          name
          es
          it
          fr
          de
          pt
        }
        allowInformationMails
        acceptanceDateTermsOfService
        locale
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
      }
    }
  }
`);

const userLicense = graphql(`
  query userLicense($id: UUID!) {
    userLicense(id: $id) {
      id
      mail
      personalInfo {
        firstName
        lastName
        pictureURL
        mail
        phone
      }
      country {
        id
        name
        es
        it
        fr
        de
        pt
      }
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
      currentRole {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      roles {
        id
        userLicense {
          id
          mail
          locale
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
    }
  }
`);

const addUserLicense = graphql(`
  mutation addUserLicense($userLicense: UserLicenseInput!) {
    addUserLicense(userLicense: $userLicense) {
      id
      mail
      personalInfo {
        firstName
        lastName
        pictureURL
        mail
        phone
      }
      country {
        id
        name
        es
        it
        fr
        de
        pt
      }
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
    }
  }
`);

const updateUserLicense = graphql(`
  mutation updateUserLicense($id: UUID!, $userLicense: UserLicenseInput!) {
    updateUserLicense(id: $id, userLicense: $userLicense) {
      id
      mail
      personalInfo {
        firstName
        lastName
        pictureURL
        mail
        phone
      }
      country {
        id
        name
        es
        it
        fr
        de
        pt
      }
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
      currentRole {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      roles {
        id
        lastLogin
        lastLogout
        role
        enabled
        deleted
        createdOn
        updatedOn
        createdBy
        updatedBy
        tenant {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
          corporationLicense {
            id
            name
            domain
            deleted
            createdOn
            updatedOn
            createdBy
            updatedBy
          }
        }
      }
      administeredCorporations {
        id
        name
        domain
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        administrators {
          id
          mail
          locale
          enabled
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
        tenants {
          id
          name
          logoAssetToken
          country {
            id
            name
            es
            it
            fr
            de
            pt
            region {
              id
              name
            }
          }
          deleted
          createdOn
          updatedOn
          createdBy
          updatedBy
        }
      }
    }
  }
`);

const deleteUserLicense = graphql(`
  mutation deleteUserLicense($id: UUID!) {
    deleteUserLicense(id: $id) {
      id
      mail
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
    }
  }
`);

const deleteUserLicenses = graphql(`
  mutation deleteUserLicenses($ids: [UUID]!) {
    deleteUserLicenses(ids: $ids) {
      id
      mail
      allowInformationMails
      acceptanceDateTermsOfService
      locale
      enabled
      deleted
      createdOn
      updatedOn
      createdBy
      updatedBy
    }
  }
`);

export const UserLicenseGQL = {
  query: { authenticatedUserSession, userLicenses, userLicense },
  mutation: {
    loginAuthenticatedUserLicense,
    logoutAuthenticatedUserLicense,
    acceptTermsOfService,
    addUserLicense,
    updateUserLicenseLocale,
    updateUserLicense,
    deleteUserLicense,
    deleteUserLicenses,
  },
};
