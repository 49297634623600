import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Usage example:

// <GoogleFontLoader
//       fonts={[
//         {
//           font: 'Roboto',
//           weights: [400, '400i'],
//         },
//         {
//           font: 'Roboto Mono',
//           weights: [400, 700],
//         },
//       ]}
//       subsets={['cyrillic-ext', 'greek']}
//     />

//     <p style={{ fontFamily: 'Roboto Mono, monospaced' }}>This will be in Roboto Mono!</p>
//     <p style={{ fontFamily: 'Roboto, sans-serif' }}>This will be in Roboto!</p>

export interface Font {
  font: string;
  weights?: (string | number)[];
}

export type DisplayType = "auto" | "block" | "swap" | "fallback" | "optional";

export interface GoogleFontLoaderProps {
  fonts: Font[];
  subsets?: string[];
  display?: DisplayType;
}

const createLink = (fonts: Font[], subsets?: string[], display?: DisplayType) => {
  const families = fonts
    .reduce((prev, font) => {
      const family = font.font.replace(/ +/g, "+");
      const weights = (font.weights || []).join(",");

      return [...prev, family + (weights && `:${weights}`)];
    }, [] as string[])
    .join("|");

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `https://fonts.googleapis.com/css?family=${families}`;

  if (subsets && Array.isArray(subsets) && subsets.length > 0) {
    link.href += `&subset=${subsets.join(",")}`;
  }

  if (display) {
    link.href += `&display=${display}`;
  }

  return link;
};

const GoogleFontLoader = ({ fonts, subsets, display = undefined }: GoogleFontLoaderProps) => {
  const [link, setLink] = useState(createLink(fonts, subsets, display));

  useEffect(() => {
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [link]);

  useEffect(() => {
    setLink(createLink(fonts, subsets, display));
  }, [fonts, subsets, display]);

  return null;
};

GoogleFontLoader.propTypes = {
  fonts: PropTypes.arrayOf(
    PropTypes.shape({
      font: PropTypes.string.isRequired,
      weights: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    }),
  ).isRequired,
  subsets: PropTypes.arrayOf(PropTypes.string),
  display: PropTypes.string,
};

export default GoogleFontLoader;
