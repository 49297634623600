import { useForwardRef } from "@whyuz/hooks";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { Textarea } from "flowbite-react";
import { ChangeEvent, forwardRef, KeyboardEvent, useMemo } from "react";
import { getErrorMessageTextColor } from "../Error";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField.tsx";

export interface PageFormTextAreaFieldProps {
  id: string;
  label?: string;
  labelBold?: boolean;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  error: GQLError | undefined;
  onChange: (newValue: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  classnames?: string;
}

export const PageFormTextAreaField = forwardRef<HTMLTextAreaElement, PageFormTextAreaFieldProps>(
  (
    {
      id,
      label,
      labelBold = true,
      placeholder,
      value,
      disabled,
      columns,
      columnsBreakpointSM = true,
      error,
      onChange,
      onKeyDown,
      autoFocus = false,
      classnames,
    }: PageFormTextAreaFieldProps,
    ref,
  ) => {
    const textAreaRef = useForwardRef<HTMLTextAreaElement>(ref);
    // GetPropertyByPath used to avoid case sensitive field name errors
    const fieldErrorMessage = useMemo(
      () => (error ? (getPropertyByPath(error.fieldErrors, id) as ErrorMessageType) : undefined),
      [error, id],
    );

    return (
      <PageFormCustomEditableField
        id={id}
        label={label}
        labelBold={labelBold}
        error={error}
        columns={columns}
        columnsBreakpointSM={columnsBreakpointSM}>
        <Textarea
          id={id}
          ref={textAreaRef}
          color={getErrorMessageTextColor(fieldErrorMessage)}
          placeholder={placeholder}
          value={value ?? ""}
          autoComplete="off"
          disabled={disabled}
          onChange={(e) => onChange(e.target.value, e)}
          onKeyDown={onKeyDown ? (e) => onKeyDown(e) : undefined}
          autoFocus={autoFocus}
          className={classnames}
        />
      </PageFormCustomEditableField>
    );
  },
);
