import { useForwardRef } from "@whyuz/hooks";
import { ErrorMessageType, GQLError } from "@whyuz/services";
import React, { forwardRef } from "react";
import { ErrorMessage } from "..";

export interface TextAreaProps {
  id?: string;
  name?: string;
  value?: string | number | readonly string[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: { message?: ErrorMessageType; details?: GQLError };
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string | number | readonly string[];
  fullWidth?: boolean;
  showRingWhenSelected?: boolean;
  showBorder?: boolean;
  spellCheck?: boolean;
  resize?: "none" | "vertical" | "horizontal" | "both";
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const getResize = (resize?: "none" | "vertical" | "horizontal" | "both") => {
  switch (resize) {
    case "vertical":
      return "resize-y";
    case "horizontal":
      return "resize-x";
    case "none":
      return "resize-none";
    default:
    case "both":
      return "resize";
  }
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      name,
      value,
      label,
      placeholder,
      disabled = false,
      error,
      autoComplete = "off",
      autoFocus = false,
      defaultValue,
      fullWidth = false,
      showRingWhenSelected = true,
      showBorder = true,
      spellCheck = false,
      resize,
      onChange,
    }: TextAreaProps,
    ref,
  ) => {
    const textAreaRef = useForwardRef<HTMLTextAreaElement>(ref);
    return (
      <ErrorMessage error={error?.message} details={error?.details}>
        <div>
          {label && (
            <label htmlFor={id} className={`block text-sm font-medium text-gray-800 pt-2 pb-1 select-none truncate`}>
              {label}
            </label>
          )}
          <textarea
            id={id}
            name={name}
            value={value}
            defaultValue={defaultValue}
            ref={textAreaRef}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            placeholder={disabled ? "" : placeholder}
            onChange={onChange}
            disabled={disabled}
            spellCheck={spellCheck}
            className={`relative self-center bg-white cursor-text block ${fullWidth ? "w-full" : ""} ${
              disabled && !showBorder ? "" : "px-3"
            } ${
              showBorder ? "border " + (disabled ? "border-gray-300" : "border-gray-500") : "border-none"
            } placeholder-gray-400 ${
              disabled ? "text-gray-500" + (showBorder ? " bg-gray-100" : " bg-transparent") : "text-gray-800"
            } rounded-md focus-visible:outline-none ${
              showRingWhenSelected
                ? " focus-visible:ring-primary-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                : ""
            } sm:text-sm text-ellipsis ${getResize(resize)} min-h-[30px] py-1`}>
            TextArea
          </textarea>
        </div>
      </ErrorMessage>
    );
  },
);
