import { RecursivePartial } from "@whyuz/utils";
import { FlowbiteButtonTheme, theme as FlowbiteDefaultTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

enum EnumFlowbiteButtonThemes {
  default = "default",
}

const FlowbiteDefaultButtonTheme = FlowbiteDefaultTheme.button;

export const FlowbiteButtonThemes: Record<EnumFlowbiteButtonThemes, RecursivePartial<FlowbiteButtonTheme>> = {
  default: {
    color: {
      primary: twMerge(
        FlowbiteDefaultButtonTheme.color.blue,
        "bg-primary-600 border-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-700 enabled:hover:bg-primary-700",
      ),
      primaryLight:
        "text-primary-600 bg-white border border-primary-400 enabled:hover:bg-gray-100 focus:ring-4 focus:ring-primary-400 :bg-gray-600 dark:text-primary-600 dark:border-primary-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-primary-700 dark:focus:ring-primary-700",
      red: twMerge(
        FlowbiteDefaultButtonTheme.color.red,
        "bg-[#F8B4C4] text-red-700 border-red-700 enabled:hover:bg-red-100 focus:ring-red-700",
      ),
      failure: twMerge(
        FlowbiteDefaultButtonTheme.color.failure,
        "bg-[#F8B4C4] focus:ring-0 text-red-700 enabled:hover:bg-red-400 dark:bg-[#F8B4C4] dark:focus:ring-0 dark:enabled:hover:bg-red-400",
      ),
      green: twMerge(
        FlowbiteDefaultButtonTheme.color.green,
        "text-white bg-[#84E1BC] border border-transparent enabled:hover:bg-green-500 text-green-700 focus:ring-0 enabled:hover:bg-green-400 dark:border-transparent dark:bg-[#84E1BC] dark:text-green-700 dark:focus:ring-0 dark:enabled:hover:border-transparent dark:enabled:hover:bg-green-500",
      ),
    },
    base: twMerge(FlowbiteDefaultButtonTheme.base, "p-1"),

    size: {
      sm: twMerge(FlowbiteDefaultButtonTheme.size.sm, "min-h-[32px]"),
    },
    outline: {
      color: {
        primary: "border border-primary-600 bg-transparent dark:border-primary-600",
      },
      on: twMerge(
        FlowbiteDefaultButtonTheme.outline.on,
        "bg-transparent text-primary-600 transition-none dark:text-primary-600 w-full",
      ),
    },
  },
};
