import { captureSnapshotFromVideo, downloadFileFromURI, getLocaleTimeStringHHMMSS } from "@whyuz/utils";
import { DetailedHTMLProps, VideoHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { VideoPlayerControls } from "./components/VideoPlayerControls.tsx";
import { VideoTimelineControl } from "./components/VideoTimelineControl.tsx";
import { VideoTrimControls } from "./components/VideoTrimControls.tsx";
import logo from "./images/whyuzwbbg.png";

export interface VideoTrimTimeSeconds {
  startTimeSeconds: number;
  endTimeSeconds: number;
}

export interface VideoPlayerProps extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  showControlsOnHover?: boolean;
  showVideoTimeline?: boolean;
  showVideoTrimControls?: boolean;
  showLogo?: boolean;
  logoSrc?: string;
  autoAdjustHeight?: boolean;
  onVideoTrim?: (trimTimeSeconds: VideoTrimTimeSeconds | undefined) => void;
  disabled?: boolean;
}

export const VideoPlayer = ({
  src,
  className,
  showControlsOnHover = true,
  showVideoTimeline = true,
  showVideoTrimControls = false,
  onVideoTrim,
  showLogo = false,
  logoSrc = logo,
  autoAdjustHeight = true,
  muted,
  disabled = false,
  disablePictureInPicture = true,
  playsInline = true,
  preload = "metadata",
  ...props
}: VideoPlayerProps) => {
  const videoContainerDivRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const logoRef = useRef<HTMLImageElement>(new Image());
  logoRef.current.src = logoSrc;
  const [isMuted, setMuted] = useState<boolean>(muted ?? false);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const [videoProgress, setVideoProgress] = useState<number | undefined>();
  const [videoDurationFormattedTime, setVideoDurationFormattedTime] = useState<string | undefined>();
  const [videoTimeProgressFormatted, setVideoTimeProgressFormated] = useState<string>();
  const [trimVideoStart, setTrimVideoStart] = useState<number>(0);
  const [trimVideoEnd, setTrimVideoEnd] = useState<number>(1);
  // video.playbackrate can be used to change the speed of the video

  useEffect(() => {
    if (videoRef.current) videoRef.current.volume = volume;
  }, [volume]);

  const captureSnapshotAndDownload = () => {
    if (!videoRef.current) return;
    computeFrame();

    const dataURL = captureSnapshotFromVideo(videoRef.current);
    downloadFileFromURI(dataURL, "video-snapshot.png");
  };

  const downloadVideo = () => {
    if (!videoRef.current) return;
    const dataURL = videoRef.current.src;
    downloadFileFromURI(dataURL, "video.mpg");
  };

  const stopVideoPlay = () => {
    if (!videoRef.current) return;

    // Warning: do not change the order of the sequence
    videoRef.current.currentTime = trimVideoStart * videoRef.current.duration;
    if (isPlaying) {
      videoRef.current.pause();
    }
    setPlaying(false);
    setVideoProgress(trimVideoStart);
  };

  const toggleVideoPlay = () => {
    if (!videoRef.current || disabled) return;

    if (isPlaying) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play().catch((e) => alert(e));
      setPlaying(true);
    }
  };

  const computeFrame = useCallback(() => {
    const getVideoTimeProgressFormatted = () => {
      if (!videoRef.current) return undefined;

      if (!videoDurationFormattedTime) {
        if (videoRef.current.duration && videoRef.current.duration !== Infinity) {
          setVideoDurationFormattedTime(getLocaleTimeStringHHMMSS(videoRef.current.duration, false));
        } else {
          setVideoDurationFormattedTime(undefined);
        }
      }

      const currentFormattedTime =
        videoProgress !== undefined
          ? videoRef.current.duration === Infinity
            ? getLocaleTimeStringHHMMSS(videoRef.current.currentTime, false)
            : getLocaleTimeStringHHMMSS(videoProgress * videoRef.current.duration, false)
          : undefined;
      if (currentFormattedTime && videoDurationFormattedTime)
        return currentFormattedTime + " / " + videoDurationFormattedTime;
      else if (!currentFormattedTime && !videoDurationFormattedTime) return undefined;
      else if (currentFormattedTime) return currentFormattedTime;
      else return videoDurationFormattedTime;
    };

    if (!videoRef.current || !videoRef.current.src || !canvasRef.current) return;

    setVideoTimeProgressFormated(getVideoTimeProgressFormatted());

    const aspectRatioVideo = videoRef.current.videoWidth / videoRef.current.videoHeight;
    const canvasWidth = canvasRef.current.width;
    let canvasHeight = canvasRef.current.height;

    // For any reason initially the width and height are different from BoundingClientRect and this affect
    // to the render of the video, so to fix the bug, we force the size here. Even for resizing, we evaluate frame by frame the size
    if (autoAdjustHeight) {
      if (aspectRatioVideo) {
        canvasRef.current.height = canvasWidth / aspectRatioVideo;
        canvasHeight = canvasRef.current.height;
      } else {
        canvasRef.current.height = canvasHeight;
      }
    } else {
      canvasRef.current.height = canvasHeight;
    }

    const aspectRatioCanvas = canvasWidth / canvasHeight;
    let dx = 0;
    let dy = 0;
    let dw = canvasWidth;
    let dh = canvasHeight;
    // When the aspect ratio is not exactly the same, but very close among them, it could be due to the impossibility to split pixels, so to avoid tiny bars, we adjust the video to the canvas size
    if (
      aspectRatioVideo &&
      aspectRatioCanvas &&
      aspectRatioVideo !== aspectRatioCanvas &&
      Math.abs(aspectRatioVideo - aspectRatioCanvas) > 0.01
    ) {
      if (aspectRatioVideo > aspectRatioCanvas) {
        // Horizontal black bars
        dw = canvasWidth;
        dh = canvasWidth / aspectRatioVideo;
        dx = 0;
        dy = (canvasHeight - dh) / 2;
      } else {
        // Vertical black bars
        dw = canvasHeight * aspectRatioVideo;
        dh = canvasHeight;
        dx = (canvasWidth - dw) / 2;
        dy = 0;
      }
    }
    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    // To avoid decimal pixels, we adjust the size to the closest integers
    context.drawImage(videoRef.current, Math.floor(dx), Math.floor(dy), Math.ceil(dw), Math.ceil(dh));

    if (showLogo) {
      const aspectRatioLogo = logoRef.current.naturalWidth / logoRef.current.naturalHeight;
      const proportionLogo = 0.1;
      const logoWidth = canvasRef.current.getBoundingClientRect().width * proportionLogo;
      const logoHeight = logoWidth / aspectRatioLogo;
      const logoMargin = 0.025 * dw;
      context.drawImage(
        logoRef.current,
        dx + dw - logoWidth - logoMargin,
        dy + dh - logoHeight - logoMargin,
        logoWidth,
        logoHeight,
      );
    }
  }, [showLogo, autoAdjustHeight, videoDurationFormattedTime, videoProgress]);

  useEffect(() => {
    if (!isPlaying) {
      // Execution every time the videoProgress changes and the video is stopped
      computeFrame();
    }
  }, [isPlaying, videoProgress, computeFrame, src]);

  useEffect(() => {
    if (isPlaying) {
      // Continuous execution (only when the video starts to play)
      const timerRenderFrameCallback = setInterval(() => window.requestAnimationFrame(computeFrame), 8); // 8ms is around 120Hz as maximum
      return () => {
        clearInterval(timerRenderFrameCallback);
      };
    }
    return;
  }, [isPlaying, computeFrame]);

  const handleVideoProgressChange = (newVideoProgress: number) => {
    setVideoProgress(newVideoProgress);
    if (videoRef.current?.duration) {
      videoRef.current.currentTime = newVideoProgress * videoRef.current.duration;
    }
  };

  const handleVideoLoaded = () => {
    if (!videoRef.current) return;

    if (videoRef.current.duration && videoRef.current.duration !== Infinity) {
      setVideoDurationFormattedTime(getLocaleTimeStringHHMMSS(videoRef.current.duration, false));
    } else {
      setVideoDurationFormattedTime(undefined);
    }
    setVideoProgress(0);
    videoRef.current.currentTime = 0; // This is to force the first frame to be rendered (chrome is not rendering the first frame until there is not an event like a time seek or play)
    setPlaying(false);
  };

  const onVideoTimeUpdate = () => {
    if (videoRef.current && videoRef.current.duration >= 0 && videoRef.current.currentTime >= 0) {
      const currentVideoProgress = videoRef.current.currentTime / videoRef.current.duration;
      setVideoProgress(currentVideoProgress);
      if (videoRef.current?.currentTime && videoRef.current.duration && videoRef.current.duration !== Infinity) {
        if (videoRef.current.currentTime < trimVideoStart * videoRef.current.duration) {
          videoRef.current.currentTime = trimVideoStart * videoRef.current.duration;
        }
        if (videoRef.current.currentTime >= trimVideoEnd * videoRef.current.duration) {
          stopVideoPlay();
        }
      }
    } else {
      setVideoProgress(undefined);
    }
  };

  const handleNewTimeSeeked = () => {
    computeFrame();
  };

  const handleTimeVideoTrimmedChange = (start: number, end: number) => {
    if (videoProgress !== undefined) {
      if (start !== trimVideoStart) {
        setTrimVideoStart(start);
        if (start > videoProgress) {
          handleVideoProgressChange(start);
        }
      }

      if (end !== trimVideoEnd) {
        setTrimVideoEnd(end);
        if (end < videoProgress) {
          handleVideoProgressChange(end);
        }
      }
    }
    if (onVideoTrim && videoRef.current) {
      if (start === 0 && end === 1) {
        onVideoTrim(undefined);
      } else {
        const videoDurationSeconds = videoRef.current.duration;
        if (!isNaN(videoDurationSeconds)) {
          const startTimeSeconds = Math.max(0, videoDurationSeconds * start); /* Start is a value between 0 and 1 */
          const endTimeSeconds = Math.min(
            videoDurationSeconds,
            videoDurationSeconds * end /* End is a value between 0 and 1 */,
          );
          onVideoTrim({ startTimeSeconds, endTimeSeconds });
        }
      }
    }
  };

  return (
    <div ref={videoContainerDivRef} className="relative flex justify-center w-full h-full max-w-full group touch-none">
      {/* touch-none: To avoid problems with pointer events, we disable navigator gestures to zoom in, etc... */}
      <video
        className={twMerge(`rounded-lg w-full h-auto hidden`, className)}
        src={src}
        ref={videoRef}
        disablePictureInPicture
        playsInline
        preload="metadata"
        muted={isMuted}
        onPointerDown={toggleVideoPlay}
        onEnded={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onLoadedData={handleVideoLoaded}
        onLoadedMetadata={handleVideoLoaded}
        onLoad={handleVideoLoaded}
        onTimeUpdate={onVideoTimeUpdate}
        onSeeked={handleNewTimeSeeked}
        {...props}
      />
      <canvas ref={canvasRef} className={twMerge(`bg-gray-700 rounded-lg w-full h-full`, className)} />
      {showVideoTrimControls && !disabled && (
        <div
          className={`absolute top-0 w-full ${
            src ? (showControlsOnHover ? "invisible group-hover:visible" : "visible") : "invisible"
          }`}>
          {videoProgress !== undefined && (
            <VideoTrimControls
              src={src}
              videoProgress={videoProgress}
              onVideoProgressChange={handleVideoProgressChange}
              onTimeVideoTrimmedChange={handleTimeVideoTrimmedChange}
            />
          )}
        </div>
      )}
      {!disabled && (
        <div
          className={`absolute bottom-0 w-full mb-[20px] ${
            src ? (showControlsOnHover ? "invisible group-hover:visible" : "visible") : "invisible"
          }`}>
          {showVideoTimeline &&
            videoProgress !== undefined &&
            videoRef.current?.duration &&
            videoRef.current?.duration !== Infinity && (
              <VideoTimelineControl videoProgress={videoProgress} onVideoProgressChange={handleVideoProgressChange} />
            )}
          {videoRef.current && (
            <VideoPlayerControls
              isVideoPlaying={isPlaying}
              isMuted={isMuted}
              volume={isMuted ? 0 : volume}
              videoTimeProgress={videoTimeProgressFormatted}
              onToggleVideoPlay={toggleVideoPlay}
              onStopVideoPlay={stopVideoPlay}
              onToggleMute={() => setMuted(!isMuted)}
              onVolumeChange={(volume) => setVolume(volume)}
              onCaptureSnapshotAndDownload={captureSnapshotAndDownload}
              onDownloadVideo={downloadVideo}
            />
          )}
        </div>
      )}
    </div>
  );
};
