import { UserIcon } from "@heroicons/react/24/outline";
import { AdvancedTable, Page, Pagination, useAdvancedTable, useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_DYNAMIC_FRONTEND_URL } from "@whyuz/data";
import { GQLError, SortDirection, UserLicense, UserTenantRole, useUser } from "@whyuz/services";
import { BaseEntity } from "@whyuz/types";
import { Label } from "flowbite-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryFlagAndName } from "../../Countries/index.ts";
import {
  filterAndSortUserTenantRolesToShow,
  useUserTenantRolesTableConfiguration,
} from "../../UserTenantRoles/index.ts";
import { UserAvatar, formatUserName } from "../../Users/index.ts";

interface UserLicenseInformationProps {
  userLicense: UserLicense;
  onEditUserLicense: () => void;
}

export const UserLicenseInformation = ({ userLicense, onEditUserLicense }: UserLicenseInformationProps) => {
  const { t: tUserLicense } = useTranslation("user");
  const { t } = useTranslation();
  const userCtx = useUser();
  const { t: tTenant } = useTranslation("tenant");
  const { navigate } = useWebPageNavigation();
  const [error, setError] = useState<GQLError>();
  const usersTableConfiguration = useUserTenantRolesTableConfiguration();
  const usersTableState = useAdvancedTable<UserTenantRole, UserTenantRole>({
    tableConfiguration: usersTableConfiguration,
    defaultOrderBy: {
      field: "tenant.name" as keyof UserTenantRole,
      sortDirection: SortDirection.Desc,
    },
    hideColumnsKeys: ["firstname", "lastname", "mail"],
  });

  const rolesToShow = useMemo(
    () =>
      filterAndSortUserTenantRolesToShow(
        userLicense?.roles as UserTenantRole[],
        usersTableState.filters,
        userCtx.isUserSuperAdmin,
        usersTableState.orderBy,
      ),
    [userLicense?.roles, userCtx.isUserSuperAdmin, usersTableState.filters, usersTableState.orderBy],
  );

  const tenantUsersPagination = useMemo(() => {
    return {
      numberOfElements: rolesToShow?.length,
      totalElements: rolesToShow?.length,
      number: 0,
      size: rolesToShow?.length,
      totalPages: 1,
    } as Pagination;
  }, [rolesToShow?.length]);

  return (
    <Page error={error} onErrorClose={() => setError(undefined)}>
      {userLicense && (
        <>
          <Page.NavigationPanel />
          <Page.TitlePanel
            icon={<UserIcon className="w-8 h-8 text-primary-600" />}
            title={tUserLicense("user")}
            bottomBorder={false}
            detail={userLicense.personalInfo ? formatUserName(userLicense.personalInfo) : undefined}>
            <Page.ModalEntityInfoButton
              entity={userLicense as BaseEntity}
              detail={userLicense.personalInfo ? formatUserName(userLicense.personalInfo) : undefined}
            />
            <Page.EditEntityButton text={t("buttons.edit")} onEditEntity={onEditUserLicense} />
          </Page.TitlePanel>
          <Page.FormContainer>
            <div className="flex flex-col sm:flex-row">
              <Page.FormGrid columns={2} columnsBreakpointSM={true}>
                <Page.FormTextInfoField
                  id="firstname"
                  label={tUserLicense("firstname")}
                  text={userLicense.personalInfo?.firstName as string}
                />
                <Page.FormTextInfoField
                  id="lastname"
                  label={tUserLicense("lastname")}
                  text={userLicense.personalInfo?.lastName as string}
                />
                <Page.FormTextInfoField id="mail" label={tUserLicense("email")} text={userLicense?.mail as string} />
                <div>
                  <Label className="mb-2 text-xs font-normal text-gray-900 select-none">
                    {tUserLicense("usercurrentlocation")}
                  </Label>
                  {userLicense.country && <CountryFlagAndName country={userLicense.country} />}
                </div>
                {userCtx.isUserSuperAdmin && (
                  <Page.FormToggleField
                    id="enabled"
                    label={t("word.enabled")}
                    labelBold={false}
                    checked={userLicense.enabled ?? false}
                  />
                )}
              </Page.FormGrid>
              {userLicense.personalInfo?.pictureURL && (
                <div className="flex flex-none pt-8 sm:pt-0 sm:px-8 items-center justify-center">
                  <UserAvatar user={userLicense.personalInfo} size="xl" />
                </div>
              )}
            </div>
          </Page.FormContainer>
          {rolesToShow && (
            <>
              <AdvancedTable.Title title={tTenant("companies")} pagination={tenantUsersPagination}>
                <AdvancedTable.FilterButton tableState={usersTableState} showText={false} />
                <AdvancedTable.ColumnsManagementDropdown tableState={usersTableState} showText={false} />
              </AdvancedTable.Title>
              <AdvancedTable<UserTenantRole, UserTenantRole>
                tableState={usersTableState}
                entitiesToShow={rolesToShow}
                onViewEntity={(userRole) =>
                  navigate(WHYUZ_DYNAMIC_FRONTEND_URL.tenant.view(userRole?.tenant?.id as string))
                }
                onEditEntity={(userRole) =>
                  navigate(WHYUZ_DYNAMIC_FRONTEND_URL.tenant.edit(userRole?.tenant?.id as string))
                }
                onDeleteEntity={
                  userCtx.isUserSuperAdmin || userCtx.isUserCurrentCorporationAdministrator ? undefined : undefined
                }
                // WARNING: This screen is used from collab, and cannot allow login to tenant
                // additionalRowActions={(userRole) => (
                //   <LoginToTenantActionButton tenant={userRole.tenant as Tenant} setError={setError} />
                // )}
              />
            </>
          )}
        </>
      )}
    </Page>
  );
};
