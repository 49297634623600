import { Page } from "@whyuz/components";
import { ChatMember } from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { formatUserName } from "../../Users";
import { UserAvatar } from "../../Users/UserAvatar";

interface MemberProps {
  member: ChatMember;
  onDeleteMember: (memberId: string) => void;
}

export const Member = ({ member, onDeleteMember }: MemberProps) => {
  const { t: tChat } = useTranslation("chat");

  const deleteMember = () => {
    onDeleteMember(member.id as string);
  };
  return (
    <Page.PanelContainer>
      <div className="flex items-center">
        <div key={member?.id as string} className="mr-1">
          <UserAvatar user={member} />
        </div>
        {member.firstName && member.lastName ? <div>{formatUserName(member)}</div> : <div>{member.mail}</div>}
      </div>
      <Page.DeleteEntityButton text={tChat("deletemember")} onDeleteEntity={deleteMember} />
    </Page.PanelContainer>
  );
};
