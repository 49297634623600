import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiUndo } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonUndoProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonUndo = ({ className, children }: TextEditorMenuButtonUndoProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  return (
    <Tooltip content={t("buttons.undo")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().undo().run();
          }
        }}
        className={twMerge(buttonStyleHiddenOnMobile, className)}
        disabled={disabled}
      >
        {children ?? <BiUndo className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
