import { Country } from "@whyuz/services";
import { useMemo } from "react";
import ReactCountryFlag from "react-country-flag";
import { twMerge } from "tailwind-merge";
import { useCountryTranslation } from "../";

export interface CountryFlagAndNameProps extends React.PropsWithChildren {
  className?: string;
  country: Country;
}

export const CountryFlagAndName = ({ country, className, children }: CountryFlagAndNameProps) => {
  const countryTranslation = useCountryTranslation();
  const countryName = useMemo(() => countryTranslation(country), [country, countryTranslation]);

  return (
    <div className="flex items-center space-x-2">
      <span className="flex-none flex items-center">
        <ReactCountryFlag svg countryCode={country.id} style={{ width: "1.25rem", height: "1.25rem" }} />
      </span>
      <span className={twMerge(`block truncate flex-1 font-normal dark:text-white`, className)}>{countryName}</span>
      {children}
    </div>
  );
};
