/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Node, mergeAttributes } from "@tiptap/core";

export interface PathOptions {
  HTMLAttributes: Record<string, any>;
}

export interface PathAttributes {
  d?: string;
  "stroke-linecap"?: string;
  "stroke-linejoin"?: string;
}

export const Path = Node.create<PathOptions>({
  name: "path",
  group: "paths",
  atom: true,
  draggable: false,
  selectable: false,

  addAttributes() {
    return {
      d: {
        default: this.options.HTMLAttributes.d,
      },
      "stroke-linecap": {
        default: this.options.HTMLAttributes["stroke-linecap"],
      },
      "stroke-linejoin": {
        default: this.options.HTMLAttributes["stroke-linejoin"],
      },
    };
  },

  parseHTML: () => {
    return [
      {
        tag: "path",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["path", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  parseDOM: [{ tag: "path" }],

  toDOM(node: { attrs: Record<string, any> }) {
    return ["path", mergeAttributes(this.options.HTMLAttributes, node.attrs), 0];
  },

  addOptions: () => {
    return {
      HTMLAttributes: {},
    };
  },
});
