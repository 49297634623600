import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  AddApprovalFlowExecutionStepMutationVariables,
  ApprovalFlowExecutionStep,
  ApprovalFlowExecutionStepPage,
  ApprovalFlowExecutionStepQueryVariables,
  DeleteApprovalFlowExecutionStepMutationVariables,
  UpdateApprovalFlowExecutionStepMutationVariables,
  UpdateApprovalFlowExecutionStepPositionMutationVariables,
  UpdateApprovalFlowExecutionStepsMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowExecutionStepGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useAddApprovalFlowExecutionStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionStep, AddApprovalFlowExecutionStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionStep, AddApprovalFlowExecutionStepMutationVariables>(
    ApprovalFlowExecutionStepGQL.mutation.addApprovalFlowExecutionStep,
    mutationContext,
  );

export const useUpdateApprovalFlowExecutionStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionStep, UpdateApprovalFlowExecutionStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionStep, UpdateApprovalFlowExecutionStepMutationVariables>(
    ApprovalFlowExecutionStepGQL.mutation.updateApprovalFlowExecutionStep,
    mutationContext,
  );

export const useUpdateApprovalFlowExecutionStepsMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionStep, UpdateApprovalFlowExecutionStepsMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionStep, UpdateApprovalFlowExecutionStepsMutationVariables>(
    ApprovalFlowExecutionStepGQL.mutation.updateApprovalFlowExecutionSteps,
    mutationContext,
  );

export const useDeleteApprovalFlowExecutionStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowExecutionStep, DeleteApprovalFlowExecutionStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowExecutionStep, DeleteApprovalFlowExecutionStepMutationVariables>(
    ApprovalFlowExecutionStepGQL.mutation.deleteApprovalFlowExecutionStep,
    mutationContext,
  );

export const useUpdateApprovalFlowExecutionStepPositionMutation = (
  mutationContext?: GQLQueryContext<
    ApprovalFlowExecutionStep,
    UpdateApprovalFlowExecutionStepPositionMutationVariables
  >,
) =>
  useGQLMutation<ApprovalFlowExecutionStep, UpdateApprovalFlowExecutionStepPositionMutationVariables>(
    ApprovalFlowExecutionStepGQL.mutation.updateApprovalFlowExecutionStepPosition,
    mutationContext,
  );

export const useApprovalFlowExecutionStepsLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionStepPage, ApprovalFlowExecutionStepQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecutionStepPage, ApprovalFlowExecutionStepQueryVariables>(
    ApprovalFlowExecutionStepGQL.query.approvalFlowExecutionSteps,
    queryContext,
  );

export const useApprovalFlowExecutionStepsQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionStepPage, ApprovalFlowExecutionStepQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecutionStepPage, ApprovalFlowExecutionStepQueryVariables>(
    ApprovalFlowExecutionStepGQL.query.approvalFlowExecutionSteps,
    queryContext,
  );

export const useApprovalFlowExecutionStepLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionStep, ApprovalFlowExecutionStepQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowExecutionStep, ApprovalFlowExecutionStepQueryVariables>(
    ApprovalFlowExecutionStepGQL.query.approvalFlowExecutionStep,
    queryContext,
  );

export const useApprovalFlowExecutionStepQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowExecutionStep, ApprovalFlowExecutionStepQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowExecutionStep, ApprovalFlowExecutionStepQueryVariables>(
    ApprovalFlowExecutionStepGQL.query.approvalFlowExecutionStep,
    queryContext,
  );
