import { Pagination as PaginationType } from "@whyuz/components";
import { Pagination } from "flowbite-react";

export interface PaginationButtonGroupProps {
  pagination: PaginationType;
  onChangePage: (page: number) => void;
}

export const PaginationButtonGroup = ({ pagination, onChangePage }: PaginationButtonGroupProps) => {
  return (
    <Pagination
      theme={{
        pages: {
          base: "mt-0 inline-flex items-center -space-x-px",
        },
      }}
      currentPage={pagination.number + 1}
      totalPages={pagination.totalPages}
      onPageChange={(page) => {
        onChangePage(page - 1);
      }}
      showIcons
      layout="pagination"
      nextLabel=""
      previousLabel=""
    />
  );
};
