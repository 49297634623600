import { useGQLLazyQuery, useGQLQuery } from "../";
import { CurrenciesQueryVariables, CurrencyPage } from "../../codegen/graphql.ts";
import { CurrencyGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useCurrenciesLazyQuery = (queryContext?: GQLQueryContext<CurrencyPage, CurrenciesQueryVariables>) =>
  useGQLLazyQuery<CurrencyPage, CurrenciesQueryVariables>(CurrencyGQL.query.currencies, queryContext);

export const useCurrenciesQuery = (queryContext?: GQLQueryContext<CurrencyPage, CurrenciesQueryVariables>) =>
  useGQLQuery<CurrencyPage, CurrenciesQueryVariables>(CurrencyGQL.query.currencies, queryContext);
