import { Language } from "@whyuz/services";
import { getPropertyByPath } from "@whyuz/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useLanguageTranslations = () => {
  const { i18n } = useTranslation();

  return useCallback(
    (language: Language) =>
      (i18n.language === "en" ? language.name : getPropertyByPath(language, i18n.language)) as string,
    [i18n.language],
  );
};
