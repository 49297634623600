import { HttpStatusCode } from "@whyuz/data";
import { DocumentNode } from "graphql";
import { Variables } from "graphql-request";
import { GraphQLError } from "graphql/error";
import { OperationDefinitionNode } from "graphql/language";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  GQLError,
  GQLErrorStatusCode,
  GQLFieldErrorMessageDictionary,
  GQLFieldErrorType,
  GQLQueryContext,
  GQLQueryInformation,
} from "../types";
import { useGQLClient } from "./useGQLClient.ts";
import { useUser } from "./useUser.ts";

export const EXTENSION_CLASSIFICATION_VALIDATION_ERROR = "VALIDATION_ERROR";
export const EXTENSION_CLASSIFICATION_DATA_INTEGRITY_ERROR = "DATA_INTEGRITY_ERROR";
export const EXTENSION_CLASSIFICATION_TENANT_UNKNOWN = "TENANT_UNKNOWN";
export const EXTENSION_CLASSIFICATION_TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const EXTENSION_CLASSIFICATION_BAD_REQUEST = "BAD_REQUEST";
export const EXTENSION_CLASSIFICATION_UNAUTHORIZED = "UNAUTHORIZED";
export const EXTENSION_CLASSIFICATION_INTERNAL_ERROR = "INTERNAL_ERROR";
export const EXTENSION_CLASSIFICATION_FORBIDDEN = "FORBIDDEN";
export const EXTENSION_CLASSIFICATION_MAIL_AUTHENTICATION_ERROR = "MAIL_AUTHENTICATION_ERROR";

interface GQLControledErrorResponse extends GQLError {
  request: {
    query?: string;
    variables?: { [key: string]: unknown };
  };
  response: {
    data?: unknown;
    errors?: GraphQLError[];
    status?: HttpStatusCode;
  };
  message: string;
}

const getStatusCodeConsideringClassification = (
  statusCode?: HttpStatusCode | GQLErrorStatusCode,
  classification?: string,
): HttpStatusCode | GQLErrorStatusCode => {
  if (!statusCode) {
    return HttpStatusCode.INTERNAL_SERVER_ERROR;
  }

  if (statusCode !== HttpStatusCode.OK) {
    return statusCode;
  }

  switch (classification) {
    case EXTENSION_CLASSIFICATION_DATA_INTEGRITY_ERROR:
      return GQLErrorStatusCode.DATA_INTEGRITY_ERROR;
    case EXTENSION_CLASSIFICATION_TENANT_UNKNOWN:
      return GQLErrorStatusCode.TENANT_UNKNOWN;
    case EXTENSION_CLASSIFICATION_TOKEN_EXPIRED:
      return GQLErrorStatusCode.TOKEN_EXPIRED;
    case EXTENSION_CLASSIFICATION_VALIDATION_ERROR:
      return GQLErrorStatusCode.VALIDATION_ERROR;
    case EXTENSION_CLASSIFICATION_BAD_REQUEST:
      return HttpStatusCode.BAD_REQUEST;
    case EXTENSION_CLASSIFICATION_UNAUTHORIZED:
      return HttpStatusCode.UNAUTHORIZED;
    case EXTENSION_CLASSIFICATION_FORBIDDEN:
      return HttpStatusCode.FORBIDDEN;
    case EXTENSION_CLASSIFICATION_MAIL_AUTHENTICATION_ERROR:
      return GQLErrorStatusCode.MAIL_AUTHENTICATION_ERROR;
    case EXTENSION_CLASSIFICATION_INTERNAL_ERROR:
    default:
      return HttpStatusCode.INTERNAL_SERVER_ERROR;
  }
};

const getClassification = (controlledError: GQLControledErrorResponse): string | undefined => {
  if (controlledError.response.errors && controlledError.response.errors.length > 0) {
    // From the server it is agreed to send only one error
    const errorDetails: GraphQLError | undefined = controlledError.response.errors[0];
    if (errorDetails) {
      return errorDetails.extensions["classification"] as string;
    }
  }
  return undefined;
};

const createErrorMessageDictionaryFromControlledError = (
  controlledError: GQLControledErrorResponse,
): GQLFieldErrorMessageDictionary => {
  let errorsDictionary: GQLFieldErrorMessageDictionary = {};
  if (controlledError.response.errors && controlledError.response.errors.length > 0) {
    // From the server it is agreed to send only one error, but just in case, we create a loop
    for (const errorDetails of controlledError.response.errors) {
      if (errorDetails) {
        const fieldErrors = errorDetails.extensions["fieldErrors"] as GQLFieldErrorType[];
        if (fieldErrors && fieldErrors.length > 0) {
          errorsDictionary = fieldErrors.reduce(
            (prevErrors, actFieldError) =>
              actFieldError.field
                ? {
                    ...prevErrors,
                    [actFieldError.field.toLowerCase()]: {
                      ...actFieldError,
                      field: actFieldError.field.toLowerCase(),
                    },
                  }
                : prevErrors,
            errorsDictionary,
          );
        }
      }
    }
  }
  return errorsDictionary;
};

export const useGQLLazyQuery = <T, V extends Variables>(
  query: DocumentNode,
  queryContext?: GQLQueryContext<T, V>,
): [(callQueryContext?: GQLQueryContext<T, V>) => Promise<T>, GQLQueryInformation<T>] => {
  const GQLClient = useGQLClient(queryContext?.baseURL);
  const [isLoading, setIsLoading] = useState(false);
  const [hasEverBeenExecuted, setHasEverBeenExecuted] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<GQLError>();
  // CAUTION: Usage of useRef instead of queryContext directly => If only the query context (callQueryContext) is changed in the request callback, the useCallback function does not change (to avoid problems of loops with useEffect calling the function)
  const initialQueryContextRef = useRef<GQLQueryContext<T, V> | undefined>(queryContext);

  const request = useCallback(
    (callQueryContext?: GQLQueryContext<T, V>) => {
      setIsLoading(true);
      return new Promise((resolve: (data: T) => void, reject: (error: GQLError) => void) => {
        const variables = callQueryContext?.variables ?? initialQueryContextRef.current?.variables;

        const handleError = (internalError: GQLError) => {
          setData(undefined);
          setError(internalError);
          // The error can only be managed by the callback function or by the promise, not by both at the same time
          // (in case the callback was used and not the promise, the reject would fail as it is not being treated as a promise outside of this hook)
          const onError = callQueryContext?.onError ?? initialQueryContextRef.current?.onError;
          if (onError) {
            onError(internalError);
          } else {
            reject(internalError);
          }
        };

        GQLClient.request<T>(query, variables)
          .then((responseData) => {
            try {
              const queryOperation: OperationDefinitionNode = query.definitions[0] as OperationDefinitionNode;
              const queryResponseDataFieldName = queryOperation?.name?.value as string;
              if (queryResponseDataFieldName) {
                type responseDataType = { [key: string]: T };
                const responseDataWithType = responseData as responseDataType;
                const responseDataValue = responseDataWithType[queryResponseDataFieldName];
                setData(responseDataValue);
                setError(undefined);
                const onCompleted = callQueryContext?.onCompleted ?? initialQueryContextRef.current?.onCompleted;
                try {
                  if (onCompleted) {
                    onCompleted(responseDataValue);
                  }
                } catch (e) {
                  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                    console.log("Uncontrolled Error: treating onCompleted by the callback", e);
                  }
                  // We don't handle internal errors of the application out of this hook
                } finally {
                  // Even if the call to onCompleted fails, the promise has to resolve correctly
                  resolve(responseDataValue);
                }
              } else {
                if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                  console.log("Uncontrolled Error: query response data field name was not found");
                }
                const internalError: GQLError = {
                  message: "Data could not be identified in the server response",
                  fieldErrors: {},
                  isUncontrolledError: true,
                  statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                };
                handleError(internalError);
              }
            } catch (e) {
              if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                console.log("Uncontrolled Error: exception", { e });
              }
              const internalError: GQLError = {
                message: e as string,
                fieldErrors: {},
                isUncontrolledError: true,
              };
              handleError(internalError);
            }
          })
          .catch((responseError: GQLError | GQLControledErrorResponse | TypeError) => {
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
              console.log("Server error message", { responseError }, { query }, { variables });
            }
            const isControlledError =
              (responseError as GQLControledErrorResponse)["request"] &&
              (responseError as GQLControledErrorResponse)["response"];
            let internalError: GQLError = { ...responseError } as GQLError;
            if (isControlledError) {
              const controlledError = responseError as GQLControledErrorResponse;
              const fieldErrors = createErrorMessageDictionaryFromControlledError(controlledError);
              const classification = getClassification(controlledError);
              const message =
                controlledError.response.errors && controlledError.response.errors[0]
                  ? controlledError.response.errors[0].message
                  : "";
              const statusCode = getStatusCodeConsideringClassification(
                controlledError.response.status,
                classification,
              );
              internalError = {
                message,
                fieldErrors,
                isUncontrolledError:
                  statusCode !== HttpStatusCode.OK && statusCode !== GQLErrorStatusCode.VALIDATION_ERROR,
                statusCode,
              };
              if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                console.log("Controlled Error: Internal error message", {
                  internalError,
                });
              }
            } else {
              const errorMessage =
                responseError instanceof TypeError ? responseError.message : JSON.stringify(responseError);
              internalError = {
                message: errorMessage,
                fieldErrors: {},
                isUncontrolledError: true,
              };
              if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                console.log("Uncontrolled Error: Internal error message", {
                  internalError,
                });
              }
            }

            handleError(internalError);
          })
          .finally(() => {
            setIsLoading(false);
            setHasEverBeenExecuted(true);
          });
      });
    },
    [GQLClient, query],
  );

  return [request, { hasEverBeenExecuted, isLoading, data, error }];
};

export const useGQLQuery = <T, V extends Variables>(query: DocumentNode, queryContext?: GQLQueryContext<T, V>) => {
  const [lazyQuery, { isLoading, data, error }] = useGQLLazyQuery<T, V>(query, queryContext);
  const isQueryExecutedRef = useRef<boolean>(false);
  const userCtx = useUser();
  const tenantId = useRef<string | undefined>(userCtx.userLicense?.currentRole?.tenant?.id as string);

  // Use effect to refresh automatically and execute the query if the user changes of tenant
  useEffect(() => {
    if (tenantId.current !== userCtx.userLicense?.currentRole?.tenant?.id) {
      tenantId.current = userCtx.userLicense?.currentRole?.tenant?.id as string;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      lazyQuery();
    }
  }, [userCtx.userLicense?.currentRole?.tenant?.id, lazyQuery]);

  if (!isQueryExecutedRef.current) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    lazyQuery();
    isQueryExecutedRef.current = true;
  }
  return { isLoading, data, error, lazyQuery };
};

export function useGQLMutation<T, V extends Variables>(
  mutation: DocumentNode,
  mutationContext?: GQLQueryContext<T, V>,
) {
  return useGQLLazyQuery<T, V>(mutation, mutationContext);
}
