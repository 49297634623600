import { Tooltip } from "flowbite-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoFootstepsOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyle, buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonTrackChangesProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonTrackChanges = ({ className, children }: TextEditorMenuButtonTrackChangesProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();
  const [isTrackActive, setTrackActive] = useState<boolean>(false);

  const buttonClassName = editor && isTrackActive ? twMerge(buttonStyle, activeButtonStyle) : buttonStyle;

  return (
    <Tooltip content={t("buttons.trackchanges")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            setTrackActive((prev) => !prev);
            editor.chain().toggleTrackChangeStatus().focus().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}
      >
        {children ?? <IoFootstepsOutline className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
