import { Button, Tooltip } from "flowbite-react";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { RIGHT_BUTTON } from "@whyuz/data";
import { useTranslation } from "react-i18next";
import { FunnelIcon } from "@heroicons/react/24/solid";

export interface AddNewEntityButtonProps<T extends EntityId, Filter extends T> {
  tableState: AdvancedTableState<T, Filter>;
  text?: string;
  showText?: boolean;
}

export const AdvancedTableFilterButton = <T extends EntityId, Filter extends T>({
  tableState,
  text,
  showText = true,
}: AddNewEntityButtonProps<T, Filter>) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t("buttons.showfilters")} placement="bottom" className={showText ? "hidden" : undefined}>
      <Button
        size="sm"
        color="light"
        className={`px-0 ${showText ? "sm:px-0.5" : ""}`}
        onPointerDown={(e: React.PointerEvent) => {
          if (e.button !== RIGHT_BUTTON) {
            tableState?.setShowMoreOptions((prev) => !prev);
          }
        }}>
        <FunnelIcon className={`h-4 w-4 self-center`} />
        {showText && <span className="hidden sm:block ml-2">{text ?? t("buttons.showfilters")}</span>}
      </Button>
    </Tooltip>
  );
};
