import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { getVideoDuration, getVideoFrames } from "@whyuz/utils";
import { useCallback, useEffect, useRef, useState } from "react";

export interface VideoTrimControlsProps {
  src?: string;
  videoProgress: number;
  minAcceptedVideoTimeSeconds?: number;
  onVideoProgressChange: (videoProgress: number) => void;
  onTimeVideoTrimmedChange: (start: number, end: number) => void;
}

export const VideoTrimControls = ({
  src,
  videoProgress,
  minAcceptedVideoTimeSeconds = 5,
  onVideoProgressChange,
  onTimeVideoTrimmedChange,
}: VideoTrimControlsProps) => {
  const originalSequenceDivRef = useRef<HTMLDivElement>(null);
  const selectedSequenceDivRef = useRef<HTMLDivElement>(null);
  const videoTrimLeftDivRef = useRef<HTMLDivElement>(null);
  const videoTrimRightDivRef = useRef<HTMLDivElement>(null);
  const trimButtonLeftRef = useRef<HTMLButtonElement>(null);
  const trimButtonRightRef = useRef<HTMLButtonElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isCanvasInitialized, setCanvasInitialized] = useState<boolean>(false);
  const [trimButtonGrabbed, setTrimButtonGrabbed] = useState<HTMLButtonElement | null>(null);
  const [timeVideoTrimmed, setTimeVideoTrimmed] = useState({ start: 0, end: 1 });
  const progressButtonDivRef = useRef<HTMLDivElement>(null);
  const timelineProgressDivRef = useRef<HTMLDivElement>(null);
  const [isPointerDown, setPointerDown] = useState(false);
  const [videoDuration, setVideoDuration] = useState<number | undefined>();

  useEffect(() => {
    const handleTimelineUpdate = (e: PointerEvent) => {
      if (e.button !== RIGHT_BUTTON) {
        setPointerDown(true);
        if (!canvasRef.current) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const newVideoProgress = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
        onVideoProgressChange(newVideoProgress);
      }
    };

    const timelineDiv = canvasRef.current;
    if (timelineDiv) {
      timelineDiv.addEventListener("pointerdown", handleTimelineUpdate);
      return () => timelineDiv.removeEventListener("pointerdown", handleTimelineUpdate);
    }
    return;
  }, [onVideoProgressChange]);

  useEffect(() => {
    if (isPointerDown) {
      const handleMoveProgressButton = (e: PointerEvent) => {
        if (!canvasRef.current || !isPointerDown) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const newVideoProgress = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
        onVideoProgressChange(newVideoProgress);
      };

      const handlePointerUp = (e: PointerEvent) => {
        if (e.button !== RIGHT_BUTTON) {
          setPointerDown(false);
        }
      };

      window.addEventListener("pointermove", handleMoveProgressButton, true);
      window.addEventListener("pointerup", handlePointerUp);
      return () => {
        window.removeEventListener("pointermove", handleMoveProgressButton, true);
        window.removeEventListener("pointerup", handlePointerUp);
      };
    }
    return;
  }, [isPointerDown, onVideoProgressChange]);

  useEffect(() => {
    if (timelineProgressDivRef.current) {
      timelineProgressDivRef.current.style.width = (videoProgress * 100).toString() + "%";
    }
  }, [videoProgress]);

  useEffect(() => {
    if (src) {
      getVideoDuration(src)
        .then(setVideoDuration)
        .catch((e) => alert(e));
      setCanvasInitialized(false);
      setTimeVideoTrimmed({ start: 0, end: 1 });
      const video = document.createElement("video");
      const loadCanvasImages = () => {
        video.removeEventListener("loadeddata", loadCanvasImages);
        if (!canvasRef.current) return;
        const { width, height } = canvasRef.current.getBoundingClientRect();
        // Warning: do not delete: drawImage needs this information to work properly
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        const scaleRatio = height / video.videoHeight;
        const numberOfFrames = Math.ceil(width / (video.videoWidth * scaleRatio));
        const xOffset = (numberOfFrames * video.videoWidth * scaleRatio - width) / 2;
        getVideoFrames(src, numberOfFrames)
          .then((frames) => {
            if (canvasRef.current) {
              const context = canvasRef.current.getContext("2d");
              if (frames && context) {
                context.clearRect(0, 0, width, height);
                frames.forEach((frame, index) => {
                  createImageBitmap(frame)
                    .then((frameBitmap) => {
                      // if (index === 0) {
                      //   // First step paints onlly partially the first image due to the x offset to center the images in the canvas
                      //   context.drawImage(
                      //     frameBitmap,
                      //     xOffset * scaleRatio, // sx
                      //     0, // sy
                      //     video.videoWidth - xOffset * scaleRatio, // sw
                      //     video.videoHeight, // sh
                      //     0,
                      //     0,
                      //     frame.width * scaleRatio - xOffset,
                      //     height,
                      //   );
                      // } else {
                      context.drawImage(
                        frameBitmap,
                        index * frame.width * scaleRatio - xOffset,
                        0,
                        frame.width * scaleRatio,
                        height,
                      );
                      // }
                    })
                    .catch((e) => alert(e));
                });
              }
            }
            setCanvasInitialized(true);
          })
          .catch((e) => alert(e));
      };
      video.addEventListener("loadeddata", loadCanvasImages);
      video.preload = "auto";
      video.src = src;
      video.load();
    }
  }, [src]);

  const moveTrimButton = useCallback(
    (e: PointerEvent) => {
      const trimButtonRight = trimButtonRightRef.current;
      const trimButtonLeft = trimButtonLeftRef.current;
      const div = selectedSequenceDivRef.current;
      const canvas = canvasRef.current;
      if (!div || !canvas || !trimButtonGrabbed || !trimButtonRight || !trimButtonLeft) return;
      const leftLimit = div.getBoundingClientRect().left + trimButtonLeft.offsetWidth;
      const rightLimit = div.getBoundingClientRect().right - trimButtonRight.offsetWidth;
      const totalWidth = rightLimit - leftLimit;
      const leftLimitConsideringCurrentTrim = leftLimit + timeVideoTrimmed.start * totalWidth;
      const rightLimitConsideringCurrentTrim = leftLimit + timeVideoTrimmed.end * totalWidth;
      const pageXInLimits =
        trimButtonGrabbed === trimButtonLeft
          ? Math.min(Math.max(e.pageX, leftLimit), rightLimitConsideringCurrentTrim)
          : Math.min(Math.max(e.pageX, leftLimitConsideringCurrentTrim), rightLimit);
      if (pageXInLimits === rightLimit && timeVideoTrimmed.end === 1) return;
      if (pageXInLimits === leftLimit && timeVideoTrimmed.start === 0) return;
      // Min accepted by default 0.05% of the video length
      const MIN_ACCEPTED_VIDEO_PCT_TIME = videoDuration ? minAcceptedVideoTimeSeconds / videoDuration : 0.05;
      if (trimButtonGrabbed === trimButtonLeft) {
        let startPct = (pageXInLimits - leftLimit) / (rightLimit - leftLimit);
        if (timeVideoTrimmed.end - startPct < MIN_ACCEPTED_VIDEO_PCT_TIME)
          startPct = timeVideoTrimmed.end - MIN_ACCEPTED_VIDEO_PCT_TIME;
        setTimeVideoTrimmed({ start: startPct, end: timeVideoTrimmed.end });
      } else {
        let endPct = (pageXInLimits - leftLimit) / (rightLimit - leftLimit);
        if (endPct - timeVideoTrimmed.start < MIN_ACCEPTED_VIDEO_PCT_TIME)
          endPct = timeVideoTrimmed.start + MIN_ACCEPTED_VIDEO_PCT_TIME;
        setTimeVideoTrimmed({ start: timeVideoTrimmed.start, end: endPct });
      }
    },
    [trimButtonGrabbed, timeVideoTrimmed.start, timeVideoTrimmed.end, minAcceptedVideoTimeSeconds, videoDuration],
  );

  useEffect(() => {
    window.addEventListener("pointermove", moveTrimButton, true);
    return () => {
      window.removeEventListener("pointermove", moveTrimButton, true);
    };
  }, [moveTrimButton]);

  useEffect(() => {
    const releaseTrimButton = (e: PointerEvent) => {
      if (e.button !== RIGHT_BUTTON) {
        setTrimButtonGrabbed(null);
        onTimeVideoTrimmedChange(timeVideoTrimmed.start, timeVideoTrimmed.end);
      }
    };

    window.addEventListener("pointerup", releaseTrimButton, true);
    return () => {
      window.removeEventListener("pointerup", releaseTrimButton, true);
    };
  }, [onTimeVideoTrimmedChange, timeVideoTrimmed.start, timeVideoTrimmed.end]);

  const recalculateTrimButtonsPosition = useCallback(() => {
    const trimButtonLeft = trimButtonLeftRef.current;
    const trimButtonRight = trimButtonRightRef.current;
    const videoTrimLeftDiv = videoTrimLeftDivRef.current;
    const videoTrimRightDiv = videoTrimRightDivRef.current;
    const canvas = canvasRef.current;
    if (!canvas || !trimButtonLeft || !trimButtonRight || !videoTrimLeftDiv || !videoTrimRightDiv) return;

    const canvasWidth = canvas.getBoundingClientRect().width;
    const leftDelta = timeVideoTrimmed.start * canvasWidth;
    const rightDelta = (1 - timeVideoTrimmed.end) * canvasWidth;

    trimButtonLeft.style.left = leftDelta.toString() + "px";
    trimButtonRight.style.right = rightDelta.toString() + "px";
    videoTrimLeftDiv.style.width = leftDelta.toString() + "px";
    videoTrimRightDiv.style.width = rightDelta.toString() + "px";
  }, [timeVideoTrimmed]);

  useEffect(() => {
    recalculateTrimButtonsPosition();
  }, [timeVideoTrimmed, recalculateTrimButtonsPosition]);

  useEffect(() => {
    window.addEventListener("resize", recalculateTrimButtonsPosition, true);
    return () => window.removeEventListener("resize", recalculateTrimButtonsPosition, true);
  }, [recalculateTrimButtonsPosition]);

  const isVideoEdited = () => {
    return timeVideoTrimmed.start !== 0 || timeVideoTrimmed.end !== 1;
  };

  const trimButtonStyle = `absolute cursor-pointer h-[46px] w-[12px] rounded-sm ${
    isVideoEdited() ? "bg-yellow-500" : "bg-black border border-y-2"
  }`;
  const trimDivStyle = `absolute top-0 h-[46px] w-0 bg-gray-800/60`;

  return (
    <div
      className={`absolute top-0 mt-[30px] w-full h-[52px] ${isCanvasInitialized ? "" : "invisible"} ${
        src ? (trimButtonGrabbed == null ? "block group-hover:block" : "block") : "hidden"
      }`}
      ref={originalSequenceDivRef}>
      <div className={`absolute top-0 left-0 w-full h-[46px] border-x-[12px] border-y-2 flex items-center`}>
        <canvas ref={canvasRef} className="w-full h-full" />
        <div ref={timelineProgressDivRef} className={`absolute flex items-center w-0 h-0 left-0`}>
          <div
            ref={progressButtonDivRef}
            className={`absolute w-1 h-[46px] hover:scale-125 bg-white rounded-full border border-gray-200 translate-x-[+50%] right-0 cursor-pointer`}></div>
        </div>
      </div>
      <div ref={selectedSequenceDivRef} className={`w-full h-[46px]`}>
        <div ref={videoTrimRightDivRef} className={`${trimDivStyle} right-0`}>
          <button
            ref={trimButtonRightRef}
            className={`${trimButtonStyle}`}
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) {
                setTrimButtonGrabbed(trimButtonRightRef.current);
              }
            }}>
            <ChevronLeftIcon className={`h-5 w-3 ${isVideoEdited() ? "text-black" : "text-white"}`} />
          </button>
        </div>
        <div ref={videoTrimLeftDivRef} className={`${trimDivStyle} left-0`}>
          <button
            ref={trimButtonLeftRef}
            className={`${trimButtonStyle}`}
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) {
                setTrimButtonGrabbed(trimButtonLeftRef.current);
              }
            }}>
            <ChevronRightIcon className={`h-5 w-3 mx-[-1px] ${isVideoEdited() ? "text-black" : "text-white"}`} />
          </button>
        </div>
      </div>
    </div>
  );
};
