import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/24/solid";
import { RIGHT_BUTTON } from "@whyuz/data";
import { GQLSort, SortDirection } from "@whyuz/services";
import { deepCloneAndAssignPropertyByPath, getPropertyByPath } from "@whyuz/utils";
import { Table, TableHeadCellProps, TextInput } from "flowbite-react";
import { useCallback, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { FlowbiteTextInputThemes } from "../../FlowbiteThemes";
import { DebouncedTextInput } from "../../Input";
import { AdvancedTableColumnConfiguration } from "./AdvancedTableConfiguration.ts";
import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";

export interface AdvancedTableColumnHeaderProps<T extends EntityId, Filter extends T> extends TableHeadCellProps {
  tableState: AdvancedTableState<T, Filter>;
  columnConfiguration: AdvancedTableColumnConfiguration<T, Filter>;
  onChangeOrderBy?: (newOrderBy?: GQLSort<T>) => void;
}

export const AdvancedTableColumnHeader = <T extends EntityId, Filter extends T>({
  tableState,
  columnConfiguration,
  className,
  ...props
}: AdvancedTableColumnHeaderProps<T, Filter>) => {
  const isFiltered = useMemo(
    () =>
      tableState.filters &&
      columnConfiguration.entityField &&
      getPropertyByPath(tableState.filters, columnConfiguration.entityField) !== undefined &&
      getPropertyByPath(tableState.filters, columnConfiguration.entityField) !== null,
    [columnConfiguration.entityField, tableState.filters],
  );

  const allowOrder =
    !columnConfiguration.disableOrder &&
    columnConfiguration.entityField !== null &&
    columnConfiguration.entityField !== undefined;
  const sortDirection = useMemo(
    () =>
      tableState.orderBy && tableState.orderBy.field === columnConfiguration.entityField
        ? tableState.orderBy.sortDirection
        : undefined,
    [columnConfiguration.entityField, tableState.orderBy],
  );

  const orderByColumn = useCallback(
    (entityField: string) => {
      let sortDirection: SortDirection;
      if (tableState.orderBy?.field === entityField) {
        switch (tableState.orderBy.sortDirection) {
          case SortDirection.Asc:
            sortDirection = SortDirection.Desc;
            break;
          default:
            tableState.setOrderBy(undefined);
            return;
        }
      } else {
        sortDirection = SortDirection.Asc;
      }
      const field = entityField;
      tableState.setOrderBy({ sortDirection, field });
    },
    [tableState],
  );

  const onChangeFilterText = useCallback(
    (entityFilterValue: string | null) => {
      tableState.setFilters((prevFilter) => {
        if (columnConfiguration.entityField) {
          return deepCloneAndAssignPropertyByPath(prevFilter, columnConfiguration.entityField, entityFilterValue);
        }
        return prevFilter;
      });
    },
    [columnConfiguration.entityField, tableState],
  );

  if (!columnConfiguration.isVisible || (tableState.isMobileScreenSize && !columnConfiguration.isMobileVisible))
    return undefined;

  return (
    <Table.HeadCell
      key={columnConfiguration.columnKey}
      hidden={!columnConfiguration.isVisible || (tableState.isMobileScreenSize && !columnConfiguration.isMobileVisible)}
      className={twMerge(className, columnConfiguration.columnHeaderClassName)}
      {...props}>
      <div className="flex flex-row items-center select-none overflow-hidden text-ellipsis whitespace-nowrap">
        <div
          className={`sm:whitespace-nowrap flex flex-row items-center ${
            allowOrder ? "cursor-pointer" : "cursor-default"
          }`}
          onPointerDown={(e) => {
            if (e.button !== RIGHT_BUTTON && allowOrder && columnConfiguration.entityField) {
              orderByColumn(columnConfiguration.entityField);
            }
          }}>
          {columnConfiguration.columnName.toLocaleUpperCase()}
          {sortDirection &&
            (sortDirection === SortDirection.Asc ? (
              <ArrowDownIcon className="ml-2 h-3 w-3" />
            ) : (
              <ArrowUpIcon className="ml-2 h-3 w-3" />
            ))}
        </div>
        {isFiltered && (
          <FunnelIcon
            className="ml-2 h-3 w-3 text-gray-400 cursor-pointer"
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) {
                tableState.setShowMoreOptions(!tableState.isShowingAllFilters);
              }
            }}
          />
        )}
      </div>
      {/* WARNING: This div is visible or invisible to avoid changing the size of the columns when filters require a bigger size than the header without the filter */}
      <div className={tableState.isShowingAllFilters ? "visible" : "invisible h-0 overflow-hidden"}>
        {columnConfiguration.enableFilter ? (
          columnConfiguration.renderColumnFilter ? (
            <span className="normal-case">{columnConfiguration.renderColumnFilter(tableState)}</span>
          ) : (
            columnConfiguration.entityField && (
              <DebouncedTextInput
                id={columnConfiguration.entityField}
                type="search"
                theme={FlowbiteTextInputThemes.underlined}
                value={
                  getPropertyByPath<T>(tableState.filters, columnConfiguration.entityField)
                    ? String(getPropertyByPath<T>(tableState.filters, columnConfiguration.entityField))
                    : undefined
                }
                onChange={onChangeFilterText}
              />
            )
          )
        ) : (
          <TextInput type="search" theme={FlowbiteTextInputThemes.underlined} disabled={true} />
        )}
      </div>
    </Table.HeadCell>
  );
};
