import React from "react";
import { twMerge } from "tailwind-merge";

export interface PagePanelContainerProps {
  hideOnMobileScreens?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const PagePanelContainer = ({ hideOnMobileScreens = false, children, className }: PagePanelContainerProps) => {
  return (
    <div
      className={twMerge(
        `${
          hideOnMobileScreens ? "hidden md:flex" : "flex flex-col"
        }  md:flex-row items-start justify-between md:items-center md:space-x-4 space-y-4 md:space-y-0 p-4 border-b dark:border-gray-700`,
        className,
      )}
    >
      {children}
    </div>
  );
};
