import { DocumentIcon, FilmIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { FILE_TYPE } from "@whyuz/data";
import { CSSProperties, useMemo } from "react";
import { BsFileWord } from "react-icons/bs";
import { GrDocumentZip } from "react-icons/gr";
import { ImFilePdf } from "react-icons/im";
import { SiMicrosoftexcel } from "react-icons/si";
import { twMerge } from "tailwind-merge";
import {
  getFileIconColorClassNameFromExtension,
  getFileIconColorStyleFromExtension,
  getFileTypeFromExtension,
} from ".";

export interface FileIconProps {
  fileExtension?: string;
  className?: string;
}

// Warning: this is being used in the html text editor so the style should run on any default html environment
const iconClass = "h-6 w-6 m-0.5 text-gray-900 dark:text-white rounded-sm";
const iconStyle: CSSProperties = {
  height: "1.5rem",
  width: "1.5rem",
  margin: "0.125rem",
  color: "rgb(17,24,39)",
  borderRadius: "0.125rem",
};

export const FileIcon = ({ fileExtension, className }: FileIconProps) => {
  const iconColorClass = useMemo(() => getFileIconColorClassNameFromExtension(fileExtension), [fileExtension]);
  const iconColorStyle = useMemo(() => getFileIconColorStyleFromExtension(fileExtension), [fileExtension]);
  const fileIcon = useMemo(() => {
    if (fileExtension) {
      switch (getFileTypeFromExtension(fileExtension)) {
        case FILE_TYPE.IMAGE:
          return (
            <PhotoIcon
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        case FILE_TYPE.VIDEO:
          return (
            <FilmIcon
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        case FILE_TYPE.COMPRESSED:
          return (
            <GrDocumentZip
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        case FILE_TYPE.PDF:
          return (
            <ImFilePdf
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        case FILE_TYPE.WORD:
          return (
            <BsFileWord
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        case FILE_TYPE.EXCEL:
          return (
            <SiMicrosoftexcel
              style={{ ...iconStyle, ...iconColorStyle }}
              className={twMerge(iconClass, iconColorClass, className)}
            />
          );
        default:
          return <DocumentIcon style={iconStyle} className={twMerge(iconClass, className)} />;
      }
    }
    return <DocumentIcon style={iconStyle} className={twMerge(iconClass, className)} />;
  }, [fileExtension, className, iconColorStyle, iconColorClass]);
  return fileIcon;
};
