import {
  CollaboratorAddAssetToSuccessCaseContentItemMutationVariables,
  CollaboratorRemoveAssetFromSuccessCaseContentItemMutationVariables,
  CollaboratorUpdateContentOfSuccessCaseContentItemMutationVariables,
  SuccessCaseContentItem,
} from "../../codegen/graphql";
import { CollaboratorSuccessCaseContentItemGQL } from "../../queries";
import { GQLQueryContext } from "../../types";
import { useGQLMutation } from "../useGQLQuery";

export const useCollaboratorAddAssetToSuccessCaseContentItemMutation = (
  mutationContext?: GQLQueryContext<
    SuccessCaseContentItem,
    CollaboratorAddAssetToSuccessCaseContentItemMutationVariables
  >,
) =>
  useGQLMutation<SuccessCaseContentItem, CollaboratorAddAssetToSuccessCaseContentItemMutationVariables>(
    CollaboratorSuccessCaseContentItemGQL.mutation.addAssetToSuccessCaseContentItem,
    mutationContext,
  );

export const useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation = (
  mutationContext?: GQLQueryContext<
    SuccessCaseContentItem,
    CollaboratorRemoveAssetFromSuccessCaseContentItemMutationVariables
  >,
) =>
  useGQLMutation<SuccessCaseContentItem, CollaboratorRemoveAssetFromSuccessCaseContentItemMutationVariables>(
    CollaboratorSuccessCaseContentItemGQL.mutation.removeAssetFromSuccessCaseContentItem,
    mutationContext,
  );

export const useCollaboratorUpdateContentOfSuccessCaseContentItemMutation = (
  mutationContext?: GQLQueryContext<
    SuccessCaseContentItem,
    CollaboratorUpdateContentOfSuccessCaseContentItemMutationVariables
  >,
) =>
  useGQLMutation<SuccessCaseContentItem, CollaboratorUpdateContentOfSuccessCaseContentItemMutationVariables>(
    CollaboratorSuccessCaseContentItemGQL.mutation.updateContentOfSuccessCaseContentItem,
    mutationContext,
  );
