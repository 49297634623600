import { EyeIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Table, Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { DeleteConfirmationModal, useModal } from "../../Modal";
import { EntityId } from "./AdvancedTableState.ts";

export interface AdvancedTableRowActionsCellProps<T extends EntityId> {
  entity: T;
  entityDescription: string;
  onViewEntity?: (entity: T) => void;
  onEditEntity?: (entity: T) => void;
  onDeleteEntity?: (entity: T) => void;
  children?: React.ReactNode;
}

export const AdvancedTableRowActionsCell = <T extends EntityId>({
  entity,
  entityDescription,
  onViewEntity,
  onEditEntity,
  onDeleteEntity,
  children,
}: AdvancedTableRowActionsCellProps<T>) => {
  const { t } = useTranslation();
  const {
    showModal: showDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
    modalProps: deleteConfirmationModalProps,
  } = useModal();

  return (
    <Table.Cell key={"rowactions" + (entity.id?.toString() ?? "")} className="py-3">
      <div className="flex items-center">
        {onViewEntity && (
          <Tooltip placement="bottom" content={t("buttons.view")}>
            <EyeIcon
              className="text-primary-600 dark:text-gray-300 dark:hover:text-white w-5 h-5 m-1 hover:w-7 hover:h-7 hover:m-0 cursor-pointer"
              onPointerDown={(e) => {
                if (e.button !== RIGHT_BUTTON) {
                  onViewEntity(entity);
                }
              }}
            />
          </Tooltip>
        )}
        {onEditEntity && (
          <Tooltip placement="bottom" content={t("buttons.edit")}>
            <PencilSquareIcon
              className="text-primary-600 dark:text-gray-300 dark:hover:text-white w-5 h-5 m-1 hover:w-7 hover:h-7 hover:m-0 cursor-pointer"
              onPointerDown={(e) => {
                if (e.button !== RIGHT_BUTTON) {
                  onEditEntity(entity);
                }
              }}
            />
          </Tooltip>
        )}
        {onDeleteEntity && (
          <>
            <Tooltip placement="bottom" content={t("buttons.delete")}>
              <TrashIcon
                className="text-red-600 dark:text-gray-300 dark:hover:text-red-700 w-5 h-5 m-1 hover:w-7 hover:h-7 hover:m-0 cursor-pointer"
                onPointerDown={(e) => {
                  if (e.button !== RIGHT_BUTTON) {
                    showDeleteConfirmationModal();
                  }
                }}
              />
            </Tooltip>
            <DeleteConfirmationModal
              onAcceptDeletion={() => {
                closeDeleteConfirmationModal();
                onDeleteEntity(entity);
              }}
              itemsDescription={[entityDescription]}
              {...deleteConfirmationModalProps}
            />
          </>
        )}
        {children}
      </div>
    </Table.Cell>
  );
};
