import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

const extensionName = "pasteFileHandler";

export type PasteFileHandlerOptions = {
  onFilePasted: (file: File) => void;
};

const handleFilePaste = (event: ClipboardEvent, onPasteEvent?: (file: File) => void): boolean => {
  if (event.clipboardData) {
    const { files } = event.clipboardData;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];
        if (onPasteEvent) {
          onPasteEvent(currentFile);
        }
      }
      return true;
    }
  }
  return false;
};

export const PasteFile = Extension.create<PasteFileHandlerOptions>({
  name: extensionName,

  addOptions() {
    return {
      onFilePasted: () => false,
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(extensionName),
        props: {
          handlePaste: (_view, event) => {
            return handleFilePaste(event, this.options.onFilePasted);
          },
        },
      }),
    ];
  },
});
