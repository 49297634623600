import { AdvancedTableState, EntityId } from "./AdvancedTableState.ts";
import { PaginationButtonGroup, PaginationInformation } from "../../Pagination";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { AdvancedTablePanelFooter } from "./AdvancedTablePanelFooter.tsx";

export interface AdvancedTablePanelPaginationProps<T extends EntityId, Filter extends T> {
  tableState?: AdvancedTableState<T, Filter>;
  onChangePage: (page: number) => void;
}

export const AdvancedTablePanelPagination = <T extends EntityId, Filter extends T>({
  tableState,
  onChangePage,
}: AdvancedTablePanelPaginationProps<T, Filter>) => {
  const { t } = useTranslation();

  return (
    <>
      {tableState?.pagination && (
        <AdvancedTablePanelFooter>
          <PaginationInformation pagination={tableState.pagination} />
          {tableState.pagination.totalPages > 0 && (
            <>
              <span className="hidden sm:flex text-sm font-normal text-gray-500 dark:text-gray-400 select-none">
                <span className="pr-2">{t("pagination.itemsperpage")}</span>
                <Dropdown inline size="sm" label={tableState.pagination.size.toString()}>
                  <Dropdown.Item
                    onPointerDown={() =>
                      tableState.setPagination((prev: any) => {
                        return { ...prev, number: 0, size: 10 };
                      })
                    }>
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    onPointerDown={() =>
                      tableState.setPagination((prev: any) => {
                        return { ...prev, number: 0, size: 25 };
                      })
                    }>
                    25
                  </Dropdown.Item>
                  <Dropdown.Item
                    onPointerDown={() =>
                      tableState.setPagination((prev: any) => {
                        return { ...prev, number: 0, size: 50 };
                      })
                    }>
                    50
                  </Dropdown.Item>
                  <Dropdown.Item
                    onPointerDown={() =>
                      tableState.setPagination((prev: any) => {
                        return { ...prev, number: 0, size: 100 };
                      })
                    }>
                    100
                  </Dropdown.Item>
                </Dropdown>
              </span>
              <PaginationButtonGroup
                pagination={tableState.pagination}
                onChangePage={(pageNumber) => {
                  tableState.setPagination({ ...tableState.pagination, number: pageNumber });
                  onChangePage(pageNumber);
                }}
              />
            </>
          )}
        </AdvancedTablePanelFooter>
      )}
    </>
  );
};
