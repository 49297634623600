import { observer } from "mobx-react";
import { hex2Rgba, rgba2Hex } from "@whyuz/utils";
import { HexInput } from "./HexInput.tsx";
import { RGBInput } from "./RGBInput.tsx";
import { AlphaInput } from "./AlphaInput.tsx";
import { RgbaColor } from "react-colorful";

interface HexRgbaBoxProps {
  selectedColor: RgbaColor;
  showTransparency?: boolean;
  onChange: (color: RgbaColor) => void;
}

export const HexRgbaBox = observer(({ selectedColor, showTransparency = false, onChange }: HexRgbaBoxProps) => {
  const inputContainerStyle = "flex flex-col items-center flex-1";
  const inputLabelStyle = "select-none text-gray-800 text-sm";
  const inputStyle =
    "text-gray-500 text-xs text-center outline-none focus-visible:bg-gray-200 focus-visible:text-gray-700";

  return (
    <div className="flex">
      <div className="flex flex-col items-center flex-1">
        <label className={inputLabelStyle}>HEX</label>
        <HexInput
          className={`w-14 ${inputStyle}`}
          hex={rgba2Hex(selectedColor)}
          onChange={(value: string) => {
            onChange(hex2Rgba(value));
          }}
        />
      </div>
      <div className={inputContainerStyle}>
        <label className={inputLabelStyle}>R</label>
        <RGBInput
          className={`w-6 ${inputStyle}`}
          rgb={String(selectedColor.r)}
          onChange={(value: string) => {
            onChange({ ...selectedColor, r: Number(value) });
          }}
        />
      </div>
      <div className={inputContainerStyle}>
        <label className={inputLabelStyle}>G</label>
        <RGBInput
          className={`w-6 ${inputStyle}`}
          rgb={String(selectedColor.g)}
          onChange={(value: string) => {
            onChange({ ...selectedColor, g: Number(value) });
          }}
        />
      </div>
      <div className={inputContainerStyle}>
        <label className={inputLabelStyle}>B</label>
        <RGBInput
          className={`w-6 ${inputStyle}`}
          rgb={String(selectedColor.b)}
          onChange={(value: string) => {
            onChange({ ...selectedColor, b: Number(value) });
          }}
        />
      </div>
      {showTransparency && (
        <div className={inputContainerStyle}>
          <label className={inputLabelStyle}>A</label>
          <AlphaInput
            className={`w-6 ${inputStyle}`}
            alpha={String(selectedColor.a)}
            onChange={(value: string) => {
              onChange({ ...selectedColor, a: Number(value) });
            }}
          />
        </div>
      )}
    </div>
  );
});
