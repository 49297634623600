import { Banner } from "@whyuz/components";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export interface SuccessCaseContentEditorQuestionInstructionsProps {
  instructions?: string;
}

export const SuccessCaseContentEditorQuestionInstructions = ({
  instructions,
}: SuccessCaseContentEditorQuestionInstructionsProps) => {
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");
  return instructions ? (
    <Banner>
      <>
        <div className="font-semibold text-sm flex flex-row space-x-2">
          <DocumentTextIcon className="h-4 w-4 text-primary-600" />
          <div>{tSuccessCase("successcasetemplatecontentitem.instructions")}</div>
        </div>
        <div className="text-xs">{instructions}</div>
      </>
    </Banner>
  ) : undefined;
};
