import { CODEC } from "@whyuz/data";
import { navigatorIsSafari, waitMS } from "@whyuz/utils";
import { useCallback, useRef, useState } from "react";

interface StartRecordingArgs {
  stream: MediaStream;
  codec?: MediaRecorderOptions;
  maxRecordingTimeMS?: number;
  onStart?: (event: Event) => void;
  onStop?: (event: Event) => void;
  onDataAvailable?: (event: Event, mimeType?: string) => void;
}

export type useMediaRecorderType = {
  mediaRecorder: MediaRecorder | null;
  startRecording: (args: StartRecordingArgs) => void;
  stopRecording: () => void;
  isRecordingInProgress: boolean;
  getRecordedData: () => Blob | null;
  chunks: BlobPart[];
  getRecordedDataURL: () => string | null;
};

export const useMediaRecorder = (): useMediaRecorderType => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecordingInProgress, setRecordingInProgress] = useState<boolean>(false);
  const recordedBlobRef = useRef<Blob | null>(null);
  const chunksRef = useRef<BlobPart[]>([]);

  const getBestSupportedCodecMimeType = (): MediaRecorderOptions => {
    // MediaRecorder API is not supported yet in Safari by default (June 2022)
    if (!navigatorIsSafari()) {
      if (MediaRecorder.isTypeSupported(CODEC.AV1)) return { mimeType: CODEC.AV1 };
      if (MediaRecorder.isTypeSupported(CODEC.AVCH264)) return { mimeType: CODEC.AVCH264 };
      if (MediaRecorder.isTypeSupported(CODEC.WEBM9)) return { mimeType: CODEC.WEBM9 };
      if (MediaRecorder.isTypeSupported(CODEC.WEBM8)) return { mimeType: CODEC.WEBM8 };
    }
    return { mimeType: CODEC.WEBM }; // Default codec webm
  };

  const getRecordedData = () => {
    return recordedBlobRef.current;
  };

  const getRecordedDataURL = () => {
    return recordedBlobRef.current ? URL.createObjectURL(recordedBlobRef.current) : null;
  };

  const startRecording = ({
    stream,
    codec,
    maxRecordingTimeMS,
    onStart,
    onStop,
    onDataAvailable,
  }: StartRecordingArgs) => {
    if (isRecordingInProgress) {
      throw new Error("Start recording is not possible when recording is in progress");
    }

    if (!stream) {
      throw new Error("Stream not valid");
    }

    if (!codec) {
      codec = getBestSupportedCodecMimeType();
    }

    const newMediaRecorder = new MediaRecorder(stream, codec);
    mediaRecorderRef.current = newMediaRecorder;
    if (!mediaRecorderRef.current) {
      throw new Error("Media recorder could not be created");
    }

    mediaRecorderRef.current.onstart = (event) => {
      setRecordingInProgress(true);
      if (onStart) {
        onStart(event);
      }
    };

    chunksRef.current = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
      if (onDataAvailable) {
        onDataAvailable(event, codec?.mimeType);
      }
    };

    mediaRecorderRef.current.onstop = (event) => {
      recordedBlobRef.current = new Blob(chunksRef.current, { type: codec?.mimeType });
      setRecordingInProgress(false);
      if (onStop) {
        onStop(event);
      }
    };

    mediaRecorderRef.current.start();
    if (maxRecordingTimeMS)
      waitMS(maxRecordingTimeMS)
        .then(() => {
          if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current = null;
          }
        })
        .catch((e) => console.error(e));
  };

  const stopRecording = useCallback(() => {
    if (!mediaRecorderRef.current || !isRecordingInProgress) {
      throw new Error("Recording in progress not found");
    }

    mediaRecorderRef.current.stop();
    mediaRecorderRef.current = null;
  }, [isRecordingInProgress]);

  return {
    mediaRecorder: mediaRecorderRef.current,
    startRecording,
    stopRecording,
    getRecordedDataURL,
    getRecordedData,
    chunks: chunksRef.current,
    isRecordingInProgress,
  };
};
