import { twMerge } from "tailwind-merge";

export interface SuccessCaseContentEditorQuestionContainerProps extends React.PropsWithChildren {
  className?: string;
}

export const SuccessCaseContentEditorQuestionContainer = ({
  className,
  children,
}: SuccessCaseContentEditorQuestionContainerProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col w-full max-w-full sm:max-w-[1000px] space-y-4 sm:space-y-8 p-2 sm:px-8 snap-center",
        className,
      )}>
      {children}
    </div>
  );
};
