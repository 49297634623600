import { UserRole } from "@whyuz/services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useUserRoleTranslations = () => {
  const { t } = useTranslation("usertenantrole");

  return useMemo((): Record<UserRole, string> => {
    return {
      [UserRole.Administrator]: t("userrole.administrator"),
      [UserRole.User]: t("userrole.user"),
    };
  }, [t]);
};
