import { useTranslation } from "react-i18next";
import { GenericError } from "./GenericError.tsx";
import { GQLErrorStatusCode } from "@whyuz/services";

export interface ErrorEntityNotFoundProps {
  description: string;
  onClose?: () => void;
}

export const ErrorEntityNotFound = ({ description, onClose }: ErrorEntityNotFoundProps) => {
  const { t } = useTranslation("common");

  return (
    <GenericError
      title={t("errors.notfound")}
      errorNumber={GQLErrorStatusCode.ENTITY_NOT_FOUND}
      description={description}
      onClose={onClose}
    />
  );
};
