import { DropFileZone, FileCard, FileUploadProgress } from "@whyuz/components";
import {
  Asset,
  SuccessCaseContentItem,
  useCollaboratorAddAssetToSuccessCaseContentItemMutation,
  useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation,
  useServerFiles,
} from "@whyuz/services";
import { EntityDictionary } from "@whyuz/types";
import { getFileExtension, notifyError } from "@whyuz/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorQuestionContainer } from "./SuccessCaseContentEditorQuestionContainer";
import { SuccessCaseContentEditorQuestionInstructions } from "./SuccessCaseContentEditorQuestionInstructions";
import { SuccessCaseContentEditorQuestionTitle } from "./SuccessCaseContentEditorQuestionTitle";

export interface SuccessCaseContentEditorQuestionFilesProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled?: boolean;
}

export const SuccessCaseContentEditorQuestionFiles = ({
  tenantId,
  question,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionFilesProps) => {
  const { t } = useTranslation();
  const { t: tContentItem } = useTranslation("contentItem");
  const serverFiles = useServerFiles();
  const [addAssetMutation] = useCollaboratorAddAssetToSuccessCaseContentItemMutation();
  const [removeAssetMutation] = useCollaboratorRemoveAssetFromSuccessCaseContentItemMutation();
  const [filesUploadProgress, setFilesUploadProgress] = useState<EntityDictionary<FileUploadProgress>>({});

  const onFileAdded = useCallback(
    (fileAdded: File) => {
      const newFileUploadProgress: FileUploadProgress = {
        file: fileAdded,
        extension: getFileExtension(fileAdded),
        progress: 0,
      };
      setFilesUploadProgress((prev) => {
        return { ...prev, [fileAdded.name]: newFileUploadProgress };
      });
      serverFiles
        .uploadFileAndGetAsset(fileAdded, false, tenantId, (progress) => {
          setFilesUploadProgress((prev) => {
            return { ...prev, [fileAdded.name]: { ...newFileUploadProgress, progress } };
          });
        })
        .then((response) => {
          addAssetMutation({
            variables: { contentItemId: question.id as string, assetId: response.data.id as string, tenantId },
          })
            .then((newQuestion) => {
              setFilesUploadProgress((prev) => {
                const newFilesUploading = { ...prev };
                delete newFilesUploading[fileAdded.name];
                return newFilesUploading;
              });
              onQuestionUpdated(newQuestion);
            })
            .catch(() => {
              setFilesUploadProgress((prev) => {
                return { ...prev, [fileAdded.name]: { ...newFileUploadProgress, progress: 0, error: true } };
              });
            });
        })
        .catch(() => {
          setFilesUploadProgress((prev) => {
            return { ...prev, [fileAdded.name]: { ...newFileUploadProgress, progress: 0, error: true } };
          });
        });
    },
    [addAssetMutation, onQuestionUpdated, question.id, serverFiles, tenantId],
  );

  const onFileRemove = useCallback(
    (_id: string, fileName: string, asset: Asset) => {
      removeAssetMutation({
        variables: { contentItemId: question.id as string, assetId: asset.id as string, deleteAsset: true, tenantId },
      })
        .then((newQuestion) => {
          onQuestionUpdated(newQuestion);
        })
        .catch((e) => notifyError(tContentItem("errors.deletingattachedfile") + " " + fileName + ": " + (e as string)));
    },
    [removeAssetMutation, question.id, tenantId, onQuestionUpdated, tContentItem],
  );

  return (
    <SuccessCaseContentEditorQuestionContainer>
      <SuccessCaseContentEditorQuestionTitle title={question.title as string} />
      <SuccessCaseContentEditorQuestionInstructions instructions={question.htmlInstructions as string} />
      <DropFileZone
        maxSizeInBytes={question.contentMaxSize ? question.contentMaxSize * 1024 * 1024 : undefined}
        disabled={disabled}
        type="multiple-files"
        onFileAdded={onFileAdded}
        theme={{ placeholder: "border-primary-300 bg-primary-50 hover:bg-primary-100" }}
      />
      {((question.assets && question.assets.length > 0) || Object.entries(filesUploadProgress).length > 0) && (
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-normal text-gray-900">{t("word.files")}</p>
          <ul className="pt-4 w-full space-y-2">
            {question.assets &&
              question.assets.map((asset) =>
                !asset ? null : (
                  <li key={asset.id as string} className="w-full">
                    <FileCard
                      key={asset.id as string}
                      url={serverFiles.getFileURIByAssetToken(asset.accessToken as string)}
                      fileName={asset.originalFilename as string}
                      fileSizeBytes={asset.fileSizeBytes as number}
                      fileExtension={asset.originalExtension as string}
                      onRemove={disabled ? undefined : (id, fileName) => onFileRemove(id, fileName, asset)}
                    />
                  </li>
                ),
              )}
            {Object.values(filesUploadProgress).map((fileUploadProgress, idx) => (
              <li key={idx} className="w-full">
                <FileCard
                  key={fileUploadProgress.file.name}
                  url={URL.createObjectURL(fileUploadProgress.file)}
                  fileName={fileUploadProgress.file.name}
                  fileSizeBytes={fileUploadProgress.file.size}
                  fileExtension={fileUploadProgress.extension}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </SuccessCaseContentEditorQuestionContainer>
  );
};
