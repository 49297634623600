import { CheckIcon } from "@heroicons/react/24/outline";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Button, ButtonProps, Tooltip } from "flowbite-react";
import { twMerge } from "tailwind-merge";

export interface PageSaveButtonProps extends ButtonProps {
  text: string;
  showText?: boolean;
  onSave: () => void;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
}

export const PageSaveButton = ({
  text,
  showText = true,
  onSave,
  className,
  type = "button",
  disabled,
  ...props
}: PageSaveButtonProps) => {
  return (
    <Tooltip content={text} placement="bottom" className={`${showText ? "hidden" : undefined}`}>
      <Button
        type={type}
        size="sm"
        color="primary"
        className={twMerge(`px-0`, className, `${showText ? "sm:px-0.5" : ""}`)}
        onPointerDown={(e: React.PointerEvent) => {
          if (!disabled && e.button !== RIGHT_BUTTON) {
            onSave();
          }
        }}
        disabled={disabled}
        {...props}>
        <CheckIcon className={`h-4 w-4 stroke-2 self-center`} />
        {showText && <span className="hidden sm:block ml-2">{text}</span>}
      </Button>
    </Tooltip>
  );
};
