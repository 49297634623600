import { ENDPOINT } from "@whyuz/data";
import { isEmpty } from "@whyuz/utils";
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useKeycloak, useSession } from ".";

interface Props {
  endpoint: string;
  baseURL?: string;
  contentType?: string;
}

export const useAxios = <T, D>({ endpoint, baseURL = ENDPOINT.REST, contentType = "application/json" }: Props) => {
  const axiosInstance = useRef<AxiosInstance>(axios.create());
  const { initialized, keycloak } = useKeycloak();
  const { sessionToken } = useSession();
  const isUserAuthenticated = useMemo(() => {
    return initialized && keycloak?.authenticated && keycloak?.token ? true : false;
  }, [initialized, keycloak?.authenticated, keycloak?.token]);

  useEffect(() => {
    axiosInstance.current =
      isUserAuthenticated && !isEmpty(keycloak.token)
        ? axios.create({
            baseURL,
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
              "Content-type": contentType,
              "X-SESSION-TOKEN": sessionToken,
            },
          })
        : axios.create({
            baseURL,
          });

    return () => {
      // axiosInstance.current = undefined;
    };
  }, [baseURL, isUserAuthenticated, keycloak.token, contentType, sessionToken]);

  const GETURI = useCallback(
    (params?: { id?: string; queryParameters?: string }): string => {
      return (
        endpoint + (params?.id ? "/" + params?.id : "") + (params?.queryParameters ? "?" + params?.queryParameters : "")
      );
    },
    [endpoint],
  );

  const GET = useCallback(
    (
      params?: { id?: string; queryParameters?: string },
      config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T, D>> | undefined => {
      const uri = GETURI({
        id: params?.id,
        queryParameters: params?.queryParameters,
      });
      return axiosInstance.current.get<T, AxiosResponse<T, D>, D>(uri, config);
    },
    [GETURI],
  );

  const POST = useCallback(
    (data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
      return axiosInstance.current.post<T, AxiosResponse<T, D>, D>(endpoint, data, config);
    },
    [endpoint],
  );

  const PUT = useCallback(
    (data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
      return axiosInstance.current.put<T, AxiosResponse<T, D>, D>(endpoint, data, config);
    },
    [endpoint],
  );

  const PATCH = useCallback(
    (data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
      return axiosInstance.current.patch<T, AxiosResponse<T, D>, D>(endpoint, data, config);
    },
    [endpoint],
  );

  const DELETE = useCallback(
    (config?: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
      return axiosInstance.current?.delete<T, AxiosResponse<T, D>, D>(endpoint, config);
    },
    [endpoint],
  );

  return { axiosInstance, GET, GETURI, POST, PUT, PATCH, DELETE };
};

/*
  const customersAPI = useAxios(API_REST_ENDPOINT.CUSTOMERS);
  const contactsAPI = useAxios(API_REST_ENDPOINT.CONTACTS);
  const companiesAPI = useAxios(API_REST_ENDPOINT.COMPANIES);
  const usersAPI = useAxios(API_REST_ENDPOINT.USERS);

  return (
    <div>
        <Button onPointerDown={e => customersAPI.GET({queryParameters: 'details=true'})?.then(response => console.log(response))}>Customers</Button>
        <Button onPointerDown={e => contactsAPI.GET({queryParameters: 'details=true'})?.then(response => console.log(response))}>Contacts</Button>
        <Button onPointerDown={e => companiesAPI.GET({queryParameters: 'details=true'})?.then(response => console.log(response))}>Companies</Button>
        <Button onPointerDown={e => usersAPI.GET({queryParameters: 'details=true'})?.then(response => console.log(response))}>Users</Button>
    </div>
  )
  */
