import { MediaStreamComposer } from "@api.video/media-stream-composer";
import { StreamUserOptions } from "@api.video/media-stream-composer/dist/src/stream/stream";
import { useMediaRecorder, useMediaStream, useScreenSize } from "@whyuz/hooks";
import {
  convertVideoBlobToSeekable,
  getAvailableCameraDevices,
  getAvailableMicrophoneDevices,
  getCameraDevice,
  getMicrophoneDevice,
} from "@whyuz/utils";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { DeviceConfigurationModal } from "./components/DeviceConfigurationModal.tsx";
import { ScreenAndCameraRecorderControls } from "./components/ScreenAndCameraRecorderControls.tsx";
import { Actions, RecorderState, recorderStateReducer } from "./context/VideoRecorderReducer.ts";

const RESOLUTION_WIDTH = window.screen.width ?? 1280;
const RESOLUTION_HEIGHT = window.screen.height ?? 720;

export interface VideoRecorderProps {
  maxRecordingTimeMS?: number;
  onStartRecording?: () => void;
  onDataAvailable?: (event: Event) => void;
  onVideoRecorded: (videoBlob: Blob) => void;
}

const initialRecorderState: RecorderState = {
  isScreenShareAvailable:
    typeof navigator !== "undefined" && navigator.mediaDevices && "getDisplayMedia" in navigator.mediaDevices,
  isRecordingInProgress: false,
  mouseTool: "draw",
  isCameraEnabled: true,
  isCameraMirrorEnabled: true,
  isMicrophoneEnabled: true,
  isScreenShared: false,
  isCameraShapeCircle: true,
  isCanvasInitialized: false,
  showDeviceConfigurationModal: false,
  drawingSettings: {
    color: "#00BFFE",
    lineWidth: 4,
    autoEraseDelay: 0,
  },
};

export const VideoRecorder = ({
  maxRecordingTimeMS,
  onStartRecording,
  onDataAvailable,
  onVideoRecorded,
}: VideoRecorderProps) => {
  const [recorderState, dispatchRecorderState] = useReducer(recorderStateReducer, initialRecorderState);
  // const mediaRecorder = useReactMediaRecorder({ customMediaStream: composer.result });
  const cameraStream = useMediaStream();
  const screenStream = useMediaStream();
  const mediaRecorder = useMediaRecorder();
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const canvasContainerDivRef = useRef<HTMLDivElement>(null);
  const isCameraStartingRef = useRef<boolean>(false);
  const { isMobileScreenSize } = useScreenSize();

  const composerRef = useRef<MediaStreamComposer>(
    new MediaStreamComposer({
      resolution: {
        width: RESOLUTION_WIDTH,
        height: RESOLUTION_HEIGHT,
      },
    }),
  );

  useEffect(() => {
    const composer = composerRef.current;
    composer.setMouseTool("draw");
    return () => {
      composer.destroy();
    };
  }, []);

  useEffect(() => {
    const stream = cameraStream.stream;
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [cameraStream.stream]);

  useEffect(() => {
    const stream = screenStream.stream;
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [screenStream.stream]);

  useEffect(() => {
    composerRef.current.setDrawingSettings({
      color: recorderState.drawingSettings.color,
      lineWidth: recorderState.drawingSettings.lineWidth,
      autoEraseDelay: recorderState.drawingSettings.autoEraseDelay,
    });
  }, [
    recorderState.drawingSettings.autoEraseDelay,
    recorderState.drawingSettings.color,
    recorderState.drawingSettings.lineWidth,
  ]);

  useEffect(() => {
    if (recorderState.showDeviceConfigurationModal) {
      getAvailableMicrophoneDevices()
        .then(setAudioDevices)
        .catch((e) => alert(e));
      getAvailableCameraDevices()
        .then(setVideoDevices)
        .catch((e) => alert(e));
    }
  }, [recorderState.showDeviceConfigurationModal]);

  const calculateCameraStreamOptions = useCallback(
    (stream: MediaStream): StreamUserOptions => {
      const canvas = composerRef.current.getCanvas();
      const cameraVideoTrackSettings = stream.getVideoTracks()[0]?.getSettings();
      if (!(cameraVideoTrackSettings && cameraVideoTrackSettings.width && cameraVideoTrackSettings.height)) {
        throw new Error("Calculate camera stream options is not possible without a camera stream");
      }

      const cameraAspectRatio = cameraVideoTrackSettings.width / cameraVideoTrackSettings.height;
      if (recorderState.isScreenShared && canvas && recorderState.screenStreamId) {
        const width = recorderState.isCameraShapeCircle ? canvas.width * 0.2 : canvas.width * 0.3;
        const height = recorderState.isCameraShapeCircle ? width : width / cameraAspectRatio;
        const x = canvas.width - (recorderState.isCameraShapeCircle ? 1.1 : 1) * width;
        const y = canvas.height - (recorderState.isCameraShapeCircle ? 1.1 : 1) * height;
        return {
          x: x,
          y: y,
          width: width,
          height: height,
          position: "fixed",
          mask: recorderState.isCameraShapeCircle ? "circle" : "none",
          draggable: true,
          resizable: true,
        };
      } else {
        return {
          width: cameraVideoTrackSettings.width,
          height: cameraVideoTrackSettings.height,
          position: "cover",
          mask: "none",
          draggable: false,
          resizable: false,
        };
      }
    },
    [recorderState.isCameraShapeCircle, recorderState.isScreenShared, recorderState.screenStreamId],
  );

  const updateCameraStreamWithNewOptions = useCallback(() => {
    if (recorderState.cameraStreamId) {
      const camStreamDetails = composerRef.current.getStream(recorderState.cameraStreamId);
      const camStream = camStreamDetails?.stream;
      if (camStream)
        composerRef.current.updateStream(recorderState.cameraStreamId, calculateCameraStreamOptions(camStream));
    }
  }, [recorderState.cameraStreamId, calculateCameraStreamOptions]);

  useEffect(() => {
    updateCameraStreamWithNewOptions();
  }, [recorderState.isCameraShapeCircle, updateCameraStreamWithNewOptions]);

  // const stopCameraStream = useCallback(() => {
  //   if (recorderState.cameraStreamId) {
  //     composerRef.current.removeStream(recorderState.cameraStreamId);
  //     cameraStream.stopMediaStream();
  //   }
  // }, [cameraStream, recorderState.cameraStreamId]);

  const startCameraStream = useCallback(() => {
    if (isCameraStartingRef.current) {
      return;
    }
    isCameraStartingRef.current = true;

    const startCameraMediaStreamAndStopPreviousOne = (cameraDeviceId: string, microphoneDeviceId: string) => {
      if (recorderState.cameraStreamId && cameraStream.stream) {
        const currentAudioDeviceId = cameraStream.stream.getAudioTracks()[0].getSettings().deviceId;
        const currentVideoDeviceId = cameraStream.stream.getVideoTracks()[0].getSettings().deviceId;
        if (microphoneDeviceId !== currentAudioDeviceId || cameraDeviceId !== currentVideoDeviceId) {
          composerRef.current.removeStream(recorderState.cameraStreamId);
          cameraStream.stopMediaStream();
        }
      }

      if (!cameraStream.stream) {
        cameraStream
          .startMediaStream(
            {
              audio: {
                deviceId: microphoneDeviceId,
              },
              video: {
                deviceId: cameraDeviceId,
              },
            },
            recorderState.isCameraEnabled,
          )
          .then((stream) => {
            composerRef.current
              .addStream(stream, calculateCameraStreamOptions(stream))
              .then((newCameraStreamId) => {
                composerRef.current.moveUp(newCameraStreamId);
                const canvas = composerRef.current.getCanvas();
                if (canvas && canvasContainerDivRef.current && !canvasContainerDivRef.current.contains(canvas)) {
                  composerRef.current.appendCanvasTo("#canvas-container");
                  canvas.style.width = "100%";
                  canvas.style.boxSizing = "unset";
                }
                dispatchRecorderState(new Actions.ActionSetCameraStreamId(newCameraStreamId));
              })
              .catch((error) => alert(error));
          })
          .catch((error) => alert(error))
          .finally(() => {
            isCameraStartingRef.current = false;
          });
      } else {
        isCameraStartingRef.current = false;
      }
    };

    if (!recorderState.cameraDeviceId || !recorderState.microphoneDeviceId) {
      Promise.all([getCameraDevice(), getMicrophoneDevice()])
        .then(([cameraDevice, microphoneDevice]) => {
          if (cameraDevice && microphoneDevice) {
            startCameraMediaStreamAndStopPreviousOne(cameraDevice.deviceId, microphoneDevice.deviceId);
          }
          dispatchRecorderState(new Actions.ActionSetCameraDevice(cameraDevice?.deviceId));
          dispatchRecorderState(new Actions.ActionSetMicrophoneDevice(microphoneDevice?.deviceId));
        })
        .catch((e) => alert(e))
        .finally(() => (isCameraStartingRef.current = false));
    } else {
      startCameraMediaStreamAndStopPreviousOne(recorderState.cameraDeviceId, recorderState.microphoneDeviceId);
    }
  }, [
    calculateCameraStreamOptions,
    cameraStream,
    recorderState.cameraDeviceId,
    recorderState.cameraStreamId,
    recorderState.isCameraEnabled,
    recorderState.microphoneDeviceId,
  ]);

  useEffect(() => {
    if (!recorderState.cameraStreamId && recorderState.isCameraEnabled) {
      startCameraStream();
    }
  }, [recorderState.cameraStreamId, recorderState.isCameraEnabled, startCameraStream]);

  const onToggleMuteMicrophone = useCallback(() => {
    const stream = cameraStream.stream;
    if (!stream) return;
    cameraStream.setStreamMicrophoneEnabled(!recorderState.isMicrophoneEnabled);
    dispatchRecorderState(new Actions.ActionToggleMicrophone());
  }, [recorderState.isMicrophoneEnabled, cameraStream]);

  const onToggleMouseTool = useCallback(() => {
    composerRef.current.setMouseTool(recorderState.mouseTool === "draw" ? "move-resize" : "draw");
    dispatchRecorderState(new Actions.ActionToggleMouseTool());
  }, [recorderState.mouseTool]);

  const onToggleScreenShare = useCallback(() => {
    if (!recorderState.isScreenShared) {
      if (recorderState.screenStreamId) return; // If the screen is shared and the stream is created: nothing to be done

      const onScreenShareEnd = () => {
        dispatchRecorderState(new Actions.ActionSetScreenShared(false));
      };

      screenStream
        .startDisplayMediaStream(true, onScreenShareEnd)
        .then((newScreenStream) => {
          composerRef.current
            .addStream(newScreenStream, {
              width: newScreenStream.getVideoTracks()[0].getSettings().width,
              height: newScreenStream.getVideoTracks()[0].getSettings().height,
              position: "contain",
              mask: "none",
              draggable: false,
              resizable: false,
            })
            .then((newScreenStreamId) => {
              updateCameraStreamWithNewOptions();
              composerRef.current.moveDown(newScreenStreamId);
              dispatchRecorderState(new Actions.ActionSetScreenStreamId(newScreenStreamId));
              dispatchRecorderState(new Actions.ActionSetScreenShared(true));
            })
            .catch(() => {
              if (screenStream.stream) screenStream.stopMediaStream();
              dispatchRecorderState(new Actions.ActionSetScreenShared(false));
            });
        })
        .catch(() => {
          if (screenStream.stream) screenStream.stopMediaStream();
          dispatchRecorderState(new Actions.ActionSetScreenShared(false));
        });
    } else {
      if (recorderState.screenStreamId) {
        composerRef.current.removeStream(recorderState.screenStreamId);
        dispatchRecorderState(new Actions.ActionSetScreenStreamId(undefined));
      }
      if (screenStream.stream) {
        screenStream.stopMediaStream();
      }
      updateCameraStreamWithNewOptions();
      dispatchRecorderState(new Actions.ActionSetScreenShared(false));
    }
  }, [recorderState.isScreenShared, recorderState.screenStreamId, screenStream, updateCameraStreamWithNewOptions]);

  const onToggleVideoRecording = useCallback(() => {
    if (recorderState.isRecordingInProgress) {
      if (mediaRecorder.isRecordingInProgress) {
        mediaRecorder.stopRecording();
      }
      dispatchRecorderState(new Actions.ActionSetRecordingInProgress(false));
    } else {
      const resultStream = composerRef.current.getResultStream();
      if (!mediaRecorder.isRecordingInProgress && resultStream) {
        mediaRecorder.startRecording({
          stream: resultStream,
          onStart: () => {
            // Event notification
            if (onStartRecording) {
              onStartRecording();
            }
          },
          onDataAvailable: (event: Event) => {
            if (onDataAvailable) {
              onDataAvailable(event);
            }
          },
          onStop: () => {
            const videoBlob = mediaRecorder.getRecordedData() ?? undefined;
            if (videoBlob) {
              convertVideoBlobToSeekable(videoBlob)
                .then((seekableVideoBlob) => {
                  dispatchRecorderState(new Actions.ActionSetVideoBlob(seekableVideoBlob));
                  if (onVideoRecorded && seekableVideoBlob) {
                    onVideoRecorded(seekableVideoBlob);
                  }
                })
                .catch((e) => console.error(e));
            }
          },
          maxRecordingTimeMS,
        });
      }
      dispatchRecorderState(new Actions.ActionSetRecordingInProgress(true));
    }
  }, [
    recorderState.isRecordingInProgress,
    mediaRecorder,
    maxRecordingTimeMS,
    onStartRecording,
    onDataAvailable,
    onVideoRecorded,
  ]);

  const onToggleCamera = useCallback(() => {
    const stream = cameraStream.stream;

    const toggleCameraEnabled = () => {
      if (!stream || !recorderState.cameraStreamId) return;
      cameraStream.setStreamVideoEnabled(!recorderState.isCameraEnabled);
      composerRef.current.updateStream(recorderState.cameraStreamId, { hidden: recorderState.isCameraEnabled });
      dispatchRecorderState(new Actions.ActionToggleCamera());
    };

    // const toggleCameraStream = () => {
    //   if (!stream || !recorderState.cameraStreamId) {
    //     startCameraStream();
    //   } else {
    //     stopCameraStream();
    //   }
    //   dispatchRecorderState(new Actions.ActionToggleCamera());
    // };

    toggleCameraEnabled(); // We avoid loosing the position of the camera canvas in the shared screen
  }, [cameraStream, recorderState.cameraStreamId, recorderState.isCameraEnabled]);

  return (
    <div>
      {!recorderState.isScreenShareAvailable ? (
        <p>Your browser does not support screen sharing. Please try another browser.</p>
      ) : (
        <div className="relative group flex justify-center mx-auto">
          <div
            id="canvas-container"
            ref={canvasContainerDivRef}
            className={`w-full bg-gray-800 ${
              recorderState.isCameraMirrorEnabled && !recorderState.isScreenShared ? "scale-x-[-1]" : "scale-x-[1]"
            }`}
          />
          <div
            className={`absolute bottom-0 w-full mb-[20px] ${
              recorderState.isRecordingInProgress || recorderState.isScreenShared || isMobileScreenSize
                ? "block"
                : "hidden group-hover:block"
            }`}>
            <ScreenAndCameraRecorderControls
              isRecordingInProgress={recorderState.isRecordingInProgress}
              isCameraEnabled={recorderState.isCameraEnabled}
              isMicrophoneEnabled={recorderState.isMicrophoneEnabled}
              isScreenShared={recorderState.isScreenShared}
              isCameraShapeCircle={recorderState.isCameraShapeCircle}
              mouseTool={recorderState.mouseTool}
              onToggleVideoRecording={onToggleVideoRecording}
              onToggleCamera={onToggleCamera}
              onToggleMuteMicrophone={onToggleMuteMicrophone}
              onToggleScreenShare={onToggleScreenShare}
              onToggleMouseTool={onToggleMouseTool}
              onToggleCameraShape={() => dispatchRecorderState(new Actions.ActionToggleCameraShape())}
              onToggleCameraMirror={() => dispatchRecorderState(new Actions.ActionToggleCameraMirror())}
              onClearDrawings={() => composerRef.current.clearDrawing()}
              onShowDeviceConfigurationModal={() =>
                dispatchRecorderState(new Actions.ActionSetShowDeviceConfigurationModal(true))
              }
            />
            <DeviceConfigurationModal
              audioDevices={audioDevices}
              videoDevices={videoDevices}
              selectedAudioDeviceId={recorderState.microphoneDeviceId}
              onSelectedAudioDevice={(deviceId) =>
                dispatchRecorderState(new Actions.ActionSetMicrophoneDevice(deviceId))
              }
              selectedCameraDeviceId={recorderState.cameraDeviceId}
              onSelectedCameraDevice={(deviceId) => dispatchRecorderState(new Actions.ActionSetCameraDevice(deviceId))}
              open={recorderState.showDeviceConfigurationModal}
              onClose={() => dispatchRecorderState(new Actions.ActionSetShowDeviceConfigurationModal(false))}
            />
          </div>
        </div>
      )}
    </div>
  );
};
