import {
  ApprovalFlowTemplate,
  ApprovalFlowTemplateStep,
  ApprovalFlowTemplateUser,
  Error_Code,
  GQLError,
  GQLErrorStatusCode,
  useAddApprovalFlowTemplateStepMutation,
  useAddApprovalFlowTemplateUserMutation,
  useApprovalFlowTemplateLazyQuery,
  useDeleteApprovalFlowTemplateMutation,
  useDeleteApprovalFlowTemplateStepMutation,
  useDeleteApprovalFlowTemplateUserMutation,
  useUpdateApprovalFlowTemplateMutation,
  useUpdateApprovalFlowTemplateStepMutation,
  useUpdateApprovalFlowTemplateStepPositionMutation,
} from "@whyuz/services";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { isEmail, notifyError, notifyInfo } from "@whyuz/utils";
import { AddUserProps } from "../ApprovalFlows/components/ApprovalFlowStep/ApprovalFlowStep.tsx";
import { useDebounce } from "@whyuz/hooks";
import { useTextEditor, useVariablesSuggestionExtension } from "@whyuz/components";
import { Editor } from "@tiptap/react";
import { useMailTemplateVariablesToSuggest } from "../MailTemplates";
export interface IApprovalFlowTemplateBuilder {
  approvalFlowTemplateId?: string;
  approvalFlowTemplate?: ApprovalFlowTemplate;
  approvalFlowTemplateSteps?: ApprovalFlowTemplateStep[];
  setSteps: Dispatch<SetStateAction<ApprovalFlowTemplateStep[]>>;
  consolidateSteps: (stepToChangePosition: ApprovalFlowTemplateStep, newPosition: number) => void;
  addStep: () => void;
  updateStep: (step: ApprovalFlowTemplateStep) => void;
  deleteStep: (id: string) => void;
  addUser: (props: AddUserProps) => void;
  deleteUser: (user: ApprovalFlowTemplateUser, stepId: string) => void;
  error?: GQLError;
  hasEverBeenExecuted: boolean;
  isLoading: boolean;
  deleteApprovalFlowTemplate: () => void;
  setError: Dispatch<SetStateAction<GQLError | undefined>>;
  approvalName: string | undefined;
  setApprovalName: (e: string | undefined) => void;
  approvalMailAccountId: string | undefined;
  setApprovalMailAccountId: (mailAccountId: string | undefined) => void;
  onUseMailTemplate: (messageHtml: string, subjectHtml: string) => void;
  subjectEditor: Editor;
  messageEditor: Editor;
}

const ApprovalFlowBuilderContext = createContext<IApprovalFlowTemplateBuilder | null>(null);

export const useApprovalFlowTemplateBuilderContext = () => {
  const context = useContext(ApprovalFlowBuilderContext) as IApprovalFlowTemplateBuilder;
  if (!context) {
    throw new Error("useApprovalFlowBuilder must be used within an ApprovalFlowBuilderProvider");
  }
  return context;
};

interface ApprovalFlowBuilderProviderProps {
  children: ReactNode;
  approvalFlowTemplateId?: string;
}

interface EditorDebounce {
  key: number;
  field: "body" | "subject" | "name" | null;
}
export const ApprovalFlowTemplateBuilderProvider = ({
  approvalFlowTemplateId,
  children,
}: ApprovalFlowBuilderProviderProps) => {
  const [approvalFlowTemplateData, setApprovalFlowTemplateData] = useState<ApprovalFlowTemplate>();
  const [approvalSteps, setApprovalSteps] = useState<ApprovalFlowTemplateStep[]>([]);
  const [error, setError] = useState<GQLError>();
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const [keyEditor, setKeyEditor] = useState<EditorDebounce>({ key: 0, field: null });
  const [approvalName, setApprovalName] = useState<string | undefined>();
  const [mailAccountId, setMailAccountId] = useState<string | undefined>();
  const debouncedEditor = useDebounce<EditorDebounce>(keyEditor, 5000);
  const areUpdatingsPendings = useRef(false);

  const variablesToUse = useMailTemplateVariablesToSuggest();
  const variablesSuggestionExtension = useVariablesSuggestionExtension(variablesToUse.APPROVAL_SUCCESS_CASE_EXECUTION);
  const bodyMailEditor = useTextEditor({
    content: approvalFlowTemplateData?.messageHtml as string,
    uploadPublicFiles: true,
    onChange: () => {
      setKeyEditor({ key: Date.now(), field: "body" });
      areUpdatingsPendings.current = true;
    },
    additionalExtensions: [variablesSuggestionExtension],
  });

  const subjectMailEditor = useTextEditor({
    content: approvalFlowTemplateData?.subjectHtml as string,
    uploadPublicFiles: true,
    onChange: () => {
      setKeyEditor({ key: Date.now(), field: "body" });
      areUpdatingsPendings.current = true;
    },
  });

  const handleChangeName = (e: string | undefined) => {
    setApprovalName(e);
    setKeyEditor({ key: Date.now(), field: "name" });
    areUpdatingsPendings.current = true;
  };

  //! Mutations and queries
  const [approvalLazyQuery, { hasEverBeenExecuted, isLoading }] = useApprovalFlowTemplateLazyQuery();
  const [mutateStep] = useUpdateApprovalFlowTemplateStepMutation();
  const [mutatePosition] = useUpdateApprovalFlowTemplateStepPositionMutation();
  const [addApprovalTemplateStep] = useAddApprovalFlowTemplateStepMutation();
  const [deleteApprovalTemplateStep] = useDeleteApprovalFlowTemplateStepMutation();
  const [addApprovalTemplateUser] = useAddApprovalFlowTemplateUserMutation();
  const [deleteApprovalTemplateUser] = useDeleteApprovalFlowTemplateUserMutation();
  const [deleteApprovalFlowTemplate] = useDeleteApprovalFlowTemplateMutation();
  const [updateApprovalFlowTemplate] = useUpdateApprovalFlowTemplateMutation();

  const handleLoadApprovalFlow = useCallback(() => {
    approvalLazyQuery({ variables: { id: approvalFlowTemplateId } })
      .then((approval) => {
        if (approval) {
          setApprovalFlowTemplateData(approval);
          const stepsList = (approval?.steps ?? []) as ApprovalFlowTemplateStep[];
          const steps = stepsList.sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          setApprovalSteps(steps);
          bodyMailEditor?.commands.setContent(approval.messageHtml as string);
          subjectMailEditor?.commands.setContent(approval?.subjectHtml as string);
          setApprovalName(approval.name as string);
          setMailAccountId(approval.mailAccount?.id as string);
        } else {
          setError({
            isUncontrolledError: true,
            statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
            message: tApproval("approvals") + " " + String(approvalFlowTemplateId),
            fieldErrors: {},
          });
        }
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.httperror400detail"));
      });
  }, [approvalLazyQuery, approvalFlowTemplateId, tApproval, t, bodyMailEditor, subjectMailEditor]);

  useEffect(() => {
    if (approvalFlowTemplateId) {
      handleLoadApprovalFlow();
    }
  }, [handleLoadApprovalFlow, approvalFlowTemplateId]);

  //! Steps mutations and another logic
  const onStepsMutationsSuccess = (response: ApprovalFlowTemplateStep) => {
    setApprovalSteps((prev) => {
      return prev
        .reduce((acc, next) => {
          if (next.id === response.id) {
            return [...acc, response];
          } else {
            return [...acc, next];
          }
        }, [] as ApprovalFlowTemplateStep[])
        .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
    });
  };
  const handleConsolidateSteps = (stepToChangePosition: ApprovalFlowTemplateStep, newPosition: number) => {
    if (approvalSteps && approvalSteps?.length > 1) {
      mutatePosition({
        variables: {
          approvalFlowTemplateStepId: stepToChangePosition.id as string,
          newPosition,
        },
      })
        .then((res) => {
          onStepsMutationsSuccess(res);
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.updatingentity"));
          setApprovalSteps((approvalFlowTemplateData?.steps as ApprovalFlowTemplateStep[]) || []);
        });
    }
  };

  const handleUpdateStep = (step: ApprovalFlowTemplateStep) => {
    mutateStep({
      variables: {
        id: step?.id as string,
        approvalFlowTemplateStep: {
          stepNumber: step?.stepNumber,
          approvalFlowTemplateId: approvalFlowTemplateId,
          requiredAll: step?.requiredAll,
          messageHtml: step?.messageHtml,
          subjectHtml: step?.subjectHtml,
        },
      },
    })
      .then((res) => {
        onStepsMutationsSuccess(res);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.updatingentity"));
        setApprovalSteps((approvalFlowTemplateData?.steps as ApprovalFlowTemplateStep[]) || []);
      });
  };

  const handleAddStep = () => {
    addApprovalTemplateStep({
      variables: {
        approvalFlowTemplateStep: {
          stepNumber: approvalSteps?.length,
          messageHtml: null,
          subjectHtml: null,
          requiredAll: true,
          approvalFlowTemplateId: approvalFlowTemplateId,
        },
      },
    })
      .then((res) => {
        setApprovalSteps((prev) => [...prev, res]);
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.addingentity"));
      });
  };

  const handleAddUser = ({ name, userId, stepId, email }: AddUserProps) => {
    if (email && !isEmail(email)) {
      setError({
        message: t("errors.validationfailed"),
        fieldErrors: {
          userId: {
            entity: "approvalFlowUser",
            field: "userId",
            error: Error_Code.IncorrectValue,
          },
        },
        isUncontrolledError: false,
      });
    } else {
      addApprovalTemplateUser({
        variables: {
          approvalFlowTemplateUser: {
            name: name,
            userId: userId,
            approvalFlowTemplateStepId: stepId,
            email,
          },
        },
      })
        .then((res) => {
          setError(undefined);
          setApprovalSteps((prev) => {
            return prev
              .reduce((acc, next) => {
                if (next.id === res.approvalFlowTemplateStep?.id) {
                  return [...acc, { ...next, users: [...(next?.users || []), res] }];
                } else {
                  return [...acc, next];
                }
              }, [] as ApprovalFlowTemplateStep[])
              .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          });
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.updatingentity"));
        });
    }
  };

  const handleDeleteUser = (user: ApprovalFlowTemplateUser, stepId: string) => {
    deleteApprovalTemplateUser({
      variables: {
        id: user.id as string,
      },
    })
      .then((res) => {
        setApprovalSteps((prev) => {
          return prev
            .reduce((acc, next) => {
              if (next.id === stepId) {
                const updatedUsers = next.users?.filter((u) => u?.id !== res.id);
                return [...acc, { ...next, users: updatedUsers }];
              } else {
                return [...acc, next];
              }
            }, [] as ApprovalFlowTemplateStep[])
            .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
        });
      })
      .catch((error: GQLError) => {
        setError(error);
        notifyError(t("errors.updatingentity"));
      });
  };

  const handleDeleteStep = (id: string) => {
    if (approvalSteps.length > 1) {
      deleteApprovalTemplateStep({
        variables: {
          id,
        },
      })
        .then((res) => {
          setApprovalSteps((prev) => {
            return prev
              .filter((step) => step.id !== res.id)
              .sort((a, b) => ((a?.stepNumber || 0) < (b?.stepNumber || 0) ? -1 : 1));
          });
        })
        .catch((error: GQLError) => {
          setError(error);
          notifyError(t("errors.deletingentity"));
        });
    }
  };

  //! Approval mutations and another logic

  const deleteApproval = () => {
    deleteApprovalFlowTemplate({ variables: { id: approvalFlowTemplateId } })
      .then(() => {
        notifyInfo(t("message.entitydeletedsuccessfully"));
      })
      .catch((error: GQLError) => {
        setError(error);
      });
  };

  const updateApprovalRef = useRef<((idMailAccount?: string) => void) | null>(null);
  updateApprovalRef.current = (idMailAccount?: string) => {
    updateApprovalFlowTemplate({
      variables: {
        id: approvalFlowTemplateId,
        approvalFlowTemplate: {
          name: approvalName,
          messageHtml: bodyMailEditor?.getHTML() === "<p></p>" ? null : bodyMailEditor?.getHTML(),
          subjectHtml: subjectMailEditor?.getHTML() === "<p></p>" ? null : subjectMailEditor?.getHTML(),
          type: approvalFlowTemplateData?.type,
          mailAccountId: idMailAccount ?? mailAccountId,
        },
      },
    })
      .then((res) => {
        setApprovalFlowTemplateData(res);
      })
      .catch((error) => {
        setError(error as GQLError);
        setApprovalFlowTemplateData({ ...approvalFlowTemplateData } as ApprovalFlowTemplate);
        subjectMailEditor?.commands.setContent(approvalFlowTemplateData?.subjectHtml as string);
        bodyMailEditor?.commands.setContent(approvalFlowTemplateData?.messageHtml as string);
        setApprovalName(approvalFlowTemplateData?.name as string);
        setMailAccountId(approvalFlowTemplateData?.mailAccount?.id as string);
        notifyError(t("errors.updatingentity"));
      })
      .finally(() => (areUpdatingsPendings.current = false));
  };
  const handleChangeMailAccountId = (mailAccountId: string | undefined) => {
    setMailAccountId(mailAccountId);
    if (updateApprovalRef.current) {
      updateApprovalRef.current(mailAccountId);
    }
  };

  useEffect(() => {
    if (debouncedEditor.key > 0 && updateApprovalRef.current) {
      updateApprovalRef.current();
    }
  }, [debouncedEditor]);

  useEffect(() => {
    return () => {
      if (areUpdatingsPendings.current && updateApprovalRef.current) {
        updateApprovalRef.current();
      }
    };
  }, []);
  const updateApprovalMailUsingTemplate = (messageHtml: string, subjectHtml: string) => {
    updateApprovalFlowTemplate({
      variables: {
        id: approvalFlowTemplateId,
        approvalFlowTemplate: {
          name: approvalFlowTemplateData?.name,
          messageHtml,
          subjectHtml,
          type: approvalFlowTemplateData?.type,
          mailAccountId: approvalFlowTemplateData?.mailAccount?.id as string,
        },
      },
    })
      .then((res) => {
        setApprovalFlowTemplateData(res);
        bodyMailEditor?.commands.setContent(res.messageHtml as string);
        subjectMailEditor?.commands.setContent(res?.subjectHtml as string);
      })
      .catch((error) => {
        setError(error as GQLError);
        setApprovalFlowTemplateData({ ...approvalFlowTemplateData } as ApprovalFlowTemplate);
        bodyMailEditor?.commands.setContent(approvalFlowTemplateData?.messageHtml as string);
        subjectMailEditor?.commands.setContent(approvalFlowTemplateData?.subjectHtml as string);
        notifyError(t("errors.updatingentity"));
      });
  };

  if (approvalFlowTemplateId && !approvalFlowTemplateData) {
    return null;
  }

  const contextValue: IApprovalFlowTemplateBuilder = {
    approvalFlowTemplateId: approvalFlowTemplateId,
    approvalFlowTemplate: approvalFlowTemplateData,
    approvalFlowTemplateSteps: approvalSteps,
    setSteps: setApprovalSteps,
    consolidateSteps: handleConsolidateSteps,
    addStep: handleAddStep,
    updateStep: handleUpdateStep,
    addUser: handleAddUser,
    deleteStep: handleDeleteStep,
    deleteUser: handleDeleteUser,
    error,
    hasEverBeenExecuted,
    isLoading,
    deleteApprovalFlowTemplate: deleteApproval,
    setError,
    onUseMailTemplate: updateApprovalMailUsingTemplate,
    subjectEditor: subjectMailEditor as Editor,
    messageEditor: bodyMailEditor as Editor,
    setApprovalName: handleChangeName,
    approvalName,
    approvalMailAccountId: mailAccountId,
    setApprovalMailAccountId: handleChangeMailAccountId,
  };

  return <ApprovalFlowBuilderContext.Provider value={contextValue}>{children}</ApprovalFlowBuilderContext.Provider>;
};
