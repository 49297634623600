import { RecursivePartial } from "@whyuz/utils";
import { FlowbiteNavbarTheme } from "flowbite-react";

enum EnumFlowbiteNavbarThemes {
  default = "default",
}

export const FlowbiteNavbarThemes: Record<EnumFlowbiteNavbarThemes, RecursivePartial<FlowbiteNavbarTheme>> = {
  default: {
    root: {
      base: "fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700",
    },
  },
};
