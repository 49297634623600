import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import type { Placement } from "@floating-ui/core";
import { getLocaleSupported } from "@whyuz/utils";
import { Tooltip } from "flowbite-react";
import { Floating } from "flowbite-react/components/Floating";
import { useTranslation } from "react-i18next";
import { buttonStyle } from ".";
import { FlowbiteFloatingThemes } from "../../../FlowbiteThemes";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";

export interface TextEditorMenuButtonEmojiPickerProps extends React.PropsWithChildren {
  className?: string;
  placement?: "auto" | Placement;
}

interface Emoji {
  id: string;
  native: string;
}

export const TextEditorMenuButtonEmojiPicker = ({
  className,
  placement = "auto",
  children,
}: TextEditorMenuButtonEmojiPickerProps) => {
  const { t, i18n } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  return (
    <Floating
      arrow={false}
      content={
        !disabled ? (
          <Picker
            data={data}
            locale={getLocaleSupported(i18n.language)}
            navPosition="none"
            previewPosition="none"
            maxFrequentRows={1}
            skinTonePosition="none"
            onEmojiSelect={(emoji: Emoji) => {
              if (editor) {
                editor.chain().focus().insertContent(emoji.native).run();
              }
            }}
          />
        ) : undefined
      }
      placement={placement}
      theme={FlowbiteFloatingThemes.fit}
      // eslint-disable-next-line react/style-prop-object
      style="auto"
      trigger={"click"}
      className={className}>
      <Tooltip content={t("buttons.emojis")}>
        <button type="button" className={buttonStyle} disabled={disabled}>
          {children ?? (
            <span className="text-2xl" role="img" aria-label="emojis button">
              😀
            </span>
          )}
        </button>
      </Tooltip>
    </Floating>
  );
};
