import { useRef, useState } from "react";

export type useStreamType = {
  stream: MediaStream | null;
  startMediaStream: (mediaSettings?: MediaStreamConstraints, enableMicrophone?: boolean, enableCamera?: boolean) => Promise<MediaStream>;
  startDisplayMediaStream: (audio?: boolean, onEnded?: () => void) => Promise<MediaStream>;
  startAudioTrack: (audioSettings?: MediaTrackConstraints, enableAudio?: boolean) => Promise<MediaStream>;
  startVideoTrack: (videoSettings?: MediaTrackConstraints, enableVideo?: boolean) => Promise<MediaStream>;
  stopMediaStream: () => void;
  setStreamMicrophoneEnabled: (microphoneEnabled: boolean) => void;
  setStreamVideoEnabled: (enableCamera: boolean) => void;
};

export const useMediaStream = (): useStreamType => {
  const streamRef = useRef<MediaStream | null>(null);
  const [isMediaStreamStarting, setMediaStreamStarting] = useState(false);

  const createMediaStream = (mediaSettings?: MediaStreamConstraints): Promise<MediaStream> => {
    const initialConstraints = mediaSettings ?? {
      video: true,
      audio: true,
    };
    return navigator.mediaDevices.getUserMedia(initialConstraints);
  };

  const startDisplayMediaStream = (audio = true, onEnded?: () => void): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      if (isMediaStreamStarting) {
        // We avoid to generate an error: reject(new Error("Another startMediaStream is in progress"));
        return;
      }

      if (streamRef.current) {
        reject(new Error("Media Stream still running: stop it before starting a new one"));
        return;
      }

      setMediaStreamStarting(true);
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: audio })
        .then((newStream) => {
          if (onEnded) {
            const videoTrack = newStream.getVideoTracks()[0];
            if (videoTrack) {
              videoTrack.addEventListener("ended", onEnded);
            }
          }
          streamRef.current = newStream;
          resolve(newStream);
        })
        .catch((e) => reject(e))
        .finally(() => setMediaStreamStarting(false));
    });
  };

  const startMediaStream = (
    mediaSettings?: MediaStreamConstraints,
    enableMicrophone = true,
    enableCamera = true
  ): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      if (isMediaStreamStarting) {
        // We avoid to generate an error: reject(new Error("Another startMediaStream is in progress"));
        return;
      }

      if (streamRef.current) {
        reject(new Error("Media Stream still running: stop it before starting a new one"));
        return;
      }

      setMediaStreamStarting(true);
      createMediaStream(mediaSettings)
        .then((newStream) => {
          newStream.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = enableMicrophone ?? true));
          newStream.getVideoTracks().forEach((videoTrack) => (videoTrack.enabled = enableCamera ?? true));
          streamRef.current = newStream;
          resolve(newStream);
        })
        .catch((e) => reject(e))
        .finally(() => setMediaStreamStarting(false));
    });
  };

  const startVideoTrack = (videoSettings?: MediaTrackConstraints, enableCamera = true): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      if (isMediaStreamStarting) {
        // We avoid to generate an error: reject(new Error("Another startMediaStream is in progress"));
        return;
      }

      setMediaStreamStarting(true);

      const mediaSettings = {
        audio: false,
        video: videoSettings,
      };

      createMediaStream(mediaSettings)
        .then((newStream) => {
          newStream.getVideoTracks().forEach((videoTrack) => (videoTrack.enabled = enableCamera ?? true));
          const newVideoTrack = newStream.getVideoTracks()[0];
          if (streamRef.current) {
            const currentNumberOfVideoTracks = streamRef.current.getVideoTracks().length;
            const oldVideoTrack = streamRef.current.getVideoTracks()[currentNumberOfVideoTracks - 1];
            if (oldVideoTrack) {
              oldVideoTrack.stop();
            }
            if (newVideoTrack) {
              streamRef.current.addTrack(newVideoTrack);
            }
          } else {
            streamRef.current = newStream;
          }
          resolve(streamRef.current);
        })
        .catch((e) => reject(e))
        .finally(() => setMediaStreamStarting(false));
    });
  };

  const startAudioTrack = (audioSettings?: MediaTrackConstraints, enableAudio = true): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      if (isMediaStreamStarting) {
        // We avoid to generate an error: reject(new Error("Another startMediaStream is in progress"));
        return;
      }

      setMediaStreamStarting(true);

      const mediaSettings = {
        audio: audioSettings,
        video: false,
      };

      createMediaStream(mediaSettings)
        .then((newStream) => {
          newStream.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = enableAudio ?? true));
          const newAudioTrack = newStream.getAudioTracks()[0];
          if (streamRef.current) {
            const currentNumberOfAudioTracks = streamRef.current.getAudioTracks().length;
            const oldAudioTrack = streamRef.current.getAudioTracks()[currentNumberOfAudioTracks - 1];
            if (oldAudioTrack) {
              oldAudioTrack.stop();
            }
            if (newAudioTrack) {
              streamRef.current.addTrack(newAudioTrack);
            }
          } else {
            streamRef.current = newStream;
          }
          resolve(streamRef.current);
        })
        .catch((e) => reject(e))
        .finally(() => setMediaStreamStarting(false));
    });
  };

  const stopMediaStream = () => {
    if (!streamRef.current) throw new Error("Stream not found");
    streamRef.current.getTracks().forEach((track) => track.stop());
    streamRef.current = null;
  };

  const setStreamMicrophoneEnabled = (enableMicrophone: boolean) => {
    if (!streamRef.current) throw new Error("Stream not found");
    const audioTracks = streamRef.current.getAudioTracks();
    audioTracks.forEach((audioTrack) => (audioTrack.enabled = enableMicrophone));
  };

  const setStreamVideoEnabled = (enableCamera: boolean) => {
    if (!streamRef.current) throw new Error("Stream not found");
    const videoTracks = streamRef.current.getVideoTracks();
    videoTracks.forEach((videoTrack) => (videoTrack.enabled = enableCamera));
  };

  return {
    stream: streamRef.current,
    startMediaStream,
    startDisplayMediaStream,
    startAudioTrack,
    startVideoTrack,
    stopMediaStream,
    setStreamMicrophoneEnabled,
    setStreamVideoEnabled,
  };
};
