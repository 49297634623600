import { ScissorsIcon, TrashIcon } from "@heroicons/react/24/outline";
import { VideoPlayer, VideoTrimTimeSeconds, getFileExtensionFromMimeType } from "@whyuz/components";
import { RIGHT_BUTTON } from "@whyuz/data";
import { blobToFile, notifyError, shortenVideo } from "@whyuz/utils";
import { Spinner } from "flowbite-react";
import { useState } from "react";

export interface SuccessCaseContentEditorQuestionVideoPlayerProps {
  src?: string;
  onVideoTrimmed: (fileTrimmed: File) => void;
  onDeleteVideo: () => void;
  disabled?: boolean;
}

export const SuccessCaseContentEditorQuestionVideoPlayer = ({
  src,
  onVideoTrimmed,
  onDeleteVideo,
  disabled,
}: SuccessCaseContentEditorQuestionVideoPlayerProps) => {
  const [videoTrimTimeSeconds, setVideoTrimTimeSeconds] = useState<VideoTrimTimeSeconds>();
  const [isShorteningVideo, setIsShorteningVideo] = useState(false);

  if (disabled) {
    return undefined;
  }

  const trimVideo = () => {
    if (src && videoTrimTimeSeconds) {
      setIsShorteningVideo(true);
      shortenVideo(src, videoTrimTimeSeconds.startTimeSeconds, videoTrimTimeSeconds.endTimeSeconds)
        .then((videoBlob) => {
          const videoFile = blobToFile(
            videoBlob,
            Date.now().toString() + "." + getFileExtensionFromMimeType(videoBlob.type),
          );
          onVideoTrimmed(videoFile);
        })
        .catch((e) => notifyError(String(e)))
        .finally(() => setIsShorteningVideo(false));
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row-reverse p-2 space-x-2">
        {videoTrimTimeSeconds &&
          src &&
          (isShorteningVideo ? (
            <Spinner />
          ) : (
            <button
              type="button"
              onPointerDown={(e) => {
                if (e.button !== RIGHT_BUTTON) {
                  trimVideo();
                }
              }}>
              <ScissorsIcon className="h-6 w-6 text-gray-300 hover:text-gray-400 dark:text-gray-600 dark:hover:text-gray-500" />
            </button>
          ))}
        {src && !isShorteningVideo && (
          <button
            type="button"
            onPointerDown={(e) => {
              if (e.button !== RIGHT_BUTTON) onDeleteVideo();
            }}>
            <TrashIcon className="h-6 w-6 text-gray-300 hover:text-gray-400 dark:text-gray-600 dark:hover:text-gray-500" />
          </button>
        )}
      </div>
      <VideoPlayer
        src={src}
        showControlsOnHover={false}
        showVideoTrimControls={!disabled}
        onVideoTrim={(trimTimeSeconds) => {
          setVideoTrimTimeSeconds(trimTimeSeconds); // Undefined means no trim applied
        }}
        disabled={isShorteningVideo}
      />
    </div>
  );
};
