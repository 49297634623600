import React from "react";
import { twMerge } from "tailwind-merge";

export interface PageTitleProps {
  icon?: React.ReactNode;
  title: string;
  detail?: React.ReactNode;
  classname?: string;
}

export const PageTitle = ({ icon, title, detail, classname }: PageTitleProps) => {
  return (
    <div className={twMerge("w-full flex items-center space-x-3", classname)}>
      {icon}
      <div className="md:space-x-2 flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0">
        <h5 className="dark:text-white font-semibold select-none">{title}</h5>
        {detail &&
          (typeof detail === "string" ? (
            <div className="text-gray-400 text-xs md:text-sm font-medium">{detail}</div>
          ) : (
            detail
          ))}
      </div>
    </div>
  );
};
