import { hex2Rgba, rgb2Hex } from "@whyuz/utils";
import { Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
import { RgbaColor } from "react-colorful";
import { useTranslation } from "react-i18next";
import { MdOutlineBorderColor } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { ColorPicker } from "../../../ColorPicker";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyleHiddenOnMobile, buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonFontColorProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonFontColor = ({ className, children }: TextEditorMenuButtonFontColorProps) => {
  const { t } = useTranslation("textEditor");
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [selectedTextColor, setSelectedTextColor] = useState<RgbaColor>({ r: 0, g: 0, b: 0, a: 1 });
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName =
    editor && editor.isActive("textStyle") ? activeButtonStyleHiddenOnMobile : buttonStyleHiddenOnMobile;

  useEffect(() => {
    if (showTextColorPicker) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const currentTextColor = hex2Rgba(editor?.getAttributes("textStyle").color ?? "#000000");
      setSelectedTextColor(currentTextColor);
    }
  }, [showTextColorPicker, editor]);

  const handleTextColorChanged = (color: RgbaColor) => {
    if (editor) {
      setSelectedTextColor(color);
      editor
        .chain()
        .focus()
        .setColor("#" + rgb2Hex(color))
        .run();
    }
    setShowTextColorPicker(false);
  };

  return (
    <ColorPicker
      show={showTextColorPicker}
      selectedColor={selectedTextColor}
      showRgbColorPicker={false}
      onChange={handleTextColorChanged}>
      <Tooltip content={t("buttons.textcolor")}>
        <button
          type="button"
          onClick={() => {
            setShowTextColorPicker(!showTextColorPicker);
          }}
          className={twMerge(buttonClassName, className)}
          disabled={disabled}>
          {children ?? <MdOutlineBorderColor className="h-5 w-5 m-0.5" />}
        </button>
      </Tooltip>
    </ColorPicker>
  );
};
