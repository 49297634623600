import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BiListUl } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyle, buttonStyle } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonUnorderedListProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonUnorderedList = ({ className, children }: TextEditorMenuButtonUnorderedListProps) => {
  const { t } = useTranslation("textEditor");
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName = editor && editor.isActive("bulletList") ? activeButtonStyle : buttonStyle;

  return (
    <Tooltip content={t("buttons.unorderedlist")}>
      <button
        type="button"
        onClick={() => {
          if (editor) {
            editor.chain().focus().toggleBulletList().run();
          }
        }}
        className={twMerge(buttonClassName, className)}
        disabled={disabled}
      >
        {children ?? <BiListUl className="h-6 w-6" />}
      </button>
    </Tooltip>
  );
};
