import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { Page, TextEditor, useTextEditor } from "@whyuz/components";
import { ChatMessage } from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";

interface UpdateMessageModalProps {
  modalProps: ModalProps;
  onSave: (message: string) => void;
  message: ChatMessage;
  onClose: () => void;
}

export const UpdateMessageModal = ({ modalProps, message, onClose, onSave }: UpdateMessageModalProps) => {
  const { t } = useTranslation();
  const { t: tChat } = useTranslation("chat");

  const chatEditor = useTextEditor({
    content: message.textHtml,
    additionalExtensions: [],
  });
  const save = () => {
    if (chatEditor && chatEditor.getHTML() !== "<p></p>") {
      onSave(chatEditor.getHTML());
      chatEditor.commands.clearContent();
    } else {
      notifyError(tChat("newvaluerequired"));
    }
  };

  return (
    <Modal popup {...modalProps} onClose={onClose}>
      <Modal.Header className="p-6">
        <Page.Title
          title={tChat("updatemessageextended")}
          icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />}
        />
      </Modal.Header>
      <Modal.Body>
        <Page.FormContainer>
          <div className="flex">
            <div className="w-full mr-1 overflow-hidden">
              <TextEditor editor={chatEditor}>
                <TextEditor.ContentEditorWithMenu actions={["fontStyle", "fontColor", "uploadFile", "uploadImage"]} />
              </TextEditor>
            </div>
          </div>
        </Page.FormContainer>
        <Page.PanelFooterContainer>
          <Page.SaveButton text={t("buttons.save")} onSave={save} />
        </Page.PanelFooterContainer>
      </Modal.Body>
    </Modal>
  );
};
