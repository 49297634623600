import { WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import { useDebounce } from "@whyuz/hooks";
import { isEmpty } from "@whyuz/utils";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export type SearchEventListener = (searchTerm: string | null) => void;

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get(WHYUZ_URL_PARAMETERS.SEARCH);

  const onChangeRef = useRef<SearchEventListener | null>(null);

  const setOnChange = (onChange: SearchEventListener | null) => {
    onChangeRef.current = onChange;
  };

  const debouncedSearchText = useDebounce<string | null>(searchText, 750);

  const setSearchText = (newSearchText: string | null) =>
    setSearchParams(
      (prev) => {
        if (newSearchText == null || isEmpty(newSearchText)) {
          prev.delete(WHYUZ_URL_PARAMETERS.SEARCH);
        } else {
          prev.set(WHYUZ_URL_PARAMETERS.SEARCH, newSearchText ?? "");
        }
        return prev;
      },
      { replace: true },
    );

  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current(debouncedSearchText);
    }
  }, [debouncedSearchText]);

  return { searchText: debouncedSearchText, undebouncedSearchText: searchText, setSearchText, setOnChange };
};
