import { DropVideoZone } from "@whyuz/components";

export interface SuccessCaseContentEditorQuestionVideoUploadProps {
  onVideoAdded: (fileAdded: File) => void;
  disabled?: boolean;
  maxSizeInSeconds?: number;
}

export const SuccessCaseContentEditorQuestionVideoUpload = ({
  onVideoAdded,
  disabled,
  maxSizeInSeconds,
}: SuccessCaseContentEditorQuestionVideoUploadProps) => {
  return (
    <DropVideoZone
      disabled={disabled}
      theme={{ placeholder: "border-primary-300 bg-primary-50 hover:bg-primary-100 h-[33vh]" }}
      onVideoAdded={onVideoAdded}
      maxSizeInSeconds={maxSizeInSeconds}
    />
  );
};
