import { HttpStatusCode } from "@whyuz/data";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Error_Code } from "../codegen/graphql";
import { ErrorMessageType, GQLError, GQLErrorStatusCode } from "../types";

export const useErrorTranslation = () => {
  const { t } = useTranslation();

  const getMessageFromErrorCode = useCallback(
    ({ error, message }: ErrorMessageType) => {
      switch (error) {
        case Error_Code.ChangeNotAllowed:
          return t("errors.changenotallowed");
        case Error_Code.IncorrectValue:
          return t("errors.incorrectvalue");
        case Error_Code.NotAvailable:
          return t("errors.notavailable");
        case Error_Code.NotFound:
          return t("errors.notfound");
        case Error_Code.OutOfRange:
          return t("errors.outofrange");
        case Error_Code.RequiredField:
          return t("errors.fieldrequired");
        case Error_Code.NotAuthorized:
          return t("errors.accessdenied");
        case Error_Code.AlreadyExists:
          return t("errors.alreadyexists");
        case Error_Code.GenericError:
          return message;
        default:
          return t("errors.unknown") + "[" + String(error) + "]" + (message ? ": " + message : "");
      }
    },
    [t],
  );

  const getErrorMessageFromErrorCode = useCallback(
    (error: ErrorMessageType): ErrorMessageType | undefined => {
      const message = getMessageFromErrorCode(error);
      return message ? { ...error, message } : undefined;
    },
    [getMessageFromErrorCode],
  );

  const getMessageFromStatusCode = useCallback(
    (statusCode?: GQLErrorStatusCode | HttpStatusCode) => {
      if (!statusCode) {
        return undefined;
      }

      switch (statusCode) {
        case GQLErrorStatusCode.VALIDATION_ERROR:
          return t("errors.validationfailed");
        case GQLErrorStatusCode.DATA_INTEGRITY_ERROR:
          return t("errors.dataintegrityerror");
        case GQLErrorStatusCode.TENANT_UNKNOWN:
          return t("errors.tenantunknown");
        case GQLErrorStatusCode.TOKEN_EXPIRED:
          return t("errors.tokenexpired");
        case HttpStatusCode.BAD_REQUEST:
          return t("errors.httperror400");
        case HttpStatusCode.UNAUTHORIZED:
          return t("errors.httperror401");
        case HttpStatusCode.FORBIDDEN:
          return t("errors.httperror403");
        case GQLErrorStatusCode.MAIL_AUTHENTICATION_ERROR:
          return t("errors.mailauthenticationerror");
        case HttpStatusCode.INTERNAL_SERVER_ERROR:
          return t("errors.httperror500");
        default:
          return t("errors.unknown") + "-" + String(statusCode);
      }
    },
    [t],
  );

  const getMessage = useCallback(
    (error?: GQLError) => {
      if (!error?.statusCode && !error?.message) {
        return undefined;
      }

      const statusCodeErrorMessage = getMessageFromStatusCode(error.statusCode);
      return statusCodeErrorMessage && error.message
        ? statusCodeErrorMessage + ": " + error.message
        : statusCodeErrorMessage ?? "" + error.message ?? "";
    },
    [getMessageFromStatusCode],
  );

  const getErrorMessage = useCallback(
    (error?: GQLError): ErrorMessageType => {
      const message = getMessage(error);
      return message
        ? { error: Error_Code.GenericError, message }
        : { error: Error_Code.GenericError, message: t("errors.unknown") };
    },
    [getMessage, t],
  );

  const getMessageIfIsUcontroled = useCallback(
    (error?: GQLError) => {
      return error?.isUncontrolledError ? getMessage(error) : undefined;
    },
    [getMessage],
  );

  const getErrorMessageIfIsUcontroled = useCallback(
    (error?: GQLError): ErrorMessageType | undefined => {
      return error?.isUncontrolledError ? getErrorMessage(error) : undefined;
    },
    [getErrorMessage],
  );

  return {
    getMessageFromStatusCode,
    getMessageFromErrorCode,
    getErrorMessageFromErrorCode,
    getMessage,
    getErrorMessage,
    getMessageIfIsUcontroled,
    getErrorMessageIfIsUcontroled,
  };
};
