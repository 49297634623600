import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useModal } from "@whyuz/components";
import { RIGHT_BUTTON } from "@whyuz/data";
import { Chat, ChatMember, useUser } from "@whyuz/services";
import { EntityDictionary } from "@whyuz/types";
import { Avatar, Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { AddMemberModal, DeleteMemberModal } from ".";
import { UserAvatar } from "../../Users";

export interface ChatHeaderProps {
  chat: Chat | undefined;
  chatMembers: EntityDictionary<ChatMember>;
  addMember: (userId: string, mail: string) => void;
  deleteMember: (memberId: string) => void;
  className?: string;
  disabled?: boolean;
}

export const ChatHeader = ({ chat, chatMembers, addMember, deleteMember, className, disabled }: ChatHeaderProps) => {
  const { t: tChat } = useTranslation("chat");
  const userCtx = useUser();

  const {
    showModal: showAddMemberModal,
    closeModal: closeAddMemberModal,
    modalProps: addMemberModalProps,
  } = useModal();

  const { showModal: showMembersModal, closeModal: closeMembersModal, modalProps: membersModalProps } = useModal();

  return (
    <div
      id="chat-header"
      className={twMerge(
        "flex justify-between items-center border-b border-gray-200 dark:border-gray-600 p-2 dark:text-white",
        className,
      )}>
      {addMemberModalProps.show && (
        <AddMemberModal
          modalProps={addMemberModalProps}
          addMember={(userId, mail) => addMember(userId, mail)}
          onClose={closeAddMemberModal}
        />
      )}
      {membersModalProps.show && (
        <DeleteMemberModal
          members={Object.values(chatMembers)}
          modalProps={membersModalProps}
          onClose={closeMembersModal}
          deleteMember={(memberId) => deleteMember(memberId)}
        />
      )}
      <Avatar.Group>
        {Object.values(chatMembers)
          .slice(0, 5)
          .map((currentChatMember, idx) => (
            <UserAvatar key={idx} user={currentChatMember} />
          ))}
        {Object.values(chatMembers).length > 5 && (
          <Avatar.Counter total={Object.keys(chatMembers).length - 5} className="w-8 h-8 cursor-default" />
        )}
      </Avatar.Group>
      <div className="w-full text-sm text-center truncate flex-1">{chat?.name}</div>
      {userCtx.isUserLoggedInTenant && ( // Collaborators cannot add or delete members in the chat
        <div>
          <Dropdown
            arrowIcon={false}
            inline
            disabled={disabled}
            label={
              <span className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
                <span className="sr-only">Chat menu</span>
                <EllipsisVerticalIcon className="h-4 w-4 stroke-2 self-center" />
              </span>
            }>
            <Dropdown.Item
              onPointerDown={(e: React.PointerEvent) => {
                if (e.button !== RIGHT_BUTTON) {
                  showAddMemberModal();
                }
              }}>
              {tChat("addmember")}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onPointerDown={(e: React.PointerEvent) => {
                if (e.button !== RIGHT_BUTTON) {
                  showMembersModal();
                }
              }}>
              {tChat("deletemembers")}
            </Dropdown.Item>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
