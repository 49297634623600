import { Label } from "flowbite-react";
import { getColSpan } from "@whyuz/utils";
import { twMerge } from "tailwind-merge";

export interface PageFormTextInfoFieldProps {
  id: string;
  label: string;
  text?: string;
  columns?: number;
  columnsBreakpointSM?: boolean;
  className?: string;
}

export const PageFormTextInfoField = ({
  id,
  label,
  text,
  columns,
  columnsBreakpointSM = true,
  className,
}: PageFormTextInfoFieldProps) => {
  return (
    <div className={getColSpan(columns, columnsBreakpointSM)}>
      <Label htmlFor={id} className="mb-2 text-xs font-normal text-gray-900 select-none">
        {label}
      </Label>
      <div
        id={id}
        className={twMerge(`text-base font-semibold text-gray-900 dark:text-white break-words min-h-[24px]`, className)}
      >
        {text ?? ""}
      </div>
    </div>
  );
};
