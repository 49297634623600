import { TextEditor, useTextEditor } from "@whyuz/components";
import { useDebounce } from "@whyuz/hooks";
import { SuccessCaseContentItem, useCollaboratorUpdateContentOfSuccessCaseContentItemMutation } from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuccessCaseContentEditorQuestionContainer } from "./SuccessCaseContentEditorQuestionContainer";
import { SuccessCaseContentEditorQuestionInstructions } from "./SuccessCaseContentEditorQuestionInstructions";
import { SuccessCaseContentEditorQuestionTitle } from "./SuccessCaseContentEditorQuestionTitle";

export interface SuccessCaseContentEditorQuestionFreeProps {
  tenantId: string;
  question: SuccessCaseContentItem;
  onQuestionUpdated: (updatedQuestion: SuccessCaseContentItem) => void;
  disabled?: boolean;
}

export const SuccessCaseContentEditorQuestionFree = ({
  tenantId,
  question,
  onQuestionUpdated,
  disabled,
}: SuccessCaseContentEditorQuestionFreeProps) => {
  const { t: tContentItem } = useTranslation("contentItem");
  const [textUpdateTimestamp, setTextUpdateTimestamp] = useState<number>(0);
  const debouncedContentText = useDebounce<number>(textUpdateTimestamp, 1000);
  const areUpdatingsPendings = useRef(false);
  const [updateContentMutation] = useCollaboratorUpdateContentOfSuccessCaseContentItemMutation();

  const editor = useTextEditor({
    content: question.htmlTextContent,
    uploadPublicFiles: true,
    onChange: () => {
      setTextUpdateTimestamp(Date.now());
      areUpdatingsPendings.current = true;
    },
  });

  const updateContentItemTextContentRef = useRef<(() => void) | null>(null);
  updateContentItemTextContentRef.current = () => {
    const newText = editor?.getHTML() ?? "";
    updateContentMutation({
      variables: { contentItemId: question.id as string, content: newText, tenantId },
    })
      .then((updatedQuestion) => {
        onQuestionUpdated(updatedQuestion);
      })
      .catch((e) => {
        notifyError(tContentItem("errors.updatingcontentitem") + ": " + (e as string));
      });
  };

  useEffect(() => {
    if (debouncedContentText > 0 && updateContentItemTextContentRef.current) {
      updateContentItemTextContentRef.current();
    }
  }, [debouncedContentText, editor]);

  useEffect(() => {
    return () => {
      if (areUpdatingsPendings.current && updateContentItemTextContentRef.current) {
        updateContentItemTextContentRef.current();
      }
    };
  }, []);

  return (
    <SuccessCaseContentEditorQuestionContainer>
      <SuccessCaseContentEditorQuestionTitle title={question.title as string} />
      <SuccessCaseContentEditorQuestionInstructions instructions={question.htmlInstructions as string} />
      <TextEditor editor={editor} disabled={disabled}>
        <TextEditor.ContentEditorWithMenu className="max-h-[400px] min-h-[300px]" />
      </TextEditor>
    </SuccessCaseContentEditorQuestionContainer>
  );
};
