import { Client, StompHeaders } from "@stomp/stompjs";
import { useHeaders, useKeycloak } from "@whyuz/services";
import React, { createContext, useEffect, useRef } from "react";
import SockJS from "sockjs-client";

export type StompContextProps = {
  stompClient: Client | undefined;
};

export const StompContext = createContext<StompContextProps | undefined>(undefined);

export interface StompContextProviderProps extends React.PropsWithChildren {
  endpoint: string;
}

export const StompContextProvider = ({ endpoint, children }: StompContextProviderProps) => {
  const stompClientRef = useRef<Client | undefined>();
  const isConnectingRef = useRef<boolean>(false);
  const headers = useHeaders();
  const { keycloak } = useKeycloak();
  const connectRef = useRef<() => void>();

  connectRef.current = () => {
    if (
      isConnectingRef.current ||
      (stompClientRef.current && stompClientRef.current?.connected) ||
      !keycloak.authenticated ||
      !headers?.Authorization
    ) {
      return;
    }

    isConnectingRef.current = true;

    if (process.env.NODE_ENV === "development") {
      console.log("Connecting stomp...");
    }

    stompClientRef.current = new Client({
      debug: () => process.env.NODE_ENV === "development",
      webSocketFactory: () =>
        new SockJS(endpoint, null, {
          transports: ["websocket"],
        }),
      reconnectDelay: 100000, // Automatic reconnection enabled
      // WARNING headers which you are passing part of stompClient#connect will be passed as the header of CONNECT stomp frame sent via the websocket, and not as the request header to open the socket
      // To allow the initial HTTP negotiation to happen, in your spring SecurityFilter configuration permit all request received for the websocket's destination
      connectHeaders: headers as StompHeaders,
      disconnectHeaders: headers as StompHeaders,
      onConnect: () => {
        isConnectingRef.current = false;
        if (process.env.NODE_ENV === "development") {
          console.log("Connected stomp");
        }
      },
      onStompError: (error) => {
        console.error("Error on stomp web stomp", error);
        isConnectingRef.current = false;
      },
      onWebSocketError: (error) => {
        console.error("Error on stomp web socket", error);
        isConnectingRef.current = false;
      },
    });

    stompClientRef.current.debug = () => process.env.NODE_ENV === "development";
    stompClientRef.current.activate();

    if (process.env.NODE_ENV === "development") {
      console.log("Activated stomp");
    }

    return stompClientRef.current;
  };

  useEffect(() => {
    // Open the socket connection when the component mounts, and check if it is needed if headers change (starting authentication is empty, so headers are incorrect)
    if (connectRef.current) {
      connectRef.current();
    }
  }, [keycloak.authenticated, headers]);

  return <StompContext.Provider value={{ stompClient: stompClientRef.current }}>{children}</StompContext.Provider>;
};
