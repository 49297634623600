import Mention, { MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { useMemo } from "react";
import tippy, { Instance, Props } from "tippy.js";
import { VariablesList, VariablesListRef } from "../components/VariablesList.tsx";
import { VariableSuggestionType } from "../types/variableSuggestion.ts";

export const useVariablesSuggestionExtension = (variables: VariableSuggestionType[], triggerChar?: string) => {
  const suggestion: MentionOptions["suggestion"] = useMemo((): MentionOptions["suggestion"] => {
    return {
      char: triggerChar ?? "@",
      items: ({ query }) => variables.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())),
      render: () => {
        let component: ReactRenderer<VariablesListRef>;
        let popup: Instance<Props>[];

        return {
          onStart: (props) => {
            component = new ReactRenderer(VariablesList, {
              props,
              editor: props.editor,
            });

            if (props.clientRect) {
              popup = tippy("body", {
                getReferenceClientRect: () => {
                  if (props.clientRect) {
                    return props.clientRect() ?? new DOMRect();
                  }
                  return new DOMRect();
                },
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              });
            }
          },

          onUpdate(props) {
            component.props = props;
            component.render();

            if (!props.clientRect) {
              return;
            }

            popup[0].setProps({
              getReferenceClientRect: () => {
                if (props.clientRect) {
                  return props.clientRect() ?? new DOMRect();
                }
                return new DOMRect();
              },
            });
          },

          onKeyDown(props) {
            if (props.event.key === "Escape") {
              popup[0].hide();

              return true;
            }

            if (!component.ref) {
              return false;
            }
            return component.ref.onKeyDown(props);
          },

          onExit() {
            popup[0].destroy();
            component.destroy();
          },
        };
      },
    };
  }, [variables, triggerChar]);

  const mentionExtension = useMemo(() => {
    return Mention.configure({
      HTMLAttributes: {
        class:
          "rounded-lg pt-0 pb-0.5 px-2 bg-primary-600 text-white box-decoration-clone selection:bg-primary-700 selection:text-primary-100",
        variable: "true",
      },
      suggestion,
    });
  }, [suggestion]);

  return mentionExtension;
};
