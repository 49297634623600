import { Currency, useCurrenciesLazyQuery, useKeycloak, CurrencyDictionary } from "@whyuz/services";
import { getPropertyByPath, notifyError } from "@whyuz/utils";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrencyTranslation } from "../hooks";

export type CurrenciesContextType = {
  currencies: Currency[];
  currenciesDictionary: CurrencyDictionary;
  searchCurrencies: (searchText: string | undefined | null) => Currency[];
  currencyTranslation: (currency: Currency) => string;
};

export const CurrenciesContext = createContext<CurrenciesContextType | null>(null);

export const CurrenciesContextProvider = ({ children }: React.PropsWithChildren) => {
  const [currenciesQuery] = useCurrenciesLazyQuery();
  const currenciesRef = useRef<Currency[]>([]);
  const [currenciesDictionary, setCurrenciesDictionary] = useState<CurrencyDictionary>({});
  const { i18n } = useTranslation();
  const currencyTranslation = useCurrencyTranslation();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (currenciesRef.current.length === 0 && keycloak.authenticated) {
      currenciesQuery() // Query execution to get all the currencies in memory
        .then((page) => {
          if (page.content) {
            currenciesRef.current = page.content as Currency[];
            const currencyList = page.content as Currency[];
            const newCurrencyDictionary = currencyList.reduce(
              (prev, currency) => ({ ...prev, [currency.id]: currency }),
              {} as CurrencyDictionary,
            );
            setCurrenciesDictionary(newCurrencyDictionary);
          }
        })
        .catch((e) => notifyError(String(e)));
    }
  }, [currenciesQuery, keycloak.authenticated]);

  const searchCurrencies = useCallback(
    (searchText: string | undefined | null) => {
      const currencyFieldToFilter = i18n.language === "en" ? "name" : i18n.language;

      if (!searchText) {
        return currenciesRef.current;
      }

      return currenciesRef.current
        .filter((currency) => {
          const translatedCurrencyName = getPropertyByPath(currency, currencyFieldToFilter) as string;
          const lowerCaseSearchText = searchText.toLowerCase();
          return (
            currency.id.toLowerCase().includes(lowerCaseSearchText) ||
            (translatedCurrencyName && translatedCurrencyName.toLowerCase().includes(lowerCaseSearchText))
          );
        })
        .sort((lang1, lang2) => {
          const translatedCurrencyName1 = getPropertyByPath(lang1, currencyFieldToFilter) as string;
          const translatedCurrencyName2 = getPropertyByPath(lang2, currencyFieldToFilter) as string;
          if (!translatedCurrencyName1) {
            return -1;
          }
          if (!translatedCurrencyName2) {
            return 1;
          }
          return translatedCurrencyName1.localeCompare(translatedCurrencyName2);
        });
    },
    [i18n.language],
  );

  return (
    <CurrenciesContext.Provider
      value={{ currencies: currenciesRef.current, searchCurrencies, currencyTranslation, currenciesDictionary }}>
      {children}
    </CurrenciesContext.Provider>
  );
};
