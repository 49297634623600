import { graphql } from "../../codegen/gql";

const chatMessages = graphql(`
  query chatMessages(
    $tenantId: UUID
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    chatMessages(
      tenantId: $tenantId
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        textHtml
        deleted
        updated
        chatMember {
          id
          mail
          pictureURL
          firstName
          lastName
        }
      }
    }
  }
`);

export const ChatMessageGQL = {
  query: { chatMessages },
  mutation: {},
};
