import { SequenceStep } from "./SequenceStep.tsx";

export interface SequenceProps {
  children: React.ReactNode;
}

export const SequenceComponent = ({ children }: SequenceProps) => {
  return (
    <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base justify-center">
      {children}
    </ol>
  );
};

SequenceComponent.displayName = "Sequence";

export const Sequence = Object.assign(SequenceComponent, {
  Step: SequenceStep,
});
