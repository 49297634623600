import { RecursivePartial } from "@whyuz/utils";
import { FlowbiteSpinnerTheme } from "flowbite-react";

enum EnumFlowbiteSpinnerThemes {
  default = "default",
}

export const FlowbiteSpinnerThemes: Record<EnumFlowbiteSpinnerThemes, RecursivePartial<FlowbiteSpinnerTheme>> = {
  default: {
    color: {
      info: "fill-primary-500",
    },
  },
};
