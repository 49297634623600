import { hex2Rgba, rgb2Hex } from "@whyuz/utils";
import { Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
import { RgbaColor } from "react-colorful";
import { useTranslation } from "react-i18next";
import { MdOutlineFormatColorFill } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { ColorPicker } from "../../../ColorPicker";
import { useTextEditorContext } from "../../hooks/useTextEditorContext";
import { activeButtonStyleHiddenOnMobile, buttonStyleHiddenOnMobile } from "./TextEditorMenuStyle";

export interface TextEditorMenuButtonBackgroundColorProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextEditorMenuButtonBackgroundColor = ({
  className,
  children,
}: TextEditorMenuButtonBackgroundColorProps) => {
  const { t } = useTranslation("textEditor");
  const [showTextBackgroundColorPicker, setShowTextBackgroundColorPicker] = useState(false);
  const [selectedTextBackgroundColor, setSelectedTextBackgroundColor] = useState<RgbaColor>({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  });
  const { editor, disabled } = useTextEditorContext();

  const buttonClassName =
    editor && editor.isActive("highlight") ? activeButtonStyleHiddenOnMobile : buttonStyleHiddenOnMobile;

  useEffect(() => {
    if (showTextBackgroundColorPicker) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const currentHighlightColor = hex2Rgba(editor?.getAttributes("highlight").color ?? "#ffffff");
      setSelectedTextBackgroundColor(currentHighlightColor);
    }
  }, [showTextBackgroundColorPicker, editor]);

  const handleTextBackgroundColorChanged = (color: RgbaColor) => {
    if (editor) {
      setSelectedTextBackgroundColor(color);
      editor
        .chain()
        .focus()
        .toggleHighlight({ color: "#" + rgb2Hex(color) })
        .run();
    }
    setShowTextBackgroundColorPicker(false);
  };

  return (
    <ColorPicker
      show={showTextBackgroundColorPicker}
      selectedColor={selectedTextBackgroundColor}
      showRgbColorPicker={false}
      onChange={handleTextBackgroundColorChanged}>
      <Tooltip content={t("buttons.backgroundcolor")}>
        <button
          type="button"
          onClick={() => setShowTextBackgroundColorPicker(!showTextBackgroundColorPicker)}
          className={twMerge(buttonClassName, className)}
          disabled={disabled}>
          {children ?? <MdOutlineFormatColorFill className="h-5 w-5" />}
        </button>
      </Tooltip>
    </ColorPicker>
  );
};
