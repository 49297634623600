import { GQLError } from "@whyuz/services";
import { DropImageZone, DropImageZoneProps } from "..";
import { PageFormCustomEditableField } from "./PageFormCustomEditableField";

export interface PageFormDropImageZoneProps extends DropImageZoneProps {
  id: string;
  label?: string;
  labelBold?: boolean;
  columns?: number;
  columnsBreakpointSM?: boolean;
  error: GQLError | undefined;
}

export const PageFormDropImageZone = ({
  id,
  label,
  labelBold = true,
  columns,
  columnsBreakpointSM = true,
  error,
  ...props
}: PageFormDropImageZoneProps) => {
  return (
    <PageFormCustomEditableField
      id={id}
      label={label}
      labelBold={labelBold}
      error={error}
      columns={columns}
      columnsBreakpointSM={columnsBreakpointSM}>
      <DropImageZone {...props} />
    </PageFormCustomEditableField>
  );
};
