import React from "react";

export interface ChatBubbleProps extends React.PropsWithChildren {
  position: "left" | "right";
  color: "blue" | "gray";
  userAvatar: React.ReactNode;
  userName: string;
  hourHHMM: string;
  additionalInformation?: string;
  actionButton?: React.ReactNode;
}

export const ChatBubble = ({
  color,
  position,
  userAvatar,
  userName,
  hourHHMM,
  additionalInformation,
  actionButton,
  children,
}: ChatBubbleProps) => {
  return (
    <div className="flex items-start gap-2.5">
      {position === "left" ? <div className="flex-none">{userAvatar}</div> : actionButton}
      <div
        className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200  ${position === "left" ? "rounded-e-xl rounded-es-xl" : "rounded-s-xl rounded-ee-xl"} ${color === "gray" ? "bg-gray-100 dark:bg-gray-700" : "bg-primary-700 dark:bg-primary-700"}`}>
        <div className="flex items-center space-x-2 rtl:space-x-reverse pb-2.5">
          <span
            className={`text-sm font-semibold ${color === "gray" ? "text-gray-900" : "text-white"} dark:text-white`}>
            {userName}
          </span>
          <span
            className={`select-none text-sm font-normal ${color === "gray" ? "text-gray-500 dark:text-gray-400" : "text-gray-200 dark:text-gray-300"}`}>
            {hourHHMM}
          </span>
        </div>
        <div className={`text-sm font-normal ${color === "gray" ? "text-gray-900" : "text-white"} dark:text-white`}>
          {children}
        </div>
        {additionalInformation && (
          <span
            className={`select-none text-sm font-normal ${color === "gray" ? "text-gray-500 dark:text-gray-400" : "text-gray-200 dark:text-gray-300"} ${children && children !== "" ? "pt-2.5" : ""} italic`}>
            {additionalInformation}
          </span>
        )}
      </div>
      {position === "right" && <div className="flex-none">{userAvatar}</div>}
    </div>
  );
};
